import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "boost-pagination" }
const _hoisted_2 = { class: "boost-pagination__controls-row flex justify--between align--center" }
const _hoisted_3 = { class: "boost-pagination__controls flex flex--wrap align--center flex--grow" }
const _hoisted_4 = { class: "margin-right-6" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_7 = ["stroke"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_10 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("strong", null, _toDisplayString(_ctx.items.length), 1),
        _createTextVNode(" results ")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createTextVNode(" Page "),
          _createElementVNode("strong", null, _toDisplayString(_ctx.currentPage), 1),
          _createTextVNode(" of "),
          _createElementVNode("strong", null, _toDisplayString(_ctx.totalPages || 1), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "boost-pagination__button",
            disabled: !_ctx.canGoPrevious,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goPrevious && _ctx.goPrevious(...args)))
          }, [
            (_openBlock(), _createElementBlock("svg", _hoisted_6, [
              _createElementVNode("path", {
                d: "M12.5 15.8334L6.66666 10.0001L12.5 4.16675",
                stroke: !_ctx.canGoPrevious ? '#CBCEDB' : '#18223A',
                "stroke-width": "1.66667",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, 8, _hoisted_7)
            ]))
          ], 8, _hoisted_5),
          _createElementVNode("button", {
            class: "boost-pagination__button",
            disabled: !_ctx.canGoNext,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goNext && _ctx.goNext(...args)))
          }, [
            (_openBlock(), _createElementBlock("svg", _hoisted_9, [
              _createElementVNode("path", {
                d: "M7.5 4.1666L13.3333 9.99993L7.5 15.8333",
                stroke: !_ctx.canGoNext ? '#CBCEDB' : '#18223A',
                "stroke-width": "1.66667",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, 8, _hoisted_10)
            ]))
          ], 8, _hoisted_8)
        ])
      ])
    ])
  ]))
}