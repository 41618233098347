
import { MarketplaceProduct } from '@/models/marketplaceProduct';
import { PropType, Ref, defineComponent, ref, watch } from 'vue';
import ProductsGallery from './ProductsGrid.vue';

export default defineComponent({
  name: 'SearchResultsComponent',
  components: {
    ProductsGallery
  },
  props: {
    products: {
      type: undefined as unknown as PropType<MarketplaceProduct[]>,
      required: false,
      default: undefined
    },
    searchTerm: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const validProducts: Ref<MarketplaceProduct[]> = ref([]);

    watch(
      () => props.products,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (newProducts, _) => {
        validProducts.value = [];
        newProducts?.forEach((product) => {
          const imageObject = new Image();
          imageObject.src = product.imageUrl;

          imageObject.onload = () => {
            validProducts.value.push(product);
          };
        });
      },
      { immediate: true }
    );

    return {
      validProducts
    };
  }
});
