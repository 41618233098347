
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { PaymentOption } from '../../buyer/settings/BuyerSettingsInterfaces';

/**
 * These credit card brands can be found in the Stripe documentation:
 * https://stripe.com/docs/api/cards/object#card_object-brand
 * Further research about each brand shows that these are how the
 * 14, 15, or 16 digits are formatted on each card.
 */
export const CARD_BRAND_DIGIT_FORMAT: { [key: string]: string } = {
  amex: '**** ****** *',
  diners: '**** ******',
  discover: '**** **** ****',
  visa: '**** **** ****',
  mastercard: '**** **** ****',
  jcb: '**** **** ****',
  unionpay: '**** **** ****',
  unknown: ''
};

export function getMaskedCreditCardNumber(
  cardBrand: string | undefined,
  last4Digits: string
): string {
  const cardBrandLower = cardBrand?.toLowerCase();
  if (cardBrandLower) {
    const digitMask = CARD_BRAND_DIGIT_FORMAT[cardBrandLower] ?? '';
    return `${digitMask} ${last4Digits}`.trim();
  }
  return '';
}

export default defineComponent({
  name: 'CreditCardDetailsComponent',
  props: {
    card: {
      type: Object as PropType<PaymentOption>,
      required: true
    },
    hideDefault: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedCardNumber() {
      return getMaskedCreditCardNumber(
        this.card?.brand?.toLowerCase(),
        this.card?.last4
      );
    },
    cardExpirationDate() {
      if (this.card?.expiredMonth && this.card?.expiredYear) {
        const paddedMonth = this.card.expiredMonth.toString().padStart(2, '0');
        return `${paddedMonth}/${this.card.expiredYear.toString().slice(-2)}`;
      }
      return '';
    }
  }
});
