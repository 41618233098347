import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5498c163"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "boost-context-menu" }
const _hoisted_2 = { ref: "button" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "button", {}, () => [
        _createVNode(_component_BoostButton, {
          label: "menu",
          "button-style": "none",
          onClick: _ctx.toggleMenu
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require('../../assets/ellipsis.svg'),
              alt: ""
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["onClick"])
      ], true)
    ], 512),
    (_openBlock(), _createBlock(_Teleport, { to: "#context-menu" }, [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("ul", {
            key: 0,
            ref: "menu",
            style: _normalizeStyle(_ctx.style),
            class: "boost-context-menu__menu",
            onWheel: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
            onTouchmove: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
            onScroll: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedItems, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: _normalizeClass(["boost-context-menu__item", {
            'boost-context-menu__item--disabled':
              item.isDisabled && item.isDisabled(_ctx.entity),
            'boost-context-menu__item--hidden':
              item.isHidden && item.isHidden(_ctx.entity),
            'boost-context-menu__item--danger':
              item.isDangerous && item.isDangerous(_ctx.entity)
          }]),
                onClick: ($event: any) => (_ctx.handleClick(item, _ctx.entity))
              }, _toDisplayString(item.label), 11, _hoisted_4))
            }), 128))
          ], 36))
        : _createCommentVNode("", true),
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "boost-context-menu__bg",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isVisible = false)),
            onWheel: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
            onTouchmove: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
            onScroll: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
          }, null, 32))
        : _createCommentVNode("", true)
    ]))
  ]))
}