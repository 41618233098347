
import { defineComponent } from 'vue';
import Helpers from '@/helpers';
import { OrderStatus } from '@/../models/sellerOrder';
import { Transaction } from '@/../models/transaction';
import { TagType } from '@/../enums/tagType';
import { TransactionStatus } from '@/../enums/transactionStatus';
import { mapGetters } from 'vuex';
import {
  ColumnItem,
  RowItem
} from '@/common/controls/BoostTable/Table.interface';

export default defineComponent({
  name: 'TransactionsListView',
  components: {},
  props: {
    transactions: {
      type: Array as () => Transaction[],
      required: true
    },
    limit: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      TagType,
      OrderStatus,
      columns: [
        {
          id: 'image',
          label: ''
        },
        {
          id: 'direction',
          label: ''
        },
        {
          id: 'name',
          label: 'Name'
        },
        {
          id: 'date',
          label: 'Date'
        },
        {
          id: 'card',
          label: 'Card'
        },
        {
          id: 'total',
          label: 'Total'
        },
        {
          id: 'tax',
          label: 'Tax'
        },
        {
          id: 'net',
          label: 'Net'
        }
      ] as ColumnItem[]
    };
  },

  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    }),
    computedTransactions(): RowItem[] {
      return this.transactions as unknown as RowItem[];
    }
  },
  async mounted() {
    if (!this.currentAccount) {
      return;
    }
  },

  methods: {
    processDate(date: number) {
      return Helpers.processDate(date);
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    },
    getStatusLabel(transaction: Transaction) {
      const status = TransactionStatus.received;
      if (transaction.refunded) {
        return TransactionStatus.refunded;
      }
      if (!transaction.stripeDetails || transaction.state.includes('failed')) {
        return TransactionStatus.failed;
      }
      return status;
    },
    calculateNet(transaction: Transaction) {
      let net = transaction.charges?.total || 0;
      if (transaction.refunded || !transaction.stripeDetails) {
        net = transaction.refundDetails
          ? net - transaction.refundDetails.amount
          : 0;
      } else {
        if (transaction.charges?.tax) {
          net -= transaction.charges?.tax;
        }
      }

      return net;
    },
    isCredit(transaction: Transaction): boolean {
      return !transaction.refunded;
    }
  }
});
