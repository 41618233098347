
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { ShippingAddress } from '@/buyer/settings/BuyerSettingsInterfaces';

export default defineComponent({
  name: 'AddressDetailComponent',
  props: {
    address: {
      type: Object as PropType<ShippingAddress>,
      required: true
    },
    hideDefault: {
      type: Boolean,
      default: false
    }
  }
});
