
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { store } from '@/store';

export default defineComponent({
  name: 'BoostLoader',
  computed: {
    ...mapGetters({
      loadingMessage: 'global/getLoadingMessage',
      isSellerLoggedIn: 'auth/isSellerLoggedIn',
      getCustomColor: 'global/getCustomColor'
    }),
    classObject() {
      const isSellerLoggedIn = store.getters[
        'auth/isSellerLoggedIn'
      ] as boolean;
      const customColor = store.getters['global/getCustomColor'] as string;
      const results: { [k: string]: boolean } = {};
      if (!isSellerLoggedIn && customColor != 'default') {
        results['custom__' + customColor + '__text'] = true;
      }
      return results;
    }
  }
});
