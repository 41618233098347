/**
 * This can be passed a byte array, like from a `File`, and a dataUrl as a `string`.
 *
 * Returns a URL.
 */
export async function uploadImage(image: string | File): Promise<string> {
  const cloudName = 'dgwzk3bjd';
  const formData = new FormData();
  formData.append('file', image);
  formData.append('upload_preset', 'product-images');

  const url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/image/upload';
  return fetch(url, {
    method: 'POST',
    body: formData
  })
    .then((res) => res.json())
    .then((res) => {
      return res.secure_url;
    });
}
