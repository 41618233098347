
import { defineComponent } from 'vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapGetters, mapMutations } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  PopupMessageButtonCaptions,
  PopupMessageStrings,
  PopupMessageTitles
} from '@/common/PopupComponent/PopupMessages';
import ConfirmComponent from '@/common/PopupComponent/ConfirmComponent.vue';

interface ReportData {
  productName: string;
  productTag: string;
  mobileNumber: string;
  date: number;
  status: number;
}

interface LaterAnalyticsData {
  purchased: number;
  conversion: number;
  engagements: number;
  totalLaterProducts: number;
  saved: {
    name: string;
    value: number;
  }[];
  popularProducts: {
    name: string;
    tag: string;
    count: number;
  }[];
  reportData: ReportData[];
}

export default defineComponent({
  name: 'LaterAnalytics',
  components: {
    ConfirmComponent
  },
  data() {
    return {
      cards: [
        {
          title: 'Purchases',
          message: '0'
        },
        {
          title: 'Conversion',
          message: '0%'
        },
        {
          title: 'Engagement',
          message: '0'
        },
        {
          title: 'Product Pages',
          message: '0'
        }
      ],
      detailedCardsOne: [
        {
          title: 'Saved',
          message: '',
          summary:
            'Showing daily breakdown of Saved Products to purchase later using the Later App.',
          details: [] as { name: string; value: string }[]
        },
        {
          title: 'Most Popular Product',
          message: 'N/A',
          summary:
            'Showing breakdown of most popular products that have been saved using the Later App.',
          details: [] as { name: string; value: string }[]
        }
      ],
      reportData: [] as ReportData[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      productSource: 'accounts/selectedFeatureTypeId'
    })
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    async refresh() {
      this.setLoading(true);
      try {
        const payload = {
          accountId: this.currentAccount.accountId,
          productSource: this.productSource
        };
        const responseData =
          await FirebaseRequest.createRequest<LaterAnalyticsData>(
            FirebaseRequestTypes.GET_LATER_ANALYTICS,
            payload
          );

        if (responseData.status === APIResponseStatus.OK && responseData.data) {
          const {
            purchased,
            conversion,
            engagements,
            totalLaterProducts,
            saved,
            popularProducts,
            reportData
          } = responseData.data;
          this.cards[0].message = purchased.toString();
          this.cards[1].message = `${conversion.toLocaleString()}%`;
          this.cards[2].message = engagements.toLocaleString();
          this.cards[3].message = totalLaterProducts.toLocaleString();

          this.detailedCardsOne[0].details = saved.map((s) => ({
            name: s.name,
            value: s.value.toLocaleString()
          }));
          this.detailedCardsOne[0].message =
            saved[saved.length - 1].value.toLocaleString();

          if (popularProducts.length) {
            this.detailedCardsOne[1].details = popularProducts.map((p) => ({
              name: `${p.name} (${p.tag})`,
              value: p.count.toLocaleString()
            }));
            this.detailedCardsOne[1].message = popularProducts[0].name;
          }

          this.reportData = reportData;
        }
      } catch (err) {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        console.error(err);
      }
      this.setLoading(false);
    },
    async showExportDialog() {
      const confirmation = this.$refs.confirmation as typeof ConfirmComponent;
      const confirmDiscard = await confirmation.show(
        PopupMessageTitles.EXPORT,
        PopupMessageStrings.EXPORT_ANALYTICS,
        PopupMessageButtonCaptions.EXPORT,
        true
      );
      if (confirmDiscard) {
        this.createAndDownloadCsvFile();
      }
    },
    createAndDownloadCsvFile() {
      const headers = `Product,Tag,Mobile,Status,Date`;
      const rows = this.reportData.map((row) => {
        const date = new Date(row.date);
        return `${row.productName},${row.productTag},${
          row.mobileNumber ?? ''
        },${row.status},${date.toLocaleDateString()}`;
      });

      const csv = [headers, ...rows].join('\n');

      const filename = `Tags-Shopify-Later-Analytics-${Date.now()}.csv`;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
});
