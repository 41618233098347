
import { defineComponent } from 'vue';

export type ButtonStyle =
  | 'primary'
  | 'submit' // only used by BoostControlFooter, which is not currently used anywhere
  | 'form' // not currently used
  | 'pill'
  | 'optional'
  | 'link'
  | 'black' // not currently used
  | 'badge' // not currently used
  | 'none';

export enum ButtonLoadingState {
  initial = 'initial',
  loading = 'loading',
  complete = 'complete'
}

export default defineComponent({
  name: 'BoostButton',
  props: {
    label: {
      type: String
    },
    buttonStyle: {
      type: String as () => ButtonStyle,
      default: 'primary'
    },
    loadingState: {
      type: String as () => ButtonLoadingState,
      default: ButtonLoadingState.initial
    },
    loadingLabel: {
      type: String,
      default: 'Thinking'
    },
    completeLabel: {
      type: String,
      default: 'Done'
    },
    preventLoadingReset: {
      type: Boolean,
      default: false
    },
    completeStateResetTime: {
      type: Number,
      default: 3000
    },
    type: {
      type: String as () => 'button' | 'submit',
      default: 'button'
    },
    whiteSpinner: {
      type: Boolean,
      default: true
    },
    mithrilSpinner: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  data() {
    return {
      ButtonLoadingState,
      innerLoadingState: ButtonLoadingState.initial as ButtonLoadingState
    };
  },
  watch: {
    loadingState(val: ButtonLoadingState) {
      this.processLoadingState(val);
    }
  },
  mounted() {
    this.processLoadingState(this.loadingState);
  },
  methods: {
    handleClick() {
      // Disable further button clicks while loading in progress
      if (this.innerLoadingState !== ButtonLoadingState.loading) {
        this.$emit('click');
      }
    },
    onLoadingComplete() {
      setTimeout(() => {
        this.innerLoadingState = ButtonLoadingState.initial;
      }, this.completeStateResetTime);
    },
    processLoadingState(val: ButtonLoadingState) {
      if (val === ButtonLoadingState.complete && !this.preventLoadingReset) {
        this.innerLoadingState = this.loadingState;
        this.onLoadingComplete();
      } else {
        this.innerLoadingState = this.loadingState;
      }
    }
  }
});
