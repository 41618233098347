import SellerBase from '@/seller/SellerBase.vue';
import SellerRegistration from '@/seller/registration/SellerRegistration.vue';
import SellerDashboard from '@/seller/SellerDashboard.vue';
import SellerSettings from '@/seller/settings/SellerSettings.vue';
import OrdersComponent from '@/seller/orders/Orders.vue';
import TransactionsComponent from '@/seller/transactions/Transactions.vue';
import ResetPassword from '@/seller/ResetPassword.vue';
import TransactionDetail from '@/seller/transactions/TransactionDetail.vue';
import ProductDetail from '@/seller/products/ProductDetail.vue';
import Products from '@/seller/products/Products.vue';
import PreRegistration from '@/pre-registration/PreRegistration.vue';
import Audience from '@/seller/audience/Audience.vue';
import AudienceList from '@/seller/audience/AudienceList.vue';
import AudienceMember from '@/seller/audience/AudienceMember.vue';
import AudienceAffiliateMember from '@/seller/audience/AudienceAffiliateMember.vue';
import Integrations from '@/seller/integrations/Integrations.vue';
import IntegrationsList from '@/seller/integrations/IntegrationsList.vue';
import AppsList from '@/seller/integrations/AppsList.vue';
import SaveForLater from '@/seller/integrations/SaveForLater.vue';
import Customizations from '@/seller/customizations/Customizations.vue';
import CustomizationSummary from '@/seller/customizations/CustomizationSummary.vue';
import ProductsParent from '@/seller/products/ProductsParent.vue';
import TipEdit from '@/seller/products/TipEdit.vue';
import ProductEdit from '@/seller/products/ProductEdit.vue';
import ProductEditShopify from '@/seller/products/ProductEdit-Shopify.vue';
import ProductEditAttributions from '@/seller/products/ProductEditAttributions.vue';
import ProductEditVariations from '@/seller/products/ProductEditVariations.vue';
import SellerUserInvitation from '@/seller/SellerUserInvitation.vue';
import SellerTransferOwnership from '@/seller/SellerTransferOwnership.vue';
import AttributionList from '@/seller/attribution/AttributionList.vue';
import AnalyticsList from '@/seller/analytics/AnalyticsList.vue';
import OrdersParent from '@/seller/orders/OrdersParent.vue';
import TagsComponent from '@/seller/tags/TagsComponent.vue';
import ShopifyImportProducts from '@/seller/products/ShopifyImportProducts.vue';
import SellerSettingsShopify from '@/seller/settings/SellerSettingsShopify.vue';

export default [
  {
    path: '/store',
    redirect: '/store/dashboard',
    component: SellerBase,
    meta: { requiresSellerAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'seller-home',
        component: SellerDashboard,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showBackToTop: false,
          showSubHeader: true
        }
      },
      {
        path: 'analytics',
        name: 'seller-analytics',
        component: AnalyticsList,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showSubHeader: true
        }
      },
      {
        path: 'settings',
        name: 'seller-settings',
        component: SellerSettings,
        meta: {
          layout: 'OpenDetailLayout',
          showSubHeader: true
        }
      },
      {
        path: 'orders',
        component: OrdersParent,
        children: [
          {
            path: '',
            name: 'seller-orders',
            component: OrdersComponent,
            meta: {
              showHelp: true,
              layout: 'OpenLayout',
              showSubHeader: true
            }
          },
          {
            path: ':orderId',
            name: 'seller-order',
            component: TransactionDetail,
            meta: {
              showHelp: false,
              layout: 'OpenDetailLayout',
              showSubHeader: false
            }
          }
        ]
      },
      {
        path: 'transactions',
        name: 'seller-transactions',
        component: TransactionsComponent,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showSubHeader: true
        }
      },
      {
        path: 'tags',
        name: 'seller-tags',
        component: TagsComponent,
        meta: {
          layout: 'OpenLayout',
          showSubHeader: false,
          showHelp: true
        }
      },
      {
        path: 'products',
        component: ProductsParent,
        children: [
          {
            name: 'seller-products',
            path: '',
            component: Products,
            meta: {
              showHelp: true,
              layout: 'OpenLayout',
              showSubHeader: true
            }
          },
          {
            path: ':productId',
            name: 'seller-product-detail',
            component: ProductDetail,
            meta: {
              showHelp: false,
              layout: 'OpenDetailLayout',
              showSubHeader: false
            }
          },
          {
            path: ':productId/edit',
            name: 'seller-product-edit',
            component: ProductEdit,
            meta: {
              layout: 'OpenDetailLayout'
            }
          },
          {
            path: 'create',
            name: 'seller-product-create',
            component: ProductEdit,
            meta: {
              layout: 'OpenDetailLayout'
            }
          },
          {
            path: 'create-tip',
            name: 'seller-tip-create',
            component: TipEdit,
            meta: {
              layout: 'OpenDetailLayout'
            }
          },
          {
            path: ':productId/shopify/edit',
            name: 'seller-product-edit-shopify',
            component: ProductEditShopify,
            meta: {
              layout: 'OpenDetailLayout'
            }
          },
          {
            path: ':productId/edit/tip',
            name: 'seller-tip-edit',
            component: TipEdit,
            meta: {
              layout: 'OpenDetailLayout'
            }
          },
          {
            path: 'shopify/import',
            name: 'seller-product-import-shopify',
            component: ShopifyImportProducts,
            meta: {
              layout: 'OpenDetailLayout'
            }
          }
        ]
      },
      {
        path: 'attributions',
        name: 'seller-attributions',
        component: AttributionList,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showSubHeader: true
        }
      },
      {
        path: 'editattribution',
        name: 'edit-product-attribution',
        component: ProductEditAttributions,
        meta: {
          layout: 'SingleLayout'
        }
      },
      {
        path: 'editvariations',
        name: 'edit-product-variations',
        component: ProductEditVariations,
        meta: {
          layout: 'SingleLayout'
        }
      },
      {
        path: 'audience',
        component: Audience,
        children: [
          {
            path: '',
            name: 'audience',
            component: AudienceList,
            meta: {
              showHelp: true,
              layout: 'OpenLayout',
              showBackToTop: false,
              showSubHeader: true
            }
          },
          {
            path: 'advocate/:memberId',
            name: 'audience-advocate-member',
            component: AudienceAffiliateMember,
            meta: {
              showHelp: false,
              layout: 'OpenDetailLayout',
              showSubHeader: false
            }
          },
          {
            path: ':memberId',
            name: 'audience-member',
            component: AudienceMember,
            meta: {
              showHelp: false,
              layout: 'OpenDetailLayout',
              showSubHeader: false
            }
          }
        ]
      },
      {
        path: 'integrations',
        component: Integrations,
        children: [
          {
            path: 'integrations',
            name: 'integrations',
            component: IntegrationsList,
            meta: {
              layout: 'OpenLayout',
              showHelp: true,
              showBackToTop: false,
              showSubHeader: false
            }
          },
          {
            path: 'shopify',
            name: 'shopify-integration',
            component: SellerSettingsShopify,
            meta: {
              layout: 'OpenDetailLayout',
              showBackToTop: false
            }
          },
          {
            path: '',
            name: 'integration-apps',
            component: AppsList,
            meta: {
              layout: 'OpenLayout',
              showHelp: true,
              showSubHeader: false
            }
          },
          {
            path: 'saveforlater',
            name: 'integration-apps-save',
            component: SaveForLater,
            meta: {
              layout: 'OpenDetailLayout',
              showHelp: false,
              showSubHeader: false
            }
          }
        ]
      },
      {
        path: 'customizations',
        component: Customizations,
        children: [
          {
            path: '',
            name: 'customizations',
            component: CustomizationSummary,
            meta: {
              showHelp: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/registration/:sellerId',
    name: 'pre-registration',
    component: PreRegistration,
    meta: {
      layout: 'SingleLayout',
      showBackToTop: false
    }
  },
  {
    path: '/store/signup',
    name: 'seller-registration',
    component: SellerRegistration,
    meta: {
      layout: 'SingleLayout',
      showFooter: true,
      showBackToTop: false,
      showHelp: true
    }
  },
  {
    path: '/store/resetPassword',
    name: 'seller-reset-password',
    component: ResetPassword,
    meta: {
      layout: 'SingleLayout'
    }
  },
  {
    path: '/seller/accept',
    name: 'accept-invitation',
    component: SellerUserInvitation,
    meta: {
      layout: 'SingleLayout',
      showBackToTop: false,
      showFooter: true
    }
  },
  {
    path: '/seller/deny',
    name: 'deny-invitation',
    component: SellerUserInvitation,
    meta: {
      layout: 'SingleLayout',
      showBackToTop: false,
      showFooter: true
    }
  },
  {
    path: '/seller/transfer',
    name: 'accept-transfer',
    component: SellerTransferOwnership,
    meta: {
      layout: 'SingleLayout',
      showBackToTop: false,
      showFooter: true
    }
  },
  {
    path: '/seller/denyTransfer',
    name: 'deny-transfer',
    component: SellerTransferOwnership,
    meta: {
      layout: 'SingleLayout',
      showBackToTop: false,
      showFooter: true
    }
  }
];
