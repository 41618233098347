import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-514b49bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stepcard" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "stepcard__wrapper" }
const _hoisted_4 = { class: "stepcard__number" }
const _hoisted_5 = { class: "stepcard__title" }
const _hoisted_6 = { class: "stepcard__message" }
const _hoisted_7 = {
  key: 0,
  class: "stepcard__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.imageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.imageUrl,
          alt: _ctx.number + ' image'
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.number), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.message), 1),
      (_ctx.hasContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}