import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-20 mb-10 flex-col justify-start items-start gap-10 inline-flex font-light" }
const _hoisted_2 = { class: "flex-col justify-start items-start gap-6 flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-nowrap flex-col h-14 justify-start items-start gap-1" }
const _hoisted_5 = { class: "text-gray-900 text-xl" }
const _hoisted_6 = { class: "text-gray-600 text-xl" }
const _hoisted_7 = { class: "flex-col justify-start items-start gap-6 flex" }
const _hoisted_8 = { class: "text-gray-800 text-sm leading-normal line-clamp-3" }
const _hoisted_9 = { class: "justify-start items-start gap-2 inline-flex" }
const _hoisted_10 = { class: "px-2.5 py-[3px] bg-orange-200 rounded-[30px] justify-center items-center gap-px flex text-xs" }
const _hoisted_11 = { class: "text-orange-800 leading-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "w-14 h-14 rounded-lg border border-gray-100 object-cover",
        src: _ctx.selectedProduct.imageUrl
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.selectedProduct.title), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatPrice(_ctx.selectedProduct.price)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.selectedProduct.description), 1),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.brandDetails?.commission ?? 0) + "% Commission ", 1)
        ])
      ])
    ])
  ]))
}