
import { defineComponent } from 'vue';
import {
  BannerType,
  BannerMessageStrings
} from '@/common/BannerComponent/BannerMessages';
import { mapGetters, mapMutations } from 'vuex';
import { store } from '@/store';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';

export default defineComponent({
  name: 'BannerComponent',
  emits: ['update:message'],
  computed: {
    ...mapGetters({
      getBannerStatus: 'global/getBannerStatus',
      currentAccount: 'accounts/currentAccount',
      isBuyerLoggedIn: 'auth/isBuyerLoggedIn'
    }),
    message(): BannerMessageStrings | null {
      return this.getBannerStatus?.text;
    },
    type(): BannerType {
      return this.getBannerStatus?.type || BannerType.info;
    },
    classObject() {
      const results: { [k: string]: boolean } = {
        'banner-component--info': this.type === BannerType.info,
        'banner-component--error':
          this.type === BannerType.error ||
          this.type === BannerType.buyerVerification ||
          this.type === BannerType.paymentFailed,
        'banner-component--stripe--warning':
          this.type === BannerType.stripeWarning
      };
      const isSellerLoggedIn = store.getters['auth/isSellerLoggedIn'];
      if (this.type === 'success') {
        const customColor = store.getters['global/getCustomColor'] as string;
        if (customColor != 'default' && !isSellerLoggedIn) {
          results['custom__' + customColor] = true;
          results['banner-component--animate'] = true;
        } else {
          results['banner-component--success'] = true;
        }
      }

      return results;
    }
  },
  methods: {
    ...mapMutations({
      setBannerStatus: 'global/setBannerStatus',
      setBuyerVerificationAttempted: 'auth/setBuyerVerificationAttempted',
      setLoading: 'global/setLoading'
    }),
    dismissBanner(): void {
      this.setBannerStatus(null);
    },
    gotoSettings(): void {
      this.$router.push({
        name: this.isBuyerLoggedIn ? 'buyer-settings' : 'seller-settings',
        query: { menu: 'stripe' }
      });
    },
    async updateFailedPayment() {
      this.setLoading(true);
      const stripeResult = await FirebaseRequest.createRequest<{
        portalUrl: string;
      }>(FirebaseRequestTypes.GET_SUBSCRIPTION_PORTAL_STRIPE, {
        accountId: this.currentAccount.accountId,
        plan: 'Plus'
      });

      if (
        stripeResult.status === APIResponseStatus.OK &&
        stripeResult.data?.portalUrl
      ) {
        this.setBannerStatus(null);
        window.location.assign(stripeResult.data?.portalUrl);
      } else {
        this.setLoading(false);
      }
    },
    resendBuyerVerification(): void {
      // Optimistic UI: let's assume that this request will succeed.
      // So let's dismiss the banner, fire off the request, set the
      // success message. If needed, we can update the UI with an
      // error message.
      this.setBannerStatus(null);

      // "hasAttemptedVerification" will be set to true when the
      // RESEND_BUYER_VERIFICATION_EMAIL request is sent to the server.
      // But to not have to refresh the currentBuyer with a separate request
      // to GET_BUYER, we set this to true locally. The next time we refresh
      // the currentBuyer, "hasAttemptedVerification" will be true.
      this.setBuyerVerificationAttempted(true);

      FirebaseRequest.createRequest(
        FirebaseRequestTypes.RESEND_BUYER_VERIFICATION_EMAIL,
        {}
      ).then((res) => {
        if (res.status === APIResponseStatus.ERROR) {
          // TODO: we can set an error banner message if needed.
        }
      });

      // Immediately display the success message.
      store.commit('global/setBannerStatus', {
        text: BannerMessageStrings.BUYER_RESEND_VERIFICATION_EMAIL_SUCCESS,
        type: BannerType.info
      });
    }
  }
});
