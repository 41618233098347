import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AudienceState, RootState } from './types';

const audienceState: AudienceState = {
  audienceIds: []
};

const audienceMutations: MutationTree<AudienceState> = {
  setAudienceIds: (state: AudienceState, payload: string[]) => {
    state.audienceIds = payload;
  }
};

const audienceActions: ActionTree<AudienceState, RootState> = {
  getNext(context, currentId: string): string | null {
    const index = context.state.audienceIds.findIndex((x) => x === currentId);
    return index >= context.state.audienceIds.length - 1
      ? null
      : context.state.audienceIds[index + 1];
  },
  getPrevious(context, currentId: string): string | null {
    const index = context.state.audienceIds.findIndex((x) => x === currentId);
    return index <= 0 ? null : context.state.audienceIds[index - 1];
  },
  getCurrentIndex(context, currentId: string): number {
    const index = context.state.audienceIds.findIndex((x) => x === currentId);
    return index;
  }
};

const audienceGetters: GetterTree<AudienceState, RootState> = {
  getAudienceListLength(state): number {
    return state.audienceIds.length;
  }
};

export const audience = {
  mutations: audienceMutations,
  state: audienceState,
  actions: audienceActions,
  getters: audienceGetters,
  namespaced: true
};
