import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03cdc1f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex align--center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "buyer-shipping-content flex flex--column" }
const _hoisted_4 = { class: "flex input-set" }
const _hoisted_5 = { class: "flex input-set margin-bottom-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _component_BoostSectionHeader = _resolveComponent("BoostSectionHeader")!
  const _component_BoostInput = _resolveComponent("BoostInput")!
  const _component_BoostStatePicker = _resolveComponent("BoostStatePicker")!
  const _component_ValidationObserver = _resolveComponent("ValidationObserver")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "#header-back-menu" }, [
      _createVNode(_component_BoostButton, {
        "button-style": "none",
        onClick: _ctx.goBack
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              style: {"width":"16px","height":"16px"},
              src: require('../../assets/cancel-key.svg'),
              alt: "Close"
            }, null, 8, _hoisted_2),
            _createTextVNode(" Cancel ")
          ])
        ]),
        _: 1
      }, 8, ["onClick"])
    ])),
    (!_ctx.addressInfo.id)
      ? (_openBlock(), _createBlock(_component_BoostSectionHeader, {
          key: 0,
          class: "bottom-border",
          title: "Add Shipping Address",
          message: "Make sure that your information is correct before confirming the edited shipping address."
        }))
      : _createCommentVNode("", true),
    (_ctx.addressInfo.id)
      ? (_openBlock(), _createBlock(_component_BoostSectionHeader, {
          key: 1,
          class: "bottom-border",
          title: "Edit Shipping Address",
          message: "Make sure that your information is correct before confirming the new shipping address."
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ValidationObserver, {
      ref: "observer",
      tag: "div"
    }, {
      default: _withCtx(({ meta }) => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BoostInput, {
            modelValue: _ctx.addressInfo.address1,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addressInfo.address1) = $event)),
            "input-id": "address1-input",
            label: "Address line 1",
            placeholder: "Address line 1",
            rules: "required",
            autocomplete: "address-line1"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_BoostInput, {
            modelValue: _ctx.addressInfo.address2,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addressInfo.address2) = $event)),
            "input-id": "address2-input",
            label: "Address line 2",
            placeholder: "Address line 2",
            autocomplete: "address-line2"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BoostInput, {
              modelValue: _ctx.addressInfo.city,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addressInfo.city) = $event)),
              "input-id": "city-input",
              label: "City",
              placeholder: "City",
              rules: "required",
              autocomplete: "address-level2"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_BoostStatePicker, {
              modelValue: _ctx.addressInfo.state,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addressInfo.state) = $event)),
              label: "State",
              rules: "required",
              "input-id": "state-input",
              autocomplete: "address-level1"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BoostInput, {
              modelValue: _ctx.addressInfo.postalCode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addressInfo.postalCode) = $event)),
              "input-id": "postal-code-input",
              label: "Zip",
              placeholder: "Zip",
              rules: "required",
              autocomplete: "postal-code"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_BoostInput, {
              modelValue: _ctx.addressInfo.country,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addressInfo.country) = $event)),
              "input-id": "country-input",
              label: "Country",
              disabled: true
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_BoostButton, {
              label: "Confirm",
              class: "btn-form",
              "button-style": "primary",
              disabled: !meta.valid,
              type: "button",
              "loading-state": _ctx.addAddressButtonState,
              "loading-label": "Adding Address...",
              "complete-label": "Address Added",
              onClick: _ctx.addOrUpdateShippingAddress
            }, null, 8, ["disabled", "loading-state", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}