
import { defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'BoostTextarea',
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String
    },
    rules: {
      type: String
    },
    description: {
      type: String
    },
    rows: {
      type: Number,
      default: 5
    },
    placeholder: {
      type: String
    },
    inputId: {
      type: String,
      required: true
    },
    attributes: {
      type: String
    }
  },
  compatConfig: { COMPONENT_V_MODEL: false },
  emits: ['update:modelValue'],
  setup(props) {
    const { value, errorMessage, handleBlur, handleChange, meta } = useField(
      props.inputId,
      props.rules,
      {
        initialValue: props.modelValue
      }
    );

    watch(
      () => props.modelValue,
      (val) => {
        value.value = val.toString();
      }
    );

    return {
      value,
      handleChange,
      handleBlur,
      errorMessage,
      meta
    };
  },
  computed: {
    isRequired(): boolean {
      if (this.rules) {
        return this.rules.includes('required');
      } else {
        return false;
      }
    },
    showRequiredIndicator(): boolean {
      if (!this.rules || !this.rules.includes('required')) {
        return false;
      }

      return !(
        this.attributes && this.attributes.includes('no-required-indicator')
      );
    }
  },
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.value);
    }
  }
});
