import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "popup-component" }
const _hoisted_2 = { class: "popup-component__wrapper" }
const _hoisted_3 = { class: "popup-component__inner" }
const _hoisted_4 = { class: "popup-component__controls-container" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "popup-component__message"
}
const _hoisted_7 = {
  key: 2,
  class: "popup-component__buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_sanitized = _resolveDirective("sanitized")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.title?.length)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          (_ctx.message?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _withDirectives(_createElementVNode("p", null, null, 512), [
                  [_directive_sanitized, _ctx.message]
                ])
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default"),
          (_ctx.button1 || _ctx.button2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.button1)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: _normalizeClass(["btn popup-component__button", {
                'btn-active': _ctx.button1?.primary,
                'btn-optional': _ctx.button1?.primary === false,
                'btn-destructive': _ctx.button1?.buttonClass === 'destructive'
              }]),
                      type: "button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('button1-event')))
                    }, _toDisplayString(_ctx.button1.caption), 3))
                  : _createCommentVNode("", true),
                (_ctx.button2)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: _normalizeClass(["btn popup-component__button", _ctx.button2?.primary ? 'btn-active' : 'btn-optional']),
                      type: "button",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('button2-event')))
                    }, _toDisplayString(_ctx.button2?.caption), 3))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: "popup-component__overlay",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancel-modal'))),
      onWheel: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
      onTouchmove: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
      onScroll: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
    }, null, 32)
  ]))
}