
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { store } from '@/store';
import { auth } from '@/store/auth';
import Helpers from '@/helpers';
import { Permissions } from '@/store/types';
import FeatureSelector from '@/common/FeatureSelector.vue';

export default defineComponent({
  name: 'SideBar',
  components: {
    FeatureSelector
  },
  data() {
    return {
      Permissions,
      isOpen: false as boolean,
      selectedFeatureTypeId: store.getters[
        'accounts/selectedFeatureTypeId'
      ] as string
    };
  },
  computed: {
    ...mapGetters({
      isBranded: 'global/isBranded',
      getBrandName: 'global/getBrandName',
      getBrandLogo: 'global/getBrandLogo',
      isSuperAdmin: 'auth/isSuperAdmin',
      isImpersonating: 'auth/isImpersonating',
      currentUser: 'auth/getCurrentUser',
      currentBuyer: 'auth/getCurrentBuyer',
      currentAccount: 'accounts/currentAccount',
      isMobileNavOpen: 'global/isMobileNavOpen',
      getBannerStatus: 'global/getBannerStatus'
    }),
    isBuyerLoggedIn(): boolean {
      return store.getters['auth/isBuyerLoggedIn'];
    },
    isAnAffiliate(): boolean {
      return !!this.currentBuyer?.affiliateUsername;
    },
    isSellerLoggedIn(): boolean {
      return store.getters['auth/isSellerLoggedIn'];
    },
    showSellerNav(): boolean {
      if (this.isSuperAdmin) {
        return this.isImpersonating;
      } else {
        return this.isSellerLoggedIn;
      }
    },
    loginOrRegistrationType(): number {
      return auth.state.loginOrRegistrationType;
    },
    isCurrentPageExternalStore(): boolean {
      return this.$route.name === 'Store';
    },
    currentRouteName(): string {
      return (this.$route.name as string) || '';
    },
    currentRoutePath(): string {
      return this.$route.path || '';
    },
    hasBrandLogo(): boolean {
      return !this.getBrandLogo === ('' as unknown as boolean);
    },
    logoImage(): string {
      return (
        this.currentAccount?.logoUrl ||
        this.currentBuyer?.imageUrl ||
        '@/assets/default-product.jpg'
      );
    },
    showHelp(): boolean {
      if (this.isBuyerLoggedIn) {
        return true;
      } else {
        return this.currentRoutePath !== 'pre-registration';
      }
    },
    showLogin(): boolean {
      if (this.isBuyerLoggedIn) {
        return true;
      } else {
        return this.currentRoutePath !== 'pre-registration';
      }
    },
    isTagsActive(): boolean {
      return (
        this.currentRoutePath.includes('tags') ||
        this.currentRoutePath.includes('tips') ||
        this.currentRouteName === 'seller-product-create' ||
        this.currentRouteName === 'seller-tip-create'
      );
    },
    accountRoutePath(): string {
      if (this.isBuyerLoggedIn) {
        return '/user/settings';
      } else {
        return Helpers.userHasPermission(Permissions.Settings, store)
          ? '/store/settings'
          : '';
      }
    },
    isBannerShowing(): boolean {
      return !!this.getBannerStatus?.text;
    },
    showSettingsArrow(): boolean {
      return (
        this.isBuyerLoggedIn ||
        Helpers.userHasPermission(Permissions.Settings, store)
      );
    }
  },
  methods: {
    help() {
      window.open('https://support.tagscommerce.com/hc/en-us', '_blank');
    },
    logout() {
      this.$store.dispatch('auth/signOut').then((success: boolean) => {
        if (success) {
          window.location.href = '/login';
        }
      });
    },
    handleFeatureChange(featureId: string) {
      this.selectedFeatureTypeId = featureId;
    }
  }
});
