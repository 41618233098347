// NOTE: Copy this file to `settings.ts` and fill in all of the relevant keys
const settings = {
  NODE_ENV: 'production',
  FIREBASE_API_KEY: 'AIzaSyDWjEpWmEx4Q1dRc92MnLq_Y2tixH4fcuY',
  FIREBASE_URL: 'https://boost-production.firebaseio.com',
  FIREBASE_CLOUD_FUNCTIONS_URL: 'https://us-central1-boost-production.cloudfunctions.net',
  STRIPE_APP_ID: 'ca_7uq3moADysFgmST434AsHD6mGy9g9H5z',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_tRimdMVpSqSCxvrSLE8vy05a',
  AMPLITUDE_KEY: '8d68bde32bc3a6000beaf964e340c9ce'
};

export default settings;
