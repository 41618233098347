/**
 * Banner Enums
 * Prefix the const with the area that it will be used within i.e 'BUYER_'
 */
export enum BannerMessageStrings {
  CHANGES_UPDATED = 'Your changes have been saved!',
  MISSING_FIELDS = 'Some fields are missing! Take a look before continuing.',
  INVALID_PHONE_NUMBER = "Oops, that doesn't look good. Enter a valid number to continue.",
  PHONE_NUMBER_IN_USE = 'This phone number is already in use.',
  GENERIC_ERROR_MESSAGE = 'Hmm. Something seems to be wrong. Please refresh this page.',
  GENERIC_SUCCESS = 'Success!',
  GENERIC_COPIED_CLIPBOARD = 'Copied to the clipboard',
  GENERIC_SIZE_EXCEEDED = 'Max size exceeded. Please try again with a smaller file.',
  GENERIC_INVALID_FILE = 'Invalid file uploaded. Please try again with a recommended file type.',
  INVALID_PHONE_NUMBER_SIGNUP = 'Please enter a valid U.S phone number to sign up.',

  BUYER_AGE_RESTRICTION = 'You are below the age of 21. Some products may be restricted.',
  BUYER_CARD_NOT_SUPPORTED = "That card isn't support on Tags. Please add another payment method.",
  BUYER_CARD_REMOVED = 'You have removed a card from your account.',
  BUYER_CODE_EXPIRED = 'That code has expired. We might need to resend the code.',
  BUYER_FINISHED_SETUP = "Great, that was easy! You're all set up.",
  BUYER_FORM_ERROR = 'There seems to be an issue with some information. Try again?',
  BUYER_INCORRECT_PHONE_NUMBER = "That's not your mobile number. Enter your correct number to deactivate.",
  BUYER_INVALID_CODE = 'That code is incorrect. Try again?',
  BUYER_LOGIN_INVALID_PHONE_NUMBER = 'Please enter a valid U.S phone number.',
  BUYER_NEW_CARD_ADDED = "You've added a new card to your account!",
  BUYER_NEW_TRACKING_INFO = "You've received some new tracking information, take a look!",
  BUYER_NO_PURCHASES = "You haven't purchased anything yet. Come back when you have!",
  BUYER_ONLY_US_SHIPPING_ALLOWED = 'We currently only support US shipping at this time - Read more.',
  BUYER_REMOVE_DEFAULT_ADDRESS_NOT_ALLOWED = 'You cannot remove default address.',
  BUYER_REMOVE_DEFAULT_PAYMENT_NOT_ALLOWED = 'You cannot remove default payment method.',
  BUYER_EMAIL_LOGIN_ERROR = 'There seems to be a problem with your email or password. Try again?',
  BUYER_PHONE_NUMBER_LOGIN_ERROR = "We don't recognize that number. Try again?",
  BUYER_PURCHASE_ERROR = 'Hmm, something went wrong. Please check if your information is correct.',
  BUYER_PURCHASE_INVALID_ADDRESS = 'Please enter a valid U.S shipping address.',
  BUYER_RECEIPT_ERROR = 'Hmm. Something seems to be wrong. Refresh this page.',
  BUYER_REENTERED_PHONE_NUMBER = "That's the same number as before. Enter a new number or leave as it is.",
  BUYER_REGISTRATION_INVALID_ADDRESS = "Something doesn't feel right. Is that a real US address? Try again.",
  BUYER_SENDING_CONFIRMATION_CODE = 'Awesome! We are sending you a confirmation code.',
  BUYER_SENDING_LOGIN_CODE = "Awesome. We're sending you a login code!",
  BUYER_SETTINGS_INVALID_ADDRESS = "We don't recognize that address. Try again?",
  BUYER_SETTINGS_INVALID_PHONE_NUMBER = 'That number feels wrong. Try again.',
  BUYER_TOO_MANY_CARDS_ADDED = "You can't add any more cards to this account. Remove some to add more.",
  BUYER_SETTINGS_EMAIL_INVALID = "That email doesn't look right. Try again?",
  BUYER_SETTINGS_IMAGE_UPLOADED = 'Your Avatar has been uploaded.',
  BUYER_NO_INVENTORY = 'Sorry, this product is out of stock.',
  BUYUER_PURCHASE_OWN_TAG = 'Sorry, you cannot buy products using your own Tags!',
  BUYER_PRODUCT_NOT_FOUND = 'Sorry, we cannot find the product that you are looking for.',
  BUYER_PRODUCT_INACTIVE = 'Sorry, this product is currently unavailable.',
  BUYER_ACCOUNT_UNVERIFIED = 'Click here to verify your account and get the most out of Tags!',
  BUYER_BANNER_CONNECT_STRIPE = 'Connect Stripe to withdraw your earnings!',
  BUYER_RESEND_VERIFICATION_EMAIL_SUCCESS = 'Done! Check your email address for verification instructions.',
  BUYER_AFFILIATE_WITHDRAW_SUCCESS = 'Your withdrawal has been initiated. It can take up to 1-3 business days for the money to arrive in your account.',

  SELLER_ACCOUNT_DISABLED = 'Your account has been disabled.',
  SELLER_BRAND_IMAGE_UPLOADED = 'Your brand image was uploaded successfully',
  SELLER_CARD_NOT_SUPPORTED = 'We currently only accept Visa, Mastercard, Amex & Discover. Try again?',
  SELLER_CARD_UPDATED = 'Your card has been successfully updated!',
  SELLER_EMAIL_ALREADY_IN_USE = 'The email address is already in use.',
  SELLER_EMAIL_LOGIN_ERROR = 'There seems to be a problem with your email or password. Try again?',
  SELLER_ERROR_CREATING_ACCOUNT = 'There was an error while creating your account.  Please try again.',
  SELLER_ERROR_RESERVING_TAG = 'There was an error while reserving your tag. Please try again.',
  SELLER_INCORRECT_TAG = "That's not your tag! Enter your tag to deactivate.",
  SELLER_INVALID_CARD = 'You must have a valid card registered to your account.',
  SELLER_INVALID_EMAIL = "That email doesn't look right. Try again?",
  SELLER_INVALID_TAG = 'The tag can only consist of letters. Try again?',
  SELLER_INVALID_ZIP_CODE = "That zip code doesn't look right. Try again?",
  SELLER_NEW_PRODUCTS_WAITING = "You have new products waiting to be viewed & sorted. Great, that was easy! You're all set ",
  SELLER_NO_TAG_ENTERED = "You haven't added a tag yet. Register a tag to continue.",
  SELLER_PASSWORD_EMAIL_SENT = 'Password reset email sent, check your inbox.',
  SELLER_PASSWORDS_DO_NOT_MATCH = "The passwords don't match, that's awkward. Try again?",
  SELLER_PRODUCT_ADDED_SIMILAR_OPTION = "You've already added a similar option",
  SELLER_PRODUCT_ALREADY_ADDED_OPTION_VERSION = "You've already entered that {0}. Enter a new one.",
  SELLER_PRODUCT_INVALID_OPTION = 'Options should only contain letters.',
  SELLER_PRODUCT_IMAGE_UPLOADED = 'Image uploaded successfully!',
  SELLER_PRODUCT_OPTION_REMOVED = 'You have removed the {0} option.',
  SELLER_PRODUCT_READY_TO_PUBLISH = 'Your product is ready to publish!',
  SELLER_PRODUCT_STOCK_LEVEL_ALERT = 'There are a few alerts regarding stock level',
  SELLER_RECENT_NEW_PRODUCT = "You've recently added a new product!",
  SELLER_SETTINGS_INVALID_ADDRESS = "That address that doesn't look familiar. Try again?",
  SELLER_STRIPE_ACCOUNT_ERROR = "We're sorry but there was an error while connecting to Stripe.  Please try again later.\n\nError: {0}",
  SELLER_STRIPE_NOT_CONNECTED = 'In order for your products to start selling please connect your Stripe account within your account settings.',
  SELLER_STRIPE_ACCOUNT_RECONNECTED = 'Your Stripe account has been reconnected successfully!',
  SELLER_TAG_AVAILABLE = 'Awesome! That tags available!',
  SELLER_TAG_UNAVAILABLE = 'Darn, that tag has already gone! Try again.',
  SELLER_SETTINGS_STRIPE_NOT_CONNECTED = 'Your account is not active. To enable your account please Connect Stripe.',
  SELLER_SETTINGS_STRIPE_CONNECTED = 'Your Stripe account is connected and your account is now enabled!',
  SELLER_NEED_CONNECT_STRIPE = 'Connect Stripe first to start using Tags!',
  SELLER_SETTINGS_PLAN_UPGRADE = 'Congratulations! Your account has been upgraded to the Plus plan.',
  SELLER_PLAN_PAYMENT_FAILED = 'We were not able to process your recent payment for the Plus plan.',

  SHOPIFY_ERROR_SYNCING = 'Error syncing products',
  SHOPIFY_TEST_CONNECT_SUCCESS = 'The connection looks good! Please proceed to Connect.',
  SHOPIFY_CONNECTED = 'Shopify is now connected! Head over to the Products page to import your inventory.',
  SHOPIFY_ERROR = 'An error occurred communicating with Shopify',
  SHOPIFY_TAGS_ADDED = 'Shopify products added to Tags and a new filter has been added!',

  STRIPE_NOT_LOADED = 'The credit card service is not work. Refresh and try again.',
  STRIPE_DISCONNECTED = 'Stripe has been disconnected from your account.'
}

export enum BannerType {
  info = 'info',
  error = 'error',
  success = 'success',
  stripeWarning = 'stripe-warning',
  buyerVerification = 'buyer-verification',
  paymentFailed = 'payment-failed'
}

export interface BannerMessage {
  text: BannerMessageStrings;
  type?: BannerType;
}
