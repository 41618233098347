
import { defineComponent } from 'vue';
import {
  PreRegAccount,
  PreRegStatus
} from '@/pre-registration/PreRegistration.vue';
import { store } from '@/store';
export default defineComponent({
  name: 'PreRegAccount',
  props: {
    account: {
      type: Object as () => PreRegAccount,
      required: true
    },
    status: {
      type: String as () => PreRegStatus
    }
  },
  emits: ['update:account', 'next'],
  data() {
    return {};
  },
  computed: {
    customColor(): string {
      return store.getters['global/getCustomColor'] as string;
    }
  },
  methods: {
    saveAccountSetup() {
      this.$emit('update:account', this.account);
      this.$emit('next');
    }
  }
});
