import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6bd6a60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "boost-tag-card flex align--center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "boost-tag-card__content" }
const _hoisted_4 = { class: "text-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "rounded-image rounded-image--medium cursor-hand boost-tag-card__avatar",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showProduct(_ctx.productId)))
    }, [
      _createElementVNode("img", {
        src: _ctx.avatar,
        alt: "image"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.price), 1)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _renderSlot(_ctx.$slots, "action", {}, undefined, true)
  ]))
}