import { Charges } from './charges';
import { TagType } from '@/enums/tagType';

export enum ProductSource {
  Boost = 'Boost',
  Shopify = 'Shopify'
}

export interface SellerOrder {
  accountId: string;
  transactionId: string;
  userTransactionId: string;
  userId: string | null;
  productId: string;
  attributionId?: string;
  created: string;
  fulfilled: boolean;
  refunded: boolean;
  status: OrderStatus;
  provider: string;
  productHashtag: string;
  productImageUrl: string;
  productDescription: string;
  productTitle: string;
  productOptions: KeyValue[];
  productPrice: string;
  productWeight: string;
  sku: string;
  userEmail: string;
  userMobileNumber: string;
  shippedToFirstName: string;
  shippedToLastName: string;
  shippedToAddress1: string;
  shippedToAddress2: string;
  shippedToCity: string;
  shippedToState: string;
  shippedToPostalCode: string;
  userCarrier: string;
  userTrackingNo: string;
  charges: Charges;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripeDetails: any;
  type: TagType;
  productSource: ProductSource;
}

export interface KeyValue {
  key: string;
  value: string;
}

export enum OrderStatus {
  pending,
  fulfilled,
  refunded,
  failed
}
