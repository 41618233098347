import { App } from 'vue';
import { createApp } from 'vue';
import Application from './App.vue';
import router from './router';
import { store } from './store';
import { RoleAccount, UserRegistration } from './store/types';
import { sync } from 'vuex-router-sync';
import {
  registerCommmonComponents,
  registerThirdPartyComponents
} from '@/common';
import './validationConfig';
import { getAuth } from 'firebase/auth';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from './firebaseRequest';
import { Buyer } from './buyer/settings/BuyerSettingsInterfaces';
import { Role } from '@/models/role';

import { initializeApp } from 'firebase/app';
import config from '@/firebaseInit';

initializeApp(config);

// Sync vue-router's current $route as part of vuex store's state.
sync(store, router);

let app: App;

getAuth().onAuthStateChanged(async () => {
  if (!app) {
    // firebase might already be logged in, so we want
    // to update the store initially
    const user = getAuth().currentUser;
    store.commit('auth/setCurrentUser', user);
    if (user) {
      try {
        const getRoleResponse = await FirebaseRequest.createRequest<Role>(
          FirebaseRequestTypes.GET_LOGGED_IN_USER_ROLE,
          {}
        );
        if (getRoleResponse.data) {
          const role = getRoleResponse.data;
          const roleAccounts: RoleAccount[] = [];
          if (role.accounts) {
            for (const key of Object.keys(role.accounts)) {
              roleAccounts.push({
                accountId: key,
                role: role.accounts[key]
              });
            }
          }

          // determine if user is a seller
          if (role.email && role.userId) {
            store.commit(
              'auth/setLoginOrRegistrationType',
              UserRegistration.SELLER
            );
          }

          store.commit('auth/setRoleAccounts', roleAccounts);
          store.commit('auth/setSuperAdmin', role.admin ? true : false);
          await store.dispatch('accounts/loadAccounts');
        }
      } catch (error) {
        // It's possible that a user doesn't have a role.
        // In that case the RoleService throws.
        store.commit('auth/setSuperAdmin', false);
      }
      if (store.getters['auth/isBuyerLoggedIn']) {
        const buyerResponse = await FirebaseRequest.createRequest<Buyer>(
          FirebaseRequestTypes.GET_BUYER_INFO,
          {}
        );
        if (
          buyerResponse.status === APIResponseStatus.OK &&
          buyerResponse.data
        ) {
          store.commit('auth/setCurrentBuyer', buyerResponse.data);
        }
      }
    }

    app = createApp(Application);

    registerThirdPartyComponents(app);
    registerCommmonComponents(app);

    app.use(router);
    app.use(store);

    app.mount('#app');
  }
});
