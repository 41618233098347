
import { defineComponent } from 'vue';
import {
  Switch as SwitchComponent,
  SwitchGroup,
  SwitchLabel
} from '@headlessui/vue';

export default defineComponent({
  name: 'TagsToggle',
  components: {
    SwitchComponent,
    SwitchGroup,
    SwitchLabel
  },
  props: {
    description: {
      type: String
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  mounted() {
    console.log('TagsToggle: > ', this.modelValue);
  }
});
