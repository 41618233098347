
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoostSectionHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    hasContent: {
      type: Boolean,
      default: false
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    }
  }
});
