import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b98915e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify--between" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "boost-textarea__description"
}
const _hoisted_5 = { class: "boost-textarea__input-wrapper" }
const _hoisted_6 = ["id", "name", "rows", "placeholder"]
const _hoisted_7 = {
  key: 1,
  class: "boost-textarea__error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["boost-textarea", {
      'boost-textarea--has-label': _ctx.value
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["boost-textarea__element flex flex--column", {
        'boost-textarea--error': _ctx.errorMessage
      }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", {
          class: "boost-textarea__label",
          for: _ctx.inputId
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired && _ctx.showRequiredIndicator)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2),
        _renderSlot(_ctx.$slots, "link", {}, undefined, true)
      ]),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("textarea", _mergeProps(_ctx.$attrs, {
          id: _ctx.inputId,
          ref: "textarea",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          class: "boost-textarea__textarea",
          name: _ctx.name,
          rows: _ctx.rows,
          placeholder: _ctx.placeholder,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }), null, 16, _hoisted_6), [
          [
            _vModelText,
            _ctx.value,
            void 0,
            { trim: true }
          ]
        ])
      ]),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
            _createElementVNode("li", null, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}