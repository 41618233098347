
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import helpers from '@/helpers';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      refreshButtonState: ButtonLoadingState.initial,
      cards: [
        {
          title: 'Commission Earnings',
          message: '$0.00'
        },
        {
          title: 'Sales',
          message: '0'
        },
        {
          title: 'Conversion',
          message: '0%'
        },
        {
          title: 'Tags',
          message: '0'
        }
      ],
      detailedCardsOne: [
        {
          title: 'Most Popular Checkout Method',
          message: '',
          summary:
            'Showing breakdown of the various checkout methods in (%). The order is defined by highest to lowest.',
          details: [
            { name: 'Link to Checkout', value: '0%' },
            { name: 'QR to Checkout', value: '0%' },
            { name: 'SMS to Checkout', value: '0%' }
          ]
        },
        {
          title: 'Total Sessions',
          message: '0',
          summary:
            'Showing breakdown of Total Sessions by stage reached at the checkout page.',
          details: [
            { name: 'Successful', value: '0' },
            { name: 'Unfinished', value: '0' }
          ]
        }
      ],
      detailedCardsTwo: [
        {
          title: 'Most Popular Brand',
          message: '',
          summary:
            'Showing the most popular brand based on products purchased.',
          details: [
            { name: '1st', value: '' },
            { name: '2nd', value: '' },
            { name: '3rd', value: '' }
          ]
        },
        {
          title: 'Most Popular Product',
          message: '',
          summary:
            'Showing the most popular products based on products purchased.',
          details: [
            { name: '1st', value: '' },
            { name: '2nd', value: '' },
            { name: '3rd', value: '' }
          ]
        }
      ]
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.refreshButtonState = ButtonLoadingState.loading;

      const responseData = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_AFFILIATE_ANALYTICS,
        {}
      );

      if (responseData.status === APIResponseStatus.OK) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const st = responseData.data as any;
        this.cards[0].message = helpers.currencyFormatter(st.grossRevenue);
        this.cards[1].message = st.salesCount;
        this.cards[2].message = st.conversionRate + '%';
        this.cards[3].message = st.tagCount;

        this.detailedCardsOne[0].message = st.checkouts.mostPopular;
        this.detailedCardsOne[0].details[0].value =
          st.checkouts.percents[0].value;
        this.detailedCardsOne[0].details[1].value =
          st.checkouts.percents[1].value;
        this.detailedCardsOne[0].details[2].value =
          st.checkouts.percents[2].value;

        this.detailedCardsOne[1].message = st.sessions.total;
        this.detailedCardsOne[1].details[0].value = st.sessions.counts[0].value;
        this.detailedCardsOne[1].details[1].value = st.sessions.counts[1].value;

        this.detailedCardsTwo[0].message = st.popularBrands.first;
        this.detailedCardsTwo[0].details[0].value = st.popularBrands.first;
        this.detailedCardsTwo[0].details[1].value = st.popularBrands.second;
        this.detailedCardsTwo[0].details[2].value = st.popularBrands.third;

        this.detailedCardsTwo[1].message = st.popularProducts.first;
        this.detailedCardsTwo[1].details[0].value = st.popularProducts.first;
        this.detailedCardsTwo[1].details[1].value = st.popularProducts.second;
        this.detailedCardsTwo[1].details[2].value = st.popularProducts.third;
      }

      this.refreshButtonState = ButtonLoadingState.complete;
    }
  }
});
