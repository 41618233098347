
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TagsTextField',
  props: {
    text: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
});
