
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserActions',
  async mounted() {
    const actionCode = this.$route.query.oobCode;
    switch (this.$route.query.mode) {
      case 'resetPassword':
        this.$router.push({
          path: '/store/resetPassword',
          query: { actionCode }
        });
        break;
      default:
        this.$router.push({ path: '/' });
    }
  }
});
