import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex align--center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "pre-registration flex flex--wrap flex--column align--center no-pad margin-top-10" }
const _hoisted_4 = {
  key: 0,
  class: "t-4"
}
const _hoisted_5 = { class: "pre-registration__error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Stepper = _resolveComponent("Stepper")!
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _component_PreRegEntry = _resolveComponent("PreRegEntry")!
  const _component_PreRegAccount = _resolveComponent("PreRegAccount")!
  const _component_PreRegShipping = _resolveComponent("PreRegShipping")!
  const _component_PreRegCard = _resolveComponent("PreRegCard")!
  const _component_PreRegComplete = _resolveComponent("PreRegComplete")!
  const _component_PageNotFoundComponent = _resolveComponent("PageNotFoundComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "#stepper-indicator" }, [
      (_ctx.currentStep > 1 && _ctx.currentStep < 5)
        ? (_openBlock(), _createBlock(_component_Stepper, {
            key: 0,
            steps: 3,
            "current-step": _ctx.currentStep - 1
          }, null, 8, ["current-step"]))
        : _createCommentVNode("", true)
    ])),
    (_ctx.currentStep > 2 && _ctx.currentStep < 5)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#header-back-menu"
        }, [
          _createVNode(_component_BoostButton, {
            key: "account_back",
            "button-style": "none",
            onClick: _ctx.previousStep
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: require('../assets/arrow-left.svg'),
                  alt: "Back arrow"
                }, null, 8, _hoisted_2),
                _createTextVNode(" " + _toDisplayString(_ctx.currentStep === 3 ? 'Create account' : 'Shipping'), 1)
              ])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.sellerAccount)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.sellerAccount && _ctx.currentStep === 1)
              ? (_openBlock(), _createBlock(_component_PreRegEntry, {
                  key: 0,
                  "mobile-number": _ctx.mobileNumber,
                  "onUpdate:mobile-number": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mobileNumber) = $event)),
                  seller: _ctx.sellerAccount,
                  status: _ctx.status,
                  onError: _ctx.handleError,
                  onNext: _ctx.captureNumber
                }, null, 8, ["mobile-number", "seller", "status", "onError", "onNext"]))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_PreRegAccount, {
              account: _ctx.account,
              "onUpdate:account": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.account) = $event)),
              status: _ctx.status,
              onError: _ctx.handleError,
              onNext: _ctx.nextStep
            }, null, 8, ["account", "status", "onError", "onNext"]), [
              [_vShow, _ctx.currentStep === 2]
            ]),
            _withDirectives(_createVNode(_component_PreRegShipping, {
              shipping: _ctx.shipping,
              "onUpdate:shipping": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shipping) = $event)),
              status: _ctx.status,
              seller: _ctx.sellerAccount,
              loading: _ctx.getCodeState,
              onError: _ctx.handleError,
              onNext: _ctx.createSetupIntent,
              onBack: _ctx.previousStep
            }, null, 8, ["shipping", "status", "seller", "loading", "onError", "onNext", "onBack"]), [
              [_vShow, _ctx.currentStep === 3]
            ]),
            (_ctx.currentStep === 4 && _ctx.stripe)
              ? (_openBlock(), _createBlock(_component_PreRegCard, {
                  key: 1,
                  stripe: _ctx.stripe,
                  status: _ctx.status,
                  loading: _ctx.getCodeState,
                  seller: _ctx.sellerAccount,
                  onError: _ctx.handleError,
                  onNext: _ctx.captureCard,
                  onBack: _ctx.previousStep
                }, null, 8, ["stripe", "status", "loading", "seller", "onError", "onNext", "onBack"]))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_PreRegComplete, {
              seller: _ctx.sellerAccount,
              "pre-reg-info": _ctx.preRegistrationUserInfo,
              "mobile-number": _ctx.mobileNumber,
              status: _ctx.status
            }, null, 8, ["seller", "pre-reg-info", "mobile-number", "status"]), [
              [_vShow, _ctx.sellerAccount && _ctx.currentStep === 5]
            ])
          ], 64))
        : (_openBlock(), _createBlock(_component_PageNotFoundComponent, { key: 2 }))
    ])
  ], 64))
}