
import { defineComponent } from 'vue';
import HeaderComponent from '@/common/HeaderComponent.vue';
import BannerComponent from '@/common/BannerComponent/BannerComponent.vue';
import SideBar from '@/common/SideBar.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    HeaderComponent,
    BannerComponent,
    SideBar
  },
  data() {
    return {
      isMounted: false
    };
  },
  computed: {
    ...mapGetters({
      isMobileNavOpen: 'global/isMobileNavOpen',
      getBannerStatus: 'global/getBannerStatus'
    }),
    isBannerShowing(): boolean {
      return !!this.getBannerStatus?.text;
    },
    showSubHeader(): boolean {
      return this.$route.meta.showSubHeader as boolean;
    }
  },
  mounted() {
    this.isMounted = true;
  }
});
