
import { defineComponent } from 'vue';
import { uploadImage } from '@/../dataService/cloudinary';
import { SelectOption } from '@/common/controls/BoostSelect/Select.interface';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import Helpers from '@/helpers';
import { TagType } from '@/../enums/tagType';
import { TipTag } from '@/../models/tag';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapMutations } from 'vuex';
import { Keyword } from '@/common/controls/BoostKeywords.vue';
import { ProductSource } from '@/../models/sellerOrder';
import ConfirmComponent from '@/common/PopupComponent/ConfirmComponent.vue';
import {
  PopupMessageTitles,
  PopupMessageStrings,
  PopupMessageButtonCaptions
} from '@/common/PopupComponent/PopupMessages';
import { AccountObject } from '@/models/accountObject';

export default defineComponent({
  name: 'TipEdit',
  components: {
    ConfirmComponent
  },
  async beforeRouteLeave() {
    if (this.originalValue === JSON.stringify(this.tip)) {
      return true;
    } else {
      const confirmation = this.$refs.confirmation as typeof ConfirmComponent;
      const confirmed = await confirmation.show(
        PopupMessageTitles.UNSAVED_CHANGES,
        PopupMessageStrings.UNSAVED_CHANGES,
        PopupMessageButtonCaptions.DISCARD_CHANGES
      );

      return confirmed;
    }
  },
  data() {
    return {
      originalValue: '',
      tipEnabled: false,
      yesNoOptions: [
        { value: true, name: 'Yes' },
        { value: false, name: 'No' }
      ] as SelectOption[],
      amounts: [] as Keyword[],
      amountError: [] as string[],
      publishButtonState: ButtonLoadingState.initial,
      saveButtonState: ButtonLoadingState.initial,
      tip: null as null | TipTag
    };
  },
  computed: {
    productId(): string {
      if (this.$route.params.productId) {
        return this.$route.params.productId as string;
      }
      return '';
    },
    currentAccount(): AccountObject | null {
      if (
        this.$store.state.accounts &&
        this.$store.state.accounts.currentAccount
      ) {
        return this.$store.state.accounts.currentAccount;
      }
      return null;
    },
    saveLabel(): string {
      // TODO: see if this can be simplified once the save flow is clearer
      const isEditing = !!this.tip;
      if (isEditing && !this.tip?.disabled) {
        return 'Done';
      } else {
        return 'Save';
      }
    },
    amountErrorString(): string {
      return this.amountError?.length ? this.amountError.join(', ') : '';
    },
    isSaveDisabled(): boolean {
      return (
        this.tip?.title.length === 0 ||
        this.amounts.length === 0 ||
        this.amounts.length > 5
      );
    }
  },
  watch: {
    tipEnabled(newValue) {
      if (newValue != null) {
        if (this.tip) {
          this.tip.disabled = !newValue;
        }
      }
    },
    amounts: {
      deep: true,
      handler(newValue) {
        if (this.tip && newValue) {
          this.tip.amounts = newValue.map(
            (amount: Keyword) => +amount.label.toString().replace('$', '')
          );
        }
      }
    }
  },
  async mounted() {
    if (!this.$route.params.productId) {
      if (this.currentAccount) {
        this.tip = {
          imageUrl: require('../../assets/default-product.jpg'),
          amounts: [],
          accountId: this.currentAccount?.accountId,
          productId: '',
          hashtag: '',
          title: '',
          description: '',
          productSource: ProductSource.Boost,
          type: TagType.TIP,
          totalSales: 0,
          quantitySold: 0,
          savedForLaterCount: 0,
          isFeatured: false,
          attributions: [],
          chargeTax: false,
          disabled: true
        };
      }
      this.initializeForm();
      return;
    }

    this.setLoading(true);
    await this.getTip();
    this.initializeForm();
  },

  methods: {
    ...mapMutations('global', ['setLoading']),
    ...mapMutations('product', ['setCurrentProduct']),

    isInvalid(invalid: boolean): boolean {
      // If form invalid then is invalid
      if (invalid) {
        return invalid;
      }
      // default
      return false;
    },
    async getTip() {
      const responseData = await FirebaseRequest.createRequest<TipTag>(
        FirebaseRequestTypes.GET_SELLER_TAG,
        {
          accountId: this.currentAccount?.accountId,
          productId: this.productId
        }
      );
      if (responseData.status === APIResponseStatus.OK) {
        this.tip = responseData.data as TipTag;

        this.setLoading(false);
      }
    },
    initializeForm() {
      if (!this.tip) {
        return;
      }
      const tip: TipTag = this.tip;

      // penny amounts -> dollars -> strings
      const amounts = tip.amounts.map((amount: number, index) => {
        return {
          id: index,
          label: amount,
          isLocked: false
        } as Keyword;
      });
      this.tipEnabled = !this.tip.disabled;
      this.amounts = amounts || [];
      this.originalValue = JSON.stringify(this.tip);
    },

    async commitChanges() {
      if (!this.tip) {
        return;
      }

      this.saveButtonState = ButtonLoadingState.loading;
      if (
        this.tip.imageUrl &&
        /^data:image\/.*?;base64,/.test(this.tip.imageUrl)
      ) {
        // We have to write this image to the database and replace it with the URL
        this.tip.imageUrl = await uploadImage(this.tip.imageUrl);
      }

      // convert to pennies
      const amounts = this.amounts.map(
        (amount: Keyword) => +amount.label.toString().replace('$', '')
      );

      const tipData = {
        accountId: this.currentAccount?.accountId,
        productId: '',
        totalSales: 0,
        quantitySold: 0,
        description: this.tip.description || '',
        title: this.tip.title,
        message: this.tip.message,
        messageUrl: this.tip.messageUrl,
        hashtag: this.tip ? this.tip.hashtag : '',
        type: TagType.TIP,
        disabled: this.tip.disabled,
        chargeTax: this.tip.chargeTax,
        imageUrl: this.tip.imageUrl,
        amounts,
        isFeatured: this.tip.isFeatured
      } as TipTag;

      // update or create the product
      if (this.tip) {
        // update all product data
        tipData.productId = this.tip.productId;
      }

      const responseData = await FirebaseRequest.createRequest<TipTag>(
        FirebaseRequestTypes.SAVE_TIP,
        {
          tip: tipData
        }
      );
      this.saveButtonState = ButtonLoadingState.complete;
      if (responseData.status === APIResponseStatus.OK) {
        this.tip = responseData.data as TipTag;
        this.setCurrentProduct(this.tip);
        this.originalValue = JSON.stringify(this.tip);
      }
      this.goBack();
    },

    goBack() {
      const productId = this.tip?.productId;
      if (productId) {
        this.$router.push({
          name: 'seller-product-detail',
          params: { productId }
        });
      } else {
        this.$router.push({ name: 'seller-products' });
      }
    },

    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    }
  }
});
