
import { defineComponent } from 'vue';
import {
  PopupMessageButton,
  PopupMessageStrings,
  PopupMessageTitles
} from '@/common/PopupComponent/PopupMessages';

export default defineComponent({
  name: 'PopupComponent',
  props: {
    title: {
      type: String as () => PopupMessageTitles
    },
    message: {
      type: String as () => PopupMessageStrings
    },
    button1: {
      type: Object as () => PopupMessageButton
    },
    button2: {
      type: Object as () => PopupMessageButton,
      required: false
    }
  },
  emits: ['cancel-modal', 'button1-event', 'button2-event']
});
