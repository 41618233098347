import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "whitespace-nowrap overflow-hidden text-ellipsis w-20ch [414px]:w-[28ch] md:w-[28ch]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagsTextField = _resolveComponent("TagsTextField")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-nowrap justify-between w-full", _ctx.verticallyCenterActionSlot ? 'items-center' : 'items-end'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TagsTextField, {
        label: _ctx.labelText,
        text: _ctx.formattedCardNumber
      }, null, 8, ["label", "text"])
    ]),
    _renderSlot(_ctx.$slots, "action")
  ], 2))
}