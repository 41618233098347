
import { defineComponent } from 'vue';
import BoostBrandCard from '@/common/BoostBrandCard.vue';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  FirebaseRequest,
  FirebaseRequestTypes,
  APIResponseStatus
} from '@/firebaseRequest';
import { mapMutations } from 'vuex';
import {
  AffiliateBrand,
  AffiliateBrandTag
} from '../../models/affiliateBrands';
import DownloadTagsPopupComponent, {
  DownloadTagItem
} from '@/common/DownloadTagsPopupComponent.vue';
import Helpers from '@/helpers';

export default defineComponent({
  components: { BoostBrandCard, DownloadTagsPopupComponent },
  data() {
    return {
      showDownloadTags: false,
      selectedRow: {} as DownloadTagItem,
      brand: {} as AffiliateBrand,
      selectedTab: 'Active',
      allTags: [] as AffiliateBrandTag[],
      menuItems: [
        {
          label: 'Download',
          onClick: (tag) => {
            this.download(tag);
          }
        },
        {
          label: 'Remove Product',
          onClick: (tag) => {
            this.remove(tag);
          },
          isDangerous: () => {
            return true;
          }
        }
      ] as ContextMenuItem[]
    };
  },
  computed: {
    brandTags(): AffiliateBrandTag[] {
      return this.allTags.filter((x) => x.status === this.selectedTab);
    }
  },
  async mounted() {
    //Call API to get tags for the affiliate/brand
    this.setLoading(true);
    const result = await FirebaseRequest.createRequest(
      FirebaseRequestTypes.GET_AFFILIATE_BRAND,
      { accountAffiliateId: this.$route.params.accountAffiliateId as string }
    );
    if (result.status === APIResponseStatus.OK) {
      const brand = result.data as AffiliateBrand;
      this.brand = brand;
      this.allTags = brand.tags;
    } else {
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
        type: BannerType.error
      });
    }
    this.setLoading(false);
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    goBack() {
      this.$router.push({
        name: 'buyer-tags'
      });
    },
    changeTab(tab: string) {
      this.selectedTab = tab;
    },
    download(tag: AffiliateBrandTag) {
      console.log('download', tag);
      this.selectedRow = {
        title: tag.productName,
        productId: tag.productId,
        accountId: this.brand.accountId,
        hashtag: tag.affiliateTag,
        productSource: '',
        imageUrl: tag.imageUrl,
        accountAffiliateId: this.brand.accountAffiliateId
      };
      this.showDownloadTags = true;
    },
    handlePopupVisibleChange(show: boolean) {
      this.showDownloadTags = show;
    },
    async remove(tag: AffiliateBrandTag) {
      this.setLoading(true);
      const result = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.REMOVE_AFFILIATE_BRAND_TAG,
        {
          accountAffiliateId: this.$route.params.accountAffiliateId as string,
          productId: tag.productId
        }
      );
      if (result.status === APIResponseStatus.OK) {
        this.setBannerStatus({
          text: BannerMessageStrings.CHANGES_UPDATED,
          type: BannerType.success
        });
        const index = this.brandTags.indexOf(tag);
        this.brandTags.splice(index, 1);
      } else {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.setLoading(false);
    },
    findProducts() {
      window.open(this.brand.storeLink, '_blank');
    }
  }
});
