import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-between items-baseline"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "boost-input__description"
}
const _hoisted_5 = { class: "boost-input__input-wrapper" }
const _hoisted_6 = { class: "boost-input__label-icon-left-slot" }
const _hoisted_7 = {
  key: 0,
  id: "prefix-text",
  class: "margin-left-2"
}
const _hoisted_8 = { class: "boost-input__label-icon-right-slot" }
const _hoisted_9 = ["id", "placeholder", "name", "disabled"]
const _hoisted_10 = ["id", "placeholder", "type", "name", "autocomplete", "disabled"]
const _hoisted_11 = {
  key: 2,
  class: "boost-input__error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["boost-input", {
      'boost-input--has-label': _ctx.label,
      'boost-input--has-left-icon': _ctx.leftIcon,
      'boost-input--has-right-icon': _ctx.rightIcon,
      'boost-input--has-prefix': _ctx.hasPrefix,
      'boost-input--password': _ctx.type === 'password',
      'boost-input--collapse': !_ctx.fullWidth
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["boost-input__element flex flex--column", {
        'boost-input--error': _ctx.errorMessage || _ctx.customFailed
      }])
    }, [
      (!_ctx.hideLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("label", {
              class: "boost-input__label",
              for: _ctx.inputId
            }, [
              _createTextVNode(_toDisplayString(_ctx.label), 1),
              (_ctx.isRequired && _ctx.showRequiredIndicator)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                : _createCommentVNode("", true)
            ], 8, _hoisted_2),
            _renderSlot(_ctx.$slots, "link")
          ]))
        : _createCommentVNode("", true),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "leftIcon"),
          (_ctx.prefix)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.prefix), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "rightIcon")
        ]),
        (_ctx.type === 'currency')
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              id: _ctx.inputId,
              ref: "inputRef",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
              placeholder: _ctx.placeholder,
              class: "boost-input__input",
              type: "text",
              name: _ctx.name || _ctx.label,
              disabled: _ctx.disabled
            }, null, 8, _hoisted_9)), [
              [_vModelText, _ctx.value]
            ])
          : _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 1,
              id: _ctx.inputId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
              class: _normalizeClass(["boost-input__input disabled:cursor-not-allowed disabled:bg-gray-200 placeholder:text-gray-600", {
              'ignore-global-style': _ctx.ignoreGlobalStyle
            }]),
              placeholder: _ctx.placeholder,
              type: _ctx.innerType,
              name: _ctx.name || _ctx.label,
              autocomplete: _ctx.autocomplete,
              disabled: _ctx.disabled,
              onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
              onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
            }, null, 42, _hoisted_10)), [
              [
                _vModelDynamic,
                _ctx.value,
                void 0,
                { trim: true }
              ]
            ])
      ]),
      (_ctx.errorMessage || _ctx.customError)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
            _createElementVNode("li", null, _toDisplayString(_ctx.errorMessage), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customError, (error) => {
              return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(error), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}