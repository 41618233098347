
import { defineComponent } from 'vue';
import { uploadImage } from '@/../dataService/cloudinary';
import PopupComponent from '@/common/PopupComponent/PopupComponent.vue';
import {
  PopupMessage,
  PopupMessageButtonCaptions,
  PopupMessageStrings,
  PopupMessageTitles
} from '../PopupComponent/PopupMessages';
import { store } from '@/store';
import {
  BannerMessageStrings,
  BannerType
} from '../BannerComponent/BannerMessages';

const BoostImageUpload = defineComponent({
  components: {
    PopupComponent
  },
  props: {
    label: {
      type: String,
      default: 'Upload Image'
    },
    imageUrl: {
      type: String,
      required: false,
      default: undefined
    },
    writeOnPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    mode: {
      type: String as () => 'default' | 'mini',
      default: 'default'
    },
    confirmOnRemove: {
      type: Boolean,
      default: false
    }
  },
  emits: ['imageFileUploaded', 'update:imageUrl', 'imageFilePreviewed'],
  data() {
    return {
      imageSrc: this.imageUrl,
      imageFile: undefined as unknown as File,
      loadingImage: false,
      showPopup: false,
      popup: {} as PopupMessage
    };
  },

  computed: {
    hasImage(): boolean {
      return (
        this.loadingImage || (this.imageSrc != null && this.imageSrc !== '')
      );
    }
  },

  watch: {
    imageUrl(newValue: string) {
      this.imageSrc = newValue;
    }
  },

  methods: {
    async previewAndUploadFile(file: File) {
      this.loadingImage = true;
      this.imageFile = file;
      this.getImagePreviews();

      if (this.writeOnPreview) {
        this.imageSrc = await uploadImage(this.imageFile);
        this.$emit('update:imageUrl', this.imageSrc);
      }
    },

    openFile() {
      const i = this.$refs.image_upload_file_open as HTMLInputElement;
      if (i) {
        i.click();
      }
    },

    confirmRemoveImage(optionKey: string) {
      if (this.confirmOnRemove) {
        this.popup = {
          title: PopupMessageTitles.DELETE_CONFIRMATION,
          message: PopupMessageStrings.CANCEL_IMAGE_REMOVAL,
          button1: {
            caption: PopupMessageButtonCaptions.OK_YES,
            primary: true,
            event: 'remove-image',
            payload: { optionKey }
          },
          button2: {
            caption: PopupMessageButtonCaptions.CANCEL_NO_4,
            event: 'cancel-modal'
          }
        };

        this.showPopup = true;
      } else {
        this.remove();
      }
    },

    remove() {
      this.imageSrc = '';
      const i = this.$refs.image_upload_file_open as HTMLInputElement;
      i.value = '';
      this.loadingImage = false;
      this.showPopup = false;
      this.$emit('update:imageUrl', '');
    },

    getFileContents(e: Event) {
      const fileInput = e.target as HTMLInputElement;
      if (fileInput?.files?.length) {
        // 10 MB
        if (fileInput.files[0].size > 10 * 1024 * 1024) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_SIZE_EXCEEDED,
            type: BannerType.error
          });
          return;
        }

        const acceptedMimeTypes = [
          'image/apng',
          'image/avif',
          'image/gif',
          'image/jpeg',
          'image/png',
          'image/svg+xml',
          'image/webp'
        ];
        if (!acceptedMimeTypes.includes(fileInput.files[0].type)) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_INVALID_FILE,
            type: BannerType.error
          });
          return;
        }

        this.previewAndUploadFile(fileInput.files[0]);
      }
    },

    // Get the image preview for the file
    getImagePreviews() {
      const reader = new FileReader();

      // Add an event listener for when the file has been loaded to update the src on the file preview
      reader.onload = () => {
        const result = reader.result;
        if (result != null) {
          this.imageSrc =
            typeof result === 'string'
              ? result
              : Buffer.from(result).toString();

          const img = this.$refs.image_preview as HTMLImageElement;
          const imageFilePreviewedEventArgs: ImageFilePreviewedEvent = {
            target: img,
            data: this.imageSrc
          };

          this.$emit('imageFilePreviewed', imageFilePreviewedEventArgs);
        }
      };

      // Read the data for the file in through the reader. When it has
      // been loaded, we listen to the event propagated (`onload`) and
      // set the imageSrc to what was loaded from the reader
      reader.readAsDataURL(this.imageFile);
    }
  }
});

export interface ImageFilePreviewedEvent {
  target: HTMLImageElement;
  data: string;
}

export interface ImageFileUploadedEvent {
  target: HTMLImageElement;
  url: string;
}

export default BoostImageUpload;
