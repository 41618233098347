
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { ConnectShopify } from '@/../models/connectShopify';
import { FeatureType } from '@/../enums/featureTypes';
import { defineComponent } from 'vue';
import {
  FirebaseRequest,
  FirebaseRequestTypes,
  APIResponseStatus
} from '@/firebaseRequest';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

const SellerSettingsShopify = defineComponent({
  name: 'SellerSettingsShopify',
  data() {
    return {
      connectButtonState: ButtonLoadingState.initial,
      testButtonState: ButtonLoadingState.initial,
      shopifyConfig: {} as ConnectShopify,
      allowConnect: false
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      isSuperAdmin: 'auth/isSuperAdmin'
    })
  },
  async mounted() {
    this.setLoading(true);
    const responseData = await FirebaseRequest.createRequest<{
      configData: {
        name: string;
        configuration: string;
        status: string;
      };
    }>(FirebaseRequestTypes.GET_ECOMMERCE_CONFIG, {
      accountId: this.currentAccount?.accountId,
      name: 'Shopify'
    });

    if (
      responseData.status === APIResponseStatus.OK &&
      responseData.data?.configData?.configuration
    ) {
      this.shopifyConfig = JSON.parse(
        responseData.data?.configData?.configuration
      ) as ConnectShopify;
    }
    this.allowConnect = this.isSuperAdmin || !this.shopifyConfig?.apiKey;
    this.setLoading(false);
  },
  methods: {
    ...mapMutations('global', [
      'setLoading',
      'setBannerStatus',
      'setContextualButton'
    ]),
    ...mapMutations({
      setSelectedFeatureType: 'accounts/setSelectedFeatureType'
    }),
    ...mapActions('accounts', ['loadAccounts']),
    async syncShopify() {
      this.setLoading({ isLoading: true, message: 'Connecting to Shopify...' });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.setLoading({ isLoading: true, message: 'Syncing products...' });
      const responseData = await FirebaseRequest.createRequest<{
        configData: {
          name: string;
          configuration: string;
          status: string;
        };
      }>(FirebaseRequestTypes.SYNC_ECOMMERCE_PRODUCTS, {
        accountId: this.currentAccount?.accountId,
        name: 'Shopify'
      });

      if (responseData.status === APIResponseStatus.OK) {
        this.setLoading({ isLoading: true, message: 'Finished' });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.setLoading(false);
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_SUCCESS,
          type: BannerType.success
        });
      } else {
        this.setLoading({ isLoading: true, message: 'Something went wrong' });
        this.setLoading(false);
        this.setBannerStatus({
          text: BannerMessageStrings.SHOPIFY_ERROR_SYNCING,
          type: BannerType.error
        });
      }
    },
    async onSubmit() {
      this.testButtonState = ButtonLoadingState.loading;

      const responseData = await FirebaseRequest.createRequest<{
        connectionResults: {
          isSuccessful: boolean;
          message: string;
        };
      }>(FirebaseRequestTypes.CONNECT_ECOMMERCE, {
        testConnection: true,
        accountId: this.currentAccount?.accountId,
        configData: this.shopifyConfig
      });

      if (responseData.status === APIResponseStatus.OK) {
        this.setBannerStatus({
          text: BannerMessageStrings.SHOPIFY_TEST_CONNECT_SUCCESS,
          type: BannerType.success
        });
      } else {
        this.setBannerStatus({
          text:
            responseData?.data?.connectionResults?.message ||
            BannerMessageStrings.SHOPIFY_ERROR,
          type: BannerType.error
        });
      }
      this.testButtonState = ButtonLoadingState.complete;
    },
    async connectToShopify() {
      this.connectButtonState = ButtonLoadingState.loading;

      const responseData = await FirebaseRequest.createRequest<{
        connectionResults: {
          isSuccessful: boolean;
          message: string;
        };
      }>(FirebaseRequestTypes.CONNECT_ECOMMERCE, {
        testConnection: false,
        accountId: this.currentAccount?.accountId,
        configData: this.shopifyConfig
      });

      if (responseData.status === APIResponseStatus.OK) {
        this.setBannerStatus({
          text: BannerMessageStrings.SHOPIFY_CONNECTED,
          type: BannerType.success
        });
        //Need to reload the account and set the selected feature to Shopify
        await this.loadAccounts();
        this.allowConnect = this.isSuperAdmin || !this.shopifyConfig?.apiKey;
        this.setSelectedFeatureType(FeatureType.Shopify);
      } else {
        this.setBannerStatus({
          text:
            responseData?.data?.connectionResults?.message ||
            BannerMessageStrings.SHOPIFY_ERROR,
          type: BannerType.error
        });
      }
      this.connectButtonState = ButtonLoadingState.complete;
    },
    openShopifyInstructions() {
      window.open(
        'https://support.tagscommerce.com/hc/en-us/articles/5475743760023-Connecting-Shopify',
        '_blank'
      );
    },
    contactUs() {
      const link =
        'mailto:support@tagscommerce.com?subject=' +
        encodeURIComponent('Update Store Details') +
        '&body=' +
        encodeURIComponent(
          'Please assist me with updating my store credentials'
        );
      window.location.href = link;
    }
  }
});
export default SellerSettingsShopify;
