import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "boost-table__table-wrapper" }
const _hoisted_2 = { class: "boost-table__table" }
const _hoisted_3 = { class: "boost-table__thead" }
const _hoisted_4 = {
  key: 0,
  class: "boost-table__thead-column boost-table__row-column--icon"
}
const _hoisted_5 = {
  key: 1,
  class: "boost-table__thead-column boost-table__row-column--icon"
}
const _hoisted_6 = { class: "boost-table__tbody" }
const _hoisted_7 = { class: "boost-table__row" }
const _hoisted_8 = ["col", "data-label", "onClick"]
const _hoisted_9 = {
  key: 0,
  class: "boost-table__row-column boost-table__row-column--icon"
}
const _hoisted_10 = ["colspan"]
const _hoisted_11 = {
  key: 0,
  class: "boost-table__action-tray"
}
const _hoisted_12 = { class: "w-100 flex justify--between align--center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _component_BoostPagination = _resolveComponent("BoostPagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["boost-table", {
      'boost-table--fixed': _ctx.fixedColumns,
      'boost-table--no-arrow': _ctx.hideArrow,
      'boost-table--has-context-menu': _ctx.hasContextMenu,
      'boost-table--has-tray': _ctx.hasTray,
      'boost-table--hide-overflow': _ctx.hideOverflow,
      'boost-table--no-image': !_ctx.imageFirst
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", _hoisted_3, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, key) => {
              return (_openBlock(), _createElementBlock("th", {
                key: key,
                class: "boost-table__thead-column"
              }, [
                _createTextVNode(_toDisplayString(col.label) + " ", 1),
                (col.id === 'selection')
                  ? (_openBlock(), _createElementBlock("input", {
                      key: 0,
                      type: "checkbox",
                      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select-all', $event.target.checked)))
                    }, null, 32))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_ctx.showTray)
              ? (_openBlock(), _createElementBlock("th", _hoisted_4))
              : _createCommentVNode("", true),
            (!_ctx.hideArrow)
              ? (_openBlock(), _createElementBlock("th", _hoisted_5))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("tbody", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerRows, (row, rowKey) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: rowKey }, [
              _createElementVNode("tr", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, columnKey) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: columnKey,
                    col: col,
                    class: _normalizeClass([col, "boost-table__row-column"]),
                    "data-label": col.label,
                    onClick: ($event: any) => (_ctx.clickAction(rowKey, columnKey, _ctx.clickId))
                  }, [
                    _renderSlot(_ctx.$slots, "default", {
                      row: row,
                      rowKey: rowKey,
                      column: col,
                      columnKey: columnKey,
                      columnValue: row[col.id]
                    }, () => [
                      _createTextVNode(_toDisplayString(row[col.id]), 1)
                    ])
                  ], 10, _hoisted_8))
                }), 128)),
                (_ctx.showTray)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_9, [
                      (_ctx.innerRows.length > 0 && !_ctx.innerRows[rowKey].showDetails)
                        ? (_openBlock(), _createBlock(_component_BoostButton, {
                            key: 0,
                            label: "View",
                            "button-style": "primary",
                            onClick: ($event: any) => (_ctx.toggleTray(rowKey, true))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.innerRows.length > 0 && _ctx.innerRows[rowKey].showDetails)
                        ? (_openBlock(), _createBlock(_component_BoostButton, {
                            key: 1,
                            class: "text-xs sm:text-sm",
                            label: "Hide",
                            "button-style": "link",
                            onClick: ($event: any) => (_ctx.toggleTray(rowKey, false))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.innerRows.length > 0 && _ctx.innerRows[rowKey].showDetails)
                ? (_openBlock(), _createElementBlock("tr", {
                    key: `tray-row-${rowKey}`,
                    class: "boost-table__tray"
                  }, [
                    _createElementVNode("td", {
                      colspan: _ctx.columns.length + 1
                    }, [
                      _renderSlot(_ctx.$slots, "tray", {
                        row: row,
                        rowKey: rowKey
                      })
                    ], 8, _hoisted_10)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]),
      (_ctx.hasTray)
        ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _renderSlot(_ctx.$slots, "action-tray")
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.hideFooter)
        ? (_openBlock(), _createBlock(_Teleport, {
            key: 1,
            to: "#bottom-tray"
          }, [
            _createVNode(_component_BoostPagination, {
              items: _ctx.rows,
              "per-page": _ctx.perPage,
              class: "boost-table__pagination",
              onItems: _ctx.handleItems
            }, null, 8, ["items", "per-page", "onItems"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}