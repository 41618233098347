
import { defineComponent } from 'vue';
import { ShippingAddress } from '../settings/BuyerSettingsInterfaces';
import BaseCheckoutStep from './BaseCheckoutStep.vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import CheckoutDetailDivider from './CheckoutDetailDivider.vue';

export default defineComponent({
  name: 'AddShippingOption',
  components: { BaseCheckoutStep, CheckoutDetailDivider },
  props: {
    title: {
      type: String,
      default: 'Add New Shipping Address'
    },
    customColor: {
      type: String,
      required: true
    }
  },
  emits: ['add-address-completed', 'add-address-canceled'],
  data() {
    return {
      shippingAddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States',
        isDefault: true
      } as ShippingAddress,
      addAddressButtonState: ButtonLoadingState.initial
    };
  },
  methods: {
    async saveShippingAddress() {
      this.addAddressButtonState = ButtonLoadingState.loading;
      try {
        const success = await this.$store.dispatch(
          'accounts/addShippingAddress',
          this.shippingAddress
        );
        if (success) {
          this.addAddressButtonState = ButtonLoadingState.complete;
          this.$emit('add-address-completed', this.shippingAddress.isDefault);
        }
      } catch (e) {
        console.log('adding address failed: ', e);
      }
    }
  }
});
