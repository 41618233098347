
import { defineComponent, ref, watch } from 'vue';
import { mapMutations } from 'vuex';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import Helpers from '@/helpers';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';

type STATE = 'REGISTER' | 'LOGIN';

enum ReplyCode {
  INVALID_CONTEXT = 'INVALID_CONTEXT',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  MISSING_CONTEXT = 'MISSING_CONTEXT',
  MISSING_PHONE_NUMBER = 'MISSING_PHONE_NUMBER',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  PHONE_NUMBER_ALREADY_IN_USE = 'PHONE_NUMBER_ALREADY_IN_USE',
  PHONE_NUMBER_NOT_FOUND = 'PHONE_NUMBER_NOT_FOUND',
  PREREGISTER_EXISTING_USER = 'PREREGISTER_EXISTING_USER',
  PREREGISTER_NEW_USER = 'PREREGISTER_NEW_USER',
  SELLER_DOES_EXIST = 'SELLER_DOES_EXIST',
  AFFILIATE_HAS_USERNAME = 'AFFILIATE_HAS_USERNAME',
  INVALID_CODE = 'INVALID_CODE',
  OK = 'OK'
}

interface RequestConfirmationResponseData {
  number: string;
  isUserRegistered: boolean;
  isUserAnAffiliate: boolean;
  token?: string;
}

interface ValidateConfirmationResponseData {
  token: string;
  userId: string;
  username?: string;
}

interface RequestConfirmationResponse {
  code: ReplyCode;
  status: string;
  data: RequestConfirmationResponseData;
}

interface ValidateConfirmationResponse {
  code: ReplyCode;
  status: string;
  data: ValidateConfirmationResponseData;
}

interface RequestConfirmationPayload {
  number: string;
  context: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
}

export default defineComponent({
  name: 'MarketplaceRegisterComponent',
  setup() {
    const codeInputRef = ref<HTMLInputElement | null>(null);
    const codeRegisterInputRef = ref<HTMLInputElement | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    watch(
      [codeInputRef, codeRegisterInputRef],
      ([newLoginRef, newRegisterRef]) => {
        if (newLoginRef) {
          newLoginRef.focus();
        }

        if (newRegisterRef) {
          newRegisterRef.focus();
        }
      }
    );

    return {
      codeInputRef,
      codeRegisterInputRef
    };
  },
  data() {
    return {
      getCodeState: ButtonLoadingState.initial,
      currentState: 'REGISTER' as STATE,
      usernameError: [] as string[],
      mobileNumberError: [] as string[],
      loginMobileNumberError: [] as string[],
      loginInstead: false,
      requestedCode: false,
      invalidConfirmationCode: false,
      email: '',
      firstName: '',
      lastName: '',
      username: '',
      mobileNumber: '',
      confirmationCode: ''
    };
  },
  computed: {
    buttonLabel() {
      if (this.currentState === 'REGISTER') {
        return this.requestedCode ? 'Confirm' : 'Create My Checkout';
      }

      // We need some state to check if we requested the code.
      return this.requestedCode ? 'Confirm' : 'Send Code';
    }
  },
  methods: {
    ...mapMutations('auth', ['setCurrentUser', 'setCurrentBuyer']),
    async submitForm() {
      this.usernameError = [];
      this.mobileNumberError = [];
      this.loginMobileNumberError = [];
      this.loginInstead = false;

      if (this.currentState === 'REGISTER') {
        if (this.requestedCode) {
          await this.verifyConfirmationCode(this.currentState);
        } else {
          await this.registerUser();
        }
      }

      if (this.currentState === 'LOGIN') {
        if (this.requestedCode) {
          await this.verifyConfirmationCode(this.currentState);
        } else {
          await this.requestConfirmationCode(this.currentState);
        }
      }
    },
    async registerUser() {
      try {
        await this.requestConfirmationCode(this.currentState);
      } catch (ex) {
        console.error(ex);
      }
    },
    async requestConfirmationCode(state: STATE) {
      try {
        this.getCodeState = ButtonLoadingState.loading;
        const isRegistering = state === 'REGISTER';
        const payload: RequestConfirmationPayload = {
          number: Helpers.prefixPhoneNumber(this.mobileNumber),
          context: isRegistering ? 'SIGNUP_AFFILIATE' : 'LOGIN_MOBILE_NUMBER',
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username
        };

        const confirmationCodeResponse = (await FirebaseRequest.createRequest(
          FirebaseRequestTypes.REQUEST_CONFIRMATION_CODE,
          payload
        )) as RequestConfirmationResponse;

        if (!confirmationCodeResponse) {
          console.error('confirmationCodeResponse was undefined??');
          return;
        }

        console.log('confirmationCodeResponse', confirmationCodeResponse);
        if (confirmationCodeResponse.status === APIResponseStatus.OK) {
          this.handleSuccessResponse(confirmationCodeResponse.code);
        } else {
          this.handleErrorResponse(
            confirmationCodeResponse.code,
            confirmationCodeResponse.data.isUserAnAffiliate
          );
        }
      } catch (ex) {
        console.log('error: ', ex);
      }
      this.getCodeState = ButtonLoadingState.complete;
    },
    handleSuccessResponse(code: ReplyCode) {
      const successCodes = [
        ReplyCode.OK,
        ReplyCode.PREREGISTER_EXISTING_USER,
        ReplyCode.PREREGISTER_NEW_USER
      ];
      if (successCodes.includes(code)) {
        // this.currentState = 'LOGIN';
        this.requestedCode = true;
        this.codeRegisterInputRef?.focus();
      }

      if (code === ReplyCode.AFFILIATE_HAS_USERNAME) {
        this.mobileNumberError = [
          'You already have an account. A confirmation code was sent to your mobile number.'
        ];
        this.requestedCode = true;
        this.codeInputRef?.focus();
      }
    },
    handleErrorResponse(code: string, isUserAnAffiliate: boolean) {
      if (code === ReplyCode.SELLER_DOES_EXIST) {
        this.usernameError.push(
          `The username "${this.username}" is not available.`
        );

        if (isUserAnAffiliate) {
          this.loginInstead = true;
          this.mobileNumberError.push(
            'You may already be a Tagger. Try logging in instead.'
          );
        }
      }

      if (code === ReplyCode.PHONE_NUMBER_NOT_FOUND) {
        this.loginMobileNumberError.push(
          'This number is not registered. Please register instead.'
        );
      }
    },
    async verifyConfirmationCode(state: STATE) {
      if (!this.mobileNumber) {
        return;
      }
      // Check if phone number exists
      // 1. call existing code validation api
      // 2. if existing, call new api to register the relationship
      // 3. if new customer, proceed to next step to collect info
      this.getCodeState = ButtonLoadingState.loading;
      this.invalidConfirmationCode = false;

      const payload = {
        number: Helpers.prefixPhoneNumber(this.mobileNumber),
        code: this.confirmationCode,
        context:
          state === 'REGISTER' ? 'SIGNUP_AFFILIATE' : 'LOGIN_MOBILE_NUMBER',
        firstName: state === 'REGISTER' ? this.firstName : undefined,
        lastName: state === 'REGISTER' ? this.lastName : undefined,
        username: state === 'REGISTER' ? this.username : undefined,
        pendingEmail: state === 'REGISTER' ? this.email : undefined
      };

      const confirmationCodeResponse = (await FirebaseRequest.createRequest(
        FirebaseRequestTypes.VALIDATE_CONFIRMATION_CODE,
        payload
      )) as ValidateConfirmationResponse;

      if (confirmationCodeResponse.status === APIResponseStatus.OK) {
        const auth = getAuth();
        const response = await signInWithCustomToken(
          auth,
          confirmationCodeResponse.data?.token || ''
        );
        if (!(response && response.user && response.user.uid)) {
          console.log('Mobile number error?');
        }

        this.setCurrentUser(auth.currentUser);

        const buyerResponse = await FirebaseRequest.createRequest<Buyer>(
          FirebaseRequestTypes.GET_BUYER_INFO,
          {}
        );
        if (
          buyerResponse.status === APIResponseStatus.OK &&
          buyerResponse.data
        ) {
          this.setCurrentBuyer(buyerResponse.data);
        }
      } else {
        if (confirmationCodeResponse.code === ReplyCode.INVALID_CODE) {
          this.invalidConfirmationCode = true;
        }
      }
      this.getCodeState = ButtonLoadingState.complete;
    }
  }
});
