
import { defineComponent } from 'vue';
import Helpers from '@/helpers';
import { OrderStatus, SellerOrder } from '@/../models/sellerOrder';
import { TagType } from '@/../enums/tagType';
import { mapGetters } from 'vuex';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';

export default defineComponent({
  name: 'OrdersListView',
  components: {},
  props: {
    transactions: {
      type: Array as () => SellerOrder[],
      required: true
    },
    limit: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      TagType,
      OrderStatus,
      columns: [
        {
          id: 'image',
          label: ''
        },
        {
          id: 'orderNo',
          label: 'Order No'
        },
        {
          id: 'customer',
          label: 'Customer'
        },
        {
          id: 'amount',
          label: 'Amount'
        },
        {
          id: 'date',
          label: 'Date'
        },
        {
          id: 'badges',
          label: 'Status'
        }
      ] as ColumnItem[]
    };
  },

  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    })
  },
  async mounted() {
    if (!this.currentAccount) {
      return;
    }
  },

  methods: {
    viewOrder(orderId: string) {
      this.$router.push({ path: `/store/orders/${orderId}` });
    },
    processDate(date: number) {
      return Helpers.processDate(date);
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    },
    getShippingStatusLabel(status: OrderStatus) {
      return Helpers.getOrderStatusLabel(status);
    }
  }
});
