
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import { Buyer, ShippingAddress } from './BuyerSettingsInterfaces';
import AddressDetailComponent from '@/common/info/AddressDetailComponent.vue';
import { defineComponent } from 'vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapGetters } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { store } from '@/store';
export enum ReplyCodes {
  CANNOT_REMOVE_DEFAULT_ADDRESS = 'CANNOT_REMOVE_DEFAULT_ADDRESS'
}

export default defineComponent({
  name: 'BuyerShipping',
  components: {
    AddressDetailComponent
  },
  data() {
    return {
      menuItems: [
        {
          label: 'Make Default',
          onClick: (address) => {
            this.setDefaultShippingAddress(address);
          },
          isDisabled: (address) => address.isDefault
        },
        {
          label: 'Edit Address',
          onClick: (address) => {
            this.editShippingAddress(address);
          }
        },
        {
          label: 'Remove Address',
          onClick: (address) => {
            this.removeShippingAddress(address);
          },
          isDisabled: (address) => address.isDefault
        }
      ] as ContextMenuItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentBuyer: 'auth/getCurrentBuyer'
    }),
    canAddShippingAddress(): boolean {
      return this.shippingAddresses.length < 12;
    },
    shippingAddresses(): ShippingAddress[] {
      return (this.currentBuyer as Buyer)?.shippingAddresses;
    }
  },
  methods: {
    async setDefaultShippingAddress(address: ShippingAddress) {
      this.$store.commit('global/setLoading', {
        isLoading: true,
        message: 'Setting new default address...'
      });
      const response = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.UPDATE_BUYER_SHIPPING_ADDRESS,
        { shippingAddress: { ...address, isDefault: true } }
      );

      if (response.status === APIResponseStatus.OK) {
        await this.$store.dispatch('auth/refreshCurrentBuyer');
      }
      this.$store.commit('global/setLoading', false);
    },
    addShippingAddress() {
      this.$router.push({ name: 'buyer-shipping-address-change' });
    },
    editShippingAddress(address: ShippingAddress) {
      if (address) {
        this.$router.push({
          name: 'buyer-shipping-address-change',
          params: { addressId: address.id }
        });
      }
    },
    async removeShippingAddress(address: ShippingAddress) {
      this.$store.commit('global/setLoading', {
        isLoading: true,
        message: 'Removing shipping address..'
      });
      // this menu option is disabled if this is a default shipping address
      const response = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.REMOVE_BUYER_SHIPPING_ADDRESS,
        { addressId: address.id }
      );

      if (response.status === APIResponseStatus.OK) {
        await this.$store.dispatch('auth/refreshCurrentBuyer');
      } else {
        store.commit('global/setBannerStatus', {
          text:
            response.code === ReplyCodes.CANNOT_REMOVE_DEFAULT_ADDRESS
              ? BannerMessageStrings.BUYER_REMOVE_DEFAULT_ADDRESS_NOT_ALLOWED
              : BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.$store.commit('global/setLoading', false);
    }
  }
});
