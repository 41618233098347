
import { defineComponent } from 'vue';
import { Stripe, StripeCardElement, Token } from '@stripe/stripe-js';

export default defineComponent({
  name: 'BoostStripeCard',
  props: {
    /**
     * Stripe
     * stripe needs to be instantiated in the parent component
     * so that the card element is generated with that same instance
     */
    stripe: {
      type: Object as () => Stripe,
      required: true
    },
    label: {
      type: String
    },
    showRequiredAsteriskOnLabel: {
      type: Boolean,
      default: true
    }
  },
  emits: ['card', 'incomplete'],
  data() {
    return {
      card: {} as StripeCardElement,
      hasError: false,
      errorMessage: '',
      token: {} as Token
    };
  },
  mounted() {
    if (this.stripe) {
      this.initCreditCardPayment();
    }
  },
  methods: {
    initCreditCardPayment() {
      const elements = this.stripe.elements();

      const style = {
        base: {
          'color': '#18223a',
          'fontFamily': '"Inter", sans-serif',
          'fontSmoothing': 'antialiased',
          'fontSize': '1rem',
          '::placeholder': {
            color: '#afb3c6'
          }
        },
        invalid: {
          color: '#f54d57',
          iconColor: '#f54d57'
        }
      };

      this.card = elements.create('card', {
        style
      });
      this.$nextTick(() => this.card.mount(this.$refs.card as HTMLElement));

      // eslint-disable-next-line
      // @ts-ignore
      this.card.on('change', (cardEvent) => {
        if (!cardEvent) {
          this.hasError = false;
          this.errorMessage = '';
          return;
        }

        if (cardEvent.error?.message) {
          this.hasError = true;
          this.errorMessage = cardEvent.error.message;
        }

        if (cardEvent.complete) {
          // Emit the Card up to parent to be incorporated into the Stripe API call
          this.$emit('card', this.card);
          this.errorMessage = '';
        }

        // If the cardEvent object is not complete but not empty, then the
        // input is missing some data. We emit an event to let the parent
        // know that the current payment info is not complete so that any
        // elements which might depend on this form (like a "Submit" button)
        // can be disabled.
        if (!cardEvent.complete && !cardEvent.empty) {
          this.$emit('incomplete');
        }
      });
    }
  }
});
