
import { defineComponent } from 'vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { store } from '@/store';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';

export default defineComponent({
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      sendInstructionsButtonState: ButtonLoadingState.initial
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email as string;
    }
  },
  methods: {
    async sendEmail() {
      this.sendInstructionsButtonState = ButtonLoadingState.loading;
      const success = await this.$store.dispatch('auth/forgotPassword', {
        email: this.email
      });

      // TODO: I don't think we should be displaying an error message
      // if the email address is not associated with an account. This lets
      // potential hackers know who does and does not have an account.
      // It's best to display a message like "If you have an email address
      // with us, we'll send you your instructions" or something along
      // those lines.
      if (success) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.SELLER_PASSWORD_EMAIL_SENT,
          type: BannerType.info
        });
      } else {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }

      this.sendInstructionsButtonState = ButtonLoadingState.complete;
    }
  }
});
