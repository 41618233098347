
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import GeneralAnalytics from './GeneralAnalytics.vue';
import LaterAnalytics from './LaterAnalytics.vue';
import ShareAnalytics from './ShareAnalytics.vue';
import Helpers from '@/helpers';

type AnalyticsTabName = 'General' | 'Later' | 'Share' | 'Embed';
interface AnalyticsTab {
  name: AnalyticsTabName;
  disabled: boolean;
}

export default defineComponent({
  name: 'AnalyticsList',
  components: { GeneralAnalytics, LaterAnalytics, ShareAnalytics },
  data() {
    return {
      selectedTab: 'General' as AnalyticsTabName,
      primaryTabs: [
        {
          name: 'General',
          disabled: false
        }
      ] as AnalyticsTab[],
      secondaryTabs: [] as AnalyticsTab[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      productSource: 'accounts/selectedFeatureTypeId'
    })
  },
  async mounted() {
    if (this.productSource !== 'Shopify') {
      return;
    }
    const hasAccess = Helpers.currentPlanHasAccess(
      'Plus',
      this.currentAccount.currentPlan
    );
    this.secondaryTabs.push(
      {
        name: 'Later',
        disabled: !hasAccess
      },
      {
        name: 'Share',
        disabled: !hasAccess
      },
      {
        name: 'Embed',
        disabled: true
      }
    );
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus'])
  }
});
