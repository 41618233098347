
import { defineComponent } from 'vue';
import { store } from '@/store';

const BoostIcon = defineComponent({
  name: 'BoostIcon',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass(): string {
      const customValue = store.getters['global/getCustomColor'] as string;
      return 'custom__' + customValue || 'default';
    }
  }
});

export default BoostIcon;
