import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0da9871b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stepper" }
const _hoisted_2 = { class: "stepper__steps" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "stepper__step"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["stepper__step-block", _ctx.getCustomColorClass(step)]),
            style: _normalizeStyle(step > _ctx.currentStep ? 'background-color: #CBCEDB' : '')
          }, null, 6)
        ]))
      }), 128))
    ])
  ]))
}