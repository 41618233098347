
import { defineComponent } from 'vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import Stepper from '@/common/Stepper.vue';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import { mapMutations } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

export default defineComponent({
  name: 'SellerRegistrationDetails',
  components: {
    Stepper
  },
  props: {
    hashtagPrefix: {
      type: String,
      default: '',
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['cancelRegistration', 'createAccount'],
  data() {
    return {
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        company: {
          email: '',
          phone: '',
          postalCode: ''
        }
      },
      confirmPassword: '',
      currentStep: 1,
      completeButtonState: ButtonLoadingState.initial
    };
  },
  watch: {
    isLoading() {
      this.processLoading();
    }
  },
  mounted() {
    this.processLoading();
  },
  methods: {
    ...mapMutations({
      setBannerStatus: 'global/setBannerStatus'
    }),
    cancelRegistration() {
      this.$emit('cancelRegistration');
    },
    async checkSellerEmail() {
      try {
        this.completeButtonState = ButtonLoadingState.loading;
        const emailValidationPayload = {
          email: this.userInfo.email
        };
        const emailValidationResponse = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.CHECK_SELLER_EMAIL,
          emailValidationPayload
        );
        if (
          !emailValidationResponse ||
          emailValidationResponse.code === 'SELLER_EMAIL_DOES_EXIST'
        ) {
          this.setBannerStatus({
            text: BannerMessageStrings.SELLER_EMAIL_ALREADY_IN_USE,
            type: BannerType.error
          });
          this.completeButtonState = ButtonLoadingState.complete;
          return;
        }
        this.setBannerStatus({ text: '', type: BannerType.success });
        this.currentStep = 2;
      } catch (ex) {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.completeButtonState = ButtonLoadingState.complete;
    },
    createAccount() {
      this.$emit('createAccount', this.userInfo);
    },
    processLoading() {
      if (this.isLoading) {
        this.completeButtonState = ButtonLoadingState.loading;
      } else {
        this.completeButtonState = ButtonLoadingState.initial;
      }
    }
  }
});
