
import { defineComponent, reactive } from 'vue';
import type { PropType } from 'vue';
import { PaymentOption } from '../../buyer/settings/BuyerSettingsInterfaces';
import AddPaymentOption from './AddPaymentOption.vue';
import BaseCheckoutStep from './BaseCheckoutStep.vue';
import CheckoutDetailDivider from './CheckoutDetailDivider.vue';
import StripeCreditCardSummaryComponent from '../../common/info/StripeCreditCardSummaryComponent.vue';
import TagsToggle from '@/common/controls/TagsToggle.vue';

interface SelectablePaymentOption extends PaymentOption {
  isSelected: boolean;
}

interface PaymentOptions {
  defaultCard: SelectablePaymentOption | null;
  nonDefaultCards: SelectablePaymentOption[];
}

export default defineComponent({
  name: 'UpdatePaymentOptions',
  components: {
    StripeCreditCardSummaryComponent,
    AddPaymentOption,
    CheckoutDetailDivider,
    BaseCheckoutStep,
    TagsToggle
  },
  props: {
    cards: {
      type: Object as PropType<Array<PaymentOption>>,
      required: true
    },
    initialSelectedPaymentOption: {
      type: Object as PropType<PaymentOption>
    },
    customColor: {
      type: String,
      required: true
    }
  },
  emits: {
    backNavigationRequested() {
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    paymentOptionUpdated(payload: PaymentOption) {
      return true;
    }
  },
  setup(props) {
    // Here, in setup, take the initialSelectedPaymentOption and
    // create 2 reactive objects, with isSelected properties
    const paymentOptions: PaymentOptions = reactive({
      defaultCard: null,
      nonDefaultCards: []
    });
    const defaultCard = props.cards.find((c) => c.isDefault);
    if (defaultCard) {
      paymentOptions.defaultCard = { ...defaultCard, isSelected: true };
    }

    paymentOptions.nonDefaultCards = props.cards
      .filter((c) => !c.isDefault)
      .map((c) => ({ ...c, isSelected: false }));

    return {
      paymentOptions
    };
  },
  data() {
    return {
      addNewCard: false,
      selectedPaymentOption: this.initialSelectedPaymentOption as PaymentOption
    };
  },
  computed: {
    canAddCard() {
      return this.cards.length < 3;
    },
    defaultCard() {
      const defaultCard = this.cards.find((c) => c.isDefault);
      if (defaultCard) {
        return { ...defaultCard, isSelected: true };
      }

      return null;
    },
    nonDefaultCards() {
      return this.cards
        .filter((c) => !c.isDefault)
        .map((c) => ({ ...c, isSelected: false }));
    }
  },
  async mounted() {
    await this.getCurrentBuyer();
  },
  methods: {
    async getCurrentBuyer() {
      this.addNewCard = false;
      this.$store.commit('global/setLoading', {
        isLoading: true,
        message: 'Retrieving payment options...'
      });
      await this.$store.dispatch('auth/refreshCurrentBuyer');
      this.$store.commit('global/setLoading', false);
    },
    selectedPaymentOptionChanged() {
      if (this.initialSelectedPaymentOption === this.selectedPaymentOption) {
        this.$emit('backNavigationRequested');
        return;
      }

      this.$store.commit('global/setBannerStatus', null);
      this.$emit('paymentOptionUpdated', this.selectedPaymentOption);
    },
    isPaymentOptionSelected(paymentOption: PaymentOption) {
      return paymentOption.id === this.selectedPaymentOption?.id;
    },
    async newPaymentAdded(setAsDefault: boolean) {
      await this.getCurrentBuyer();

      if (setAsDefault) {
        const defaultCard = this.cards.find((c) => c.isDefault);
        if (defaultCard) {
          this.selectedPaymentOption = defaultCard;
        }
      }
    },
    selectPaymentOption(selectedPaymentOption: PaymentOption) {
      // do not allow the option of deselecting.
      if (selectedPaymentOption.id === this.selectedPaymentOption.id) {
        return;
      }

      // When we select a new address, we need to remove isSelected from the other
      // addresses.
      this.selectedPaymentOption = selectedPaymentOption;

      if (this.paymentOptions.defaultCard !== null) {
        this.paymentOptions.defaultCard.isSelected =
          this.paymentOptions.defaultCard?.id === selectedPaymentOption.id;
      }

      for (const paymentOption of this.paymentOptions.nonDefaultCards) {
        paymentOption.isSelected =
          paymentOption.id === selectedPaymentOption.id;
      }
    }
  }
});
