
import { defineComponent } from 'vue';
import Helpers from '@/helpers';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import { TagDetail, TipTag } from '@/models/tag';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { TagType } from '@/../enums/tagType';
import { ProductSource } from '@/../models/sellerOrder';
import {
  Attribution,
  AttributionType,
  AttributionStatus
} from '@/../models/firebase/product';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';

export default defineComponent({
  name: 'ProductDetail',

  data() {
    return {
      TagType,
      AttributionStatus,
      ProductSource,
      previousId: '',
      nextId: '',
      index: 1,
      columns: [
        {
          id: 'icon',
          label: ''
        },
        {
          id: 'name',
          label: 'Name/Location'
        },
        {
          id: 'attributionType',
          label: 'Type'
        },
        {
          id: 'sold',
          label: 'Sold'
        },
        {
          id: 'revenue',
          label: 'Revenue'
        },
        {
          id: 'conversion',
          label: 'Conversion'
        },
        {
          id: 'badges',
          label: 'Status'
        },
        {
          id: 'menu',
          label: ''
        }
      ] as ColumnItem[],
      menuItems: [
        {
          label: 'Download QR',
          onClick: (row) => {
            this.downloadAttributionQR(row);
          }
        },
        {
          label: 'Copy Link',
          onClick: (row) => {
            this.getAttributionLink(row);
          }
        },
        {
          label: 'Copy SMS',
          onClick: (row) => {
            this.copyAttributionSMS(row);
          }
        }
      ] as ContextMenuItem[]
    };
  },

  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      currentProduct: 'product/currentProduct',
      productListLength: 'product/getProductListLength'
    }),
    product(): TagDetail {
      return this.currentProduct as TagDetail;
    },
    currentDigital(): TipTag | null {
      return this.currentProduct as TipTag | null;
    },
    attributions(): Attribution[] {
      return this.product?.attributions || [];
    },
    productTypeImageUrl(): string {
      if (this.product) {
        if (this.product.shopifyShareEarn && this.product.shopifySaveForLater) {
          return require('@/assets/multi-app-icon.svg');
        }
        if (this.product.shopifySaveForLater) {
          return require('@/assets/later_icon.svg');
        }
        if (this.product.shopifyShareEarn) {
          return require('@/assets/share-icon.svg');
        }
      }

      return '';
    }
  },

  async created() {
    await this.loadDetails(this.$route.params.productId as string);
  },

  mounted() {
    if (!this.$route.params.productId) {
      return;
    }
  },

  methods: {
    ...mapActions('product', [
      'setupCurrentProduct',
      'getNext',
      'getPrevious',
      'getCurrentIndex'
    ]),
    ...mapMutations('global', [
      'setLoading',
      'setBannerStatus',
      'setContextualButton'
    ]),
    async loadDetails(productId: string) {
      this.setLoading({ isLoading: true, message: 'Loading product details' });
      const payload = {
        productId: productId,
        accountId: this.currentAccount.accountId
      };
      this.index = (await this.getCurrentIndex(productId)) + 1;
      await this.setupCurrentProduct(payload);
      this.setContextualButton(true);
      this.nextId = await this.getNext(payload.productId);
      this.previousId = await this.getPrevious(payload.productId);
      this.setLoading(false);
    },
    async previous() {
      if (this.previousId) {
        await this.$router.push({ path: `/store/products/${this.previousId}` });
        this.loadDetails(this.previousId);
      }
    },
    async next() {
      if (this.nextId) {
        await this.$router.push({ path: `/store/products/${this.nextId}` });
        this.loadDetails(this.nextId);
      }
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    },
    getShippingText(): string {
      let results = '';
      if (this.product.productSource !== 'Boost') {
        results = 'Calculated at Checkout';
      } else {
        const amount =
          this.formatPrice((this.product.shippingCharge || 0) * 100) || 'Free';
        results = amount === '$0.00' ? 'Free' : amount;
      }
      return results;
    },
    formatPrice(amount: number | undefined): string | undefined {
      if (amount === undefined) {
        return undefined;
      }
      return Helpers.currencyFormatter(amount);
    },
    getOptions(): string {
      if (!this.product?.variants) {
        // If we don't have any SKUs, we have no tags to sell (and probably a data corruption)
        return 'none';
      }

      return this.product.variants.map((v) => v.label).join(', ') || 'none';
    },
    getAmounts(): string {
      return this.currentDigital?.amounts
        ? this.currentDigital.amounts
            .map((x) => Helpers.currencyFormatter(x))
            .join(', ')
        : '';
    },
    getAllAvailableStock(): number {
      if (!this.product?.skus) {
        // If we don't have any SKUs, we have no tags to sell (and probably a data corruption)
        return 0;
      }

      const stockCount = Object.entries(this.product.skus).reduce(
        (quantity, [, sku]) => {
          if (sku.quantity.toString() === 'UNLIMITED') {
            return 999;
          } else {
            // quantity is sometimes delivered as a string and sometimes as a number, so just call toString()
            if (sku.quantity) {
              const skuQuantity = parseInt(sku.quantity.toString(), 10);
              return quantity + skuQuantity;
            }
            return quantity;
          }
        },
        0
      );

      return stockCount;
    },
    getPrice() {
      if (this.product?.skus && this.product?.variants?.length) {
        const skus = Object.entries(this.product.skus);
        if (skus?.length) {
          const prices = [] as number[];
          skus.forEach((sku) => {
            prices.push(+sku[1].price);
          });

          const min = Helpers.currencyFormatter(Math.min(...prices).toString());
          const max = Helpers.currencyFormatter(Math.max(...prices).toString());

          if (min !== max) {
            return `${min} - ${max}`;
          } else {
            return `${min}`;
          }
        }
      }

      const price = this.product?.price || 0;
      return Helpers.currencyFormatter(price);
    },
    getTotalSkus(): number {
      if (!this.product?.skus) {
        // If we don't have any SKUs, we have no tags to sell (and probably a data corruption)
        return 0;
      }
      return this.product.skus.length;
    },
    async downloadQR(singleFile?: boolean) {
      this.setLoading(true);

      const responseData = await FirebaseRequest.requestFile(
        FirebaseRequestTypes.GENERATE_PRODUCT_LINKS,
        {
          productId: this.product?.productId,
          accountId: this.product?.accountId,
          singleFile
        }
      );
      const FILE = window.URL.createObjectURL(await responseData.blob());
      const downloadUrl = document.createElement('a');
      downloadUrl.href = FILE;
      downloadUrl.setAttribute(
        'download',
        `${this.product.title.substring(0, 50).replace(/[^a-z0-9]/gi, '-')}.${
          singleFile ? 'png' : 'zip'
        }`
      );
      document.body.appendChild(downloadUrl);
      downloadUrl.click();

      this.setLoading(false);
    },
    async copySMS() {
      await navigator.clipboard.writeText(
        Helpers.formatHashTag(this.product?.hashtag)
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    async copyUrl() {
      await navigator.clipboard.writeText(
        `${window.location.origin}/buy/${this.product?.hashtag}`
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    goBack() {
      this.$router.push({
        name: 'seller-products'
      });
    },
    editProduct() {
      const productId = this.product?.productId;
      if (this.product?.type === TagType.TIP) {
        this.$router.push({ name: 'seller-tip-edit', params: { productId } });
      } else {
        const routeName =
          this.product?.productSource === 'Shopify'
            ? 'seller-product-edit-shopify'
            : 'seller-product-edit';
        this.$router.push({ name: routeName, params: { productId } });
      }
    },
    getAttributionType(attribution: Attribution): string {
      return AttributionType[attribution.type];
    },
    getAttributionTag(attribution: Attribution): string {
      return (
        this.getHashtag(this.product?.hashtag || '') + attribution.identifier
      );
    },
    async downloadAttributionQR(attribution: Attribution) {
      const responseData = await FirebaseRequest.requestFile(
        FirebaseRequestTypes.GENERATE_PRODUCT_LINKS,
        {
          productId: this.product?.productId,
          accountId: this.product?.accountId,
          attributionIdentifier: attribution.identifier
        }
      );
      const FILE = window.URL.createObjectURL(await responseData.blob());
      const downloadUrl = document.createElement('a');
      downloadUrl.href = FILE;
      downloadUrl.setAttribute(
        'download',
        `${this.product?.hashtag}${attribution.identifier}.png`
      );
      document.body.appendChild(downloadUrl);
      downloadUrl.click();
    },
    async getAttributionLink(attribution: Attribution) {
      await navigator.clipboard.writeText(
        `${window.location.origin}/buy/${this.getHashtag(
          this.product?.hashtag
        )}/${attribution.identifier}`
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    async copyAttributionSMS(attribution: Attribution) {
      await navigator.clipboard.writeText(
        `${this.product?.hashtag}${attribution.identifier}`
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    }
  }
});
