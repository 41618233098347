
import { defineComponent, reactive } from 'vue';
import type { PropType } from 'vue';
import AddressSummaryComponent from '@/common/info/AddressSummaryComponent.vue';
import AddShippingOption from './AddShippingOption.vue';
import BaseCheckoutStep from './BaseCheckoutStep.vue';
import { store } from '@/store';
import { ShippingAddress } from '../settings/BuyerSettingsInterfaces';
import CheckoutDetailDivider from './CheckoutDetailDivider.vue';

interface SelectableShippingAddress extends ShippingAddress {
  isSelected: boolean;
}

interface ShippingAddresses {
  defaultAddress: SelectableShippingAddress | null;
  nonDefaultAddresses: SelectableShippingAddress[];
}

export default defineComponent({
  name: 'UpdateShippingOptions',
  components: {
    AddressSummaryComponent,
    AddShippingOption,
    CheckoutDetailDivider,
    BaseCheckoutStep
  },
  props: {
    shippingOptions: {
      type: Object as PropType<Array<ShippingAddress>>,
      required: true
    },
    initialSelectedShippingOption: {
      type: Object as PropType<ShippingAddress>
    },
    customColor: {
      type: String,
      required: true
    }
  },
  // We use the `emits` object syntax so that we can declare the payload types of our emitted events.
  // Read More: https://vuejs.org/guide/typescript/options-api.html#typing-component-emits
  emits: {
    backNavigationRequested() {
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shippingAddressUpdated(payload: ShippingAddress) {
      return true;
    }
  },
  setup(props) {
    // Here, in setup, take the initialSelectedPaymentOption and
    // create 2 reactive objects, with isSelected properties
    const shippingAddresses: ShippingAddresses = reactive({
      defaultAddress: null,
      nonDefaultAddresses: []
    });
    const defaultAddress = props.shippingOptions.find((c) => c.isDefault);
    if (defaultAddress) {
      shippingAddresses.defaultAddress = {
        ...defaultAddress,
        isSelected: true
      };
    }

    shippingAddresses.nonDefaultAddresses = props.shippingOptions
      .filter((c) => !c.isDefault)
      .map((c) => ({ ...c, isSelected: false }));

    return {
      shippingAddresses
    };
  },
  data() {
    return {
      addNewShippingOption: false,
      selectedShippingOption: this
        .initialSelectedShippingOption as ShippingAddress
    };
  },
  computed: {
    canAddShippingAddress(): boolean {
      return this.shippingOptions.length < 12;
    }
  },
  async mounted() {
    // Each time this component is mounted, we should reload the currentBuyer
    // so that we get the latest shippingAddresses.
    await this.getCurrentBuyer();
  },
  methods: {
    async getCurrentBuyer() {
      this.addNewShippingOption = false;
      store.commit('global/setLoading', {
        isLoading: true,
        message: 'Retrieving shipping addresses...'
      });
      await store.dispatch('auth/refreshCurrentBuyer');
      store.commit('global/setLoading', false);
    },
    selectedShippingAddressChanged() {
      if (this.initialSelectedShippingOption === this.selectedShippingOption) {
        this.$emit('backNavigationRequested');
        return;
      }

      store.commit('global/setBannerStatus', null);
      this.$emit('shippingAddressUpdated', this.selectedShippingOption);
    },
    async shippingOptionAdded(setAsDefault: boolean) {
      await this.getCurrentBuyer();
      if (setAsDefault) {
        const newAddress = this.shippingOptions.find((so) => so.isDefault);
        if (newAddress) {
          this.selectedShippingOption = newAddress;
        }
      }
    },
    selectShippingAddress(selectedAddress: ShippingAddress) {
      // do not allow the option of deselecting.
      if (selectedAddress.id === this.selectedShippingOption.id) {
        return;
      }

      // When we select a new address, we need to remove isSelected from the other
      // addresses.
      this.selectedShippingOption = selectedAddress;

      if (this.shippingAddresses.defaultAddress !== null) {
        this.shippingAddresses.defaultAddress.isSelected =
          this.shippingAddresses.defaultAddress?.id === selectedAddress.id;
      }

      for (const address of this.shippingAddresses.nonDefaultAddresses) {
        address.isSelected = address.id === selectedAddress.id;
      }
    }
  }
});
