
import { defineComponent } from 'vue';
import HeaderComponent from '@/common/HeaderComponent.vue';
import FooterComponent from '@/common/FooterComponent.vue';
import BannerComponent from '@/common/BannerComponent/BannerComponent.vue';

export default defineComponent({
  name: 'SingleLayout',
  components: {
    HeaderComponent,
    FooterComponent,
    BannerComponent
  },
  data() {
    return {
      isMounted: false,
      showFooter: false
    };
  },
  computed: {
    showBackToTop(): boolean {
      if (typeof this.$route.meta.showBackToTop === 'boolean') {
        return this.$route.meta.showBackToTop;
      }
      return true;
    }
  },
  watch: {
    $route(to) {
      if (to.meta.showFooter) {
        this.showFooter = true;
      } else {
        this.showFooter = false;
      }
    }
  },
  mounted() {
    this.isMounted = true;
    if (this.$route.meta.showFooter) {
      this.showFooter = true;
    } else {
      this.showFooter = false;
    }
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
});
