import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _component_BoostSectionHeader = _resolveComponent("BoostSectionHeader")!

  return (_openBlock(), _createBlock(_component_BoostSectionHeader, {
    title: "Stripe",
    "has-content": ""
  }, {
    default: _withCtx(() => [
      (_ctx.buyerSettings.isAffiliatePayoutConnected)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, " Disconnecting from Stripe will prevent you from withdrawing any earnings. "))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, " Connect Stripe to withdraw commissions earned from selling with Tags. ")),
      (_ctx.buyerSettings.isAffiliatePayoutConnected)
        ? (_openBlock(), _createBlock(_component_BoostButton, {
            key: 2,
            label: "Disconnect Stripe",
            "button-style": "optional",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('disconnectFromStripe')))
          }))
        : (_openBlock(), _createBlock(_component_BoostButton, {
            key: 3,
            label: "Connect Stripe",
            "button-style": "primary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('connectToStripe')))
          }))
    ]),
    _: 1
  }))
}