
import { defineComponent } from 'vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';

export default defineComponent({
  name: 'BuyerCreateAccount',
  props: {
    buttonLoadingState: {
      type: String as () => ButtonLoadingState,
      required: true
    }
  },
  emits: ['startRegistration', 'confirmMobileNumber'],
  data() {
    return {
      hasSentConfirmationCode: false,
      confirmationCode: '',
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: ''
      }
    };
  },
  methods: {
    startRegistration() {
      this.$emit('startRegistration', this.userInfo);
      this.hasSentConfirmationCode = true;
    },
    confirmMobileNumber() {
      console.log('confirm mobile number');
      this.$emit('confirmMobileNumber', this.confirmationCode);
    }
  }
});
