
import { defineComponent } from 'vue';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import { store } from '@/store';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import SellerCreateAccount from '@/seller/registration/SellerCreateAccount.vue';
import SellerRegistrationDetails from '@/seller/registration/SellerRegistrationDetails.vue';
import helpers from '@/helpers';
import { UserRegistration } from '@/store/types';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { mapMutations } from 'vuex';

export default defineComponent({
  name: 'SellerRegistration',
  components: {
    SellerCreateAccount,
    SellerRegistrationDetails
  },
  data() {
    return {
      registrationState: {
        CREATE_ACCOUNT: 1,
        DETAILS: 2
      },
      currentState: 1,
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        hashtagPrefix: '',
        company: {
          email: '',
          phone: '',
          postalCode: ''
        }
      },
      isLoading: false
    };
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    setRegistrationType(state: number) {
      this.currentState = state;
    },
    cancelRegistration() {
      this.setRegistrationType(this.registrationState.CREATE_ACCOUNT);
    },
    async reserveTag(tag: string) {
      this.isLoading = true;
      Object.assign(this.userInfo, tag);

      try {
        const hashtagGenerateResponse = await FirebaseRequest.createRequest<{
          hashtagPrefix: string;
        }>(FirebaseRequestTypes.CHECK_SELLER_HASHTAG, {
          hashtagPrefix: this.userInfo.hashtagPrefix
        });

        if (hashtagGenerateResponse) {
          if (hashtagGenerateResponse.code === 'SELLER_DOES_EXIST') {
            this.isLoading = false;
            store.commit('global/setBannerStatus', {
              text: BannerMessageStrings.SELLER_TAG_UNAVAILABLE,
              type: BannerType.error
            });
            return;
          }

          this.userInfo.hashtagPrefix = helpers.formatHashTag(
            hashtagGenerateResponse.data?.hashtagPrefix || ''
          );
        } else {
          this.isLoading = false;
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.SELLER_ERROR_RESERVING_TAG,
            type: BannerType.error
          });
        }
        this.isLoading = false;
        this.setRegistrationType(this.registrationState.DETAILS);
      } catch (ex) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/ban-types
    async createAccount(info: object) {
      this.isLoading = true;
      this.setLoading({ isLoading: true, message: 'Registration complete!' });
      Object.assign(this.userInfo, info);

      try {
        const createAccountPayload = {
          companyName: this.userInfo.hashtagPrefix,
          prefix: this.userInfo.hashtagPrefix,
          companyInfo: this.userInfo.company,
          userInfo: this.userInfo
        };
        const createResponse = await FirebaseRequest.createRequest<{
          err: { code: string; message: string };
          token: string;
          accountId: string;
        }>(FirebaseRequestTypes.CREATE_SELLER_ACCOUNT, createAccountPayload);

        if (!createResponse) {
          this.isLoading = false;
          this.setLoading({ isLoading: false });
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.SELLER_ERROR_CREATING_ACCOUNT,
            type: BannerType.error
          });
          return;
        }

        if (createResponse.code === 'INVALID_REQUEST') {
          this.isLoading = false;
          this.setLoading({ isLoading: false });
          // email address is improperly formatted
          if (createResponse.data?.err.code === 'auth/invalid-email') {
            store.commit('global/setBannerStatus', {
              text: BannerMessageStrings.SELLER_INVALID_EMAIL,
              type: BannerType.error
            });
            return;
          }

          if (createResponse.data?.err.code === 'auth/email-already-exists') {
            store.commit('global/setBannerStatus', {
              text: BannerMessageStrings.SELLER_EMAIL_ALREADY_IN_USE,
              type: BannerType.error
            });
            return;
          }

          store.commit('global/setBannerStatus', {
            text: createResponse.data?.err.message,
            type: BannerType.error
          });
          return;
        }

        // Ex)
        // {"status":"ok",
        //  "code":"SELLER_CREATED",
        //  "data":{
        //      "accountId":"-XXXXX",
        //      "uid":"1234567asdf",
        //      "token":"XYZ"
        //      }
        //  }

        // account is created, so sign in
        this.setLoading({
          isLoading: true,
          message: 'Heading to your dashboard...'
        });
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await signInWithCustomToken(
          getAuth(),
          createResponse.data?.token || ''
        );
        const currentUser = await getAuth().currentUser;
        store.commit(
          'auth/setLoginOrRegistrationType',
          UserRegistration.SELLER
        );
        store.commit('auth/setCurrentUser', currentUser);
        // Must set the role account as well as this gets loaded from the store for the account
        store.commit('auth/setRoleAccounts', [
          { accountId: createResponse.data?.accountId, role: 'owner' }
        ]);

        await this.$store.dispatch('accounts/loadAccounts').then(() => {
          console.log('registration dispatch loading complete');
        });

        // direct to account dashboard
        this.setLoading({ isLoading: false });
        await this.$router.push({ path: '/store' });
      } catch (ex) {
        this.isLoading = false;
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    }
  }
});
