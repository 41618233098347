import { ShippingStatus } from '@/../enums/shippingStatus';
import { OrderStatus } from '@/../models/sellerOrder';
import { RoleType } from '@/../enums/roleTypes';
import { Permissions, RootState } from '@/store/types';
import { Store } from 'vuex';
import {
  Buyer,
  PaymentOption,
  ShippingAddress
} from './buyer/settings/BuyerSettingsInterfaces';
import { AccountPlan } from '@/models/accountObject';

const rolePermissions = new Map<RoleType, Permissions[]>();
rolePermissions.set(RoleType.Owner, [
  Permissions.Attribution,
  Permissions.Audience,
  Permissions.Dashboard,
  Permissions.Orders,
  Permissions.Transactions,
  Permissions.Settings,
  Permissions.Tags,
  Permissions.TransferOwnership,
  Permissions.Analytics,
  Permissions.Apps
]);
rolePermissions.set(RoleType.SellerAdmin, [
  Permissions.Attribution,
  Permissions.Audience,
  Permissions.Dashboard,
  Permissions.Orders,
  Permissions.Transactions,
  Permissions.Settings,
  Permissions.Tags,
  Permissions.Analytics,
  Permissions.Apps
]);
rolePermissions.set(RoleType.Staff, [
  Permissions.Attribution,
  Permissions.Audience,
  Permissions.Dashboard,
  Permissions.Orders,
  Permissions.Transactions,
  Permissions.Tags,
  Permissions.Analytics
]);
rolePermissions.set(RoleType.Agency, [
  Permissions.Attribution,
  Permissions.Audience,
  Permissions.Dashboard,
  Permissions.Orders,
  Permissions.Transactions,
  Permissions.Tags,
  Permissions.Analytics,
  Permissions.Apps
]);

export default {
  // always return https version of url
  getSecureUrl(url: string): string {
    if (url === undefined || url === '') {
      return '';
    }

    return url.startsWith('https') || url.startsWith('/')
      ? url
      : 'https' + url.substring(4);
  },

  getExpirationYears(): Array<{ name: number; value: number }> {
    const currentYear = new Date().getFullYear();
    const optionLimit = 10;
    const years = [];

    for (let i = currentYear; i < currentYear + optionLimit; i++) {
      years.push({ name: i, value: i });
    }

    return years;
  },

  getExpirationMonths(): Array<{ name: string; value: number }> {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const options = [];

    for (let i = 0; i < months.length; i++) {
      options.push({ name: months[i], value: i + 1 });
    }

    return options;
  },

  isNumeric(str: string): boolean {
    if (typeof str !== 'string') {
      return false; // we only process strings!
    }
    // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this) and ensure strings of whitespace fail
    return !isNaN(Number(str)) && !isNaN(parseFloat(str));
  },

  // ensure a phone number always has the 1 country code prefix
  // NOTE: We only support US numbers
  prefixPhoneNumber(phoneNumber: string): string {
    return `${phoneNumber.charAt(0) === '1' ? '' : '1'}${phoneNumber}`;
  },

  getShippingStatusLabel(status: ShippingStatus): string {
    const statusMessages = {
      [ShippingStatus.pending]: 'Awaiting',
      [ShippingStatus.fulfilled]: 'Shipped',
      [ShippingStatus.refunded]: 'Refunded',
      [ShippingStatus.failed]: 'Failed'
    };
    return statusMessages[status];
  },

  getOrderStatusLabel(status: OrderStatus): string {
    const statusMessages = {
      [OrderStatus.pending]: 'Pending',
      [OrderStatus.fulfilled]: 'Fulfilled',
      [OrderStatus.refunded]: 'Refunded',
      [OrderStatus.failed]: 'Failed'
    };
    return statusMessages[status];
  },

  getTransactionStatusLabel(status: OrderStatus): string {
    const statusMessages = {
      [OrderStatus.pending]: 'Received',
      [OrderStatus.fulfilled]: 'Received',
      [OrderStatus.refunded]: 'Refunded',
      [OrderStatus.failed]: 'Failed'
    };
    return statusMessages[status];
  },

  processDate(date: number): string {
    const convertedDate = new Date(date);
    return convertedDate.toLocaleString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    });
  },

  currencyFormatter(price: number | string): string {
    if (typeof price === 'string') {
      // Since the string is numeric that means it isn't the number of pennies and so multiply x 100
      if (this.isNumeric(price)) {
        price = +price * 100;
      } else {
        // This is a non-numeric string so just display whatever it is
        return price.includes('$') ? price.toString() : `$${price}`;
      }
    }
    // This should be a number that represents number of pennies so format accordingly as $x.xx
    price = +price || 0;
    const num = (price / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return num;
  },

  validTag(
    inputTag: string,
    defineWords: { [index: string]: string }
  ): boolean {
    let isValid = true;
    for (const key of Object.keys(defineWords)) {
      const obj = defineWords[key];
      if (inputTag.toLowerCase().indexOf(obj.toString()) > -1) {
        isValid = false;
      }
    }
    return isValid;
  },

  formatHashTag(hashTag: string): string {
    return hashTag ? '$' + hashTag.replace(/^#|\$/, '').toUpperCase() : '';
  },

  getTemporaryId(): string {
    return `new-${Math.floor(Math.random() * 1000)}`;
  },

  isMobile(): boolean {
    return screen.width <= 1024;
  },

  isIOS(): boolean {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      navigator.userAgent.includes('Mac')
    );
  },

  deepCopy<T>(source: T): T {
    return JSON.parse(JSON.stringify(source));
  },

  getPermissionsForRole(role: RoleType): Permissions[] {
    return rolePermissions.get(role) || [];
  },

  userHasPermission(permission: Permissions, store: Store<RootState>): boolean {
    const isSuperAdmin = store.getters['auth/isSuperAdmin'] as boolean;
    const usersPermissions =
      store.getters['accounts/currentAccountPermissions'];
    return usersPermissions?.includes(permission) || isSuperAdmin;
  },

  currentPlanHasAccess(
    minimumPlan: AccountPlan,
    currentPlan: string | undefined
  ): boolean {
    if (minimumPlan === 'Basic') {
      return true;
    }

    // A 'currentPlan' of undefined is the same as 'basic'.
    // So, we can return false if 'currentPlan' is undefined or null.
    if (!currentPlan) {
      return false;
    }

    // If the minimumPlan required is 'plus', then any plan that is higher
    // than that should also have access.
    if (minimumPlan === 'Plus') {
      return ['plus', 'pro', 'enterprise'].includes(currentPlan.toLowerCase());
    }

    // If the minimumPlan required is 'pro', then any plan that is higher
    // than that should also have access.
    if (minimumPlan === 'Pro') {
      return ['pro', 'enterprise'].includes(currentPlan.toLowerCase());
    }

    if (minimumPlan === 'Enterprise') {
      return 'enterprise' === currentPlan.toLowerCase();
    }

    // If there is some unaccounted plan, return false.
    // We want to explicitly define access/permissions.
    return false;
  },

  /**
   * Gets the default PaymentOption for the Buyer. If there is no default,
   * then the first element in the PaymentOptions list is returned.
   * Otherwise, if no paymentOptions are available, returns undefined.
   * @param buyer {Buyer} The buyer.
   */
  getDefaultPaymentOption(buyer: Buyer): PaymentOption | undefined {
    const defaultPaymentOption = buyer.paymentOptions.find(
      (sa) => sa.isDefault
    );
    if (defaultPaymentOption) {
      return defaultPaymentOption;
    }

    return buyer.paymentOptions?.length > 0
      ? buyer.paymentOptions[0]
      : undefined;
  },

  /**
   * Gets the default ShippingAddress for the Buyer. If there is no default,
   * then the first element in the ShippingAddresses list is returned.
   * Otherwise, if no ShippingAddresses are available, returns undefined.
   * @param buyer {Buyer} The buyer.
   */
  getDefaultShippingAddress(buyer: Buyer): ShippingAddress | undefined {
    const defaultShippingAddress = buyer.shippingAddresses.find(
      (sa) => sa.isDefault
    );
    if (defaultShippingAddress) {
      return defaultShippingAddress;
    }

    return buyer.shippingAddresses?.length > 0
      ? buyer.shippingAddresses[0]
      : undefined;
  }
};

export class PredicateBuilder<T> {
  constructor(public predicate: (x: T) => boolean) {}

  and(predicate: (x: T) => boolean): (x: T) => boolean {
    const priorCondition = this.predicate;
    this.predicate = (x: T) => {
      return priorCondition(x) && predicate(x);
    };
    return this.predicate;
  }
}
