
import { TagType } from '@/../enums/tagType';
import { ProductSource } from '@/../models/sellerOrder';
import { TagDetail, TagSku, TagVariant } from '@/../models/tag';
import { store } from '@/store';
import { defineComponent } from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import ConfirmComponent from '@/common/PopupComponent/ConfirmComponent.vue';
import {
  PopupMessageTitles,
  PopupMessageStrings,
  PopupMessageButtonCaptions
} from '@/common/PopupComponent/PopupMessages';
import ProductEditAttributions from '@/seller/products/ProductEditAttributions.vue';
import ProductEditVariations from '@/seller/products/ProductEditVariations.vue';
import { Attribution } from '@/models/firebase/product';
import { AccountObject } from '@/models/accountObject';

enum ProductEditStatus {
  EditProduct,
  AddEditVariations,
  AddEditAttribution
}

export default defineComponent({
  name: 'ProductEdit',
  components: {
    ConfirmComponent,
    ProductEditAttributions,
    ProductEditVariations
  },
  async beforeRouteLeave() {
    if (this.originalValue === JSON.stringify(this.product)) {
      return true;
    } else {
      const confirmation = this.$refs.confirmation as typeof ConfirmComponent;
      const confirmed = await confirmation.show(
        PopupMessageTitles.UNSAVED_CHANGES,
        PopupMessageStrings.UNSAVED_CHANGES,
        PopupMessageButtonCaptions.DISCARD_CHANGES
      );

      return confirmed;
    }
  },
  data() {
    return {
      ProductEditStatus,
      originalValue: '',
      hasVariations: false,
      noVariantionsQuantity: 0,
      saveButtonState: ButtonLoadingState.initial,
      productEditState: ProductEditStatus.EditProduct
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      product: 'product/currentProduct'
    }),
    notConnectedToStripe(): boolean {
      return !(this.currentAccount as AccountObject).isStripeConnected;
    },
    readyToSell: {
      set(val: boolean) {
        this.product.disabled = !val;
      },
      get(): boolean {
        return !this.product.disabled;
      }
    },
    canSave(): boolean {
      let results = true;
      if (this.hasVariations) {
        results = this.product.variants?.length > 0;
      } else {
        const tag = this.product as TagDetail;
        results = tag?.price
          ? parseFloat(tag?.price.toString().replace('$', '')) > 0.99
          : false;
      }
      return results;
    }
  },
  watch: {
    noVariantionsQuantity(newValue: number) {
      if (!this.product.skus.length) {
        const tagSku: TagSku = {
          id: '',
          imageUrl: this.product.imageUrl,
          sku: '00000',
          price: this.product.price,
          quantity: newValue
        };
        this.product.skus = [tagSku];
      } else {
        this.product.skus[0].quantity = newValue;
      }
    }
  },
  created() {
    this.originalValue = JSON.stringify(this.product);
  },
  async mounted() {
    this.setLoading({ isLoading: true, message: 'Loading product details' });

    if (!this.$route.params.productId && !this.product) {
      const newProduct: TagDetail = {
        accountId: this.currentAccount?.accountId,
        imageUrl: require('../../assets/default-product.jpg'),
        productId: '',
        hashtag: '',
        title: '',
        productSource: ProductSource.Boost,
        type: TagType.PRODUCT,
        totalSales: 0,
        quantitySold: 0,
        enableMarketplace: true,
        savedForLaterCount: 0,
        isFeatured: false,
        attributions: [],
        chargeTax: false,
        hideProduct: false,
        variants: [],
        description: '',
        skus: [],
        keywords: [],
        price: '',
        shippingCharge: 0,
        disabled: true,
        parcel: {
          weight: 0,
          height: 0,
          length: 0,
          width: 0
        }
      };
      store.commit('product/setCurrentProduct', newProduct);
    } else if (this.$route.params.productId && !this.product) {
      const payload = {
        productId: this.$route.params.productId,
        accountId: this.currentAccount.accountId
      };

      await this.setupCurrentProduct(payload);
    }

    this.hasVariations = this.product?.variants?.length > 0;
    if (!this.hasVariations && this.product?.skus?.length) {
      this.noVariantionsQuantity = this.product.skus[0].quantity;
    }

    this.setLoading(false);
  },
  methods: {
    ...mapActions('product', ['saveCurrentProductToDb', 'setupCurrentProduct']),
    ...mapMutations('global', ['setLoading']),
    ...mapMutations('product', [
      'setCurrentProductAttributions',
      'setCurrentProductVariants'
    ]),
    async commitChanges() {
      this.saveButtonState = ButtonLoadingState.loading;
      //Have to deal with No variations product and setup the sku with the quantity
      if (!this.hasVariations) {
        if (!this.product.skus?.length) {
          const tagSku: TagSku = {
            id: '',
            imageUrl: this.product.imageUrl,
            sku: '00000',
            price: this.product.price,
            quantity: this.noVariantionsQuantity
          };
          this.product.skus = [tagSku];
        } else {
          this.product.skus[0].quantity = this.noVariantionsQuantity;
          this.product.skus[0].sku = '00000';
          this.product.skus[0].price = this.product.price;
          this.product.skus[0].imageUrl = this.product.imageUrl;
          this.product.skus.length = 1;
          this.product.variants = [];
        }
      }

      await this.saveCurrentProductToDb();
      this.saveButtonState = ButtonLoadingState.complete;
      this.originalValue = JSON.stringify(this.product);
      this.$router.push({
        name: 'seller-product-detail',
        params: { productId: this.product.productId }
      });
    },
    goBack() {
      //TODO: Need to make sure that the changes are abandonded and the current product is reloaded
      const productId = this.product?.productId;
      if (productId) {
        this.$router.push({
          name: 'seller-product-detail',
          params: { productId }
        });
      } else {
        this.$router.push({ name: 'seller-products' });
      }
    },
    editAttribution() {
      this.productEditState = ProductEditStatus.AddEditAttribution;
    },
    editVariations() {
      this.productEditState = ProductEditStatus.AddEditVariations;
    },
    handleSaveAttributions(attributions: Attribution[]) {
      this.setCurrentProductAttributions(attributions);
      this.productEditState = ProductEditStatus.EditProduct;
    },
    handleSaveVariations({
      variants,
      skus
    }: {
      variants: TagVariant[];
      skus: TagSku[];
    }) {
      this.setCurrentProductVariants({
        variants,
        skus
      });
      this.hasVariations = this.product?.variants?.length > 0;
      this.productEditState = ProductEditStatus.EditProduct;
    }
  }
});
