import { GetterTree, MutationTree } from 'vuex';
import { BrandInformation, GlobalState, RootState } from './types';
import { BannerMessage } from '@/common/BannerComponent/BannerMessages';

const globalState: GlobalState = {
  brandInformation: null as BrandInformation | null,
  isLoading: false,
  loadingMessage: '',
  bannerMessage: null as BannerMessage | null,
  mobileNavOpen: false,
  hasContextualButton: false,
  searchText: null,
  customColor: null,
  showHelp: false,
  showLogo: true
};

const globalGetters: GetterTree<GlobalState, RootState> = {
  isBranded: (state: GlobalState): boolean => {
    return state.brandInformation !== null;
  },
  getBrandGuidelines: (state: GlobalState): string[] => {
    return state.brandInformation?.guidelines ?? [];
  },
  getBrandName: (state: GlobalState): string => {
    if (state.brandInformation) {
      return state.brandInformation.brandName;
    }
    return '';
  },
  getBrandLogo: (state: GlobalState): string => {
    if (state.brandInformation) {
      return state.brandInformation.logoUrl;
    }
    return '';
  },
  isLoading: (state: GlobalState): boolean => {
    return state.isLoading;
  },
  getLoadingMessage: (state: GlobalState): string => {
    return state.loadingMessage;
  },
  getBannerStatus: (state: GlobalState): BannerMessage | null => {
    return state.bannerMessage;
  },
  isMobileNavOpen: (state: GlobalState): boolean => {
    return state.mobileNavOpen;
  },
  hasContextualButton: (state: GlobalState): boolean => {
    return state.hasContextualButton;
  },
  getSearchText: (state: GlobalState): string | null => {
    return state.searchText;
  },
  getCustomColor: (state: GlobalState): string => {
    return state.customColor ? state.customColor : 'default';
  },
  getShowHelp: (state: GlobalState): boolean => {
    return state.showHelp;
  },
  getShowLogo: (state: GlobalState): boolean => {
    return state.showLogo;
  }
};

const globalMutations: MutationTree<GlobalState> = {
  setBrandInformation: (state: GlobalState, payload: BrandInformation) => {
    state.brandInformation = payload;
    state.customColor = payload?.customColor || 'default';
  },
  setLoading: (
    state: GlobalState,
    payload: boolean | { isLoading: boolean; message: string }
  ) => {
    if (typeof payload === 'object') {
      state.loadingMessage = payload.message || '';
      state.isLoading = payload.isLoading;
    }
    if (typeof payload === 'boolean') {
      state.isLoading = payload;
      state.loadingMessage = '';
    }
  },
  setBannerStatus: (state: GlobalState, payload: BannerMessage | null) => {
    state.bannerMessage = payload;
  },
  setMobileNavOpen: (state: GlobalState, payload: boolean) => {
    state.mobileNavOpen = payload;
  },
  setContextualButton: (state: GlobalState, payload: boolean) => {
    state.hasContextualButton = payload;
  },
  setSearchText: (state: GlobalState, payload: string | null) => {
    state.searchText = payload;
  },
  setCustomColor: (state: GlobalState, payload: string | null) => {
    state.customColor = payload;
  },
  setShowHelp: (state: GlobalState, payload: boolean) => {
    state.showHelp = payload;
  },
  setShowLogo: (state: GlobalState, payload: boolean) => {
    state.showLogo = payload;
  }
};

export const global = {
  getters: globalGetters,
  state: globalState,
  mutations: globalMutations,
  namespaced: true
};
