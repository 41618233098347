import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "aspect-h-1 aspect-w-1 aspect-square w-full overflow-hidden relative" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "object-cover object-center w-full h-full rounded-lg overflow-hidden",
      src: _ctx.productImage,
      alt: _ctx.product.title
    }, null, 8, _hoisted_2)
  ]))
}