
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { ShippingAddress } from '@/buyer/settings/BuyerSettingsInterfaces';

export default defineComponent({
  name: 'AddressSummaryComponent',
  props: {
    address: {
      type: Object as PropType<ShippingAddress>,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    verticallyCenterActionSlot: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    fullAddress() {
      if (!this.address) {
        return 'No delivery information';
      }

      const addressComponents = [
        this.address.address1,
        this.address.address2,
        this.address.city,
        this.address.state,
        this.address.postalCode,
        this.address.country
      ];

      // filter(Boolean) removes any null/undefined values.
      return addressComponents.filter(Boolean).join(', ');
    },
    labelText() {
      return this.hideLabel ? '' : 'Delivery';
    }
  }
});
