
import { defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';
import { useCurrencyInput, CurrencyInputOptions } from 'vue-currency-input';

export type InputType = 'text' | 'currency' | 'password';

export default defineComponent({
  name: 'BoostInput',
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String as () => InputType,
      default: 'text'
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    returnType: {
      type: String,
      default: 'string'
    },
    name: {
      type: String
    },
    description: {
      type: String
    },
    rules: {
      type: String
    },
    placeholder: {
      type: String
    },
    inputId: {
      type: String,
      required: true
    },
    attributes: {
      type: String
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    customError: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    ignoreGlobalStyle: {
      type: Boolean,
      default: false
    },
    currencyOptions: {
      type: Object as () => CurrencyInputOptions,
      default: () => {
        return {
          currency: 'USD',
          locale: 'en',
          precision: 2,
          valueScaling: 'precision',
          autoDecimalDigits: true
        };
      }
    },
    autocomplete: {
      type: String
    }
  },
  compatConfig: { COMPONENT_V_MODEL: false },
  emits: ['update:modelValue', 'input'],
  setup(props) {
    // Vee Validate

    const { value, errorMessage, handleBlur, handleChange, meta } = useField(
      props.inputId,
      props.rules,
      {
        initialValue: props.modelValue,
        // We need to set this to false when type === 'currency' because it
        // breaks the vue-currency-input functionality. The modelValue update
        // event is still emitted by this component, instead of the vee-validate
        // useField hook, so the binding still works properly. This was just
        // a case of 2 plugins competing and breaking each other.
        syncVModel: props.type !== 'currency'
      }
    );

    // Vue Currency
    const { inputRef, setOptions, setValue } = useCurrencyInput(
      props.currencyOptions
    );

    watch(
      () => props.modelValue,
      (val) => {
        if (props.type === 'currency') {
          setValue(val as number | null);
        } else {
          value.value = val.toString();
        }
      }
    );

    watch(
      () => props.currencyOptions,
      (options) => {
        setOptions(options);
      }
    );

    return {
      inputRef,
      value,
      handleChange,
      handleBlur,
      errorMessage,
      meta
    };
  },
  data() {
    return {
      innerType: 'text'
    };
  },
  computed: {
    isRequired(): boolean {
      if (this.rules) {
        return this.rules.includes('required');
      } else {
        return false;
      }
    },
    showRequiredIndicator(): boolean {
      if (!this.rules || !this.rules.includes('required')) {
        return false;
      }

      return !(
        this.attributes && this.attributes.includes('no-required-indicator')
      );
    },
    leftIcon(): boolean {
      return !!this.$slots.leftIcon;
    },
    rightIcon(): boolean {
      return !!this.$slots.rightIcon;
    },
    hasPrefix(): boolean {
      return !!this.prefix;
    },
    customFailed(): boolean {
      return this.customError.length > 0;
    }
  },
  mounted() {
    if (this.type) {
      this.innerType = this.type;
    }
  },
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.value);
      this.$emit('input', this.value);
    },
    togglePassword() {
      this.innerType === 'text'
        ? (this.innerType = 'password')
        : (this.innerType = 'text');
    }
  }
});
