
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoostTagCard',
  props: {
    avatar: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    productId: {
      type: String,
      default: ''
    }
  },
  methods: {
    showProduct(productId: string) {
      this.$router.push({
        name: 'seller-product-detail',
        params: { productId }
      });
    }
  }
});
