
import { defineComponent } from 'vue';

interface ButtonPosition {
  x: number;
  y: number;
  top: number;
  left: number;
  bottom: number;
  right: number;
}

export interface ContextMenuCoords {
  x: number;
  y: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Entity = Record<string, string | number | object>;

export interface ContextMenuItem {
  label: string;
  isHidden?(entity: Entity): boolean;
  isDisabled?(entity: Entity): boolean;
  isDangerous?(entity: Entity): boolean;
  onClick(entity: Entity): void;
}

export default defineComponent({
  name: 'BoostContextMenu',
  props: {
    menu: {
      type: Array as () => ContextMenuItem[],
      required: true
    },
    entity: {
      type: Object as () => Entity
    }
  },
  data() {
    return {
      isVisible: false,
      buttonCoords: null as null | ButtonPosition,
      windowWidth: window.innerWidth,
      menuCoords: {
        x: 0,
        y: 0
      } as ContextMenuCoords
    };
  },
  computed: {
    computedItems() {
      return this.menu; // this.menu.filter((item) => !item.isHidden);
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
    style() {
      if (this.isMobile) {
        return {
          left: '20px',
          bottom: '20px'
        };
      } else if (this.menuCoords) {
        return {
          top: this.menuCoords.y + 'px',
          left: this.menuCoords.x + 'px'
        };
      }
      return {};
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.windowWidth = window.innerWidth;
    },
    toggleMenu() {
      // set visibility to true first so we can capture ref dimensions
      this.isVisible = true;

      this.$nextTick(() => {
        const contextMenu = (
          this.$refs.menu as HTMLElement
        ).getBoundingClientRect();
        const coord = (
          this.$refs.button as HTMLElement
        ).getBoundingClientRect();
        const coords = {
          x: coord.x,
          y: coord.y,
          top: coord.top,
          left: coord.left,
          bottom: coord.bottom,
          right: coord.right
        };
        this.buttonCoords = coords;

        // Out of bounds rules to adjust alignment
        const outOfBoundsX = coord.x + contextMenu.width > window.innerWidth;
        const outOfBoundsY =
          coord.bottom + contextMenu.height > window.innerHeight;

        if (outOfBoundsX) {
          this.menuCoords.x = coord.right - contextMenu.width;
        } else {
          this.menuCoords.x = coord.left;
        }

        if (outOfBoundsY) {
          this.menuCoords.y = coord.top - contextMenu.height;
        } else {
          this.menuCoords.y = coord.bottom;
        }
      });
    },
    handleClick(item: ContextMenuItem, entity: Entity) {
      if (item.isDisabled && item.isDisabled(entity)) {
        return;
      }

      item.onClick(entity);
      this.isVisible = false;
    }
  }
});
