
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmComponent',

  data: () => ({
    isVisible: false,
    title: '',
    message: '',
    confirmText: '',
    showCancelButton: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resolvePromise: (data: boolean) => {
      return;
    }
  }),

  methods: {
    async show(
      title: string,
      message: string,
      confirmText: string,
      showCancel = false
    ): Promise<boolean> {
      this.title = title;
      this.message = message;
      this.confirmText = confirmText;
      this.showCancelButton = showCancel;

      // Once we set our config, we tell the popup modal to open
      this.open();
      // Return promise so the caller can get results
      return new Promise<boolean>((resolve) => {
        this.resolvePromise = resolve;
      });
    },

    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },

    _confirm() {
      this.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.close();
      this.resolvePromise(false);
    }
  }
});
