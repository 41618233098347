/**
 * Popup Messages
 */

export enum PopupMessageTitles {
  GENERAL_ALERT = 'Woah!',
  GENERAL_ALERT_2 = 'Hmm...',
  GENERAL_ALERT_3 = 'Stop there!',
  GENERAL_CONFIRMATION = 'Are you absolutely sure?',

  DELETE_CONFIRMATION = 'Are you sure?',
  SAVE_CONFIRMATION = 'Save Changes?',
  OPTION_REMOVAL = 'Option removal',
  UNSAVED_CHANGES = 'Changes are not saved!',

  ADD_USER = 'Add User',
  TRANSFER_OWNER = 'Transfer Ownership',

  DOWNLOAD_TAGS = 'Download Tags',

  EXPORT = 'Export',
  EDIT_AFFILIATE_COMMISSION = 'Edit Commission',
  AFFILIATE_SUSPEND = 'Suspend Advocate',
  AFFILIATE_REINSTATE = 'Reinstate Advocate',

  LATER_APP = 'Later App',
  SHARE_APP = 'Share App',
  GUIDELINES_APP = 'Edit Guidelines',
  MARKETPLACE_APP = 'Enable Marketplace'
}

export enum PopupMessageStrings {
  CANCEL_REGISTRATION_PROCESS = 'You want to leave so soon? You haven’t completed your registration!',

  BUYER_CANCEL_UPDATE_ACCOUNT_SETTINGS = 'Stop right there, soldier! You haven’t finished updating your settings.',

  SELLER_CANCEL_SAVE_CHANGES = 'You have unsaved changes. If you leave before saving, your changes will be lost.',
  SELLER_DEACTIVATE_ACCOUNT = 'Are you sure you want to deactivate your account? You will lose your tag and your account.',
  SELLER_PRODUCT_MISSING_QUANTITY = 'There doesn’t seem to be a quantity set, is that on purpose?\n' +
    'We’ll automatically set it to 0 if you don’t specify.',
  SELLER_DELETE_PRODUCT = 'By deleting this product, you delete the entire product. Is that what you wanted?',
  SELLER_OPTION_REMOVAL = 'By removing this option, you remove it from all\n' +
    'other products on this list.',
  SELLER_DELETE_PRODUCT_VERSION = 'Would you like to delete this specific version?\n' +
    'You can’t go back once you do.',
  CANCEL_IMAGE_REMOVAL = 'Do you really want to remove this image?',
  ADD_USER = 'Enter the users email address and assign them a role to send an account invitation.',
  UNSAVED_CHANGES = 'You are navigating away from this page and have unsaved changes.',

  EXPORT_ANALYTICS = 'Press "Export" to download your analytics breakdown in a .CSV file.',
  EDIT_AFFILIATE_COMMISSION = 'Apply your global commission or set a custom percentage to be earned by the advocate.',
  SUSPEND_AFFILIATE = 'Suspending advocates will deactivate their tags and prevent them from generating any new tags associated with your brand.',
  REINSTATE_AFFILIATE = 'Reinstating advocates will restore all of their tags and allow them to generate new tags associated with your brand.',

  EDIT_LATER_APP = 'Capture top of funnel data by letting your customers save products directly from the product page.',
  EDIT_SHARE_APP = 'Convert your customers into advocates by allowing them to generate tags on the product page.',
  EDIT_GUIDELINES_APP = 'These will show when someone creates their own checkout for your product.',
  EDIT_MARKETPLACE_APP = 'Allow sellers to discover your products in our Marketplace.'
}

export enum PopupMessageButtonCaptions {
  OK_YES = 'Yes',
  OK_YES_2 = 'Okay, sweet!',
  OK_YES_3 = 'I want to go.',
  OK_YES_4 = 'Yeah I meant to!',
  OK_YES_5 = 'Save them!',
  OK_YES_6 = 'Yes, that’s what I want.',
  OK_YES_7 = 'Yeah that’s cool.',
  OK_YES_8 = 'Yeah, let’s go!',
  OK_YES_9 = 'Remove image',
  OK_YES_10 = 'Save & Proceed',
  OK_YES_11 = 'Add User',
  OK_YES_12 = 'Transfer',
  OK_DISCARD = 'Discard Changes & Continue',

  CANCEL_NO = 'Cancel',
  CANCEL_NO_2 = 'I want to stay!',
  CANCEL_NO_3 = 'I’ll go fix that.',
  CANCEL_NO_4 = 'Keep image',
  CANCEL_NO_5 = 'I want to keep it.',
  CANCEL_NO_6 = 'I’ve changed my mind!',
  CANCEL_NO_7 = 'Go Back',

  DISCARD_CHANGES = 'Discard Changes and Continue',

  EXPORT = 'Export'
}

export interface PopupMessageButton {
  caption: PopupMessageButtonCaptions;
  primary?: boolean;
  buttonClass?: string;
  event?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: {};
}

export interface PopupMessage {
  title: PopupMessageTitles | string;
  message: PopupMessageStrings | string;
  button1?: PopupMessageButton;
  button2?: PopupMessageButton;
}
