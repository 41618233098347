
import { defineComponent } from 'vue';
import BoostIcon from '@/common/controls/BoostIcon.vue';
import { mapMutations } from 'vuex';
import { store } from '@/store';
import { Account } from '@/models/account';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';

const SellerSettingsCustom = defineComponent({
  name: 'SellerSettingsCustom',
  components: {
    'boost-icon': BoostIcon
  },
  props: {
    customSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      completeButtonState: ButtonLoadingState.initial as ButtonLoadingState,
      // These values must match the class names in _customColors.scss
      colorOptions: [
        { name: 'Default', value: 'default' },
        { name: 'Orange', value: 'orange' },
        { name: 'Tomato', value: 'tomato' },
        { name: 'Red', value: 'red' },
        { name: 'Crimson', value: 'crimson' },
        { name: 'Pink', value: 'pink' },
        { name: 'Plum', value: 'plum' },
        { name: 'Purple', value: 'purple' },
        { name: 'Violet', value: 'violet' },
        { name: 'Indigo', value: 'indigo' },
        { name: 'Blue', value: 'blue' },
        { name: 'Cyan', value: 'cyan' },
        { name: 'Teal', value: 'teal' },
        { name: 'Green', value: 'green' },
        { name: 'Grass', value: 'grass' },
        { name: 'Brown', value: 'brown' },
        { name: 'Yellow', value: 'yellow' },
        { name: 'Light Orange', value: 'light-orange' },
        { name: 'Tan', value: 'tan' },
        { name: 'Black', value: 'black' }
      ]
    };
  },
  created() {
    const account = store.getters['accounts/currentAccount'] as Account;
    store.commit('global/setCustomColor', account.customColor);
  },
  methods: {
    ...mapMutations('global', ['setCustomColor']),
    changeColor(ev: Event) {
      const input = ev.target as HTMLInputElement;
      this.setCustomColor(input.value);
    },
    testClick() {
      this.completeButtonState = ButtonLoadingState.loading;
      setTimeout(() => {
        this.completeButtonState = ButtonLoadingState.complete;
      }, 3000);
    }
  }
});

export default SellerSettingsCustom;
