import { User } from 'firebase/auth';
import { RoleType } from '@/enums/roleTypes';
import { BannerMessage } from '@/common/BannerComponent/BannerMessages';
import { TagDetail } from '@/models/tag';
import { TagType } from '@/enums/tagType';
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';
import { FeatureType } from '@/enums/featureTypes';
import { AccountObject } from '@/models/accountObject';
import {
  MarketplaceProduct,
  MarketplaceSearchResults
} from '@/models/marketplaceProduct';
import { AffiliateBrand } from '@/models/affiliateBrands';

export interface RoleAccount {
  accountId: string;
  // TODO:  we need to role types
  role: RoleType;
}

export interface AccountsState {
  accounts: AccountObject[];
  currentAccount: AccountObject | null;
  selectedFeatureTypeId: FeatureType | string;
}

export enum UserRegistration {
  BUYER = 1,
  SELLER = 2
}

export interface AuthState {
  currentUser: User | null;
  currentBuyer: Buyer | null;
  isBuyerLoggedIn: boolean;
  isSellerLoggedIn: boolean;
  isSuperAdmin: boolean;
  isImpersonating: boolean;

  /**
   * Only Sellers and Admins have roleAccounts
   */
  roleAccounts: RoleAccount[];
  loginOrRegistrationType: UserRegistration;
}

export interface MarketplaceState {
  isSearchOpen: boolean;
  searchResults: MarketplaceSearchResults | undefined;
  selectedProductToSell: MarketplaceProduct | undefined;
  allBrands: AffiliateBrand[];
  featuredProducts: MarketplaceProduct[];
  taggedProducts: { [key: string]: string };
}

export interface RootState {
  auth: AuthState;
  accounts: AccountsState;
}

export interface ProductState {
  currentProduct: TagDetail | null;
  currentFilter: TagType | 'All';
  productIds: string[];
}

export interface PurchasesState {
  transactionIds: string[];
}

export interface OrderState {
  orderedIds: string[];
  buyerName?: string;
}

export interface AudienceState {
  audienceIds: string[];
}

export interface BannerState {
  fade: boolean;
  text: string;
  textAlign: 'left' | 'center' | 'right';
  variant: string;
  visible: boolean | number;
}

export interface BrandInformation {
  brandName: string;
  logoUrl: string;
  orderEmail: string;
  metaPixel?: string;
  customColor?: string;
  guidelines?: string[];
}

export interface GlobalState {
  brandInformation: BrandInformation | null;
  isLoading: boolean;
  loadingMessage: string;
  bannerMessage: BannerMessage | null;
  mobileNavOpen: boolean;
  hasContextualButton: boolean;
  searchText: string | null;
  customColor: string | null;
  showHelp: boolean;
  showLogo: boolean;
}

export enum Permissions {
  Dashboard,
  Tags, // Also Products
  Orders,
  Transactions,
  Audience,
  Attribution,
  Settings,
  TransferOwnership,
  Analytics,
  Apps
}
