
import { defineComponent } from 'vue';
import { FeatureType } from '@/../enums/featureTypes';
import { mapGetters, mapMutations } from 'vuex';
import { AccountObject } from '@/models/accountObject';
import { store } from '@/store';
import Helpers from '@/helpers';
import { Permissions } from '@/store/types';

export interface FeatureOption {
  featureType: FeatureType;
  imageUrl: string;
  connected: boolean;
}

const FeatureSelector = defineComponent({
  name: 'FeatureSelector',
  emits: ['feature-change'],
  data() {
    return {
      unsub: undefined as unknown as () => void,
      FeatureType,
      featureOptions: [
        {
          featureType: FeatureType.Kiosk,
          imageUrl: require('../assets/kiosk.svg'),
          connected: true
        },
        {
          featureType: FeatureType.Shopify,
          imageUrl: require('../assets/shopify.svg'),
          connected: (
            this.$store.getters['accounts/currentAccount'] as AccountObject
          )?.isShopifyConnected
        }
      ] as FeatureOption[],
      isExpanded: false,
      // Added this data property to correct issue with reactivity not working with selectedFeatureTypeId changes in the store
      selectedFeatureType: FeatureType.Kiosk
    };
  },
  computed: {
    ...mapGetters({
      selectedFeatureTypeId: 'accounts/selectedFeatureTypeId'
    }),
    currentAccount(): AccountObject {
      return this.$store.getters['accounts/currentAccount'] as AccountObject;
    },
    selectedOptionImageUrl(): string {
      return (
        this.featureOptions.find(
          (x) => x.featureType === this.selectedFeatureType
        )?.imageUrl || ''
      );
    },
    showUpgradeOption(): boolean {
      return (
        this.currentAccount.currentPlan === 'Basic' ||
        !this.currentAccount.currentPlan
      );
    },
    allowConnect(): boolean {
      return Helpers.userHasPermission(Permissions.Settings, store);
    }
  },
  created() {
    this.selectedFeatureType = this.selectedFeatureTypeId;
    // it is possible for setSelectedFeatureType to be changed via the store
    // this is being done when Shopify is connected
    // mutation, so listen for that change and update this component as required
    this.unsub = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'accounts/setSelectedFeatureType') {
        if (state.accounts.selectedFeatureTypeId !== this.selectedFeatureType) {
          this.selectedFeatureType = state.accounts
            .selectedFeatureTypeId as FeatureType;
          const shopifyFeature = this.featureOptions.find(
            (x) => x.featureType === FeatureType.Shopify
          );
          if (shopifyFeature) {
            shopifyFeature.connected =
              this.currentAccount.isShopifyConnected || false;
          }
          this.$emit('feature-change', this.selectedFeatureType);
        }
      }
    });
  },
  beforeUnmount() {
    this.unsub();
  },
  methods: {
    ...mapMutations({
      setSelectedFeatureType: 'accounts/setSelectedFeatureType'
    }),
    selectFeature(selection: FeatureOption) {
      console.log(selection);
      this.setSelectedFeatureType(selection.featureType);
      this.selectedFeatureType = selection.featureType;
      this.isExpanded = false;
      this.$router.push({ name: 'seller-home' });
      this.$emit('feature-change', selection.featureType);
    },
    connectShopify() {
      if (this.showUpgradeOption) {
        this.$router.push({
          name: 'seller-settings',
          query: { tab: 'integrations' }
        });
      } else {
        this.$router.push({ name: 'shopify-integration' });
      }
    }
  }
});

export default FeatureSelector;
