
import { defineComponent } from 'vue';
import MarketplaceHeaderComponent from '@/marketplace/components/MarketplaceHeaderComponent.vue';

export default defineComponent({
  name: 'MarketplaceLayout',
  components: {
    MarketplaceHeaderComponent
  },
  data() {
    return {
      isMounted: false
    };
  },
  mounted() {
    this.isMounted = true;
  }
});
