
import { defineComponent } from 'vue';
import CheckoutHeaderComponent from '@/common/CheckoutHeaderComponent.vue';

export default defineComponent({
  name: 'SingleLayout',
  components: {
    CheckoutHeaderComponent
  },
  data() {
    return {
      isMounted: false
    };
  },
  mounted() {
    this.isMounted = true;
  }
});
