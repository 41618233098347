
import { defineComponent } from 'vue';
import { store } from '../store/index';
import { Stat } from '../../models/stat';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import Helpers from '@/helpers';
import { Permissions } from '../store/types';
import { Buyer } from './settings/BuyerSettingsInterfaces';

const BuyerDashboard = defineComponent({
  name: 'BuyerDashboard',
  data() {
    return {
      Permissions,
      selectedMenu: 'overview',
      stats: [
        {
          title: 'Today',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 24 Hours',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 7 Days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 30 Days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 90 Days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Lifetime',
          amount: 0,
          sales: 0
        }
      ],
      statRefreshButtonState: ButtonLoadingState.initial
    };
  },
  mounted() {
    const currentBuyer = store.getters['auth/getCurrentBuyer'] as Buyer;
    if (
      !(
        currentBuyer.isVerified ||
        currentBuyer.hasAttemptedVerification ||
        currentBuyer.isAffiliatePayoutConnected
      )
    ) {
      this.selectedMenu = 'get-started';
    }
  },
  methods: {
    getPrice(price: number) {
      return Helpers.currencyFormatter(price);
    },
    async overview() {
      this.selectedMenu = 'overview';
      await this.getStats();
    },
    async getStats() {
      this.statRefreshButtonState = ButtonLoadingState.loading;

      const responseData = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_AFFILIATE_STATS,
        {}
      );

      if (responseData.status === APIResponseStatus.OK) {
        const st = responseData.data as Stat;
        if (st.today) {
          this.stats[0].amount = parseFloat(st.today.amount.toFixed(2));
          this.stats[0].sales = st.today.orders;
        }
        if (st.yesterday) {
          this.stats[1].amount = parseFloat(st.yesterday.amount.toFixed(2));
          this.stats[1].sales = st.yesterday.orders;
        }
        if (st.week) {
          this.stats[2].amount = parseFloat(st.week.amount.toFixed(2));
          this.stats[2].sales = st.week.orders;
        }
        if (st.month) {
          this.stats[3].amount = parseFloat(st.month.amount.toFixed(2));
          this.stats[3].sales = st.month.orders;
        }
        if (st.quarter) {
          this.stats[4].amount = parseFloat(st.quarter.amount.toFixed(2));
          this.stats[4].sales = st.quarter.orders;
        }
        if (st.totals) {
          this.stats[5].amount = parseFloat(st.totals.amount.toFixed(2));
          this.stats[5].sales = st.totals.orders;
        }
      }
      this.statRefreshButtonState = ButtonLoadingState.complete;
    }
  }
});

export default BuyerDashboard;
