
import { defineComponent, computed, ComputedRef } from 'vue';
import { mapMutations, useStore } from 'vuex';
import helpers from '@/helpers';
// import BuyerRegistration from '@/buyer/registration/BuyerRegistration.vue';
import { AffiliateBrand } from '@/models/affiliateBrands';

export default defineComponent({
  name: 'MarketplaceProductDetailsComponent',
  setup() {
    const store = useStore();
    function formatPrice(price: string): string {
      return helpers.currencyFormatter(price);
    }

    const selectedProduct = computed(
      () => store.getters['marketplace/getSelectedProductToSell']
    );

    const brands: ComputedRef<AffiliateBrand[]> = computed(
      () => store.getters['marketplace/getAllBrands']
    );
    const brandDetails = computed(() =>
      brands.value?.find((b) => b.accountId === selectedProduct.value.accountId)
    );

    return {
      // open,
      selectedProduct,
      brandDetails,
      formatPrice
    };
  },
  data() {
    return {
      brandName: '',
      brandLogo: ''
    };
  },
  computed: {
    // ...mapGetters('marketplace', {
    //   // selectedProduct: 'getSelectedProductToSell',
    //   // brands: 'getAllBrands'
    // }),
    // ...mapGetters({
    //   isBuyerLoggedIn: 'auth/isBuyerLoggedIn',
    //   currentUser: 'auth/getCurrentUser',
    //   currentBuyer: 'auth/getCurrentBuyer',
    //   getBrandName: 'global/getBrandName'
    // }),
  },
  // mounted() {
  //   if (!this.selectedProduct) {
  //     return;
  //   }

  //   const selectedBrand = this.brands.find(
  //     (b: AffiliateBrand) => b.accountId === this.selectedProduct.accountId
  //   );
  //   console.log(selectedBrand[0]);
  //   this.brandName = selectedBrand.brandName;
  //   this.brandLogo =
  //     selectedBrand.imageUrl ??
  //     'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=oilqXxSqey&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';
  // },
  methods: {
    ...mapMutations('marketplace', {
      selectProduct: 'setSelectedProductToSell'
    })
  }
});
