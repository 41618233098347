import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "boost-toggle" }
const _hoisted_2 = { class: "boost-toggle__element flex flex--row flex--no-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "boost-toggle__description"
}
const _hoisted_4 = { class: "boost-toggle__input-wrapper" }
const _hoisted_5 = ["id", "value", "disabled"]
const _hoisted_6 = ["for"]
const _hoisted_7 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          id: _ctx.inputId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          type: "checkbox",
          class: "boost-toggle__input",
          value: _ctx.model,
          disabled: _ctx.disabled
        }, null, 8, _hoisted_5), [
          [_vModelCheckbox, _ctx.model]
        ]),
        _createElementVNode("label", { for: _ctx.inputId }, null, 8, _hoisted_6)
      ]),
      (!_ctx.hideLabel)
        ? (_openBlock(), _createElementBlock("label", {
            key: 1,
            class: _normalizeClass(["boost-toggle__label", { 'boost-toggle-disabled': _ctx.disabled }]),
            for: _ctx.inputId
          }, _toDisplayString(_ctx.label), 11, _hoisted_7))
        : _createCommentVNode("", true)
    ])
  ]))
}