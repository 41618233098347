
import { defineComponent } from 'vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapGetters, mapMutations } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  PopupMessageButtonCaptions,
  PopupMessageStrings,
  PopupMessageTitles
} from '@/common/PopupComponent/PopupMessages';
import ConfirmComponent from '@/common/PopupComponent/ConfirmComponent.vue';

interface ReportData {
  affiliateName: string;
  email: string;
  sales: number;
  commission: number;
  revenueGenerated: number;
  amountEarned: number;
}

interface ShareAnalyticsData {
  affiliateCount: number;
  salesCount: number;
  productPagesCount: number;
  revenueGenerated: {
    total: string;
    list: {
      name: string;
      value: string;
    }[];
  };
  totalPayout: {
    total: string;
    list: {
      name: string;
      value: string;
    }[];
  };
  bestAffiliate: {
    best: string;
    topList: {
      name: string;
      value: string;
    }[];
  };
  bestProduct: {
    best: string;
    topList: {
      name: string;
      value: string;
    }[];
  };
  reportData: ReportData[];
}

export default defineComponent({
  name: 'ShareAnalytics',
  components: {
    ConfirmComponent
  },
  data() {
    return {
      cards: [
        {
          title: 'Advocates',
          message: '0'
        },
        {
          title: 'Sales',
          message: '0'
        },
        {
          title: 'Product Pages',
          message: '0'
        }
      ],
      detailedCardsOne: [
        {
          title: 'Revenue Generated',
          message: '$0.00',
          summary:
            'Showing daily breakdown of sales from Tags generated using the Share App.',
          details: [] as { name: string; value: string }[]
        },
        {
          title: 'Total Payout',
          message: '$0.00',
          summary:
            'Total payout amount made to advocates. Edit Global and Custom commissions by going to their respective pages.',
          details: [] as { name: string; value: string }[]
        }
      ],
      detailedCardsTwo: [
        {
          title: 'Best Performing Advocates',
          message: '',
          summary: 'Showing breakdown of advocate performance by sales.',
          details: [] as { name: string; value: string }[]
        },
        {
          title: 'Most Shared Product',
          message: '',
          summary: 'Showing breakdown of most shared products by sales.',
          details: [] as { name: string; value: string }[]
        }
      ],
      reportData: [] as ReportData[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    })
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    async refresh() {
      this.setLoading(true);
      try {
        const payload = {
          accountId: this.currentAccount.accountId
        };
        const responseData =
          await FirebaseRequest.createRequest<ShareAnalyticsData>(
            FirebaseRequestTypes.GET_SHARE_ANALYTICS,
            payload
          );
        if (responseData.status === APIResponseStatus.OK && responseData.data) {
          const {
            affiliateCount,
            salesCount,
            productPagesCount,
            revenueGenerated,
            totalPayout,
            bestAffiliate,
            bestProduct,
            reportData
          } = responseData.data;
          this.cards[0].message = affiliateCount.toString();
          this.cards[1].message = salesCount.toString();
          this.cards[2].message = productPagesCount.toString();

          this.detailedCardsOne[0].message = revenueGenerated.total;
          this.detailedCardsOne[0].details = revenueGenerated.list.map((s) => ({
            name: s.name,
            value: s.value.toLocaleString()
          }));

          this.detailedCardsOne[1].message = totalPayout.total;
          this.detailedCardsOne[1].details = totalPayout.list.map((s) => ({
            name: s.name,
            value: s.value.toLocaleString()
          }));

          this.detailedCardsTwo[0].message = bestAffiliate.best;
          this.detailedCardsTwo[0].details = bestAffiliate.topList.map((s) => ({
            name: s.name,
            value: s.value.toLocaleString()
          }));

          this.detailedCardsTwo[1].message = bestProduct.best;
          this.detailedCardsTwo[1].details = bestProduct.topList.map((s) => ({
            name: s.name,
            value: s.value.toLocaleString()
          }));

          this.reportData = reportData;
        }
      } catch (err) {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        console.error(err);
      }
      this.setLoading(false);
    },
    async showExportDialog() {
      const confirmation = this.$refs.confirmation as typeof ConfirmComponent;
      const confirmDiscard = await confirmation.show(
        PopupMessageTitles.EXPORT,
        PopupMessageStrings.EXPORT_ANALYTICS,
        PopupMessageButtonCaptions.EXPORT,
        true
      );
      if (confirmDiscard) {
        this.createAndDownloadCsvFile();
      }
    },
    createAndDownloadCsvFile() {
      const headers = `Advocate Name,Email,Sales,Commission,Revenue Generated,Amount Earned`;
      const rows = this.reportData.map((row) => {
        return `${row.affiliateName},${row.email},${row.sales},${row.commission},${row.revenueGenerated},${row.amountEarned}`;
      });

      const csv = [headers, ...rows].join('\n');

      const filename = `Tags-Shopify-Share-Analytics-${Date.now()}.csv`;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
});
