
import { defineComponent } from 'vue';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import {
  PreRegStatus,
  SellerAccount
} from '@/pre-registration/PreRegistration.vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { store } from '@/store';

export default defineComponent({
  name: 'PreRegCard',
  props: {
    stripe: {
      type: Object as () => Stripe,
      required: true
    },
    status: {
      type: String as () => PreRegStatus
    },
    loading: {
      type: String as () => ButtonLoadingState
    },
    seller: {
      type: Object as () => SellerAccount
    }
  },
  emits: ['next', 'back'],
  data() {
    return {
      stripeCardElement: null as null | StripeCardElement,
      stripeClientSecret: '',
      cardError: '' as string | undefined,
      complete: false,
      agreeToTerms: false
    };
  },
  computed: {
    customColor(): string {
      return store.getters['global/getCustomColor'] as string;
    }
  },
  methods: {
    collectCard(card: StripeCardElement) {
      this.stripeCardElement = card;
      this.cardError = '';
      this.complete = true;
    },
    saveCard() {
      this.$emit('next', this.stripeCardElement);
    },
    back() {
      this.$emit('back');
    }
  }
});
