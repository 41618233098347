
import { defineComponent } from 'vue';
import AccountsMenuControlsComponent from '../admin/AccountsMenuControlsComponent.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { AccountFilterType, AccountSortOption } from '@/constants';
import { AccountObject } from '@/models/accountObject';
import Helpers from '@/helpers';
import { SearchActionEvent } from '@/common/controls/SearchBar.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { store } from '@/store';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';

export default defineComponent({
  name: 'AccountsComponent',
  components: {
    AccountsMenuControlsComponent
  },
  data() {
    return {
      searchValue: '' as string,
      accountFilterType: AccountFilterType.ALL,
      accounts: [] as AccountObject[],
      sortBy: AccountSortOption.CREATEDDESC,
      columns: [
        {
          id: 'imageUrl',
          label: ''
        },
        {
          id: 'accountName',
          label: 'Name'
        },
        {
          id: 'hashTag',
          label: 'Tag'
        },
        {
          id: 'contact',
          label: 'Contact'
        },
        {
          id: 'tagCount',
          label: 'Tags'
        },
        {
          id: 'customerCount',
          label: 'Customers'
        },
        {
          id: 'totalSales',
          label: 'Sales'
        },
        {
          id: 'badges',
          label: 'Status'
        },
        {
          id: 'menu'
        }
      ] as ColumnItem[]
    };
  },
  computed: {
    ...mapState('accounts', ['currentAccount', 'isGridDisplayType', 'filter']),
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin'
    }),
    filteredAccounts(): AccountObject[] {
      const search: string = this.searchValue.toLowerCase().trim();

      let results: AccountObject[] = this.accounts.filter(
        (a: AccountObject) =>
          (a.name && a.name.toLowerCase().includes(search)) ||
          (a.hashtagPrefix &&
            a.hashtagPrefix.toLocaleLowerCase().includes(search))
      );

      switch (this.accountFilterType) {
        case AccountFilterType.ACTIVE:
          results = results.filter(
            (a: AccountObject) => !a.disabled && a.isStripeConnected
          );
          break;
        case AccountFilterType.DISCONNECTED:
          results = results.filter(
            (a: AccountObject) => !a.disabled && !a.isStripeConnected
          );
          break;
        case AccountFilterType.INACTIVE:
          results = results.filter(
            (a: AccountObject) => a.disabled && !a.isStripeConnected
          );
          break;
        case AccountFilterType.CANCELED:
          results = results.filter(
            (a: AccountObject) => a.disabled && a.isStripeConnected
          );
          break;
        default:
          break;
      }
      if (this.sortBy) {
        // Must handle latest since it is ordered by the key which can give us
        // the latest first if we just reverse the set
        if (this.sortBy === AccountSortOption.CREATEDDESC) {
          return results.reverse();
        }
        results = results.sort((a, b) => {
          let sortResult = 0;
          switch (this.sortBy) {
            case AccountSortOption.NAMEASC:
              sortResult = a.name.localeCompare(b.name);
              break;
            case AccountSortOption.NAMEDESC:
              sortResult = b.name.localeCompare(a.name);
              break;
            case AccountSortOption.SALESASC:
              sortResult = a.totalSales - b.totalSales;
              break;
            case AccountSortOption.SALESDESC:
              sortResult = b.totalSales - a.totalSales;
              break;
            default:
              break;
          }
          return sortResult;
        });
      }
      return results;
    }
  },
  async created() {
    this.setLoading(true);
    const result = await FirebaseRequest.createRequest<AccountObject[]>(
      FirebaseRequestTypes.GET_ADMIN_ACCOUNTS,
      {}
    );
    this.setLoading(false);
    if (result.status === APIResponseStatus.OK) {
      this.accounts = result.data || [];
    } else {
      store.commit('global/setBannerStatus', {
        text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
        type: BannerType.error
      });
    }
  },
  methods: {
    ...mapMutations('accounts', ['setCurrentAccount']),
    ...mapMutations('auth', ['setImpersonating']),
    ...mapMutations('global', ['setLoading']),
    getProfile(account: AccountObject): string {
      return account.logoUrl
        ? account.logoUrl
        : require('../assets/default-brand.jpg');
    },
    getPrice(price: number) {
      return Helpers.currencyFormatter(price);
    },
    getSecureUrl(url: string) {
      return Helpers.getSecureUrl(url);
    },
    formatHashTag(tag: string) {
      return Helpers.formatHashTag(tag);
    },
    fireSearch(data: SearchActionEvent) {
      this.searchValue = data.searchValue || '';
    },
    async onAccountClick(accountId: string) {
      const result = await FirebaseRequest.createRequest<AccountObject[]>(
        FirebaseRequestTypes.GET_ACCOUNT_DATA,
        {
          accountId
        }
      );
      if (result.data) {
        this.setCurrentAccount(result.data);
        this.setImpersonating(true);
        await this.$router.push({
          path: '/store'
        });
      }
    }
  }
});
