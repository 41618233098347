
import { defineComponent } from 'vue';
import settings from '@/settings';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { PartialAccount } from '@/models/account';
import { store } from '@/store';
import { auth } from '@/store/auth';
import { uploadImage } from '@/../dataService/cloudinary';
import {
  AllSellerSettings,
  SellerBusinessSettings,
  SellerCustomSettings,
  SellerShippingSettings
} from '@/seller/settings/SellerSettingsInterfaces';
import SellerSettingsAccount from './SellerSettingsAccount.vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import Helpers from '@/helpers';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { mapMutations } from 'vuex';
import { AccountObject } from '@/models/accountObject';

export default defineComponent({
  components: {
    SellerSettingsAccount
  },
  data() {
    return {
      saveButtonLoadingState: ButtonLoadingState.initial,
      disconnectStripeButtonLoadingState: ButtonLoadingState.initial,
      settingsCurrentTab: 0,

      accountSettings: {
        companyName: '',
        companyEmail: '',
        companyNumber: '',
        companyZipCode: '',
        refundPolicy: '',
        hashTagPrefix: '',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        logoUrl: require('../../assets/default-product.jpg') as string,
        isConnectedToStripe: false,
        accountHolderLastName: '',
        accountHolderFirstName: ''
      } as SellerBusinessSettings,
      shippingSettings: {
        companyName: '',
        companyAddress1: '',
        companyAddress2: '',
        companyCity: '',
        companyState: '',
        companyZipCode: '',
        companyCountry: 'United States'
      } as SellerShippingSettings,
      customSettings: {} as SellerCustomSettings
    };
  },
  compatConfig: { WATCH_ARRAY: false },
  computed: {
    account(): AccountObject {
      // eslint-disable-next-line
      // @ts-ignore
      const account: AccountObject = this.$store.state.accounts.currentAccount;
      if (!account.logoUrl) {
        account.logoUrl = require('../../assets/default-product.jpg');
      }
      return account;
    },
    isSuperAdmin(): boolean {
      return auth.state.isSuperAdmin;
    }
  },

  watch: {
    account(newValue) {
      if (newValue !== null) {
        this.initializeData();
      }
    }
  },

  async created() {
    if (this.account) {
      this.initializeData();
      if (
        this.$route.query.callback &&
        this.$route.query.callback === 'stripe_callback'
      ) {
        await this.handleStripeCallback();
      }
    }
  },

  methods: {
    ...mapMutations('accounts', ['setCurrentAccount']),
    ...mapMutations('auth', ['setImpersonating']),
    ...mapMutations({
      setBannerStatus: 'global/setBannerStatus'
    }),
    setCurrentTab(currentTab: number) {
      this.settingsCurrentTab = currentTab;
    },
    initializeData() {
      this.accountSettings.logoUrl = this.account.logoUrl || '';
      this.accountSettings.email = this.account.email;
      this.accountSettings.companyEmail = this.account.orderEmail || '';
      this.accountSettings.companyNumber = this.account.phone || '';
      this.accountSettings.companyZipCode = this.account.postalCode || '';
      this.accountSettings.companyName = this.account.name;
      this.accountSettings.refundPolicy = this.account.refundPolicy || '';
      this.accountSettings.hashTagPrefix = Helpers.formatHashTag(
        this.account.hashtagPrefix
      );
      this.accountSettings.isConnectedToStripe = this.account.isStripeConnected;
      this.accountSettings.accountHolderFirstName = this.account.firstName;
      this.accountSettings.accountHolderLastName = this.account.lastName;

      if (this.account.shippingAddress) {
        this.shippingSettings.companyName = this.account.name;
        this.shippingSettings.companyAddress1 =
          this.account.shippingAddress.address1 || '';
        this.shippingSettings.companyAddress2 =
          this.account.shippingAddress.address2 || '';
        this.shippingSettings.companyCity =
          this.account.shippingAddress.city || '';
        this.shippingSettings.companyState =
          this.account.shippingAddress.state || '';
        this.shippingSettings.companyZipCode =
          this.account.shippingAddress.postalCode || '';
        this.shippingSettings.companyCountry =
          this.account.shippingAddress.country || 'United States';
      }

      this.customSettings.metaPixel = this.account.metaPixel || '';
      this.customSettings.customColor = this.account.customColor || '';
    },
    async disableAccount() {
      const r = confirm('Are you sure you wish to disable your account?');
      if (r !== true) {
        return;
      }

      const accountId =
        this.$store.state.accounts.currentAccount?.accountId || '';
      try {
        await FirebaseRequest.createRequest(
          FirebaseRequestTypes.DISABLE_SELLER_ACCOUNT,
          {
            accountId
          }
        );
        this.setBannerStatus({
          text: BannerMessageStrings.SELLER_ACCOUNT_DISABLED,
          type: BannerType.info
        });

        const result = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.GET_ACCOUNT_DATA,
          { accountId }
        );
        store.commit('accounts/setCurrentAccount', result.data);
      } catch {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    },
    async cancelUpdateSellerSettings() {
      await this.$router.push({ name: 'seller-home' });
    },
    async updateSellerSettings(sellerSettings: AllSellerSettings) {
      // TODO: This needs to be changed to a function call instead of direct db access
      this.saveButtonLoadingState = ButtonLoadingState.loading;

      this.accountSettings = sellerSettings.data.accountSettings;
      this.shippingSettings = sellerSettings.data.shippingSettings;

      const accountId =
        this.$store.state.accounts.currentAccount?.accountId || '';
      if (
        this.accountSettings.logoUrl != null &&
        /^data:image\/.*?;base64,/.test(this.accountSettings.logoUrl)
      ) {
        // We have to write this image to the database and replace it with the URL
        this.accountSettings.logoUrl = await uploadImage(
          this.accountSettings.logoUrl
        );
      }

      // TODO: remove unnecessary fields from model
      const tempAccount = {
        accountId,
        name: this.accountSettings.companyName,
        hashtagPrefix: this.account.hashtagPrefix,
        phone: this.accountSettings.companyNumber,
        orderEmail: this.accountSettings.companyEmail,
        postalCode: this.accountSettings.companyZipCode,
        shippingAddress: {
          firstName: this.shippingSettings.companyName,
          address1: this.shippingSettings.companyAddress1,
          address2: this.shippingSettings.companyAddress2,
          city: this.shippingSettings.companyCity,
          state: this.shippingSettings.companyState,
          postalCode: this.shippingSettings.companyZipCode,
          country: this.shippingSettings.companyCountry
        },
        refundPolicy: this.accountSettings.refundPolicy,
        logoUrl: this.accountSettings.logoUrl,
        accountHolderLastName: this.accountSettings.accountHolderLastName,
        accountHolderFirstName: this.accountSettings.accountHolderFirstName,
        metaPixel: this.customSettings.metaPixel,
        customColor: this.customSettings.customColor
      } as PartialAccount;

      const updateResult = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.UPDATE_SELLER_ACCOUNT,
        { accountUpdate: tempAccount, accountId }
      );
      if (updateResult.status === APIResponseStatus.OK) {
        const result = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.GET_ACCOUNT_DATA,
          { accountId }
        );
        store.commit('accounts/setCurrentAccount', result.data);
        this.setBannerStatus({
          text: BannerMessageStrings.CHANGES_UPDATED,
          type: BannerType.success
        });
      } else {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.saveButtonLoadingState = ButtonLoadingState.complete;
    },
    connectToStripe() {
      const accountId =
        this.$store.state.accounts.currentAccount?.accountId || '';
      const stripeAppId = settings.STRIPE_APP_ID;
      const callBackURL =
        location.origin + '/store/settings?callback=stripe_callback';
      window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeAppId}&scope=read_write&state=${accountId}&redirect_uri=${callBackURL}`;
    },
    async disconnectFromStripe() {
      this.disconnectStripeButtonLoadingState = ButtonLoadingState.loading;
      const accountId =
        this.$store.state.accounts.currentAccount?.accountId || '';
      const stripeResult = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.DISCONNECT_STRIPE,
        { accountId }
      );

      if (stripeResult.status === APIResponseStatus.OK) {
        // Reload the account with the removed stripe credentials
        await this.$store.dispatch('accounts/loadAccounts').then(() => {
          const account = this.$store.state.accounts.currentAccount;
          if (account) {
            this.$nextTick(() => {
              this.accountSettings.isConnectedToStripe = false;
              account.stripeCredentials = '';
            });
          }
          this.setBannerStatus({
            text: BannerMessageStrings.SELLER_SETTINGS_STRIPE_NOT_CONNECTED,
            type: BannerType.stripeWarning
          });
        });
      } else {
        this.setBannerStatus({
          text: BannerMessageStrings.SELLER_STRIPE_ACCOUNT_ERROR,
          type: BannerType.error
        });
      }
      this.disconnectStripeButtonLoadingState = ButtonLoadingState.complete;
    },
    changePassword() {
      this.$store
        .dispatch('auth/forgotPassword', {
          email: this.$store.state.accounts.currentAccount?.email
        })
        .then((success: boolean) => {
          if (success) {
            this.setBannerStatus({
              text: BannerMessageStrings.SELLER_PASSWORD_EMAIL_SENT,
              type: BannerType.info
            });
          } else {
            this.setBannerStatus({
              text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
              type: BannerType.error
            });
          }
        });
    },

    async handleStripeCallback() {
      try {
        if (this.isSuperAdmin && this.$route.query.state) {
          const accountId = this.$route.query.state as string;
          const result = await FirebaseRequest.createRequest(
            FirebaseRequestTypes.GET_ACCOUNT_DATA,
            { accountId }
          );
          this.setCurrentAccount(result.data);
          this.setImpersonating(true);
        }
        const code = this.$route.query.code;
        const accountId = this.$store.state.accounts.currentAccount?.accountId;
        store.commit('global/setLoading', true);
        const stripeResult = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.CONNECT_STRIPE,
          { accountId, code }
        );

        if (stripeResult.status === APIResponseStatus.OK) {
          // Reload the account with the updated stripe credentials
          await this.$store.dispatch('accounts/loadAccounts').then(() => {
            this.$nextTick(
              () => (this.accountSettings.isConnectedToStripe = true)
            );
            this.setBannerStatus({
              text: BannerMessageStrings.SELLER_STRIPE_ACCOUNT_RECONNECTED,
              type: BannerType.success
            });
            // remove stripe callback params from query string
            this.$router.replace({ query: {} });
          });
        } else {
          this.setBannerStatus({
            text: BannerMessageStrings.SELLER_STRIPE_ACCOUNT_ERROR,
            type: BannerType.error
          });
        }
      } catch (ex) {
        this.setBannerStatus({
          text: BannerMessageStrings.SELLER_STRIPE_ACCOUNT_ERROR + ex,
          type: BannerType.error
        });
      }
      store.commit('global/setLoading', false);
    }
  }
});
