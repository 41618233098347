import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c7fc680"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "single__header-image flex align--center image-view-only image-view-only__container" }
const _hoisted_2 = { class: "rounded-image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "type-image"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "image-view-only__title-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imageUrl || require('../../assets/default-product.jpg'),
        alt: "Tags image"
      }, null, 8, _hoisted_3),
      (_ctx.typeImageUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.typeImageUrl,
              alt: "Type indicator image"
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "pills", {}, undefined, true)
    ])
  ]))
}