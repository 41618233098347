import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex--no-wrap" }
const _hoisted_3 = { class: "rounded-image rounded-image--small" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { key: 5 }
const _hoisted_10 = { key: 6 }
const _hoisted_11 = { key: 7 }
const _hoisted_12 = {
  key: 0,
  class: "pill pill-stripe-blue"
}
const _hoisted_13 = {
  key: 1,
  class: "pill pill-red"
}
const _hoisted_14 = {
  key: 2,
  class: "pill pill-inactive"
}
const _hoisted_15 = { key: 8 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountsMenuControlsComponent = _resolveComponent("AccountsMenuControlsComponent")!
  const _component_BoostTable = _resolveComponent("BoostTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AccountsMenuControlsComponent, {
      filter: _ctx.accountFilterType,
      "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accountFilterType) = $event)),
      sort: _ctx.sortBy,
      "onUpdate:sort": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sortBy) = $event)),
      onAccountSearch: _ctx.fireSearch
    }, null, 8, ["filter", "sort", "onAccountSearch"]),
    _createVNode(_component_BoostTable, {
      rows: _ctx.filteredAccounts,
      columns: _ctx.columns,
      "click-id": "accountId",
      "hide-arrow": true,
      "has-context-menu": false,
      onAction: _ctx.onAccountClick
    }, {
      default: _withCtx((data) => [
        (data.column.id === 'imageUrl')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: _ctx.getProfile(data.row),
                    alt: "Seller logo"
                  }, null, 8, _hoisted_4)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (data.column.id === 'accountName')
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(data.row.name), 1))
          : _createCommentVNode("", true),
        (data.column.id === 'hashTag')
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.formatHashTag(data.row.hashtagPrefix)), 1))
          : _createCommentVNode("", true),
        (data.column.id === 'contact')
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(`${data.row.lastName}, ${data.row.firstName} ${data.row.email}`), 1))
          : _createCommentVNode("", true),
        (data.column.id === 'tagCount')
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(data.row.totalTags), 1))
          : _createCommentVNode("", true),
        (data.column.id === 'customerCount')
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(data.row.totalCustomers), 1))
          : _createCommentVNode("", true),
        (data.column.id === 'totalSales')
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.getPrice(data.row.totalSales)), 1))
          : _createCommentVNode("", true),
        (data.column.id === 'badges')
          ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
              (data.row.isStripeConnected)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Stripe "))
                : _createCommentVNode("", true),
              (!data.row.isStripeConnected)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, " Inactive "))
                : _createCommentVNode("", true),
              (data.row.disabled)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, " Canceled "))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (data.column.id === 'menu')
          ? (_openBlock(), _createElementBlock("span", _hoisted_15, " "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["rows", "columns", "onAction"])
  ], 64))
}