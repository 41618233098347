
import { defineComponent } from 'vue';
import ProductList from './ProductList.vue';
import ProductsMenuControls from './ProductsMenuControls.vue';
import { DisplayType } from '@/constants';
import { mapGetters, mapMutations } from 'vuex';
import { FilterProductListEvent } from '@/enums/productTypes';
import { TagType } from '@/../enums/tagType';

export default defineComponent({
  name: 'ProductsRoot',
  components: {
    ProductList,
    ProductsMenuControls
  },
  data() {
    return {
      TagType,
      display: DisplayType.GRID,
      tagFilter: {
        filterType: (this.currentFilter || TagType.PRODUCT).toString(),
        searchValue: undefined,
        tagSortOption: undefined,
        productSource: 'all'
      } as FilterProductListEvent,
      tagCount: 0
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      currentFilter: 'product/currentFilter',
      productSource: 'accounts/selectedFeatureTypeId'
    })
  },
  mounted() {
    if (!this.currentAccount) {
      return;
    }
  },
  methods: {
    ...mapMutations('global', ['setLoading']),
    handleAddNewTag() {
      // Set the current product to undefined to clear out any existing product information left behind
      this.$store.commit('product/setCurrentProduct', undefined);
      if (this.productSource === 'Kiosk') {
        if (this.currentFilter === TagType.TIP) {
          this.$router.push({ name: 'seller-tip-create' });
        } else {
          this.$router.push({ name: 'seller-product-create' });
        }
      } else {
        this.$router.push({ name: 'seller-product-import-shopify' });
      }
    },
    handleFilter(filter: FilterProductListEvent) {
      this.tagFilter = filter;
    }
  }
});
