
import { defineComponent, PropType } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { TagDetail, TagSku, TagVariant } from '@/../models/tag';
import { Keyword } from '@/common/controls/BoostKeywords.vue';
import helpers from '@/helpers';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';

export default defineComponent({
  name: 'ProductEditVariations',
  props: {
    product: {
      type: Object as PropType<TagDetail>,
      required: true
    }
  },
  emits: {
    backNavigationRequested() {
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveVariations(payload: { variants: TagVariant[]; skus: TagSku[] }) {
      return true;
    }
  },
  data() {
    return {
      showOptions: false,
      skuList: [] as TagSku[],
      variantList: [] as TagVariant[],
      tempVariantList: [] as TagVariant[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    }),
    columns(): ColumnItem[] {
      const step1 = [
        {
          id: 'image',
          label: ''
        },
        {
          id: 'sku',
          label: 'SKU'
        },
        {
          id: 'quantity',
          label: 'Quantity'
        },
        {
          id: 'price',
          label: 'Price'
        }
      ] as ColumnItem[];

      this.variantList.forEach((variant) => {
        step1.push({
          id: variant.name || variant.label?.toLocaleLowerCase(),
          label: variant.label
        });
      });

      step1.push({
        id: 'actions',
        label: ''
      });

      return step1;
    },
    hasOptions(): boolean {
      return this.variantList.length > 0;
    },
    variantsAsKeywords(): Keyword[] {
      const variants = [] as Keyword[];
      this.variantList.forEach((variant: TagVariant, index: number) => {
        variants.push({
          id: index,
          label: variant.label,
          isLocked: variant.isLocked
        });
      });
      return variants;
    },
    disableDone(): boolean {
      if (this.showOptions) {
        return this.tempVariantList.some((x) => !x.label || !x.options.length);
      }
      //Need to add check that all options have a value specified on every sku
      const hasBlankSkuOptions = (s: TagSku): boolean => {
        let results = false;
        this.variantList.forEach((v) => {
          const name = v.name || v.label?.toLocaleLowerCase();
          if (!s[name]) {
            results = true;
          }
        });
        return results;
      };
      const results =
        this.variantList.some((x) => !x.label || !x.options.length) ||
        this.skuList.length === 0 ||
        this.skuList.some(
          (x) =>
            !x.sku ||
            !x.quantity ||
            !x.price ||
            parseFloat(x.price) < 1.0 ||
            !parseFloat(x.price) ||
            hasBlankSkuOptions(x)
        );
      return results;
    }
  },
  created() {
    if (!this.product) {
      this.$router.push({
        name: 'seller-products'
      });
      return;
    }
    // Get a copy of the current variants and skus to start, these should be abandoned if navigate back
    // or saved back to the store when the user clicks the save button

    if (this.product.variants?.length) {
      this.product.variants.forEach((x) => {
        this.variantList.push({ ...x });
        // this.tempVariantList.push({ ...x });
        this.tempVariantList = helpers.deepCopy(this.variantList);
      });
    }
    if (this.product.skus?.length) {
      this.product.skus.forEach((x) => {
        this.skuList.push({ ...x });
      });
    }
  },
  methods: {
    ...mapMutations('product', ['setCurrentProductVariants']),
    goBack() {
      if (this.showOptions) {
        this.showOptions = false;
        this.tempVariantList = helpers.deepCopy(this.variantList);
        return;
      }

      this.$emit('backNavigationRequested');
    },
    commitChanges() {
      if (!this.showOptions) {
        this.$emit('saveVariations', {
          variants: this.variantList,
          skus: this.skuList
        });
        this.showOptions = false;
        return;
      }

      //Commit the changes to the store for potential final save later to the DB
      // Find any variants that were removed or variant options that are used on the skus
      for (let key = 0; key < this.variantList.length; key++) {
        const originalVariant = this.variantList[key];
        const tempV = this.tempVariantList.find(
          (x) =>
            x.label.toString().toLocaleLowerCase() ===
            originalVariant.label.toString().toLocaleLowerCase()
        );
        if (tempV) {
          // This variant exists still so check if all of the options are still in existence
          for (let index = 0; index < originalVariant.options.length; index++) {
            const opt = originalVariant.options[index];
            if (
              !tempV.options.some(
                (t) =>
                  t.label.toString().toLocaleLowerCase() ===
                  opt.label.toString().toLocaleLowerCase()
              )
            ) {
              // Then remove those properties from the skus
              const name = originalVariant.label.toString().toLocaleLowerCase();
              const value = opt.label.toString().toLocaleLowerCase();
              this.skuList
                .filter((s) => s[name] === value)
                .forEach((s) => delete s[name]);
            }
          }
        }
      }

      this.variantList = [];
      this.tempVariantList.forEach((x) => this.variantList.push({ ...x }));

      this.showOptions = false;
      this.tempVariantList = helpers.deepCopy(this.variantList);
    },

    // These methods are for managing the skus
    getOptions(sku: TagSku): { key: TagVariant; value: number | string }[] {
      if (!this.variantList.length) {
        return [];
      }
      // Build an array from the variant options and set the values from the provided sku
      return this.variantList.map((x) => {
        const option = { key: x, value: sku[x.name] };
        return option;
      });
    },
    skuVariantSelectOptions(id: string) {
      let variant = this.variantList.find((x) => x.name === id);
      if (!variant) {
        variant = this.variantList.find(
          (x) => x.label?.toLocaleLowerCase() === id
        );
      }

      // Build the select options array for the
      const options = variant?.options
        ? variant.options.map((opt) => {
            return {
              name: opt.label,
              value: opt.name
                ? opt.name
                : opt.label.toString().toLocaleLowerCase()
            };
          })
        : [];

      // Add a default empty value
      options.push({
        name: '-',
        value: ''
      });

      return options;
    },
    addSku() {
      const sku: TagSku = {
        id: '',
        imageUrl: this.skuList.length
          ? this.skuList[this.skuList.length - 1].imageUrl || ''
          : this.product.imageUrl || '',
        sku: '000' + this.skuList.length,
        price: this.skuList.length
          ? this.skuList[0].price || '0.00'
          : this.product.price,
        quantity: 999
      };

      if (this.variantList.length > 0) {
        this.variantList.forEach((variant: TagVariant) => {
          const name = variant.name || variant.label?.toLocaleLowerCase();
          sku[name] = '';
        });
      }

      this.skuList.push(sku);
    },
    removeSku(sku: TagSku) {
      const index = this.skuList.indexOf(sku);
      this.skuList.splice(index, 1);
    },
    // The following methods are for managing the variant options
    addVariant() {
      // Save the option values
      const order = this.tempVariantList.length + 1;

      // Add this variant to the options array
      this.tempVariantList.push({
        id: helpers.getTemporaryId(),
        name: '',
        label: '',
        order,
        isLocked: false,
        options: []
      });
    },
    addVariantOption(keyword: Keyword, key: number) {
      const order = this.tempVariantList[key].options.length + 1;

      const variantOption = {
        id: helpers.getTemporaryId(),
        name: keyword.label.toString().toLocaleLowerCase(),
        label: keyword.label.toString(),
        order,
        isLocked: false
      };

      this.tempVariantList[key].options.push(variantOption);

      // Lock the parent variant
      this.tempVariantList[key].isLocked = true;
    },
    removeVariantOption(index: number, key: number) {
      this.tempVariantList[key].options.splice(index, 1);
    },
    removeVariant(variant: TagVariant, index: number) {
      // Remove Variant key/value from each sku
      this.tempVariantList.splice(index, 1);
    }
  }
});
