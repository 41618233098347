import {
  email,
  min,
  min_value,
  required,
  numeric,
  length,
  max,
  max_value,
  alpha,
  alpha_spaces
} from '@vee-validate/rules';
import { Profanity, SmsSensitiveWords } from './constants';
import Helpers from '@/helpers';

import { configure, defineRule } from 'vee-validate';

type messages = {
  [k: string]: string;
};

defineRule('required', required);
defineRule('email', email);
defineRule('numeric', numeric);
defineRule('postal', numeric);
defineRule('min', min);
defineRule('max', max);
defineRule('max_value', max_value);
defineRule('max_withdraw', max_value);
defineRule('min_value', min_value);
defineRule('length', length);
defineRule('alpha', alpha);
defineRule('alpha_spaces', alpha_spaces);
defineRule('profanity', (value: string) => {
  return Helpers.validTag(value, Profanity);
});
defineRule('sms', (value: string) => {
  return Helpers.validTag(value, SmsSensitiveWords);
});
defineRule('money', (value: string) => {
  const valid = /^\d+\.\d{2}$/;
  return valid.test(value);
});

configure({
  generateMessage: (context) => {
    const params = context.rule?.params as unknown[];

    const messages: messages = {
      required: 'This field is required',
      email: 'Please enter a valid email address',
      numeric: 'Only numbers are allowed',
      postal: 'Please enter a valid postal code',
      length: 'Field is not the correct length',
      min: `This field must be at least ${params[0]} characters`,
      max: `This field may only have ${params[0]} characters at most`,
      min_value: `This field can't be less than ${params[0]}`,
      max_value: `This field can't be more than ${params[0]}`,
      max_withdraw: `The amount is greater than your available balance`,
      alpha: 'Only alphabetical characters are allowed',
      alpha_spaces: 'Only alphabetical characters are allowed',
      profanity:
        'There was an error while reserving your tag. Please try another word',
      sms: 'There was an error while reserving your tag. Please try another word',
      money: 'Only amounts in the form 10.00 are allowed'
    };

    if (context.rule && context.rule.name) {
      return messages[context.rule.name]
        ? messages[context.rule.name]
        : `The field ${context.field} is invalid`;
    }

    return `The field ${context.field} is invalid`;
  }
});
