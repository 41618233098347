
import { defineComponent } from 'vue';
import { SearchActionEvent } from '@/common/controls/SearchBar.vue';
import {
  AudienceFilterOptions,
  AudienceSortOptions,
  AudienceType,
  FilterAudienceEvent
} from '@/../enums/audienceTypes';
import { mapGetters } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

export default defineComponent({
  props: {
    filteredCount: {
      type: Number
    }
  },
  emits: ['filterAudienceList'],
  data() {
    return {
      AudienceType,
      selectedTab: AudienceType.Customers,
      searchString: ('' as string) || undefined,
      filter: AudienceFilterOptions.All,
      filterOptions: [
        { name: 'All', value: AudienceFilterOptions.All },
        { name: 'New', value: AudienceFilterOptions.NewCustomer },
        { name: 'Active', value: AudienceFilterOptions.Active },
        { name: 'Inactive', value: AudienceFilterOptions.Inactive },
        { name: 'Registered', value: AudienceFilterOptions.PreRegistered }
      ],
      sort: AudienceSortOptions.MostRecent,
      sortOptions: [
        { name: 'Most Recent', value: AudienceSortOptions.MostRecent },
        { name: 'Most Orders', value: AudienceSortOptions.MostOrders },
        { name: 'Least Orders', value: AudienceSortOptions.LeastOrders },
        { name: 'Highest Revenue', value: AudienceSortOptions.HighestRevenue },
        { name: 'Lowest Revenue', value: AudienceSortOptions.LowestRevenue },
        { name: 'Highest Refunds', value: AudienceSortOptions.HighestRefunds },
        { name: 'Least Refunds', value: AudienceSortOptions.LeastRefunds },
        { name: 'A - Z', value: AudienceSortOptions.AlphaAscending },
        { name: 'Z - A', value: AudienceSortOptions.AlphaDescending }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getSearchText: 'global/getSearchText',
      currentAccount: 'accounts/currentAccount'
    }),
    filterText(): string {
      const selection =
        this.filterOptions.find((x) => x.value == this.filter) ||
        this.filterOptions[0];
      return (
        selection.name +
        (this.filter === AudienceFilterOptions.All ? ' Audience' : '')
      );
    },
    registrationUrl(): string {
      const slug = this.currentAccount.hashtagPrefix.replace(/\W/g, '');
      return `${window.location.origin}/registration/${
        this.selectedTab === 'Customers' ? '' : 'advocate/'
      }${slug}`;
    }
  },
  watch: {
    getSearchText(val: string) {
      this.searchString = val;
      this.setFilter();
    },
    selectedTab(val: AudienceType) {
      if (val === 'Advocates') {
        this.filterOptions = [
          { name: 'All', value: AudienceFilterOptions.All },
          { name: 'Active', value: AudienceFilterOptions.Active },
          { name: 'Suspended', value: AudienceFilterOptions.Suspended }
        ];

        this.sortOptions = [
          { name: 'Most Recent', value: AudienceSortOptions.MostRecent },
          {
            name: 'Highest Revenue',
            value: AudienceSortOptions.HighestRevenue
          },
          { name: 'Lowest Revenue', value: AudienceSortOptions.LowestRevenue },
          { name: 'A - Z', value: AudienceSortOptions.AlphaAscending },
          { name: 'Z - A', value: AudienceSortOptions.AlphaDescending }
        ];
      } else {
        this.filterOptions = [
          { name: 'All', value: AudienceFilterOptions.All },
          { name: 'New', value: AudienceFilterOptions.NewCustomer },
          { name: 'Active', value: AudienceFilterOptions.Active },
          { name: 'Inactive', value: AudienceFilterOptions.Inactive },
          { name: 'Registered', value: AudienceFilterOptions.PreRegistered }
        ];

        this.sortOptions = [
          { name: 'Most Recent', value: AudienceSortOptions.MostRecent },
          { name: 'Most Orders', value: AudienceSortOptions.MostOrders },
          { name: 'Least Orders', value: AudienceSortOptions.LeastOrders },
          {
            name: 'Highest Revenue',
            value: AudienceSortOptions.HighestRevenue
          },
          { name: 'Lowest Revenue', value: AudienceSortOptions.LowestRevenue },
          {
            name: 'Highest Refunds',
            value: AudienceSortOptions.HighestRefunds
          },
          { name: 'Least Refunds', value: AudienceSortOptions.LeastRefunds },
          { name: 'A - Z', value: AudienceSortOptions.AlphaAscending },
          { name: 'Z - A', value: AudienceSortOptions.AlphaDescending }
        ];
      }
    }
  },
  methods: {
    setFilter() {
      this.viewAudience({
        filterType: this.filter.toString(),
        sortOption: this.sort,
        searchValue: this.searchString
      });
    },
    viewAudience(data: SearchActionEvent) {
      this.filter = data.filterType as AudienceFilterOptions;
      this.searchString = data.searchValue;
      const filterAudienceEventArgs: FilterAudienceEvent = {
        filterOption: this.filter,
        searchValue: data.searchValue,
        sortOption: data.sortOption || AudienceSortOptions.MostRecent,
        typeSelection: this.selectedTab
      };
      this.$emit('filterAudienceList', filterAudienceEventArgs);
    },
    selectAudienceType(selectedType: AudienceType) {
      this.selectedTab = selectedType;
      this.setFilter();
    },
    async copyPreRegUrl() {
      await navigator.clipboard.writeText(this.registrationUrl);
      this.$store.commit('global/setBannerStatus', {
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    }
  }
});
