import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c4328dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "boost-info-card flex" }
const _hoisted_2 = { class: "flex w-100 align--center" }
const _hoisted_3 = {
  key: 0,
  class: "boost-info-card__icon flex justify--start align--center"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "boost-info-card__action flex justify--start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", { src: _ctx.icon }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "action", {}, undefined, true)
    ])
  ]))
}