
import { defineComponent } from 'vue';
import { AccountFilterType, AccountSortOption } from '@/constants';
import { mapState } from 'vuex';
import { SelectOption } from '@/common/controls/BoostSelect/Select.interface';
import SearchBar, { SearchActionEvent } from '@/common/controls/SearchBar.vue';

const AccountsMenuControlsComponent = defineComponent({
  name: 'AccountsMenuControlsComponent',
  components: {
    SearchBar
  },
  props: {
    filter: {
      type: String,
      default: AccountFilterType.ACTIVE
    }
  },
  emits: ['update:filter', 'update:sort', 'accountSearch'],
  data() {
    return {
      innerFilter: AccountFilterType.ACTIVE,
      filterOptions: [
        { value: AccountFilterType.ACTIVE, name: 'Active' },
        { value: AccountFilterType.INACTIVE, name: 'Inactive' },
        { value: AccountFilterType.DISCONNECTED, name: 'Disconnected' },
        { value: AccountFilterType.CANCELED, name: 'Canceled' },
        { value: AccountFilterType.ALL, name: 'All' }
      ] as SelectOption[],
      sortBy: AccountSortOption.CREATEDDESC,
      sortOptions: [
        { value: AccountSortOption.NAMEASC, name: 'Name a to z' },
        { value: AccountSortOption.NAMEDESC, name: 'Name z to a' },
        { value: AccountSortOption.SALESASC, name: 'Sales 0 to 9' },
        { value: AccountSortOption.SALESDESC, name: 'Sales 9 to 0' },
        { value: AccountSortOption.CREATEDDESC, name: 'Latest Account Created' }
      ] as SelectOption[]
    };
  },
  computed: {
    ...mapState('accounts', ['isGridDisplayType']),
    AccountFilterType: () => AccountFilterType
  },
  watch: {
    innerFilter(newVal) {
      this.filterList(newVal);
    },
    sortBy(newVal) {
      this.sortList(newVal);
    }
  },
  mounted() {
    this.innerFilter = this.filter;
  },
  methods: {
    filterList(value: string) {
      this.$emit('update:filter', value);
    },
    sortList(value: string) {
      this.$emit('update:sort', value);
    },
    viewAccounts(data: SearchActionEvent) {
      this.innerFilter = data.filterType;
      this.$emit('accountSearch', data);
    }
  }
});

export default AccountsMenuControlsComponent;
