
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { SellerAccount } from '@/pre-registration/PreRegistration.vue';
import { store } from '@/store';
import { BrandInformation } from '@/store/types';
import { defineComponent } from 'vue';
import Helpers from '@/helpers';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { mapGetters } from 'vuex';

type SIGNUP_TYPE = 'GENERIC' | 'BRAND';

export default defineComponent({
  data() {
    return {
      percentage: 11,
      sellerInfo: {} as SellerAccount,
      currentStep: 1,
      getCodeState: ButtonLoadingState.initial,
      internalMobileNumber: '',
      isExistingUser: false,
      hasUserName: false,
      hasSentConfirmationCode: false,
      confirmationCode: '',
      affiliate: {
        firstName: '',
        lastName: '',
        email: '',
        userName: ''
      },
      defaultSlug: 'tags',
      signupType: 'GENERIC' as SIGNUP_TYPE
    };
  },
  computed: {
    ...mapGetters({
      brandName: 'global/getBrandName'
    }),
    customColor(): string {
      return store.getters['global/getCustomColor'] as string;
    },
    possessiveBrandName() {
      const possessiveSIfNeeded = this.sellerInfo.name.endsWith('s') ? '' : 's';
      return `${this.sellerInfo.name}'${possessiveSIfNeeded}`;
    },
    pageHeaderTitle() {
      return this.signupType === 'BRAND'
        ? 'Get paid for sharing'
        : 'Join Network';
    },
    joinDescriptionText() {
      return this.signupType === 'BRAND'
        ? 'Love this brand’s products so much you want to tell everyone about it? Create your own checkout Tags and get paid every time someone buys from you.'
        : 'Generate checkouts for thousands of products and earn cash every time someone buys from you — without needing to fulfill or manage orders.';
    }
  },
  async mounted() {
    this.$store.commit('global/setLoading', {
      isLoading: true
    });

    // This page can be used as a generic Tags Network signup page.
    // If there is no slug, we offer the user generic registration.
    let { slug } = this.$route.params;
    if (!slug) {
      slug = 'tags';
      this.signupType = 'GENERIC';
    } else {
      this.signupType = 'BRAND';
    }

    const payload = {
      slug: slug as string
    };
    const response = await FirebaseRequest.createRequest<{
      seller: SellerAccount;
    }>(FirebaseRequestTypes.GET_SELLER_INFO, payload);

    if (response?.status === APIResponseStatus.OK && response.data?.seller) {
      this.sellerInfo = response.data.seller;
      store.commit('global/setCustomColor', this.sellerInfo.customColor);
      store.commit('global/setBrandInformation', {
        brandName: this.sellerInfo.name,
        logoUrl: this.sellerInfo.logoUrl,
        metaPixel: this.sellerInfo.metaPixel,
        customColor: this.sellerInfo.customColor
      } as BrandInformation);
    }
    this.$store.commit('global/setLoading', false);
    if (
      !this.sellerInfo.name ||
      this.sellerInfo.plan === 'Basic' ||
      !this.sellerInfo.enableShareEarn
    ) {
      await this.$router.push({ name: 'PageNotFound' });
    }
  },
  methods: {
    getStarted() {
      this.currentStep = 2;
    },
    async requestConfirmationCode() {
      try {
        this.getCodeState = ButtonLoadingState.loading;
        const payload = {
          number: Helpers.prefixPhoneNumber(this.internalMobileNumber),
          context: 'SIGNUP_AFFILIATE'
        };

        const confirmationCodeResponse = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.REQUEST_CONFIRMATION_CODE,
          payload
        );

        if (confirmationCodeResponse) {
          this.isExistingUser =
            confirmationCodeResponse.code === 'PREREGISTER_EXISTING_USER' ||
            confirmationCodeResponse.code === 'AFFILIATE_HAS_USERNAME';
          this.hasUserName =
            confirmationCodeResponse.code === 'AFFILIATE_HAS_USERNAME';
          this.hasSentConfirmationCode = true;

          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_SENDING_CONFIRMATION_CODE,
            type: BannerType.success
          });
        }
      } catch (ex) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });

        this.hasSentConfirmationCode = false;
      }
      this.getCodeState = ButtonLoadingState.complete;
    },
    async checkConfirmationCode() {
      if (!this.internalMobileNumber) {
        return;
      }
      // Check if phone number exists
      // 1. call existing code validation api
      // 2. if existing, call new api to register the relationship
      // 3. if new customer, proceed to next step to collect info
      this.getCodeState = ButtonLoadingState.loading;

      const context = 'SIGNUP_AFFILIATE';

      const payload = {
        number: Helpers.prefixPhoneNumber(this.internalMobileNumber),
        code: this.confirmationCode,
        context
      };

      const confirmationCodeResponse = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.VALIDATE_CONFIRMATION_CODE,
        payload
      );

      if (confirmationCodeResponse.status === APIResponseStatus.OK) {
        if (this.hasUserName) {
          await this.registerAffiliate();
          this.currentStep = 5;
        } else {
          this.currentStep = this.isExistingUser ? 4 : 3;
        }
      } else {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.getCodeState = ButtonLoadingState.complete;
    },
    async registerAffiliate() {
      this.getCodeState = ButtonLoadingState.loading;
      if (!(await this.validateAffiliate())) {
        this.getCodeState = ButtonLoadingState.complete;
        return;
      }
      const payload = {
        ...this.affiliate,
        mobileNumber: Helpers.prefixPhoneNumber(this.internalMobileNumber),
        slug: this.sellerInfo.slug ?? this.defaultSlug,
        isNewUser: !this.isExistingUser
      };
      try {
        const response = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.COMPLETE_AFFILIATE_SIGNUP,
          payload
        );
        if (response.status === APIResponseStatus.OK) {
          this.currentStep = 5;
        } else {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }
      } catch (err) {
        console.error(err);
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.getCodeState = ButtonLoadingState.complete;
    },
    async validateAffiliate(): Promise<boolean> {
      if (this.hasUserName) {
        return true;
      }
      const response = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.CHECK_SELLER_HASHTAG,
        { hashtagPrefix: this.affiliate.userName }
      );
      if (response.code === 'SELLER_DOES_EXIST') {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.SELLER_TAG_UNAVAILABLE,
          type: BannerType.error
        });
        return false;
      }

      return true;
    },
    async goToStore() {
      if (this.sellerInfo.shopifyStore) {
        window.location.href = this.sellerInfo.shopifyStore;
      }
    },
    async goToFindProducts() {
      window.location.href = 'https://tagscommerce.com/find-products';
    }
  }
});
