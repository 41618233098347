
import { defineComponent } from 'vue';
import { AudienceMember } from '@/models/audienceMember';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Helpers from '@/helpers';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { SellerOrder } from '@/models/sellerOrder';
import { Charges } from '@/models/charges';

export default defineComponent({
  name: 'AudienceMember',
  emits: ['update:filteredCount'],
  data() {
    return {
      memberId: '',
      nextId: '',
      previousId: '',
      index: 1,
      member: null as null | AudienceMember,
      filter: 'All',
      sortBy: 'Date',
      searchString: '',
      transactionCount: 0,
      filteredOrders: [] as SellerOrder[],
      filterOptions: [
        { name: 'All', value: 'All' },
        { name: 'New Orders', value: 'New Orders' },
        { name: 'Fulfilled', value: 'Fulfilled' },
        { name: 'Refunds', value: 'Refunds' }
      ],
      sortOptions: [
        { name: 'Most Recent', value: 'Date' },
        { name: 'Least Recent', value: 'DateAsc' },
        { name: 'Sales Highest', value: 'Amount' },
        { name: 'Sales Lowest', value: 'AmountAsc' },
        { name: 'Tag', value: 'Name' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      audienceListLength: 'audience/getAudienceListLength'
    })
  },
  mounted() {
    if (this.$route.params.memberId) {
      this.memberId = this.$route.params.memberId as string;
    }
    this.getAudience();
  },
  methods: {
    ...mapMutations('global', ['setLoading']),
    ...mapMutations('orders', ['setBuyerName']),
    ...mapActions('audience', ['getNext', 'getPrevious', 'getCurrentIndex']),
    async getAudience() {
      this.setLoading(true);
      const payload = {
        accountId: this.currentAccount.accountId,
        userId: this.memberId
      };
      const responseData = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_BUYER_FOR_ACCOUNT,
        payload
      );

      if (responseData.status === APIResponseStatus.OK) {
        this.member = responseData.data as AudienceMember;

        this.index = (await this.getCurrentIndex(this.memberId)) + 1;
        this.nextId = await this.getNext(this.memberId);
        this.previousId = await this.getPrevious(this.memberId);

        this.setLoading(false);
      } else {
        this.setLoading(false);
        this.$router.push({
          name: 'PageNotFound'
        });
      }
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    processDate(date: number | string) {
      return Helpers.processDate(new Date(date).getTime());
    },
    isActive(member: AudienceMember): boolean {
      return member.totalOrders > 1 && !this.isInactive(member);
    },
    isInactive(member: AudienceMember): boolean {
      const sixtyDaysPast = new Date().getTime() - 60 * 24 * 60 * 60 * 1000;
      if (member.lastTransaction) {
        return (
          member.totalOrders > 1 &&
          new Date(member.lastTransaction).getTime() < sixtyDaysPast
        );
      }
      return false;
    },
    setFilter() {
      const getTime = (d?: string) => {
        return d != null ? new Date(d).getTime() : 0;
      };
      const getAmount = (c?: Charges) => {
        return c != null && c.total ? c.total : 0;
      };
      if (this.member) {
        switch (this.filter) {
          case 'Fulfilled':
            this.filteredOrders = this.member.orders.filter(
              this.filterTransactionsByFulfilled
            );
            break;
          case 'Refunds':
            this.filteredOrders = this.member.orders.filter(
              this.filterTransactionsByRefunds
            );
            break;
          case 'New Orders':
            this.filteredOrders = this.member.orders.filter(
              this.filterTransactionByWaiting
            );
            break;
          default:
            this.filteredOrders = this.member.orders;
            break;
        }

        if (this.searchString) {
          this.filteredOrders = this.filteredOrders.filter(
            this.filterTransactionsBySearchValue
          );
        }

        if (this.sortBy) {
          this.filteredOrders = this.filteredOrders.sort(
            (a: SellerOrder, b: SellerOrder) => {
              let results = 0;
              if (a && b) {
                switch (this.sortBy) {
                  case 'Name':
                    results = a.productHashtag.localeCompare(b.productHashtag);
                    break;
                  case 'Amount':
                    results = getAmount(b.charges) - getAmount(a.charges);
                    break;
                  case 'AmountAsc':
                    results = getAmount(a.charges) - getAmount(b.charges);
                    break;
                  case 'Date':
                    results = getTime(b.created) - getTime(a.created);
                    break;
                  case 'DateAsc':
                    results = getTime(a.created) - getTime(b.created);
                    break;
                  default:
                    results = 1;
                }
              }
              return results;
            }
          );
        }
      }

      this.$emit('update:filteredCount', this.filteredOrders.length);
    },

    filterTransactionsByFulfilled(transaction: SellerOrder): boolean {
      // return true if transaction has been fulfilled, false otherwise
      return !!transaction.fulfilled;
    },

    filterTransactionsByRefunds(transaction: SellerOrder): boolean {
      // return true if transaction has been refunded, false otherwise
      return !!transaction.refunded;
    },

    filterTransactionByWaiting(transaction: SellerOrder): boolean {
      // return false if transaction has benn fulfilled or refunded, true otherwise
      return !transaction.refunded && !transaction.fulfilled;
    },

    filterTransactionsBySearchValue(transaction: SellerOrder) {
      const searchValue = this.searchString;
      const firstName = transaction.shippedToFirstName
        ? transaction.shippedToFirstName.toLowerCase()
        : '';
      const lastName = transaction.shippedToLastName
        ? transaction.shippedToLastName.toLowerCase()
        : '';
      const via = transaction.provider
        ? transaction.provider.toLowerCase()
        : '';
      const date = transaction.created
        ? new Date(transaction.created).toLocaleDateString()
        : '';
      const hashtag = transaction.productHashtag
        ? transaction.productHashtag.toLowerCase()
        : '';
      const userTransactionId = transaction.transactionId
        ? transaction.transactionId
        : '';
      const amount = transaction.charges
        ? Helpers.currencyFormatter(transaction.charges.price)
        : '';

      if (searchValue.length > 0) {
        const searchComponents = searchValue.split(' ');
        let value;

        for (value of searchComponents) {
          if (
            !firstName.includes(value) &&
            !lastName.includes(value) &&
            !date.includes(value) &&
            !hashtag.includes(value) &&
            !userTransactionId.includes(value) &&
            !via.includes(value) &&
            !amount.includes(value)
          ) {
            return false;
          }
        }
      }
      return true;
    },
    viewOrders() {
      this.setBuyerName(`${this.member?.lastName}, ${this.member?.firstName}`);
      this.$router.push({
        name: 'seller-orders',
        query: {
          userId: this.memberId
        }
      });
    },
    goBack() {
      this.$router.push({
        name: 'audience'
      });
    },
    async previous() {
      if (this.previousId) {
        await this.$router.push({ path: `/store/audience/${this.previousId}` });
        this.memberId = this.previousId;
        this.getAudience();
      }
    },
    async next() {
      if (this.nextId) {
        await this.$router.push({ path: `/store/audience/${this.nextId}` });
        this.memberId = this.nextId;
        this.getAudience();
      }
    }
  }
});
