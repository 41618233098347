const DisplayType = {
  LIST: 'list',
  GRID: 'grid'
};

const ProductFilterType = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  LOWSTOCK: 'lowstock',
  ALL: 'all'
};

const TipFilterType = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ALL: 'all'
};

const TagSortOptions = {
  MOSTRECENT: 1,
  LEASTRECENT: 2,
  HIGHESTSALES: 3,
  LOWESTSALES: 4
};

const AccountFilterType = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISCONNECTED: 'disconnected',
  CANCELED: 'canceled',
  ALL: 'all'
};

const AccountSortOption = {
  NAMEASC: 'Name a to z',
  NAMEDESC: 'Name z to a',
  SALESASC: 'Sales 0 to 9',
  SALESDESC: 'Sales 9 to 0',
  CREATEDDESC: 'Latest account created'
};

const Profanity = {
  BASTARD: 'bastard',
  FUCK: 'fuck',
  HELP: 'help',
  ANAL: 'anal',
  ANUS: 'anus',
  ARSE: 'arse',
  ASS: 'ass',
  ASSSHOLE: 'assshole',
  BITCH: 'bitch',
  BLOODYHELL: 'bloody hell',
  BOONG: 'boong',
  COCK: 'cock',
  COCKFUCKER: 'cockfucker',
  COCKSUCK: 'cocksuck',
  COCKSUCKER: 'cocksucker',
  COON: 'coon',
  COONNASS: 'coonnass',
  CRAP: 'crap',
  CUNT: 'cunt',
  CYBERFUCK: 'cyberfuck',
  DAMN: 'damn',
  DARN: 'darn',
  DICK: 'dick',
  DIRTY: 'dirty',
  DOUCHE: 'douche',
  DUMMY: 'dummy',
  ERECT: 'erect',
  ERECTION: 'erection',
  EROTIC: 'erotic',
  ESCORT: 'escort',
  FAG: 'fag',
  FAGGOT: 'faggot',
  FUCKOFF: 'Fuck off',
  FUCKYOU: 'fuck you',
  FUCKASS: 'fuckass',
  FUCKHOLE: 'fuckhole',
  GODDAMN: 'god damn',
  GOOK: 'gook',
  HARDCORE: 'hardcore',
  HOMOEROTIC: 'homoerotic',
  HORE: 'hore',
  LESBIAN: 'lesbian',
  LESBIANS: 'lesbians',
  MOTHER_FUCKER: 'mother fucker',
  MOTHERFUCK: 'motherfuck',
  MOTHERFUCKER: 'motherfucker',
  NEGRO: 'negro',
  NIGGER: 'nigger',
  ORGASIM: 'orgasim',
  ORGASM: 'orgasm',
  PENIS: 'penis',
  PENISFUCKER: 'penisfucker',
  PISS: 'piss',
  PISSOFF: 'piss off',
  PORN: 'porn',
  PORNO: 'porno',
  PORNOGRAPHY: 'pornography',
  PUSSY: 'pussy',
  RETARD: 'retard',
  SADIST: 'sadist',
  SEX: 'sex',
  SEXY: 'sexy',
  SHIT: 'shit',
  SLUT: 'slut',
  SONOFABITCH: 'son of a bitch',
  SUCK: 'suck',
  TITS: 'tits',
  VIAGRA: 'viagra',
  WHORE: 'whore',
  XXX: 'xxx',
  DELETED: 'deleted',
  EXPIRED: 'expired'
};

const SmsSensitiveWords = {
  HELP: 'help',
  SMS: 'sms',
  MMS: 'mms',
  CALL: 'call'
};

export {
  DisplayType,
  ProductFilterType,
  TipFilterType,
  AccountFilterType,
  AccountSortOption,
  Profanity,
  SmsSensitiveWords,
  TagSortOptions
};
