
import { defineComponent } from 'vue';
import { Stat } from '../../models/stat';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import Helpers from '@/helpers';

const AdminStats = defineComponent({
  data() {
    return {
      totalSellers: 0,
      totalTagCount: 0,
      totalBuyers: 0,
      totalGMV: 0,
      stats: [
        {
          title: 'Last 24 hrs',
          amount: 0,
          sales: 0
        },
        {
          title: 'Yesterday',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 7 days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 30 days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 90 days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Lifetime',
          amount: 0,
          sales: 0
        }
      ],
      statRefreshButtonState: ButtonLoadingState.initial
    };
  },
  async mounted() {
    await this.getStats();
  },
  methods: {
    getPrice(price: number) {
      return Helpers.currencyFormatter(price);
    },
    async getStats() {
      this.statRefreshButtonState = ButtonLoadingState.loading;

      const responseData = await FirebaseRequest.createRequest<{
        totalSellers: number;
        totalTagCount: number;
        totalBuyers: number;
        stripeGrossTotal: number;
        overallStats: Stat;
      }>(FirebaseRequestTypes.GET_ADMIN_STATS, {});

      if (responseData.status === APIResponseStatus.OK && responseData.data) {
        this.totalSellers = responseData.data.totalSellers;
        this.totalTagCount = responseData.data.totalTagCount;
        this.totalBuyers = responseData.data.totalBuyers;
        this.totalGMV = responseData.data.stripeGrossTotal;

        const st = responseData.data.overallStats as Stat;
        if (st.today) {
          this.stats[0].amount = parseFloat(st.today.amount.toFixed(2));
          this.stats[0].sales = st.today.orders;
        }
        if (st.yesterday) {
          this.stats[1].amount = parseFloat(st.yesterday.amount.toFixed(2));
          this.stats[1].sales = st.yesterday.orders;
        }
        if (st.week) {
          this.stats[2].amount = parseFloat(st.week.amount.toFixed(2));
          this.stats[2].sales = st.week.orders;
        }
        if (st.month) {
          this.stats[3].amount = parseFloat(st.month.amount.toFixed(2));
          this.stats[3].sales = st.month.orders;
        }
        if (st.quarter) {
          this.stats[4].amount = parseFloat(st.quarter.amount.toFixed(2));
          this.stats[4].sales = st.quarter.orders;
        }
        if (st.totals) {
          this.stats[5].amount = parseFloat(st.totals.amount.toFixed(2));
          this.stats[5].sales = st.totals.orders;
        }
      }
      this.statRefreshButtonState = ButtonLoadingState.complete;
    }
  }
});

export default AdminStats;
