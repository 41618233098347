
import { defineComponent } from 'vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import { BannerMessageStrings } from '@/common/BannerComponent/BannerMessages';

export default defineComponent({
  name: 'SellerCreateAccount',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['reserveTag'],
  data() {
    return {
      userInfo: {
        hashtagPrefix: ''
      },
      reserveButtonState: ButtonLoadingState.initial,
      errors: [] as string[]
    };
  },
  watch: {
    isLoading() {
      this.processLoading();
    }
  },
  mounted() {
    this.processLoading();
  },
  methods: {
    async reserveTag() {
      this.errors = [];
      this.reserveButtonState = ButtonLoadingState.loading;
      const tag = this.userInfo.hashtagPrefix;
      const hashtagGenerateResponse = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.CHECK_SELLER_HASHTAG,
        { hashtagPrefix: tag }
      );

      this.reserveButtonState = ButtonLoadingState.initial;
      if (hashtagGenerateResponse.code === 'SELLER_DOES_EXIST') {
        this.errors.push(BannerMessageStrings.SELLER_TAG_UNAVAILABLE);
      } else {
        this.$emit('reserveTag', this.userInfo);
      }
    },
    processLoading() {
      if (this.isLoading) {
        this.reserveButtonState = ButtonLoadingState.loading;
      } else {
        this.reserveButtonState = ButtonLoadingState.initial;
      }
    }
  }
});
