
import { defineComponent } from 'vue';
import { PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js';

export default defineComponent({
  name: 'BoostWalletPay',
  props: {
    /**
     * Stripe
     * stripe needs to be instantiated in the parent component
     * so that the card element is generated with that same instance
     */
    stripe: {
      type: Object as () => Stripe,
      required: true
    },
    /*
     * Amount
     * The amount in the currency's subunit (e.g. cents, yen, etc.)
     * i.e. 1000 = $10.00
     */
    amount: {
      type: Number,
      required: true
    },
    shippingAmount: {
      type: Number
    },
    paymentLabel: {
      type: String,
      required: true
    },
    paymentClientSecret: {
      type: String,
      required: true
    }
  },
  emits: ['success', 'failed', 'has-wallet', 'shipping', 'shipping-option'],
  data() {
    return {
      errorMessage: ''
    };
  },
  async mounted() {
    if (this.stripe) {
      await this.initDigitalWalletPayments();
    }
  },
  methods: {
    async initDigitalWalletPayments() {
      const elements = this.stripe.elements();

      /*
       * Stripe payment request config
       * US only
       * usd
       * request name, email, phone, shipping info from buyer to be recorded
       * in transaction
       */
      const paymentRequest = this.stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: this.paymentLabel,
          amount: this.amount
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        // needed for shipping calcs
        requestShipping: true
      });

      // Create Payment request button
      // eslint-disable-next-line
      // @ts-ignore
      const paymentRequestButton = elements.create('paymentRequestButton', {
        paymentRequest,
        style: {
          paymentRequestButton: {
            theme: 'dark',
            height: '40px'
          }
        }
      });

      // Wire up payment button element
      // eslint-disable-next-line
      // @ts-ignore
      const result = await paymentRequest.canMakePayment();
      if (result) {
        paymentRequestButton.mount(
          this.$refs.paymentRequestButton as HTMLElement
        );
        this.$emit('has-wallet', true);
      } else {
        this.$emit('has-wallet', true);
      }

      // Shipping changes
      // eslint-disable-next-line
      // @ts-ignore
      paymentRequest.on('shippingaddresschange', (ev) => {
        if (ev.shippingAddress.country !== 'US') {
          ev.updateWith({ status: 'invalid_shipping_address' });
        } else if (!ev.shippingAddress.postalCode) {
          ev.updateWith({ status: 'invalid_shipping_address' });
        } else {
          this.$emit('shipping', ev);
        }
      });

      paymentRequest.on('shippingoptionchange', (ev) => {
        this.$emit('shipping-option', ev);
      });

      //  Listen for Wallet Pay payment attempt
      paymentRequest.on(
        'paymentmethod',
        async (ev: PaymentRequestPaymentMethodEvent) => {
          const res = await this.stripe.confirmCardSetup(
            this.paymentClientSecret,
            // eslint-disable-next-line
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );

          if (res.error) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
            this.errorMessage = res.error.message as string;
            console.log('paymentmethod error: ');
            console.error(res.error);
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow.
            if (
              res.setupIntent &&
              res.setupIntent.status === 'requires_action'
            ) {
              // Let Stripe.js handle the rest of the payment flow.
              // const { error } = await this.stripe.confirmCardSetup(
              const { error } = await this.stripe.handleCardAction(
                this.paymentClientSecret
              );
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
                this.errorMessage = error.message as string;
                this.$emit('failed');
                console.log('handleCardAction error: ');
                console.error(error);
              } else {
                // The payment has succeeded.
                this.$emit('success', ev);
              }
            } else {
              // The payment has succeeded.
              this.$emit('success', ev);
            }
          }
        }
      );
    }
  }
});
