
import { defineComponent, ref, computed, ComputedRef, watchEffect } from 'vue';
import { mapMutations, useStore } from 'vuex';
import {
  Dialog as HeadlessDialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import helpers from '@/helpers';
import RegisterComponent from './MarketplaceRegisterComponent.vue';
import MarketplaceCreateCheckoutComponent from './MarketplaceCreateCheckoutComponent.vue';
import ProductDetailsComponent from './MarketplaceProductDetails.vue';
import CheckoutReadyComponent from './MarketplaceCheckoutReadyComponent.vue';
import { AffiliateBrand } from '@/models/affiliateBrands';
import { useUrlSearchParams } from '@vueuse/core';
import { MarketplaceProduct } from '@/models/marketplaceProduct';

type DrawerStep = 'REGISTER' | 'LOGIN' | 'CREATE_CHECKOUT' | 'CHECKOUT_READY';

export default defineComponent({
  name: 'MarketplaceDrawerComponent',
  components: {
    HeadlessDialog,
    DialogPanel,
    TransitionChild,
    RegisterComponent,
    ProductDetailsComponent,
    CheckoutReadyComponent,
    MarketplaceCreateCheckoutComponent,
    TransitionRoot
  },
  setup() {
    const params = useUrlSearchParams('history');
    const store = useStore();

    function updateUrl(productId: string) {
      if (productId) {
        params.product = productId;
      } else {
        delete params.product;
      }
    }

    function formatPrice(price: string): string {
      return helpers.currencyFormatter(price);
    }

    const isBuyerLoggedIn: ComputedRef<boolean> = computed(
      () => store.getters['auth/isBuyerLoggedIn']
    );

    const selectedProduct: ComputedRef<MarketplaceProduct> = computed(
      () => store.getters['marketplace/getSelectedProductToSell']
    );

    const openDrawer: ComputedRef<boolean> = computed(
      () => selectedProduct.value !== undefined
    );

    const brands: ComputedRef<AffiliateBrand[]> = computed(
      () => store.getters['marketplace/getAllBrands']
    );
    const brandDetails = computed(() =>
      brands.value?.find((b) => b.accountId === selectedProduct.value.accountId)
    );

    const productTag = ref('');

    const taggedProducts: ComputedRef<{ [key: string]: string }> = computed(
      () => store.getters['marketplace/getTaggedProducts']
    );
    const currentStep = ref<DrawerStep>('REGISTER');

    watchEffect(() => {
      if (!selectedProduct.value) {
        return;
      }

      const productId = selectedProduct.value.id;
      updateUrl(productId);

      // Now that a product has been selected, check if the Tagger has already
      // created a Checkout Tag for that product. If so, set the productTag.
      productTag.value = taggedProducts.value[selectedProduct.value.id] ?? '';
      if (productTag.value) {
        currentStep.value = 'CHECKOUT_READY';
      }
    });

    watchEffect(
      () =>
        (currentStep.value = isBuyerLoggedIn.value
          ? 'CREATE_CHECKOUT'
          : 'REGISTER')
    );

    return {
      selectedProduct,
      brandDetails,
      isBuyerLoggedIn,
      currentStep,
      updateUrl,
      openDrawer,
      productTag,
      formatPrice
    };
  },
  data() {
    return {
      brandName: '',
      brandLogo: ''
    };
  },
  mounted() {
    if (!this.selectedProduct) {
      return;
    }

    this.updateUrl(this.selectedProduct.id);
  },
  methods: {
    ...mapMutations('marketplace', {
      selectProduct: 'setSelectedProductToSell'
    }),
    handleCheckoutCreated(productTag: string) {
      this.productTag = productTag;
      this.currentStep = 'CHECKOUT_READY';
    },
    closeDrawer() {
      this.updateUrl('');
      this.productTag = '';
      this.selectProduct(undefined);
      this.currentStep = this.isBuyerLoggedIn ? 'CREATE_CHECKOUT' : 'REGISTER';
    }
  }
});
