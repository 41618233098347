
import { defineComponent } from 'vue';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import settings from '@/settings';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { store } from '@/store';
import BoostSectionHeader from '@/common/BoostSectionHeader.vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';

export default defineComponent({
  components: { BoostSectionHeader },
  data() {
    return {
      stripeCardElement: null as null | StripeCardElement,
      stripe: null as null | Stripe,
      clientSecret: '',
      cardError: '' as string | undefined,
      isDefault: true,
      addPaymentButtonState: ButtonLoadingState.initial
    };
  },
  async mounted() {
    this.initializeStripe();
    const results = await FirebaseRequest.createRequest(
      FirebaseRequestTypes.INITIATE_ADD_NEW_PAYMENT,
      {}
    );
    if (results.status === APIResponseStatus.OK) {
      this.clientSecret = results.data as string;
    }
  },
  methods: {
    getImageUrlForCard(brand: string) {
      return require('../../assets/' +
        brand.replace(/\s/g, '').toLowerCase() +
        '.svg');
    },
    async initializeStripe() {
      // Instantiate stripe instance to be used for card and payment processing
      this.stripe = await loadStripe(settings.STRIPE_PUBLISHABLE_KEY);
    },
    async saveCard() {
      try {
        this.addPaymentButtonState = ButtonLoadingState.loading;
        const success = await this.$store.dispatch(
          'accounts/addPaymentOption',
          {
            stripeCardElement: this.stripeCardElement,
            stripe: this.stripe,
            setAsDefault: this.isDefault
          }
        );
        if (success) {
          this.addPaymentButtonState = ButtonLoadingState.complete;
          await this.$store.dispatch('auth/refreshCurrentBuyer');
          this.$router.push({
            name: 'buyer-settings',
            query: { menu: 'payment' }
          });
        }
      } catch (e) {
        console.log('adding payment option failed: ', e);
        this.addPaymentButtonState = ButtonLoadingState.initial;
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    },
    collectCard(card: StripeCardElement) {
      this.stripeCardElement = card;
      this.cardError = '';
    },
    goBack() {
      this.$router.push({ name: 'buyer-settings', query: { menu: 'payment' } });
    }
  }
});
