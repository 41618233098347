
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  data() {
    return {
      selectedMenu: 'All',
      menuOptions: ['All', 'Commerce', 'Shipping'],
      isConnectedToShopify: false
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    })
  },
  async mounted() {
    const responseData = await FirebaseRequest.createRequest<{
      configData: {
        name: string;
        configuration: string;
        status: string;
      };
    }>(FirebaseRequestTypes.GET_ECOMMERCE_CONFIG, {
      accountId: this.currentAccount?.accountId,
      name: 'Shopify'
    });

    if (
      responseData.status === APIResponseStatus.OK &&
      responseData.data?.configData?.configuration
    ) {
      this.isConnectedToShopify = true;
    }
  },
  methods: {
    ...mapMutations('global', [
      'setLoading',
      'setBannerStatus',
      'setContextualButton'
    ]),
    goToShopify() {
      this.$router.push({ name: 'shopify-integration' });
    },
    async syncShopify() {
      this.setLoading({ isLoading: true, message: 'Connecting to Shopify...' });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.setLoading({ isLoading: true, message: 'Syncing products...' });
      const responseData = await FirebaseRequest.createRequest<{
        configData: {
          name: string;
          configuration: string;
          status: string;
        };
      }>(FirebaseRequestTypes.SYNC_ECOMMERCE_PRODUCTS, {
        accountId: this.currentAccount?.accountId,
        name: 'Shopify'
      });

      if (responseData.status === APIResponseStatus.OK) {
        this.setLoading({ isLoading: true, message: 'Finished' });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.setLoading(false);
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_SUCCESS,
          type: BannerType.success
        });
      } else {
        this.setLoading({ isLoading: true, message: 'Something went wrong' });
        this.setLoading(false);
        this.setBannerStatus({
          text: BannerMessageStrings.SHOPIFY_ERROR_SYNCING,
          type: BannerType.error
        });
      }
    }
  }
});
