
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import SingleLayout from '@/layouts/SingleLayout.vue';
import CheckoutLayout from '@/layouts/CheckoutLayout.vue';
import OpenLayout from '@/layouts/OpenLayout.vue';
import OpenDetailLayout from '@/layouts/OpenDetailLayout.vue';
import MarketplaceLayout from '@/layouts/MarketplaceLayout.vue';

export default defineComponent({
  name: 'LayoutSelector',
  components: {
    DefaultLayout,
    SingleLayout,
    CheckoutLayout,
    OpenLayout,
    OpenDetailLayout,
    MarketplaceLayout
  },
  data() {
    return {
      layout: 'DefaultLayout'
    };
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout;
      } else {
        this.layout = 'DefaultLayout';
      }
    }
  }
});
