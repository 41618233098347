
import { defineComponent, computed, ComputedRef } from 'vue';
import { mapGetters, mapMutations, useStore } from 'vuex';
import helpers from '@/helpers';
import { AffiliateBrand } from '@/models/affiliateBrands';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';

export default defineComponent({
  name: 'MarketplaceCheckoutReadyComponent',
  props: {
    productTag: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore();
    function formatPrice(price: string): string {
      return helpers.currencyFormatter(price);
    }

    const selectedProduct = computed(
      () => store.getters['marketplace/getSelectedProductToSell']
    );

    const brands: ComputedRef<AffiliateBrand[]> = computed(
      () => store.getters['marketplace/getAllBrands']
    );
    const brandDetails = computed(() =>
      brands.value?.find((b) => b.accountId === selectedProduct.value.accountId)
    );

    return {
      selectedProduct,
      brandDetails,
      formatPrice
    };
  },
  data() {
    return {
      copyButtonState: ButtonLoadingState.initial,
      brandName: '',
      brandLogo: ''
    };
  },
  computed: {
    ...mapGetters({
      isBuyerLoggedIn: 'auth/isBuyerLoggedIn',
      currentUser: 'auth/getCurrentUser',
      currentBuyer: 'auth/getCurrentBuyer',
      getBrandName: 'global/getBrandName'
    }),
    openDrawer() {
      return this.selectedProduct !== undefined;
    },
    checkoutUrl() {
      return `${window.location.origin}/buy/${this.$props.productTag}`;
    },
    showGuidelines() {
      return (
        this.brandDetails?.guidelines !== undefined &&
        this.brandDetails.guidelines.length > 0
      );
    }
  },
  // mounted() {
  //   if (!this.selectedProduct) {
  //     return;
  //   }

  //   const selectedBrand = this.brands.find(
  //     (b: AffiliateBrand) => b.accountId === this.selectedProduct.accountId
  //   );
  //   console.log(selectedBrand[0]);
  //   this.brandName = selectedBrand.brandName;
  //   this.brandLogo =
  //     selectedBrand.imageUrl ??
  //     'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=oilqXxSqey&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';
  // },
  methods: {
    ...mapMutations('marketplace', {
      selectProduct: 'setSelectedProductToSell'
    }),
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    async copyLink() {
      this.copyButtonState = ButtonLoadingState.loading;
      await navigator.clipboard.writeText(this.checkoutUrl);

      this.copyButtonState = ButtonLoadingState.complete;
    }
  }
});
