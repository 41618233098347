import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19065e32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex--wrap margin-top-10" }
const _hoisted_2 = { class: "flex justify--between t-12 no-pad grid-row--large" }
const _hoisted_3 = { class: "t-12 d-6 justify--between center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _component_LoginBuyer = _resolveComponent("LoginBuyer")!
  const _component_LoginSeller = _resolveComponent("LoginSeller")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "#subheader" }, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_BoostButton, {
            label: "User",
            "button-style": "pill",
            class: _normalizeClass({
            'pill pill-white': true,
            'pill-blue': _ctx.loginType === _ctx.userType.BUYER
          }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setUserType(_ctx.userType.BUYER)))
          }, null, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_BoostButton, {
            label: "Brand",
            "button-style": "pill",
            class: _normalizeClass({
            'pill pill-white': true,
            'pill-blue': _ctx.loginType === _ctx.userType.SELLER
          }),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setUserType(_ctx.userType.SELLER)))
          }, null, 8, ["class"])
        ])
      ])
    ])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.loginType === _ctx.userType.BUYER)
            ? (_openBlock(), _createBlock(_component_LoginBuyer, { key: 0 }))
            : (_openBlock(), _createBlock(_component_LoginSeller, { key: 1 }))
        ])
      ])
    ])
  ], 64))
}