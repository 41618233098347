import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchComponent = _resolveComponent("SwitchComponent")!
  const _component_SwitchLabel = _resolveComponent("SwitchLabel")!
  const _component_SwitchGroup = _resolveComponent("SwitchGroup")!

  return (_openBlock(), _createBlock(_component_SwitchGroup, {
    as: "div",
    class: "flex flex-nowrap items-center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SwitchComponent, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        class: _normalizeClass([
        _ctx.model ? 'bg-dorito' : 'bg-gray-600',
        'relative inline-flex h-4 w-[32px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-dorito focus:ring-offset-2'
      ])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            "aria-hidden": "true",
            class: _normalizeClass([
          _ctx.model ? 'translate-x-4' : 'translate-x-0',
          'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        ])
          }, null, 2)
        ]),
        _: 1
      }, 8, ["modelValue", "class"]),
      (_ctx.label)
        ? (_openBlock(), _createBlock(_component_SwitchLabel, {
            key: 0,
            as: "span",
            class: "ml-3 text-sm"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ])
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}