import settings from './settings';
import { getAuth, signInAnonymously, User } from 'firebase/auth';

export const FirebaseRequestTypes = {
  CHECK_SELLER_EMAIL: 'checkSellerEmail',
  CHECK_SELLER_HASHTAG: 'checkSellerHashtag',
  CREATE_SELLER_ACCOUNT: 'createSellerAccount',
  GET_SELLER_INFO: 'getSellerInfo',
  REQUEST_CONFIRMATION_CODE: 'requestConfirmationCode',
  UPDATE_BUYER_INFO: 'updateBuyerInfo',
  VALIDATE_CONFIRMATION_CODE: 'validateConfirmationCode',
  VERIFY_BUYER_EMAIL: 'verifyEmail',
  RESEND_BUYER_VERIFICATION_EMAIL: 'resendBuyerVerificationEmail',
  GET_BUYER_PAYMENT_OPTIONS: 'fetchPayments',
  GET_BUYER_INFO: 'getBuyer',
  SAVE_BUYER: 'saveBuyer',
  REMOVE_BUYER_PAYMENT_OPTION: 'removePayment',
  UPDATE_BUYER_PAYMENT_OPTION: 'updatePayment',
  UPDATE_BUYER_SHIPPING_ADDRESS: 'updateShippingAddress',
  REMOVE_BUYER_SHIPPING_ADDRESS: 'removeShippingAddress',
  GET_SELLER_STATS: 'sellerStats',
  BUYER_UPDATE_DEFAULT_CARD: 'updateDefaultCard',
  GET_CHECKOUT: 'getCheckoutInfo',
  BEGIN_INSTANT_CHECKOUT: 'beginInstantCheckout',
  GET_INSTANT_CHECKOUT: 'getInstantCheckout',
  BUY_INSTANT_CHECKOUT: 'buyInstantCheckout',
  GET_PRODUCT_TAXES: 'getProductTaxes',
  GET_PRODUCT_INFO: 'getProductInfo',
  PROCESS_CHECKOUT: 'expressCheckout',
  SET_TRANSACTION_FULFILLMENT: 'setTransactionFulfillment',
  REFUND_PURCHASE: 'refundPurchase',
  SAVE_TRACKING: 'saveTracking',
  GET_ADMIN_ACCOUNTS: 'getAccounts',
  GET_ACCOUNT_DATA: 'getAccount',
  UPDATE_SELLER_ACCOUNT: 'updateSellerAccount',
  CONNECT_STRIPE: 'connectStripe',
  DISCONNECT_STRIPE: 'disconnectStripe',
  GET_SUBSCRIPTION_PORTAL_STRIPE: 'getSubscriptionPortal',
  COMPLETE_SUBSCRIPTION_PORTAL_STRIPE: 'completeSubscriptionPortal',
  DISABLE_SELLER_ACCOUNT: 'disableAccount',
  GET_ADMIN_STATS: 'getBoostStats',
  GET_BUYER_PURCHASES: 'getBuyerPurchases',
  GET_BUYER_PURCHASE: 'getBuyerPurchase',
  GET_SELLER_ORDERS: 'getSellerOrders',
  GET_SELLER_ORDER_BY_ID: 'getSellerOrderById',
  GET_SELLER_TRANSACTIONS: 'getSellerTransactions',
  GET_SELLER_ATTRIBUTION_LIST: 'getAttributionList',
  INITIATE_ADD_NEW_PAYMENT: 'initiateAddNewPayment',
  TEMP_CREATE_PAYMENT_INTENT: 'tempCreatePaymentIntent',
  GET_TAG_VARIATIONS: 'getTagVariations',
  UPDATE_TAG_VARIATIONS: 'updateTagVariations',
  GET_SELLER_PRODUCTS: 'getSellerProducts',
  GET_SELLER_TAGS: 'getSellerTags',
  GET_SELLER_TAG: 'getSellerTag',
  CREATE_PAYMENT_INTENT: 'createPaymentIntent',
  COMPLETE_PRE_REGISTRATION: 'completePreRegistration',
  COMPLETE_AFFILIATE_SIGNUP: 'completeAffiliateSignup',
  COMPLETE_AFFILIATE_SIGNUP_FOR_PRODUCT: 'completeAffiliateSignupForProduct',
  CONNECT_AFFILIATE_PAYOUT: 'connectAffiliatePayout',
  GET_AFFILIATE_BRANDS: 'getAffiliateBrands',
  GET_AFFILIATE_BRAND: 'getAffiliateBrand',
  GET_AFFILIATE_EARNINGS: 'getAffiliateEarnings',
  AFFILIATE_WITHDRAW_AMOUNT: 'withdrawAffiliateEarnings',
  REMOVE_AFFILIATE_BRAND: 'removeAffiliateBrand',
  REMOVE_AFFILIATE_BRAND_TAG: 'removeAffiliateBrandTag',
  DISCONNECT_AFFILIATE_PAYOUT: 'disconnectAffiliatePayout',
  UPDATE_BRAND_AFFILIATE: 'updateBrandAffiliate',
  GET_AFFILIATE_STATS: 'getAffiliateStats',
  GET_AFFILIATE_ANALYTICS: 'getAnalyticsForAffiliate',
  GET_AFFILIATE_DETAILS: 'getDetailsForAffiliate',
  GET_AUDIENCE: 'getAudience',
  GET_BUYER_FOR_ACCOUNT: 'getBuyerForAccount',
  SAVE_TIP: 'saveTip',
  SAVE_TAG: 'saveTag',
  SAVE_ATTRIBUTIONS: 'updateProductAttributions',
  GLOBAL_SEARCH: 'search',
  CONNECT_ECOMMERCE: 'connectEcommerce',
  GET_ECOMMERCE_CONFIG: 'getEcommerceConfig',
  UPDATE_ECOMMERCE_APPS: 'updateEcommerceApps',
  GET_ECOMMERCE_PRODUCTS: 'getECommerceProducts',
  GET_ECOMMERCE_SHIPPING_RATES: 'getEcommerceShippingRates',
  CREATE_ECOMMERCE_TAGS: 'tagECommerceProducts',
  SYNC_ECOMMERCE_PRODUCTS: 'syncECommerceProducts',
  GENERATE_PRODUCT_LINKS: 'generateInstantCheckoutLink',
  GET_SELLER_USERS: 'getSellerAccountUsers',
  ADD_SELLER_USER_INVITE: 'inviteSellerAccountUser',
  VALIDATE_SELLER_INVITE: 'validateAccountUserInvitation',
  ACCEPT_SELLER_USER_INVITE: 'acceptInvitation',
  REMOVE_SELLER_USER: 'removeAccountUser',
  REQUEST_TRANSFER_OWNERSHIP: 'requestOwnerTransfer',
  ACCEPT_TRANSFER_OWNERSHIP: 'acceptOwnerTransfer',
  CHANGE_SELLER_USER_ROLE: 'changeSellerUserAccountRole',
  CHECK_USER_EMAIL_EXISTS: 'checkIfEmailUserExists',
  GET_SELLER_ANALYTICS: 'getAnalyticsByAccountId',
  GET_LATER_ANALYTICS: 'getLaterAnalyticsByAccountId',
  GET_SHARE_ANALYTICS: 'getShareAnalyticsByAccountId',
  GET_LOGGED_IN_USER_ROLE: 'getLoggedInUserRole'
};

export enum ProductReplyCodes {
  HASHTAG_NOT_FOUND = 'HASHTAG_NOT_FOUND',
  INACTIVE = 'INACTIVE',
  NO_INVENTORY = 'NO_INVENTORY',
  SKU_NOT_FOUND = 'SKU_NOT_FOUND',
  PURCHASE_OWN_TAG = 'PURCHASE_OWN_TAG'
}

export enum Context {
  LOGIN_EMAIL = 'LOGIN_EMAIL',
  LOGIN_MOBILE = 'LOGIN_MOBILE_NUMBER',
  SIGNUP_MOBILE_NUMBER = 'SIGNUP_MOBILE_NUMBER',
  SIGNUP_VIA_SMS = 'SIGNUP_VIA_SMS',
  UPDATE_MOBILE_NUMBER = 'UPDATE_MOBILE_NUMBER'
}

export enum APIResponseCode {
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  PHONE_NUMBER_ALREADY_IN_USE = 'PHONE_NUMBER_ALREADY_IN_USE'
}

export enum APIResponseStatus {
  OK = 'ok',
  ERROR = 'error'
}

export interface APIResponse<T> {
  status: APIResponseStatus.OK | APIResponseStatus.ERROR;
  code: string;
  data?: T;
}

export class FirebaseRequest {
  public static async requestFile(
    type: string,
    data: unknown
  ): Promise<Response> {
    const user = getAuth().currentUser;
    if (!user) {
      throw Error();
    }

    const accessToken = await user.getIdToken(true).then((idToken: string) => {
      return idToken;
    });

    const dataWithId =
      typeof data === 'object'
        ? {
            uid: user.uid,
            ...data
          }
        : data;

    const body = JSON.stringify(dataWithId);
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + accessToken
    };

    const response = await fetch(
      `${settings.FIREBASE_CLOUD_FUNCTIONS_URL}/${type}`,
      {
        method: 'POST',
        body,
        headers
      }
    );

    return response;
  }

  public static async createRequest<T>(
    type: string,
    data: unknown
  ): Promise<APIResponse<T>> {
    let user = getAuth().currentUser;
    let isAnonymous = false;

    if (!user) {
      // for list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // NOTE: when signed-in anonymously, check userObj.user
      isAnonymous = true;
      const cred = await signInAnonymously(getAuth());
      user = cred.user;
    }

    const results = await this.userAction<T>(type, data, user).then(
      async (response) => {
        // is anonymous user, so sign right back out
        if (isAnonymous) {
          await getAuth().signOut();
        }

        return response;
      }
    );

    return results;
  }

  private static async userAction<T>(
    type: string,
    data: unknown,
    user: User
  ): Promise<APIResponse<T>> {
    const accessToken = await user.getIdToken(true).then((idToken: string) => {
      return idToken;
    });

    const dataWithId =
      typeof data === 'object'
        ? {
            uid: user.uid,
            ...data
          }
        : data;

    const body = JSON.stringify(dataWithId);
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + accessToken
    };

    try {
      const response = await fetch(
        `${settings.FIREBASE_CLOUD_FUNCTIONS_URL}/${type}`,
        {
          method: 'POST',
          body,
          headers
        }
      );

      return response.json() as unknown as APIResponse<T>;
    } catch (error) {
      return {
        status: APIResponseStatus.ERROR,
        code: String(error)
      } as APIResponse<T>;
    }
  }
}
