
import { defineComponent } from 'vue';
import BoostBrandCard from '@/common/BoostBrandCard.vue';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { mapMutations } from 'vuex';
import { AffiliateBrand } from '../../models/affiliateBrands';

export default defineComponent({
  components: { BoostBrandCard },
  data() {
    return {
      brands: [] as AffiliateBrand[],
      menuItems: [
        {
          label: 'View Tags',
          onClick: (brand) => {
            this.showBrandTags(brand);
          }
        },
        {
          label: 'Remove Brand',
          onClick: (brand) => {
            this.remove(brand);
          },
          isDangerous: () => {
            return true;
          }
        }
      ] as ContextMenuItem[]
    };
  },
  async mounted() {
    this.setLoading(true);
    const result = await FirebaseRequest.createRequest(
      FirebaseRequestTypes.GET_AFFILIATE_BRANDS,
      {}
    );
    if (result.status === APIResponseStatus.OK) {
      this.brands = result.data as AffiliateBrand[];
    } else {
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
        type: BannerType.error
      });
    }
    this.setLoading(false);
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    showBrandTags(brand: AffiliateBrand) {
      this.$router.push({
        name: 'buyer-brand-tags',
        params: { accountAffiliateId: brand.accountAffiliateId }
      });
    },
    async remove(brand: AffiliateBrand) {
      this.setLoading(true);
      const result = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.REMOVE_AFFILIATE_BRAND,
        { accountAffiliateId: brand.accountAffiliateId }
      );
      if (result.status === APIResponseStatus.OK) {
        this.setBannerStatus({
          text: BannerMessageStrings.CHANGES_UPDATED,
          type: BannerType.success
        });
        const index = this.brands.indexOf(brand);
        this.brands.splice(index, 1);
      } else {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.setLoading(false);
    },
    openFindProductsLink() {
      window.open('https://tagscommerce.com/find-products', '_blank');
    }
  }
});
