
import { defineComponent } from 'vue';
import { TagDetail, TagSku } from '@/models/tag';

export default defineComponent({
  props: {
    product: {
      type: Object as () => TagDetail,
      required: true
    },
    sku: {
      type: Object as () => TagSku,
      required: false
    }
  },
  computed: {
    productImage(): string {
      return (
        this.sku?.imageUrl ||
        this.product?.imageUrl ||
        require('../../assets/default-product.jpg')
      );
    }
  }
});
