
import { defineComponent } from 'vue';
import {
  PreRegStatus,
  SellerAccount
} from '@/pre-registration/PreRegistration.vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { PreRegistrationUserInfo } from '@/models/audienceMember';
import PageNotFoundComponent from '@/common/PageNotFoundComponent.vue';
import { store } from '@/store';

export default defineComponent({
  name: 'PreRegComplete',
  components: { PageNotFoundComponent },
  props: {
    seller: {
      type: Object as () => SellerAccount
    },
    preRegInfo: {
      type: Object as () => PreRegistrationUserInfo,
      required: true
    },
    mobileNumber: {
      type: String
    },
    status: {
      type: String as () => PreRegStatus
    }
  },
  data() {
    return {
      hasSentConfirmationCode: false,
      confirmationCode: '',
      getCodeState: ButtonLoadingState.initial,
      loginContext: {
        LOGIN_EMAIL: 'LOGIN_EMAIL',
        LOGIN_MOBILE_NUMBER: 'LOGIN_MOBILE_NUMBER',
        PREREGISTER_USER: 'PREREGISTER_USER'
      }
    };
  },
  computed: {
    customColor(): string {
      return store.getters['global/getCustomColor'] as string;
    }
  },
  methods: {
    handleNextAction() {
      this.$router.push({
        name: 'Login',
        query: { type: 1 }
      });
    }
  }
});
