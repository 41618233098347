import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bcf209cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "confirm-component"
}
const _hoisted_2 = { class: "confirm-component__wrapper" }
const _hoisted_3 = { class: "confirm-component__inner" }
const _hoisted_4 = { class: "confirm-component__controls-container" }
const _hoisted_5 = { class: "confirm-component__title" }
const _hoisted_6 = { style: {"margin-top":"0"} }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "confirm-component__message"
}
const _hoisted_9 = { class: "confirm-component__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _directive_sanitized = _resolveDirective("sanitized")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.title), 1),
                _createVNode(_component_BoostButton, {
                  type: "button",
                  "button-style": "none",
                  onClick: _ctx._cancel
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: require('../../assets/cancel-key.svg'),
                      alt: ""
                    }, null, 8, _hoisted_7)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              (_ctx.message?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("p", null, null, 512), [
                      [_directive_sanitized, _ctx.message]
                    ]),
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.showCancelButton)
                  ? (_openBlock(), _createBlock(_component_BoostButton, {
                      key: 0,
                      label: "Cancel",
                      class: "w-50",
                      "button-style": "optional",
                      type: "button",
                      onClick: _ctx._cancel
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_BoostButton, {
                  label: _ctx.confirmText,
                  class: _normalizeClass({ 'w-100': !_ctx.showCancelButton, 'w-50': _ctx.showCancelButton }),
                  "button-style": _ctx.showCancelButton ? 'primary' : 'optional',
                  type: "button",
                  onClick: _ctx._confirm
                }, null, 8, ["label", "class", "button-style", "onClick"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", {
          class: "confirm-component__overlay",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx._cancel && _ctx._cancel(...args))),
          onWheel: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
          onTouchmove: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
          onScroll: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
        }, null, 32)
      ]))
    : _createCommentVNode("", true)
}