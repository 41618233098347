
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { computed, defineComponent, ref, watch } from 'vue';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import { mapGetters, mapMutations, useStore } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from './BannerComponent/BannerMessages';

interface SearchResult {
  category: string;
  title: string;
  route: string;
  metaData: string;
  id: string;
}

enum SearchCategory {
  Tags = 'Tags',
  Products = 'Products',
  Orders = 'Orders',
  Audience = 'Audience',
  Customizations = 'Customizations',
  Transactions = 'Transactions',
  Attributions = 'Attributions'
}

const MIN_CHAR = 3;

export default defineComponent({
  name: 'AppSearch',
  emits: ['search'],
  setup() {
    const store = useStore();
    const searchText = ref('');
    const results = ref([] as SearchResult[]);
    const hasResults = computed(() => results.value.length > 0);
    const currentCategory = ref('');
    const clearCategory = ref(false);

    const currentRoute = useRoute();
    const checkRoute = (rt: RouteLocationNormalizedLoaded) => {
      if (
        rt.name === 'seller-products' ||
        rt.name === 'seller-product-detail'
      ) {
        currentCategory.value = SearchCategory.Products;
      } else if (rt.name === 'seller-orders' || rt.name === 'seller-order') {
        currentCategory.value = SearchCategory.Orders;
      } else if (rt.name === 'audience' || rt.name === 'audience-member') {
        currentCategory.value = SearchCategory.Audience;
      } else if (rt.name === 'seller-transactions') {
        currentCategory.value = SearchCategory.Transactions;
      } else if (rt.name === 'seller-attributions') {
        currentCategory.value = SearchCategory.Attributions;
      } else if (rt.name === 'seller-tags') {
        currentCategory.value = SearchCategory.Tags;
      } else {
        currentCategory.value = '';
      }
      results.value = [];
      searchText.value = '';
    };
    checkRoute(currentRoute);

    watch(currentRoute, (rt) => {
      checkRoute(rt);
    });

    const userId = computed(() => !!currentRoute.query.userId);
    const userName = computed(() => store.getters['orders/currentBuyerName']);

    return {
      userId,
      userName,
      clearCategory,
      currentCategory,
      searchText,
      hasResults,
      results
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      productSource: 'accounts/selectedFeatureTypeId'
    })
  },
  methods: {
    ...mapMutations('global', [
      'setSearchText',
      'setLoading',
      'setBannerStatus'
    ]),
    ...mapMutations('orders', ['setBuyerName']),
    onSelection(result: SearchResult) {
      this.results = [];
      this.searchText = '';
      this.currentCategory = result.category;
      if (result.category === SearchCategory.Tags) {
        this.$router.push({
          name: 'seller-tags',
          params: { tagId: result.id }
        });
      }
      if (result.category === SearchCategory.Products) {
        this.$router.push({
          name: 'seller-product-detail',
          params: { productId: result.id }
        });
      }
      if (result.category === SearchCategory.Orders) {
        this.$router.push({
          name: 'seller-order',
          params: { orderId: result.id }
        });
      }
      if (result.category === SearchCategory.Transactions) {
        this.$router.push({
          name: 'seller-transactions',
          params: { transactionId: result.id }
        });
      }
      if (result.category === SearchCategory.Audience) {
        this.$router.push({
          name: 'audience-member',
          params: { memberId: result.id }
        });
      }
    },
    onAnyKey() {
      if (
        this.searchText.endsWith(':') &&
        Object.keys(SearchCategory).some(
          (x) =>
            x.toLocaleLowerCase() ===
            this.searchText.slice(0, -1).toLocaleLowerCase()
        )
      ) {
        const category = this.searchText.slice(0, -1).toLocaleLowerCase();
        this.searchText = '';
        this.currentCategory =
          category[0].toLocaleUpperCase() + category.slice(1);
      }
    },
    onDeleteKey() {
      if (
        this.currentCategory === SearchCategory.Orders &&
        this.userId &&
        !this.searchText &&
        this.clearCategory
      ) {
        // Orders with a User Id must be handled specially
        this.$router.push({
          name: 'seller-orders'
        });
        return;
      }
      // Clear out the category if the user has deleted all characters entered, and then hit delete again
      if (!this.searchText && this.currentCategory) {
        if (this.clearCategory) {
          this.currentCategory = '';
          this.clearCategory = false;
          this.results = [];
        } else {
          this.clearCategory = true;
        }
      }
    },
    onEscapeKey() {
      this.searchText = '';
      this.results = [];
      this.clearCategory = false;
    },
    async onEnterKey() {
      await this.search(this.searchText);
    },
    async search(searchText: string) {
      if (searchText.length >= MIN_CHAR) {
        if (this.currentCategory === '') {
          // Call Search API
          this.setLoading(true);
          const requestParams = {
            accountId: this.currentAccount.accountId,
            searchText: searchText,
            category: this.currentCategory,
            productSource: this.productSource
          };

          try {
            const responseData = await FirebaseRequest.createRequest<
              SearchResult[]
            >(FirebaseRequestTypes.GLOBAL_SEARCH, requestParams);
            this.setLoading(false);
            if (responseData.status === APIResponseStatus.OK) {
              if (responseData.data?.length) {
                this.results = responseData.data;
              } else {
                this.results = [
                  {
                    category: '',
                    title: 'No matching results found',
                    route: '',
                    metaData: '',
                    id: ''
                  }
                ];
              }
              this.$emit('search', true);
            }
          } catch (err) {
            console.log(err);
            this.setLoading(false);
            this.setBannerStatus({
              text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
              type: BannerType.error
            });
          }
        } else {
          // Filter category list
          this.setSearchText(searchText);
        }
      } else {
        this.results = [];
        if (this.currentCategory) {
          this.setSearchText(searchText);
        }
        this.$emit('search', false);
      }
      this.clearCategory = false;
    }
  }
});
