
import { defineComponent } from 'vue';
import PopupComponent from '@/common/PopupComponent/PopupComponent.vue';
import { mapMutations } from 'vuex';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from './BannerComponent/BannerMessages';
import Helpers from '@/helpers';

export interface DownloadTagItem {
  title: string;
  productId: string;
  accountId: string;
  hashtag: string;
  productSource: string;
  imageUrl: string;
  accountAffiliateId?: string;
}
export default defineComponent({
  name: 'ProductList',
  components: {
    PopupComponent
  },
  props: {
    selectedRow: {
      type: Object as () => DownloadTagItem
    },
    hideDownloadAll: {
      type: Boolean,
      default: false
    }
  },
  emits: ['visible-change'],
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    async downloadQR(singleFile?: boolean) {
      if (this.selectedRow) {
        this.setLoading(true);
        const responseData = await FirebaseRequest.requestFile(
          FirebaseRequestTypes.GENERATE_PRODUCT_LINKS,
          {
            productId: this.selectedRow.productId,
            accountId: this.selectedRow.accountId,
            singleFile,
            accountAffiliateId: this.selectedRow.accountAffiliateId
          }
        );
        const FILE = window.URL.createObjectURL(await responseData.blob());
        const downloadUrl = document.createElement('a');
        downloadUrl.href = FILE;
        downloadUrl.setAttribute(
          'download',
          `${this.selectedRow.title
            .substring(0, 50)
            .replace(/[^a-z0-9]/gi, '-')}.${singleFile ? 'png' : 'zip'}`
        );
        document.body.appendChild(downloadUrl);
        downloadUrl.click();
        this.$emit('visible-change', false);
        this.setLoading(false);
      }
    },
    async copyUrl() {
      await navigator.clipboard.writeText(
        `${window.location.origin}/buy/${this.selectedRow?.hashtag}`
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
      this.$emit('visible-change', false);
    },
    async copySMS() {
      if (this.selectedRow) {
        await navigator.clipboard.writeText(
          Helpers.formatHashTag(this.selectedRow?.hashtag)
        );
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
          type: BannerType.success
        });
        this.$emit('visible-change', false);
      }
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    }
  }
});
