
import { defineComponent } from 'vue';

// Note: Be careful when using v-if to hide tables as it will interfere with the
// coordination with the Boost Table component. Better to hide with display: none

export default defineComponent({
  name: 'BoostPagination',
  props: {
    items: {
      // eslint-disable-next-line @typescript-eslint/ban-types
      type: Array as () => Array<{}>,
      required: true
    },
    perPage: {
      type: Number,
      default: 25
    }
  },
  emits: ['items'],
  data() {
    return {
      currentPage: 1
    };
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    computedItems(): Array<{}> {
      if (this.items.length > 0) {
        const startCurrentPage = (this.currentPage - 1) * this.perPage;
        const endCurrentPage = this.currentPage * this.perPage;
        // eslint-disable-next-line @typescript-eslint/ban-types
        return this.items.filter((item: {}, index: number) => {
          if (index >= startCurrentPage && index < endCurrentPage) {
            return true;
          }
        });
      }
      return [];
    },
    totalPages(): number {
      if (this.items) {
        return Math.ceil(this.items.length / this.perPage);
      }
      return 0;
    },
    canGoNext(): boolean {
      return this.currentPage < this.totalPages;
    },
    canGoPrevious(): boolean {
      return this.currentPage > 1;
    }
  },
  watch: {
    items: {
      handler(newVal, oldVal) {
        if (newVal.length !== oldVal.length) {
          this.currentPage = 1;
        }
      },
      deep: true
    },
    computedItems(newVal) {
      this.$emit('items', newVal);
    }
  },
  methods: {
    goNext() {
      if (this.canGoNext) {
        this.currentPage++;
      }
    },
    goPrevious() {
      if (this.canGoPrevious) {
        this.currentPage--;
      }
    }
  }
});
