import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { OrderState, RootState } from './types';

const orderState: OrderState = {
  orderedIds: [],
  buyerName: ''
};

const orderMutations: MutationTree<OrderState> = {
  setOrderedIds: (state: OrderState, payload: string[]) => {
    state.orderedIds = payload;
  },
  setBuyerName: (state: OrderState, payload: string) => {
    state.buyerName = payload;
  }
};

const orderActions: ActionTree<OrderState, RootState> = {
  getNext(context, currentId: string): string | null {
    const index = context.state.orderedIds.findIndex((x) => x === currentId);
    return index >= context.state.orderedIds.length - 1
      ? null
      : context.state.orderedIds[index + 1];
  },
  getPrevious(context, currentId: string): string | null {
    const index = context.state.orderedIds.findIndex((x) => x === currentId);
    return index <= 0 ? null : context.state.orderedIds[index - 1];
  },
  getCurrentIndex(context, currentId: string): number {
    const index = context.state.orderedIds.findIndex((x) => x === currentId);
    return index;
  }
};

const orderGetters: GetterTree<OrderState, RootState> = {
  currentBuyerName(state): string | undefined {
    return state.buyerName;
  },
  getOrderListLength(state): number {
    return state.orderedIds.length;
  }
};

export const orders = {
  mutations: orderMutations,
  state: orderState,
  actions: orderActions,
  getters: orderGetters,
  namespaced: true
};
