
import { computed, defineComponent, ref } from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog as HeadlessDialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import SearchIcon from '@/marketplace/components/SearchIcon.vue';
import { useUrlSearchParams } from '@vueuse/core';

export default defineComponent({
  components: {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    HeadlessDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    SearchIcon
  },
  setup() {
    const params = useUrlSearchParams('history');

    function updateUrl(query: string) {
      if (query) {
        params.search = query;
      }
    }
    const people = [{ id: 1, name: 'Leslie Alexander', url: 'hello' }];

    const open = ref(false);
    const isSearching = ref(false);
    const query = ref('');
    const filteredPeople = computed(() =>
      query.value === ''
        ? []
        : people.filter((person) => {
            return person.name
              .toLowerCase()
              .includes(query.value.toLowerCase());
          })
    );

    return {
      open,
      query,
      isSearching,
      updateUrl,
      filteredPeople
    };
  },
  computed: {
    ...mapGetters({
      isSearchOpen: 'marketplace/isSearchOpen'
    })
  },
  methods: {
    ...mapMutations('marketplace', ['setIsSearchOpen']),
    ...mapActions('marketplace', ['performSearch']),
    async onSelect() {
      this.isSearching = true;
      this.updateUrl(this.query);
      await this.performSearch(this.query);
      this.isSearching = false;
      this.setIsSearchOpen(false);
    }
  }
});
