/* Role types.
 *
 * @export
 * @enum RoleType
 */
export enum RoleType {
  Owner = 'owner',
  Admin = 'admin',
  SellerAdmin = 'sellerAdmin',
  Staff = 'staff',
  Agency = 'agency'
}
