
import { defineComponent } from 'vue';
import { ColumnItem, RowItem } from './Table.interface';

// Note: Be careful when using v-if to hide tables as it will interfere with the
// coordination with the Boost Pagination component. Better to hide with display: none
export default defineComponent({
  name: 'BoostTable',
  props: {
    columns: {
      type: Array as () => ColumnItem[],
      required: true
    },
    rows: {
      type: Array as () => RowItem[],
      required: true
    },
    showTray: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 25
    },
    fixedColumns: {
      type: Boolean,
      default: true
    },
    hideArrow: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    clickId: {
      type: [String, Number]
    },
    hasContextMenu: {
      type: Boolean,
      default: false
    },
    hasTray: {
      type: Boolean,
      default: false
    },
    hideOverflow: {
      type: Boolean,
      default: true
    },
    imageFirst: {
      type: Boolean,
      default: true
    }
  },
  emits: ['action', 'select-all'],
  data() {
    return {
      innerColumns: [] as ColumnItem[],
      innerRows: [] as RowItem[]
    };
  },
  watch: {
    rows: {
      handler(newValue) {
        if ((newValue as []).length !== this.innerRows.length) {
          this.innerRows = newValue.map((item: RowItem) => {
            item.showDetails = false;
            return item;
          });
        }
      },
      deep: true
    }
  },
  mounted() {
    this.innerRows = this.rows.map((item: RowItem) => {
      return item;
    });
  },
  methods: {
    toggleTray(rowIndex: number, value: boolean) {
      this.innerRows[rowIndex].showDetails = value;
    },
    clickAction(
      index: number,
      columnIndex: number,
      id: string | number | undefined
    ) {
      const isLastColumn = columnIndex === this.columns.length - 1;
      if (this.hasContextMenu && isLastColumn) {
        return;
      }
      if (id) {
        this.$emit('action', this.innerRows[index][id]);
      }
    },
    handleItems(newValue: RowItem[]) {
      this.innerRows = newValue.map((item: RowItem) => {
        return item;
      });
    }
  }
});
