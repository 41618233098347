
import {
  FirebaseRequest,
  FirebaseRequestTypes,
  APIResponseStatus
} from '@/firebaseRequest';
import { Tag, TagDetail, TipTag } from '@/models/tag';
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import BoostTagCard from '@/common/BoostTagCard.vue';
import { TagType } from '@/../enums/tagType';
import Helpers from '@/helpers';
import DownloadTagsPopupComponent, {
  DownloadTagItem
} from '@/common/DownloadTagsPopupComponent.vue';

export default defineComponent({
  name: 'TagsComponent',
  components: { BoostTagCard, DownloadTagsPopupComponent },
  data() {
    return {
      tags: [] as Tag[],
      filteredTags: [] as Tag[],
      innerRows: [] as Tag[],
      showDownloadTags: false,
      selectedTag: {} as DownloadTagItem
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      currentFilter: 'product/currentFilter',
      productSource: 'accounts/selectedFeatureTypeId',
      getSearchText: 'global/getSearchText'
    })
  },
  watch: {
    async getSearchText(val) {
      await this.filterTagsBySearchTerm(val);
    }
  },
  async mounted() {
    this.tags = await this.getAllTags();
    this.filteredTags = this.tags;
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    async getAllTags() {
      this.setLoading(true);
      let tags: Tag[] = [];
      const responseData = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_SELLER_TAGS,
        {
          accountId: this.currentAccount?.accountId,
          productSource: this.productSource
        }
      );
      if (responseData.status === APIResponseStatus.OK) {
        tags = (responseData.data as Tag[]).sort(this.sortMostRecentTags);
      }
      this.setLoading(false);
      return tags;
    },
    async filterTagsBySearchTerm(searchText: string) {
      this.filteredTags = this.tags.filter((t) =>
        t.title.toLocaleLowerCase()?.includes(searchText)
      );
    },
    sortMostRecentTags(a: Tag, b: Tag): number {
      // Get just the number portion out of the tag to use for sorting, use 0 if no numbers in the tag
      const hashTagNoA = a.hashtag.replace(/\D/g, '') || '0';
      const hashTagNoB = b.hashtag.replace(/\D/g, '') || '0';
      return +hashTagNoB - +hashTagNoA;
    },
    getPrice(tag: Tag) {
      if (tag.type === TagType.TIP) {
        const prices = (tag as never as TipTag).amounts.map((x) => +x);

        const min = Math.min(...prices);
        const max = Math.max(...prices);

        if (min !== max) {
          return `${Helpers.currencyFormatter(
            min
          )} - ${Helpers.currencyFormatter(max)}`;
        } else {
          return `${Helpers.currencyFormatter(min)}`;
        }
      } else {
        if (tag?.skus && (tag as TagDetail)?.variants?.length) {
          const skus = Object.entries(tag.skus);
          if (skus.length > 0) {
            const prices = [] as number[];
            skus.forEach((sku) => {
              prices.push(+sku[1].price);
            });
            const min = Helpers.currencyFormatter(
              Math.min(...prices).toString()
            );
            const max = Helpers.currencyFormatter(
              Math.max(...prices).toString()
            );
            if (min !== max) {
              return `${min} - ${max}`;
            } else {
              return `${min}`;
            }
          }
        }

        const price = tag?.price || 0;
        return Helpers.currencyFormatter(price);
      }
    },
    openDownloadPopup(tag: Tag) {
      this.selectedTag = tag as DownloadTagItem;
      this.showDownloadTags = true;
    },
    handlePopupVisibleChange(show: boolean) {
      this.showDownloadTags = show;
    },
    handleItems(newValue: Tag[]) {
      this.innerRows = newValue.map((item: Tag) => {
        return item;
      });
    }
  }
});
