
import { defineComponent } from 'vue';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import settings from '@/settings';
import BaseCheckoutStep from './BaseCheckoutStep.vue';
import CheckoutDetailDivider from './CheckoutDetailDivider.vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';

export default defineComponent({
  name: 'AddPaymentOption',
  components: { BaseCheckoutStep, CheckoutDetailDivider },
  props: {
    title: {
      type: String,
      default: 'Add New Card'
    },
    customColor: {
      type: String,
      required: true
    }
  },
  emits: ['add-payment-completed', 'add-payment-canceled'],
  data() {
    return {
      stripe: null as null | Stripe,
      stripeCardElement: null as null | StripeCardElement,
      isDefault: true,
      addPaymentButtonState: ButtonLoadingState.initial
    };
  },
  async mounted() {
    await this.initializeStripe();
  },
  methods: {
    async initializeStripe() {
      // Instantiate stripe instance to be used for card and payment processing
      this.stripe = await loadStripe(settings.STRIPE_PUBLISHABLE_KEY);
    },
    collectCard(card: StripeCardElement) {
      this.stripeCardElement = card;
      this.addPaymentButtonState = ButtonLoadingState.initial;
    },
    async confirmAddCard() {
      try {
        this.addPaymentButtonState = ButtonLoadingState.loading;
        const success = await this.$store.dispatch(
          'accounts/addPaymentOption',
          {
            stripeCardElement: this.stripeCardElement,
            stripe: this.stripe,
            setAsDefault: this.isDefault
          }
        );
        if (success) {
          this.addPaymentButtonState = ButtonLoadingState.complete;
          this.$emit('add-payment-completed', this.isDefault);
        }
      } catch (e) {
        console.log('adding payment option failed: ', e);
        this.addPaymentButtonState = ButtonLoadingState.initial;
      }
    }
  }
});
