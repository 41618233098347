
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { store } from '@/store';
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';
import { ImageFilePreviewedEvent } from '@/common/controls/BoostImageUpload.vue';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';

export default defineComponent({
  emits: ['updateBuyerSettings', 'hasUnsavedChanges'],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      imageUrl: ''
    };
  },
  computed: {
    currentBuyer() {
      return this.$store.getters['auth/getCurrentBuyer'] as Buyer;
    }
  },
  watch: {
    firstName() {
      this.compareOriginalBuyer();
    },
    lastName() {
      this.compareOriginalBuyer();
    },
    email() {
      this.compareOriginalBuyer();
    },
    imageUrl() {
      this.compareOriginalBuyer();
    }
  },
  mounted() {
    // set up current buyer info
    this.firstName = this.currentBuyer.firstName;
    this.lastName = this.currentBuyer.lastName;
    this.email = this.currentBuyer.email;
    this.imageUrl = this.currentBuyer.imageUrl;
  },
  methods: {
    ...mapMutations({
      setBuyerVerificationAttempted: 'auth/setBuyerVerificationAttempted'
    }),
    compareOriginalBuyer() {
      const hasChanges =
        this.firstName !== this.currentBuyer.firstName ||
        this.lastName !== this.currentBuyer.lastName ||
        this.email !== this.currentBuyer.email ||
        this.imageUrl !== this.currentBuyer.imageUrl;
      this.$emit('hasUnsavedChanges', hasChanges);
    },
    logoUploaded(event: ImageFilePreviewedEvent) {
      this.imageUrl = event.data;
      store.commit('global/setBannerStatus', {
        text: BannerMessageStrings.BUYER_SETTINGS_IMAGE_UPLOADED,
        type: BannerType.success
      });
    },
    changeMobileNumber() {
      this.$router.push({ name: 'buyer-number-change' });
    },
    commitChanges() {
      this.$emit('updateBuyerSettings', {
        ...this.currentBuyer,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        imageUrl: this.imageUrl
      });
    },
    resendBuyerVerification(): void {
      // "hasAttemptedVerification" will be set to true when the
      // RESEND_BUYER_VERIFICATION_EMAIL request is sent to the server.
      // But to not have to refresh the currentBuyer with a separate request
      // to GET_BUYER, we set this to true locally. The next time we refresh
      // the currentBuyer, "hasAttemptedVerification" will be true.
      this.setBuyerVerificationAttempted(true);

      FirebaseRequest.createRequest(
        FirebaseRequestTypes.RESEND_BUYER_VERIFICATION_EMAIL,
        {}
      );

      // Immediately display the success message.
      store.commit('global/setBannerStatus', {
        text: BannerMessageStrings.BUYER_RESEND_VERIFICATION_EMAIL_SUCCESS,
        type: BannerType.info
      });
    }
  }
});
