
import { defineComponent } from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  Attribution,
  AttributionType,
  AttributionStatus
} from '@/../models/firebase/product';
import Helpers from '@/helpers';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import ConfirmComponent from '@/common/PopupComponent/ConfirmComponent.vue';
import {
  PopupMessageTitles,
  PopupMessageStrings,
  PopupMessageButtonCaptions
} from '@/common/PopupComponent/PopupMessages';
import ProductEditAttributions from '@/seller/products/ProductEditAttributions.vue';
import { AccountObject } from '@/models/accountObject';

enum ProductEditStatus {
  EditProduct,
  AddEditAttribution
}

export default defineComponent({
  name: 'ProductEditShopify',
  components: {
    ConfirmComponent,
    ProductEditAttributions
  },
  async beforeRouteLeave() {
    if (this.originalValue === JSON.stringify(this.product)) {
      return true;
    } else {
      const confirmation = this.$refs.confirmation as typeof ConfirmComponent;
      const confirmed = await confirmation.show(
        PopupMessageTitles.UNSAVED_CHANGES,
        PopupMessageStrings.UNSAVED_CHANGES,
        PopupMessageButtonCaptions.DISCARD_CHANGES
      );

      return confirmed;
    }
  },
  data() {
    return {
      ProductEditStatus,
      productEditState: ProductEditStatus.EditProduct,
      originalValue: '',
      AttributionStatus,
      hasVariations: false,
      noVariantionsQuantity: 0,
      saveButtonState: ButtonLoadingState.initial,
      columns: [
        {
          id: 'icon',
          label: ''
        },
        {
          id: 'name',
          label: 'Name/Location'
        },
        {
          id: 'attributionType',
          label: 'Type'
        },
        {
          id: 'sold',
          label: 'Sold'
        },
        {
          id: 'revenue',
          label: 'Revenue'
        },
        {
          id: 'conversion',
          label: 'Conversion'
        },
        {
          id: 'badges',
          label: 'Status'
        },
        {
          id: 'menu',
          label: ''
        }
      ] as ColumnItem[],
      menuItems: [
        {
          label: 'Download QR',
          onClick: (row) => {
            this.downloadAttributionQR(row);
          }
        },
        {
          label: 'Copy Link',
          onClick: (row) => {
            this.getAttributionLink(row);
          }
        },
        {
          label: 'Copy SMS',
          onClick: (row) => {
            this.copyAttributionSMS(row);
          }
        }
      ] as ContextMenuItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      product: 'product/currentProduct'
    }),
    notConnectedToStripe(): boolean {
      return !(this.currentAccount as AccountObject).isStripeConnected;
    },
    readyToSell: {
      set(val: boolean) {
        this.product.disabled = !val;
      },
      get(): boolean {
        return !this.product.disabled;
      }
    },
    productTypeImageUrl(): string {
      if (this.product) {
        if (this.product.shopifyShareEarn && this.product.shopifySaveForLater) {
          return require('@/assets/multi-app-icon.svg');
        }
        if (this.product.shopifySaveForLater) {
          return require('@/assets/later_icon.svg');
        }
        if (this.product.shopifyShareEarn) {
          return require('@/assets/share-icon.svg');
        }
      }

      return '';
    }
  },
  created() {
    this.originalValue = JSON.stringify(this.product);
  },
  async mounted() {
    if (this.$route.params.productId && !this.product) {
      const payload = {
        productId: this.$route.params.productId,
        accountId: this.currentAccount.accountId
      };

      this.setLoading({ isLoading: true, message: 'Loading product details' });
      await this.setupCurrentProduct(payload);
      this.setLoading(false);
    }
  },
  methods: {
    ...mapActions('product', ['saveCurrentProductToDb', 'setupCurrentProduct']),
    ...mapMutations('global', ['setBannerStatus', 'setLoading']),
    ...mapMutations('product', ['setCurrentProductAttributions']),
    async commitChanges() {
      this.saveButtonState = ButtonLoadingState.loading;
      await this.saveCurrentProductToDb();

      this.saveButtonState = ButtonLoadingState.complete;
      this.originalValue = JSON.stringify(this.product);
      this.$router.push({
        name: 'seller-product-detail',
        params: { productId: this.product.productId }
      });
    },
    goBack() {
      //TODO: Need to make sure that the changes are abandonded and the current product is reloaded
      const productId = this.product?.productId;
      if (productId) {
        this.$router.push({
          name: 'seller-product-detail',
          params: { productId }
        });
      } else {
        this.$router.push({ name: 'seller-products' });
      }
    },
    editAttribution() {
      this.productEditState = ProductEditStatus.AddEditAttribution;
    },
    getAttributionTag(attribution: Attribution): string {
      return (
        Helpers.formatHashTag(this.product?.hashtag || '') +
        attribution.identifier
      );
    },
    getAttributionType(attribution: Attribution): string {
      return AttributionType[attribution.type];
    },
    async downloadAttributionQR(attribution: Attribution) {
      const responseData = await FirebaseRequest.requestFile(
        FirebaseRequestTypes.GENERATE_PRODUCT_LINKS,
        {
          productId: this.product?.productId,
          accountId: this.product?.accountId,
          attributionIdentifier: attribution.identifier
        }
      );
      const FILE = window.URL.createObjectURL(await responseData.blob());
      const downloadUrl = document.createElement('a');
      downloadUrl.href = FILE;
      downloadUrl.setAttribute(
        'download',
        `${this.product?.hashtag}${attribution.identifier}.png`
      );
      document.body.appendChild(downloadUrl);
      downloadUrl.click();
    },
    async getAttributionLink(attribution: Attribution) {
      await navigator.clipboard.writeText(
        `${window.location.origin}/buy/${Helpers.formatHashTag(
          this.product?.hashtag
        )}/${attribution.identifier}`
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    async copyAttributionSMS(attribution: Attribution) {
      await navigator.clipboard.writeText(
        `${this.product?.hashtag}${attribution.identifier}`
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    handleSaveAttributions(attributions: Attribution[]) {
      this.setCurrentProductAttributions(attributions);
      this.productEditState = ProductEditStatus.EditProduct;
    }
  }
});
