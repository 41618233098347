import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f68883e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap justify--center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "t-6 no-pad"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.name === 'deny-transfer')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Denied!!!"))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "Accepted!!!"))
  ]))
}