
import { defineComponent } from 'vue';
import { store } from '@/store';

const BoostLogo = defineComponent({
  name: 'BoostLogo',
  computed: {
    logoClass(): string {
      const customValue = store.getters['global/getCustomColor'] as string;
      return ('custom__' + customValue || 'default') + '__logo';
    }
  }
});

export default BoostLogo;
