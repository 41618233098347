
import { store } from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CommonStepper',
  props: {
    steps: {
      type: Number,
      default: 3
    },
    currentStep: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getCustomColorClass(step: number): string {
      const customValue =
        (store.getters['global/getCustomColor'] as string) || 'default';
      return step < this.currentStep + 1 ? 'custom__' + customValue : '';
    }
  }
});
