
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoostBrandCard',
  props: {
    avatar: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    tagCount: {
      type: Number,
      default: 0
    },
    price: {
      type: String,
      default: ''
    },
    commission: {
      type: Number,
      default: 0
    }
  },
  emits: ['image-clicked'],
  methods: {
    onImageClick() {
      this.$emit('image-clicked');
    }
  }
});
