
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoostCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    subMessage: {
      type: String,
      default: ''
    },
    summary: {
      type: String,
      default: ''
    },
    hasExpander: {
      type: Boolean,
      default: false
    },
    details: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      isExpanded: false
    };
  }
});
