
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { store } from '@/store';
import { defineComponent } from 'vue';

enum Messages {
  Pending = 'Validating your email...',
  Success = "You've verified your email address thank you. Click down below to log in your account.",
  Failure = 'Unable to verify your email address. Please try again.'
}

export default defineComponent({
  name: 'EmailVerified',
  data() {
    return {
      message: Messages.Pending,
      loadingState: ButtonLoadingState.loading
    };
  },
  async mounted() {
    const userId = this.$route.query.userId as string;
    const code = this.$route.query.emailVerificationCode as string;

    await this.handleVerifyEmail(code, userId);
  },
  methods: {
    async handleVerifyEmail(code: string, userId: string) {
      let success = false;
      if (code && userId) {
        const responseData = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.VERIFY_BUYER_EMAIL,
          { emailVerificationCode: code, userId }
        );
        success = responseData.status === APIResponseStatus.OK;
      }
      this.loadingState = ButtonLoadingState.complete;
      this.message = success ? Messages.Success : Messages.Failure;
    },
    async returnToLogin() {
      const buyerResponse = await FirebaseRequest.createRequest<Buyer>(
        FirebaseRequestTypes.GET_BUYER_INFO,
        {}
      );
      if (buyerResponse.status === APIResponseStatus.OK && buyerResponse.data) {
        store.commit('auth/setCurrentBuyer', buyerResponse.data);
      }
      this.$router.push({
        path: '/login'
      });
    }
  }
});
