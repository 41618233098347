import { ActionTree, Commit, GetterTree, MutationTree } from 'vuex';
import { AccountObject } from '../../models/accountObject';
import { AccountsState, RootState, RoleAccount, Permissions } from './types';
import { store } from './index';
import Helpers from '@/helpers';
import { RoleType } from '@/enums/roleTypes';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import {
  Buyer,
  ShippingAddress
} from '@/buyer/settings/BuyerSettingsInterfaces';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { FeatureType } from '@/../enums/featureTypes';
import { StripeCardElement, Stripe } from '@stripe/stripe-js';

const accountsState: AccountsState = {
  accounts: [],
  currentAccount: null,
  selectedFeatureTypeId: ''
};

// Helper functions to set global banner messages
function setErrorBannerMessage(commit: Commit, text: string) {
  commit(
    'global/setBannerStatus',
    {
      text,
      type: BannerType.error
    },
    { root: true }
  );
}

const accountsActions: ActionTree<AccountsState, RootState> = {
  loadAccounts: async ({ commit }): Promise<boolean> => {
    try {
      const roleAccounts = store.getters['auth/roleAccounts'];
      const userAccounts: AccountObject[] = [];

      const isSuperAdmin = store.getters['auth/isSuperAdmin'];
      if (isSuperAdmin) {
        const result = await FirebaseRequest.createRequest<AccountObject[]>(
          FirebaseRequestTypes.GET_ADMIN_ACCOUNTS,
          {}
        );
        const accountsList = result.data || [];
        if (accountsList) {
          for (const a of accountsList) {
            userAccounts.push(a);
          }
        }
      } else {
        for (const ra of roleAccounts) {
          const result = await FirebaseRequest.createRequest<AccountObject>(
            FirebaseRequestTypes.GET_ACCOUNT_DATA,
            {
              accountId: ra.accountId
            }
          );
          if (result.data) {
            userAccounts.push(result.data);
          }
        }
      }

      commit('setAccounts', userAccounts);

      const currentAccount = store.getters['accounts/currentAccount'];
      // default the current account to the first userAccount
      if (currentAccount) {
        commit(
          'setCurrentAccount',
          userAccounts.find((x) => x.accountId === currentAccount.accountId)
        );
      } else {
        commit('setCurrentAccount', userAccounts[0]);
      }

      return true;
    } catch (ex) {
      console.log('Login exception: ', ex);
      return false;
    }
  },
  addShippingAddress: async (
    { commit },
    shippingAddress: ShippingAddress
  ): Promise<boolean> => {
    commit('global/setBannerStatus', null, { root: true });

    const response = await FirebaseRequest.createRequest(
      FirebaseRequestTypes.UPDATE_BUYER_SHIPPING_ADDRESS,
      { shippingAddress }
    );
    if (response?.status !== APIResponseStatus.OK) {
      commit(
        'global/setBannerStatus',
        {
          text: BannerMessageStrings.BUYER_SETTINGS_INVALID_ADDRESS,
          type: BannerType.error
        },
        { root: true }
      );
      return false;
    }

    return true;
  },
  addPaymentOption: async (
    { commit, dispatch, rootGetters },
    payload: {
      stripeCardElement: StripeCardElement;
      stripe: Stripe;
      setAsDefault: boolean;
    }
  ): Promise<boolean> => {
    try {
      const { stripeCardElement, stripe, setAsDefault } = payload;
      const results = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.INITIATE_ADD_NEW_PAYMENT,
        {}
      );

      if (
        !stripeCardElement ||
        !stripe ||
        results.status !== APIResponseStatus.OK
      ) {
        setErrorBannerMessage(commit, BannerMessageStrings.STRIPE_NOT_LOADED);
        return false;
      }

      let originalDefaultPaymentId;
      const currentBuyer = rootGetters['auth/getCurrentBuyer'] as Buyer;
      if (!setAsDefault) {
        originalDefaultPaymentId = currentBuyer.paymentOptions.length
          ? currentBuyer.paymentOptions.find((po) => po.isDefault)?.id
          : '';
      }

      const clientSecret = results.data as string;
      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: stripeCardElement as StripeCardElement
          }
        }
      );

      if (error) {
        console.log('Unhandled error', error);
        setErrorBannerMessage(
          commit,
          BannerMessageStrings.GENERIC_ERROR_MESSAGE
        );
        return false;
      }

      if (!setupIntent) {
        setErrorBannerMessage(
          commit,
          BannerMessageStrings.GENERIC_ERROR_MESSAGE
        );
        return false;
      }

      // We always need to set a defaultCard. Either the newly added card or the previous default.
      // This is because it seems like adding a new card sometimes sets it as the default, even if
      // there is already a default card. There doesn't seem to be a way to control that behavior
      // when adding a card. So we need to make sure we set a default every time.
      await dispatch('setPaymentOptionAsDefault', {
        paymentMethodId: setAsDefault
          ? setupIntent.payment_method
          : originalDefaultPaymentId
      });
    } catch (error) {
      console.log('Unhandled error', error);
      setErrorBannerMessage(commit, BannerMessageStrings.GENERIC_ERROR_MESSAGE);
      return false;
    }

    return true;
  },
  setPaymentOptionAsDefault: async (
    { commit },
    payload: { paymentMethodId: string }
  ): Promise<boolean> => {
    const response = await FirebaseRequest.createRequest(
      FirebaseRequestTypes.BUYER_UPDATE_DEFAULT_CARD,
      { cardId: payload.paymentMethodId }
    );

    if (response.status === APIResponseStatus.OK) {
      commit(
        'global/setLoading',
        {
          isLoading: true,
          message: 'Retrieving payment options..'
        },
        { root: true }
      );
      commit('global/setLoading', false, { root: true });
      return true;
    } else {
      commit(
        'global/setBannerStatus',
        {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        },
        { root: true }
      );
    }

    return false;
  }
};

const accountsGetters: GetterTree<AccountsState, RootState> = {
  currentAccount(state): AccountObject | null {
    return state.currentAccount;
  },
  currentAccountRole(state): string {
    const roleAccounts = store.getters['auth/roleAccounts'] as RoleAccount[];
    return roleAccounts.find(
      (x) => x.accountId === state.currentAccount?.accountId
    )?.role as string;
  },
  currentAccountPermissions(): Permissions[] {
    const role = store.getters['accounts/currentAccountRole'] as RoleType;
    return Helpers.getPermissionsForRole(role);
  },
  selectedFeatureTypeId(state): FeatureType {
    if (!state.selectedFeatureTypeId) {
      state.selectedFeatureTypeId = localStorage.getItem(
        'selected-feature-id'
      ) as FeatureType;
    }
    if (
      !state.currentAccount?.currentPlan ||
      state.currentAccount?.currentPlan === 'Basic'
    ) {
      state.selectedFeatureTypeId = FeatureType.Kiosk;
    }
    return (state.selectedFeatureTypeId as FeatureType) || FeatureType.Kiosk;
  }
};

const accountsMutations: MutationTree<AccountsState> = {
  setAccounts: (state: AccountsState, payload: AccountObject[]) => {
    state.accounts = payload;
  },
  setCurrentAccount: (state: AccountsState, payload: AccountObject) => {
    state.currentAccount = payload;
  },
  setSelectedFeatureType: (state: AccountsState, payload: FeatureType) => {
    // Using local storage to save the user's selection for future uses
    state.selectedFeatureTypeId = payload;
    localStorage.setItem('selected-feature-id', payload);
  }
};

export const accounts = {
  actions: accountsActions,
  getters: accountsGetters,
  mutations: accountsMutations,
  state: accountsState,
  namespaced: true
};
