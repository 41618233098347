
import { defineComponent } from 'vue';
import PopupComponent from '@/common/PopupComponent/PopupComponent.vue';
import {
  PopupMessageStrings,
  PopupMessageTitles
} from '@/common/PopupComponent/PopupMessages';
import {
  FirebaseRequest,
  FirebaseRequestTypes,
  APIResponseStatus
} from '@/firebaseRequest';

import { mapGetters, mapMutations } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { AccountObject } from '@/../models/accountObject';

interface AppUpdates {
  applyToAllAffiliates?: boolean;
  globalCommission?: number;
  enableShareEarn?: boolean;
  enableSaveForLater?: boolean;
  enableMarketplace?: boolean;
  widgetAutomaticPlacement?: boolean;
  guidelines?: string[];
}

export default defineComponent({
  name: 'AppsList',
  components: {
    PopupComponent
  },
  data() {
    return {
      PopupMessageStrings,
      PopupMessageTitles,
      editLaterSettings: false,
      editShareSettings: false,
      editGuidelines: false,
      openMarketplacePopup: false,
      applyToAllAffiliates: false,
      addAllProductsToMarketplace: true,
      shareEnabled: true,
      laterEnabled: true,
      marketplaceEnabled: true,
      automaticWidget: true,
      globalCommission: 0,
      guidelines: {
        one: '',
        two: '',
        three: '',
        four: '',
        five: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    }),
    hasGuidelines() {
      return this.currentAccount.guidelines?.length > 0;
    },
    haveGuidelinesChanged() {
      return (
        (this.currentAccount.guidelines ?? []).join('') !==
        Object.values(this.guidelines).join('')
      );
    }
  },
  mounted() {
    this.cancel();
  },
  methods: {
    ...mapMutations('global', ['setBannerStatus', 'setLoading']),
    cancel() {
      const account = this.currentAccount as AccountObject;
      this.shareEnabled = account.enableShareEarn || false;
      this.laterEnabled = account.enableSaveForLater || false;
      this.marketplaceEnabled = account.enableMarketplace || false;
      this.automaticWidget = account.widgetAutomaticPlacement || false;
      this.globalCommission = account.globalCommission || 0;
      this.editLaterSettings = false;
      this.editShareSettings = false;
      this.editGuidelines = false;
      this.openMarketplacePopup = false;

      // Currently, we only support a max of 5 guidelines.
      if (account.guidelines?.length) {
        // Accessing an index without an element will return undefined, which is not a problem.
        this.guidelines.one = account.guidelines[0];
        this.guidelines.two = account.guidelines[1];
        this.guidelines.three = account.guidelines[2];
        this.guidelines.four = account.guidelines[3];
        this.guidelines.five = account.guidelines[4];
      }
    },
    async save(updates: AppUpdates): Promise<boolean> {
      const responseData = await FirebaseRequest.createRequest<{
        connectionResults: {
          isSuccessful: boolean;
          message: string;
        };
      }>(FirebaseRequestTypes.UPDATE_ECOMMERCE_APPS, {
        accountId: this.currentAccount?.accountId,
        updates
      });
      if (responseData.status === APIResponseStatus.OK) {
        this.setBannerStatus({
          text: BannerMessageStrings.CHANGES_UPDATED,
          type: BannerType.success
        });
        return true;
      } else {
        this.setBannerStatus({
          text:
            responseData?.data?.connectionResults?.message ||
            BannerMessageStrings.SHOPIFY_ERROR,
          type: BannerType.error
        });
      }
      return false;
    },
    async saveAutomaticPlacement() {
      const updates = {
        widgetAutomaticPlacement: this.automaticWidget
      };
      if (await this.save(updates)) {
        this.currentAccount.widgetAutomaticPlacement = this.automaticWidget;
      }
    },
    async saveShare() {
      this.setLoading(true);
      const updates = {
        globalCommission: this.globalCommission,
        enableShareEarn: this.shareEnabled,
        applyToAllAffiliates: this.applyToAllAffiliates
      };
      if (await this.save(updates)) {
        this.currentAccount.globalCommission = this.globalCommission;
        this.currentAccount.enableShareEarn = this.shareEnabled;
      }
      this.applyToAllAffiliates = false;
      this.setLoading(false);
      this.editShareSettings = false;
    },
    async saveLater() {
      this.setLoading(true);
      const updates = {
        enableSaveForLater: this.laterEnabled
      };
      if (await this.save(updates)) {
        this.currentAccount.enableSaveForLater = this.laterEnabled;
      }
      this.setLoading(false);
      this.editLaterSettings = false;
    },
    async saveGuidelines() {
      this.setLoading(true);
      const guidelineList = Object.values(this.guidelines).filter((x) =>
        Boolean(x)
      );
      const updates = {
        guidelines: guidelineList
      };
      if (await this.save(updates)) {
        this.currentAccount.guidelines = guidelineList;
      }
      this.setLoading(false);
      this.editGuidelines = false;
    },
    async saveMarketplace() {
      this.setLoading(true);
      const updates = {
        enableMarketplace: this.marketplaceEnabled,
        addAllProductsToMarketplace: this.addAllProductsToMarketplace
      };
      if (await this.save(updates)) {
        this.currentAccount.enableMarketplace = this.marketplaceEnabled;
      }
      this.setLoading(false);
      this.openMarketplacePopup = false;
    },
    async copyCode() {
      await navigator.clipboard.writeText(
        '<div id="boostSaveForLaterWrapper"></div>'
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    onOpenHowTo() {
      window.open(
        'https://support.tagscommerce.com/hc/en-us/articles/8343081242903-Activating-Save-for-Later',
        '_blank'
      );
    }
  }
});
