
import { defineComponent } from 'vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { mapGetters, mapMutations } from 'vuex';
import { store } from '@/store';
import { FeatureType } from '@/../enums/featureTypes';
import { AccountObject } from '@/models/accountObject';

export default defineComponent({
  name: 'LoginSeller',
  data() {
    return {
      email: '',
      password: '',
      loginButtonState: ButtonLoadingState.initial
    };
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin', 'isImpersonating']),
    showAccounts(): boolean {
      if (this.isSuperAdmin) {
        return !this.isImpersonating;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapMutations({
      setSelectedFeatureType: 'accounts/setSelectedFeatureType'
    }),
    async loginSeller() {
      this.loginButtonState = ButtonLoadingState.loading;
      this.$store
        .dispatch('auth/signIn', {
          email: this.email,
          password: this.password
        })
        .then(async (success: boolean) => {
          if (success) {
            await this.$store.dispatch('accounts/loadAccounts');

            if (this.showAccounts) {
              await this.$router.push({ path: '/accounts' });
            } else {
              const currentBuyer = store.getters[
                'accounts/currentAccount'
              ] as AccountObject;
              if (currentBuyer?.isShopifyConnected) {
                this.setSelectedFeatureType(FeatureType.Shopify);
              }
              await this.$router.push({ path: '/store' });
            }
          } else {
            store.commit('global/setBannerStatus', {
              text: BannerMessageStrings.SELLER_EMAIL_LOGIN_ERROR,
              type: BannerType.error
            });
            this.loginButtonState = ButtonLoadingState.initial;
          }
        });
    }
  }
});
