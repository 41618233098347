
import { defineComponent } from 'vue';

const SearchBar = defineComponent({
  name: 'SearchBar',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: undefined
    },
    filter: {
      type: String,
      required: false,
      default: undefined
    }
  },
  emits: ['searchAction'],
  data() {
    return {
      filterValue: '',
      searchValue: ''
    };
  },
  methods: {
    searchAction(filterType: string | undefined) {
      const searchActionEventArgs: SearchActionEvent = {
        filterType: filterType || '',
        searchValue: this.searchValue
      };
      this.$emit('searchAction', searchActionEventArgs);
    }
  }
});

export interface SearchActionEvent {
  filterType: string;
  searchValue?: string;
  sortOption?: number;
}

export default SearchBar;
