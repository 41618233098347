import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

// seller
import sellerRoutes from '@/seller/sellerRoutes';

// buyer
import buyerRoutes from '@/buyer/buyerRoutes';

// shared
import Login from './login/Login.vue';
import ForgotPassword from './login/ForgotPassword.vue';
import PageNotFoundComponent from '@/common/PageNotFoundComponent.vue';
import EmailVerified from './login/EmailVerified.vue';
import UserActions from './common/UserActions.vue';
import MarketplaceHome from './marketplace/pages/MarketplaceHome.vue';

// Lazy Loaded pages
// Async component without options
// const asyncPage = defineAsyncComponent(() => import('./NextPage.vue'))

const PrivacyPolicy = () => import('@/static/PrivacyPolicy.vue');
const BuyerTermsConditions = () => import('@/static/BuyerTermsConditions.vue');
const SellerTermsConditions = () =>
  import('@/static/SellerTermsConditions.vue');

import Policy from './user_store/Policy.vue';
import AccountsComponent from './admin/AccountsComponent.vue';
import AdminStats from './admin/AdminStats.vue';
import Sample from './sample.vue';

import { store } from './store';
import {
  BannerMessageStrings,
  BannerType
} from './common/BannerComponent/BannerMessages';
import { Buyer } from './buyer/settings/BuyerSettingsInterfaces';
import { AccountObject } from '@/models/accountObject';

/*
Url structure:

Buyer signup: https://boo.st/user/signup
Buyer account: https://boo.st/user
Other Buyer account pages: https://boo.st/user/{page}

Seller signup: https://boo.st/store/signup
Seller account: https://boo.st/store/
Other Seller account pages: https://boo.st/store/{page}

Seller public store: https://boo.st/{storeTag}
Seller policy: https://boo.st/{storeTag}/policy
 */

const baseRoutes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  },
  {
    path: '',
    name: 'base-index',
    redirect: () => {
      const isSellerLoggedIn = store.getters['auth/isSellerLoggedIn'];
      const isBuyerLoggedIn = store.getters['auth/isBuyerLoggedIn'];

      // Resolve home page location based on user auth type
      if (isSellerLoggedIn) {
        return '/store';
      } else if (isBuyerLoggedIn) {
        // If the buyer is a Network affiliate, redirect to the dashboard.
        const buyer = store.getters['auth/getCurrentBuyer'] as Buyer;
        if (buyer?.affiliateUsername) {
          return '/user/dashboard';
        }

        return '/user';
      } else {
        return '/login';
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'SingleLayout',
      showFooter: true,
      showHelp: true,
      showBackToTop: false
    }
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: MarketplaceHome,
    meta: {
      layout: 'MarketplaceLayout'
    }
  },
  {
    path: '/userActions',
    name: 'User Actions',
    component: UserActions
  },
  {
    path: '/privacy-policy',
    name: 'Privacy-policy',
    component: PrivacyPolicy,
    meta: {
      layout: 'SingleLayout'
    }
  },
  {
    path: '/buyer-terms',
    name: 'Buyer-Terms-and-conditions',
    component: BuyerTermsConditions,
    meta: {
      layout: 'SingleLayout'
    }
  },
  {
    path: '/seller-terms',
    name: 'Seller-Terms-and-conditions',
    component: SellerTermsConditions,
    meta: {
      layout: 'SingleLayout'
    }
  },
  {
    path: '/forgotPassword',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: {
      layout: 'SingleLayout'
    }
  },
  {
    path: '/emailVerified',
    name: 'Verify Email',
    component: EmailVerified
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: PageNotFoundComponent,
    meta: {
      layout: 'SingleLayout'
    }
  },
  // super admin
  {
    path: '/accounts',
    name: 'Accounts',
    component: AccountsComponent,
    meta: { requiresSuperAdmin: true }
  },
  {
    path: '/adminstats',
    name: 'AdminStats',
    component: AdminStats,
    meta: { requiresSuperAdmin: true }
  },
  // public seller store routes
  {
    path: '/public/:storeSlug/policy',
    name: 'StorePolicy',
    component: Policy,
    meta: {
      layout: 'SingleLayout'
    }
  },
  {
    path: '/samples',
    name: 'samples',
    component: Sample,
    meta: {
      // requiresSuperAdmin: true,
      layout: 'OpenLayout',
      showSubHeader: false
    }
  }
] as RouteRecordRaw[];

const router = createRouter({
  history: createWebHistory(),
  routes: [...sellerRoutes, ...buyerRoutes, ...baseRoutes],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
});
// const unsavedChangesWarningMessage = 'Do you wish to navigate away from this page? Changes you made may not be saved.  Click OK to leave this page, or Cancel to continue editing.';

router.beforeEach(async (to, from, next) => {
  // Authorization Types
  const isSellerLoggedIn = store.getters['auth/isSellerLoggedIn'];
  const isBuyerLoggedIn = store.getters['auth/isBuyerLoggedIn'];
  const isSuperAdmin = store.getters['auth/isSuperAdmin'];

  // Process Auth Requirements
  const requiresSellerAuth = to.matched.some(
    (record) => record.meta.requiresSellerAuth
  );
  const requiresBuyerAuth = to.matched.some(
    (record) => record.meta.requiresBuyerAuth
  );
  const requiresSuperAdmin = to.matched.some(
    (record) => record.meta.requiresSuperAdmin
  );

  // Clears any banner messages that might have existed
  store.commit('global/setBannerStatus', null);
  store.commit('global/setContextualButton', false);

  // Setup login forwarding to prevent user types
  // from attempting to get into eachother's routes
  if (to.name === 'Login') {
    if (isSellerLoggedIn || isBuyerLoggedIn) {
      next('/');
    } else {
      next();
    }
  }

  // Check if the route requires authorization to proceed
  if (requiresSellerAuth || requiresBuyerAuth || requiresSuperAdmin) {
    // Check Seller Auth
    if (requiresSellerAuth) {
      if (!isSellerLoggedIn) {
        next({ name: 'Login' });
      } else {
        const currentAccount = store.getters[
          'accounts/currentAccount'
        ] as AccountObject;
        if (!currentAccount.isStripeConnected) {
          // if not connected to stripe show the banner message for stripe
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.SELLER_NEED_CONNECT_STRIPE,
            type: BannerType.stripeWarning
          });
        }
        if (currentAccount.planPaymentFailed) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.SELLER_PLAN_PAYMENT_FAILED,
            type: BannerType.paymentFailed
          });
        }
        next();
      }
    }

    // Check Buyer Auth
    if (requiresBuyerAuth) {
      if (!isBuyerLoggedIn) {
        // eslint-disable-next-line
        // @ts-ignore
        next({ name: 'Login', query: { type: 1 } });
      }
      const currentBuyer = store.getters['auth/getCurrentBuyer'] as Buyer;
      if (
        currentBuyer &&
        !(currentBuyer.isVerified || currentBuyer.hasAttemptedVerification)
      ) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.BUYER_ACCOUNT_UNVERIFIED,
          type: BannerType.buyerVerification
        });
      } else {
        if (
          !!currentBuyer.affiliateUsername &&
          !currentBuyer.isAffiliatePayoutConnected
        ) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_BANNER_CONNECT_STRIPE,
            type: BannerType.stripeWarning
          });
        }
      }

      next();
    }

    // Check Super Admin Auth
    if (requiresSuperAdmin) {
      if (!isSuperAdmin) {
        next({ name: 'Login' });
      } else {
        next();
      }
    }
  } else {
    // Otherwise, proceed as intended
    next();
  }
});

export default router;
