
import { defineComponent } from 'vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '../../firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import Helpers from '@/helpers';
import { SellerOrder } from '@/models/sellerOrder';
import { Charges } from '@/models/charges';
import { mapGetters, mapMutations } from 'vuex';
import OrdersListView from './OrdersListView.vue';
import { TagType } from '@/../enums/tagType';
import { store } from '@/store';

export interface OrderSearch {
  searchValue: string;
  filterValue: string;
  sortBy: string;
}

export default defineComponent({
  name: 'OrdersList',
  components: {
    OrdersListView
  },
  props: {
    limit: {
      type: Number,
      required: false
    },
    filteredCount: {
      type: Number
    },
    searchInput: {
      type: Object as () => OrderSearch,
      required: true
    },
    type: {
      type: String,
      required: false
    }
  },
  emits: ['update:filteredCount'],
  data() {
    return {
      tableTransactionData: [] as SellerOrder[],
      loadingExport: ButtonLoadingState.initial
    };
  },

  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      productSource: 'accounts/selectedFeatureTypeId'
    }),
    tableFilteredTransactionData(): SellerOrder[] {
      const t = this.tableTransactionData
        .filter(this.filterTransactionList)
        .sort(this.sortTransactionList) as SellerOrder[];
      this.setOrderedIds(t.map((x) => x.transactionId));
      this.$emit('update:filteredCount', t.length);
      return t;
    }
  },
  async mounted() {
    if (!this.currentAccount) {
      return;
    }
    await this.getTransactions();
    this.$watch(() => this.$route.params, this.getTransactions);
  },

  methods: {
    ...mapMutations('global', ['setLoading']),
    ...mapMutations('orders', ['setOrderedIds']),

    viewOrder(orderId: string) {
      this.$router.push({ path: `/store/orders/${orderId}` });
    },
    processDate(date: number) {
      return Helpers.processDate(date);
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },

    async exportOrdersToCSV() {
      this.loadingExport = ButtonLoadingState.loading;
      // Shopify CSV format
      let csv =
        'Name,Email,Financial Status,Fulfillment Status,Currency,Buyer Accepts Marketing,Cancel Reason,Cancelled At,Closed At,Tags,Note,' +
        'Phone,Referring Site,Processed At,Source name,Total weight,Total Tax,' +
        'Shipping Company,Shipping Name,Shipping Phone,Shipping First Name,Shipping Last Name,Shipping Address1,Shipping Address2,Shipping City,Shipping Province,Shipping Province Code,Shipping Zip,Shipping Country,Shipping Country Code,' +
        'Billing Company,Billing Name,Billing Phone,Billing First Name,Billing Last Name,Billing Address1,Billing Address2,Billing City,Billing Province,Billing Province Code,Billing Zip,Billing Country,Billing Country Code,' +
        'Lineitem name,Lineitem variant id,Lineitem quantity,Lineitem price,Lineitem variant title,Lineitem compare at price,Lineitem sku,Lineitem requires shipping,Lineitem taxable,Lineitem fulfillment status,' +
        'Taxes Included,Tax 1 Title,Tax 1 Price,Tax 1 Rate,Tax 2 Title,Tax 2 Price,Tax 2 Rate,Tax 3 Title,Tax 3 Price,Tax 3 Rate,' +
        'Transaction Amount, Transaction Kind, Transaction Status,Transaction Processed At, Transaction Gateway,Transaction Location Id,Transaction Source Name,' +
        'Shipping Line Code,Shipping Line Price,Shipping Line Source,Shipping Line Title,Shipping Line Carrier Identifier,Shipping Line Requested Fulfillment Service Id,Shipping Tax 1 Title,Shipping Tax 1 Rate,Shipping Tax 1 Price,' +
        'Discount Code,Discount Amount,Discount Type,Metafield Namespace,Metafield Key,Metafield Value,Metafield Value Type\n';

      const tableData = this.tableFilteredTransactionData;

      if (tableData) {
        document.body.style.cursor = 'wait';
        try {
          // construct the CSV data
          tableData.forEach((t: SellerOrder) => {
            csv +=
              `${t.userTransactionId},${t.userEmail},paid,${
                t.fulfilled ? 'fulfilled' : ''
              },${t.stripeDetails ? t.stripeDetails.currency : ''},,,,,,,` +
              `,,${t.created},,"${t.productWeight}",${
                t.charges ? t.charges.tax : 0
              },` +
              `"${t.userCarrier}",,,"${t.shippedToFirstName}","${t.shippedToLastName}","${t.shippedToAddress1}","","${t.shippedToCity}","${t.shippedToState}",,${t.shippedToPostalCode},USA,,` +
              `,,,,,,,,,,${
                t.stripeDetails &&
                t.stripeDetails.billing_details &&
                t.stripeDetails.billing_details.address &&
                t.stripeDetails.billing_details.address.postal_code
                  ? t.stripeDetails.billing_details.address.postal_code
                  : ''
              },,,` +
              `"${t.productHashtag}",,1,${t.charges ? t.charges.price : 0},,,"${
                t.sku
              }",,,${t.fulfilled ? 'fulfilled' : ''},` +
              ',,,,,,,,,,' +
              `${t.charges ? t.charges.total : 0},sale,${
                t.stripeDetails ? t.stripeDetails.status : ''
              },${t.stripeDetails ? t.stripeDetails.created : ''},,,,` +
              `,${t.charges ? t.charges.shipping : 0},,,${
                t.userCarrier ? t.userCarrier : ''
              },${t.userTrackingNo ? t.userTrackingNo : ''},,,,` +
              ',,,,,,' +
              '\n';
          });

          const filename = `${this.currentAccount.name} - Orders.csv`;
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');

          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.log(e);

          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }
        this.loadingExport = ButtonLoadingState.complete;
      }

      document.body.style.cursor = 'default';
    },

    async getTransactions() {
      this.setLoading(true);
      const query = this.$route.query;
      const params = {
        accountId: this.currentAccount.accountId,
        productSource: this.productSource,
        userId: query.userId // If no query.userId, this property will be undefined
      };

      const results = await FirebaseRequest.createRequest<SellerOrder[]>(
        FirebaseRequestTypes.GET_SELLER_ORDERS,
        params
      );
      if (results.status === APIResponseStatus.OK) {
        this.tableTransactionData =
          (this.type as TagType) === TagType.TIP
            ? (results.data || []).filter((x) => x.type === TagType.TIP)
            : results.data || [];
        if (this.limit) {
          this.tableTransactionData = this.tableTransactionData.slice(
            0,
            this.limit
          );
        }
      }

      // this.tableFilteredTransactionData = this.tableTransactionData;
      // this.filterTransactionList();
      this.setLoading(false);
    },

    sortTransactionList(a: SellerOrder, b: SellerOrder): number {
      const getTime = (d?: string) => {
        return d != null ? new Date(d).getTime() : 0;
      };
      const getAmount = (c?: Charges) => {
        return c != null && c.total ? c.total : 0;
      };

      let results = 0;

      if (this.searchInput.sortBy) {
        if (a && b) {
          switch (this.searchInput.sortBy) {
            case 'NameAsc':
              results = a.productTitle.localeCompare(b.productTitle);
              break;
            case 'NameDesc':
              results = b.productTitle.localeCompare(a.productTitle);
              break;
            case 'Amount':
              results = getAmount(b.charges) - getAmount(a.charges);
              break;
            case 'AmountAsc':
              results = getAmount(a.charges) - getAmount(b.charges);
              break;
            case 'Date':
              results = getTime(b.created) - getTime(a.created);
              break;
            case 'DateAsc':
              results = getTime(a.created) - getTime(b.created);
              break;
            default:
              results = 1;
          }
        }
      }
      return results;
    },

    filterTransactionList(sellerOrder: SellerOrder) {
      let filterResults = true;

      if (this.type === TagType.TIP) {
        switch (this.searchInput.filterValue) {
          case 'Received':
            filterResults =
              !sellerOrder.refunded && sellerOrder.type === TagType.TIP;
            break;
          case 'Refunded':
            filterResults =
              !!sellerOrder.refunded && sellerOrder.type === TagType.TIP;
            break;
          default:
            filterResults = sellerOrder.type === TagType.TIP;
        }
      } else {
        switch (this.searchInput.filterValue) {
          case 'Fulfilled':
            filterResults = this.filterTransactionsByFulfilled(sellerOrder);
            break;
          case 'Refunded':
            filterResults = this.filterTransactionsByRefunds(sellerOrder);
            break;
          case 'Pending':
            filterResults = this.filterTransactionByWaiting(sellerOrder);
            break;
          default:
            filterResults = true; // All
        }
      }

      if (this.searchInput.searchValue) {
        filterResults =
          filterResults && this.filterTransactionsBySearchValue(sellerOrder);
      }
      return filterResults;
    },

    filterTransactionsByFulfilled(transaction: SellerOrder): boolean {
      // return true if transaction has been fulfilled, false otherwise
      return !!transaction.fulfilled && transaction.type === TagType.PRODUCT;
    },

    filterTransactionsByRefunds(transaction: SellerOrder): boolean {
      // return true if transaction has been refunded, false otherwise
      return !!transaction.refunded && transaction.type === TagType.PRODUCT;
    },

    filterTransactionsByTips(transaction: SellerOrder): boolean {
      // return true if transaction has been refunded, false otherwise
      return transaction.type === TagType.TIP;
    },

    filterTransactionByWaiting(transaction: SellerOrder): boolean {
      // return false if transaction has benn fulfilled or refunded, true otherwise
      return (
        !transaction.refunded &&
        !transaction.fulfilled &&
        transaction.type === TagType.PRODUCT
      );
    },

    filterTransactionsBySearchValue(transaction: SellerOrder) {
      const searchValue = this.searchInput.searchValue.toLocaleLowerCase();
      const productTitle = transaction.productTitle?.toLocaleLowerCase() || '';
      const firstName = transaction.shippedToFirstName
        ? transaction.shippedToFirstName.toLowerCase()
        : '';
      const lastName = transaction.shippedToLastName
        ? transaction.shippedToLastName.toLowerCase()
        : '';
      const via = transaction.provider
        ? transaction.provider.toLowerCase()
        : '';
      const date = transaction.created
        ? new Date(transaction.created).toLocaleDateString()
        : '';
      const hashtag = transaction.productHashtag
        ? transaction.productHashtag.toLowerCase()
        : '';
      const userTransactionId = transaction.userTransactionId
        ? transaction.userTransactionId
        : '';
      const amount = transaction.charges
        ? Helpers.currencyFormatter(transaction.charges.price)
        : '';

      if (searchValue.length > 0) {
        const searchComponents = searchValue.split(' ');
        let value;

        for (value of searchComponents) {
          if (
            !firstName.includes(value) &&
            !lastName.includes(value) &&
            !date.includes(value) &&
            !hashtag.includes(value) &&
            !productTitle.includes(value) &&
            !userTransactionId.includes(value) &&
            !via.includes(value) &&
            !amount.includes(value)
          ) {
            return false;
          }
        }
      }
      return true;
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    }
  }
});
