import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { PurchasesState, RootState } from './types';

const purchasesState: PurchasesState = {
  transactionIds: []
};

const purchasesMutations: MutationTree<PurchasesState> = {
  setPurchasesIds: (state: PurchasesState, payload: string[]) => {
    state.transactionIds = payload;
  }
};

const purchasesActions: ActionTree<PurchasesState, RootState> = {
  getNext(context, currentId: string): string | null {
    const index = context.state.transactionIds.findIndex(
      (x) => x === currentId
    );
    return index >= context.state.transactionIds.length - 1
      ? null
      : context.state.transactionIds[index + 1];
  },
  getPrevious(context, currentId: string): string | null {
    const index = context.state.transactionIds.findIndex(
      (x) => x === currentId
    );
    return index <= 0 ? null : context.state.transactionIds[index - 1];
  },
  getCurrentIndex(context, currentId: string): number {
    const index = context.state.transactionIds.findIndex(
      (x) => x === currentId
    );
    return index;
  }
};

const purchasesGetters: GetterTree<PurchasesState, RootState> = {
  getPurchasesListLength(state): number {
    return state.transactionIds.length;
  }
};

export const purchases = {
  mutations: purchasesMutations,
  state: purchasesState,
  actions: purchasesActions,
  getters: purchasesGetters,
  namespaced: true
};
