
import { store } from '@/store';
import { defineComponent } from 'vue';
import { Buyer } from './BuyerSettingsInterfaces';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapGetters, mapMutations } from 'vuex';
import BuyerAccount from './BuyerAccount.vue';
import BuyerPayment from './BuyerPayment.vue';
import BuyerShipping from './BuyerShipping.vue';
import BuyerStripe from './BuyerStripe.vue';
import {
  PopupMessage,
  PopupMessageButtonCaptions,
  PopupMessageStrings,
  PopupMessageTitles
} from '@/common/PopupComponent/PopupMessages';
import ConfirmComponent from '@/common/PopupComponent/ConfirmComponent.vue';
import settings from '@/settings';

export default defineComponent({
  components: {
    BuyerAccount,
    BuyerPayment,
    BuyerShipping,
    BuyerStripe,
    ConfirmComponent
  },
  data() {
    return {
      selectedMenu: 'account',
      popup: {} as PopupMessage,
      buyerAccountHasChanges: false
    };
  },
  computed: {
    ...mapGetters({
      buyerSettings: 'auth/getCurrentBuyer'
    })
  },
  async created() {
    if (this.$route.query.callback === 'stripe_callback') {
      this.handleStripeCallback();
    }
  },
  async mounted() {
    this.selectedMenu = (this.$route.query['menu'] as string) || 'account';
    await this.getCurrentBuyer();
  },
  methods: {
    ...mapMutations({
      setBannerStatus: 'global/setBannerStatus'
    }),
    async getCurrentBuyer() {
      this.$store.commit('global/setLoading', {
        isLoading: true,
        message: 'Retrieving account details...'
      });
      await this.$store.dispatch('auth/refreshCurrentBuyer');
      this.$store.commit('global/setLoading', false);
    },
    async changeTab(menu: string) {
      if (!this.buyerAccountHasChanges) {
        this.selectedMenu = menu;
        return;
      }

      const confirmation = this.$refs.confirmation as typeof ConfirmComponent;
      const confirmDiscard = await confirmation.show(
        PopupMessageTitles.UNSAVED_CHANGES,
        PopupMessageStrings.UNSAVED_CHANGES,
        PopupMessageButtonCaptions.DISCARD_CHANGES
      );
      if (confirmDiscard) {
        // abandoning changes
        this.selectedMenu = menu;
        this.buyerAccountHasChanges = false;
      }
    },
    async updateSettings(settings: Buyer) {
      const buyer = await FirebaseRequest.createRequest<Buyer>(
        FirebaseRequestTypes.SAVE_BUYER,
        settings
      );
      if (buyer?.status !== APIResponseStatus.OK) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.BUYER_FORM_ERROR,
          type: BannerType.error
        });
      } else {
        store.commit('auth/setCurrentBuyer', settings);
        this.$router.push({
          name: 'buyer-product-orders'
        });
      }
    },
    goBack() {
      this.$router.push({
        name: 'buyer-product-orders'
      });
    },
    connectToStripe() {
      const currentBuyer = this.buyerSettings as Buyer;
      const stripeAppId = settings.STRIPE_APP_ID;
      const callBackURL =
        location.origin + '/user/settings?callback=stripe_callback';
      window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeAppId}&scope=read_write&state=${currentBuyer.userId}&redirect_uri=${callBackURL}`;
    },
    async disconnectFromStripe() {
      store.commit('global/setLoading', true);
      const currentBuyer = this.buyerSettings as Buyer;
      const stripeResult = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.DISCONNECT_AFFILIATE_PAYOUT,
        { userId: currentBuyer.userId }
      );
      if (stripeResult.status === APIResponseStatus.OK) {
        this.getCurrentBuyer();
        this.setBannerStatus({
          text: BannerMessageStrings.STRIPE_DISCONNECTED,
          type: BannerType.info
        });
      } else {
        //TODO: Potential error coming from server that disallows disconnect until
        //      all earnings are paid out
        this.setBannerStatus({
          text: BannerMessageStrings.SELLER_STRIPE_ACCOUNT_ERROR,
          type: BannerType.error
        });
      }
      store.commit('global/setLoading', false);
    },
    async handleStripeCallback() {
      store.commit('global/setLoading', true);
      this.selectedMenu = 'stripe';
      try {
        const code = this.$route.query.code;
        const userId = (this.buyerSettings as Buyer).userId;
        store.commit('global/setLoading', true);
        const stripeResult = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.CONNECT_AFFILIATE_PAYOUT,
          { userId, code }
        );

        if (stripeResult.status === APIResponseStatus.OK) {
          // Reload the account with the updated stripe credentials
          this.setBannerStatus({
            text: BannerMessageStrings.SELLER_SETTINGS_STRIPE_CONNECTED
          });
        } else {
          this.setBannerStatus({
            text: BannerMessageStrings.SELLER_STRIPE_ACCOUNT_ERROR,
            type: BannerType.error
          });
        }
      } catch (ex) {
        this.setBannerStatus({
          text: BannerMessageStrings.SELLER_STRIPE_ACCOUNT_ERROR + ex,
          type: BannerType.error
        });
      }
      this.getCurrentBuyer();
      store.commit('global/setLoading', false);
    }
  }
});
