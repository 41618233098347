
import { defineComponent } from 'vue';
import { Stat } from '../../models/stat';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import Helpers from '@/helpers';
import { mapGetters } from 'vuex';
import { Permissions } from '../store/types';

const SellerDashboard = defineComponent({
  data() {
    return {
      Permissions,
      selectedMenu: 'overview',
      stats: [
        {
          title: 'Today',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 24 Hours',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 7 Days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 30 Days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Last 90 Days',
          amount: 0,
          sales: 0
        },
        {
          title: 'Lifetime',
          amount: 0,
          sales: 0
        }
      ],
      statRefreshButtonState: ButtonLoadingState.initial
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    }),
    // Must do this as a separate computed as it doesn't fire the watch when it is done with mapGetters() above
    // Also sometimes the store.state.accounts.selectedFeatureTypeId is blank, so the check the getter which
    // may not be blank
    productSource(): string {
      return (
        this.$store.state.accounts.selectedFeatureTypeId ||
        (this.$store.getters['accounts/selectedFeatureTypeId'] as string)
      );
    }
  },
  watch: {
    currentAccount(newValue) {
      if (newValue != null) {
        this.getStats();
      }
    },
    productSource() {
      this.getStats();
    }
  },
  mounted() {
    if (this.currentAccount == null) {
      return;
    }

    if (!this.currentAccount.isStripeConnected) {
      this.selectedMenu = 'get-started';
    }

    this.getStats();
  },
  methods: {
    getPrice(price: number) {
      return Helpers.currencyFormatter(price);
    },
    async getStats() {
      this.statRefreshButtonState = ButtonLoadingState.loading;
      const accountId = this.currentAccount?.accountId;

      const responseData = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_SELLER_STATS,
        { accountId, productSource: this.productSource }
      );

      if (responseData.status === APIResponseStatus.OK) {
        const st = responseData.data as Stat;
        this.stats[0].amount = st.today
          ? parseFloat(st.today.amount.toFixed(2))
          : 0;
        this.stats[0].sales = st.today?.orders ?? 0;

        this.stats[1].amount = st.yesterday
          ? parseFloat(st.yesterday.amount.toFixed(2))
          : 0;
        this.stats[1].sales = st.yesterday?.orders ?? 0;

        this.stats[2].amount = st.week
          ? parseFloat(st.week.amount.toFixed(2))
          : 0;
        this.stats[2].sales = st.week?.orders ?? 0;

        this.stats[3].amount = st.month
          ? parseFloat(st.month.amount.toFixed(2))
          : 0;
        this.stats[3].sales = st.month?.orders ?? 0;

        this.stats[4].amount = st.quarter
          ? parseFloat(st.quarter.amount.toFixed(2))
          : 0;
        this.stats[4].sales = st.quarter?.orders ?? 0;

        this.stats[5].amount = st.totals
          ? parseFloat(st.totals.amount.toFixed(2))
          : 0;
        this.stats[5].sales = st.totals?.orders ?? 0;
      }
      this.statRefreshButtonState = ButtonLoadingState.complete;
    }
  }
});

export default SellerDashboard;
