
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import StripeCreditCardDetailsComponent from '@/common/info/StripeCreditCardDetailsComponent.vue';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import { Buyer, PaymentOption } from './BuyerSettingsInterfaces';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

export default defineComponent({
  name: 'BuyerPayment',
  components: { StripeCreditCardDetailsComponent },
  data() {
    return {
      menuItems: [
        {
          label: 'Make Default',
          onClick: (creditCard) => {
            this.setCardAsDefault(creditCard as unknown as PaymentOption);
          },
          isDisabled: (creditCard) =>
            (creditCard as unknown as PaymentOption).isDefault
        },
        {
          label: 'Remove Card',
          onClick: (creditCard) => {
            this.removePaymentCard(creditCard);
          },
          isDisabled: (creditCard) => creditCard.isDefault
        }
      ] as ContextMenuItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentBuyer: 'auth/getCurrentBuyer'
    }),
    canAddCard(): boolean {
      return (this.currentBuyer as Buyer).paymentOptions.length < 3;
    },
    paymentOptions(): PaymentOption[] {
      return (this.currentBuyer as Buyer)?.paymentOptions?.sort((b, a) =>
        a.isDefault ? 1 : -1
      );
    }
  },
  methods: {
    addPaymentCard() {
      this.$router.push({ name: 'buyer-card-change' });
    },
    async removePaymentCard(card: PaymentOption) {
      this.$store.commit('global/setLoading', {
        isLoading: true,
        message: 'Removing payment option...'
      });

      // this menu option is disabled if this is a default payment method
      const response = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.REMOVE_BUYER_PAYMENT_OPTION,
        { cardId: card.id }
      );
      if (response && response.status === APIResponseStatus.OK) {
        await this.$store.dispatch('auth/refreshCurrentBuyer');
      } else {
        this.$store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.$store.commit('global/setLoading', false);
    },
    async setCardAsDefault(card: PaymentOption) {
      if (!card) {
        this.$store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        return;
      }

      this.$store.commit('global/setLoading', {
        isLoading: true,
        message: 'Setting default payment...'
      });
      const results = await this.$store.dispatch(
        'accounts/setPaymentOptionAsDefault',
        {
          paymentMethodId: card.id
        }
      );
      if (results) {
        await this.$store.dispatch('auth/refreshCurrentBuyer');
      }

      this.$store.commit('global/setLoading', false);
    }
  }
});
