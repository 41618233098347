import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify--between"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "boost-select__description"
}
const _hoisted_5 = ["id", "name", "disabled"]
const _hoisted_6 = {
  key: 0,
  value: "null",
  class: "boost-select__placeholder"
}
const _hoisted_7 = ["value", "selected"]
const _hoisted_8 = {
  key: 2,
  class: "boost-select__error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["boost-select", {
      'boost-select--default': _ctx.innerValue == null
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["boost-select__element", {
        'boost-select--error': !_ctx.hideErrors && _ctx.errorMessage,
        'boost-select--default': _ctx.innerValue == null
      }])
    }, [
      (!_ctx.hideLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("label", {
              for: _ctx.inputId,
              class: "boost-select__label"
            }, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              (_ctx.isRequired && _ctx.showRequiredIndicator)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                : _createCommentVNode("", true)
            ], 8, _hoisted_2),
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true),
      _withDirectives((_openBlock(), _createElementBlock("select", {
        id: _ctx.inputId,
        key: _ctx.inputId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        class: "boost-select__select",
        name: _ctx.name,
        disabled: _ctx.disabled,
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
      }, [
        (_ctx.value === undefined || _ctx.value === null || _ctx.value === '')
          ? (_openBlock(), _createElementBlock("option", _hoisted_6, _toDisplayString(_ctx.placeHolder || _ctx.defaultOption), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: option.name + option.value.toString(),
            value: option.value,
            selected: _ctx.isOptionSelected(option)
          }, _toDisplayString(option.name), 9, _hoisted_7))
        }), 128))
      ], 40, _hoisted_5)), [
        [_vModelSelect, _ctx.value]
      ]),
      (!_ctx.hideErrors && _ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
            _createElementVNode("li", null, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}