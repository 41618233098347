
import { defineComponent } from 'vue';
import { ShippingAddress } from '../settings/BuyerSettingsInterfaces';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { TagDetail } from '@/models/tag';
import InstantCheckoutProductImage from '@/buyer/checkout/InstantCheckoutProductImage.vue';

export default defineComponent({
  name: 'UnavailableProductComponent',
  components: {
    InstantCheckoutProductImage
  },
  props: {
    product: {
      type: Object as () => TagDetail,
      required: true
    },
    customColor: {
      type: String,
      required: true
    },
    shopifyStoreUrl: {
      type: String
    }
  },
  data() {
    return {
      shippingAddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States',
        isDefault: true
      } as ShippingAddress,
      addAddressButtonState: ButtonLoadingState.initial
    };
  },
  methods: {
    navigateToShopifyStore() {
      if (this.shopifyStoreUrl) {
        window.location.href = this.shopifyStoreUrl;
      }
    }
  }
});
