import amplitude from 'amplitude-js';
import { ExpressCheckout } from '@/models/expressCheckout';
import settings from './settings';
import { auth } from '@/store/auth';

export enum AnalyticEventType {
  expressCheckoutApplePayClicked = 'express_checkout_apple_pay_clicked',
  expressCheckoutPayByCardClicked = 'express_checkout_pay_by_card_clicked',
  expressCheckoutCompletePurchaseClicked = 'express_checkout_complete_purchase_clicked',
  expressCheckoutPurchaseSuccessful = 'express_checkout_purchase_successful',
  expressCheckoutPurchaseError = 'express_checkout_purchase_error',
  expressCheckoutReturnsPolicyClicked = 'express_checkout_returns_policy_clicked',
  expressCheckoutGoToAccount = 'express_checkout_go_to_account'
}

export interface AnalyticEventData {
  timestamp: string;
  userAgent: string;
  phoneNumber: string | undefined | null;
  tag: string | undefined | null;
  sku: string | undefined | null;
  brandName: string | undefined | null;
  errors: string | undefined | null;
}

export const analytics = {
  // Add additional types for "data" that can be used for analytics data, add conversion method below
  logEvent(
    eventType: AnalyticEventType,
    data: ExpressCheckout,
    error?: unknown
  ): void {
    const eventData = this.toLogEventData(data);

    const instance1 = amplitude.getInstance();
    instance1.init(settings.AMPLITUDE_KEY);
    if (auth.state.currentUser) {
      instance1.setUserId(auth.state.currentUser.uid);
    }
    if (error) {
      eventData.errors = error instanceof Error ? error.message : String(error);
    }
    instance1.logEvent(eventType, eventData);
  },
  // Add overridden conversion methods for data types as needed
  // or add logic to handle different types passed in
  toLogEventData(checkout: ExpressCheckout): AnalyticEventData {
    return {
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
      phoneNumber: checkout.phoneNumber,
      tag: checkout.hashtag,
      sku: checkout.skuId,
      brandName: checkout.hashtag ? checkout.hashtag.replace(/[0-9]/g, '') : '',
      errors: ''
    };
  }
};
