import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f7c6452"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t-12 flex--full-width standard-margins no-pad" }
const _hoisted_2 = { class: "flex input-set" }
const _hoisted_3 = { class: "margin-bottom-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostSectionHeader = _resolveComponent("BoostSectionHeader")!
  const _component_BoostImageUpload = _resolveComponent("BoostImageUpload")!
  const _component_BoostInput = _resolveComponent("BoostInput")!
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BoostSectionHeader, {
      class: "bottom-border",
      title: "Account"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BoostImageUpload, {
        "image-url": _ctx.imageUrl,
        "onUpdate:image-url": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.imageUrl) = $event)),
        class: "margin-top-14",
        label: "Profile Picture",
        "write-on-preview": true,
        onImageFilePreviewed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logoUploaded($event)))
      }, null, 8, ["image-url"])
    ]),
    _createVNode(_component_BoostSectionHeader, {
      title: "General",
      "is-compact": "",
      "has-content": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_validation_observer, {
          ref: "observer",
          tag: "div",
          class: "flex flex--wrap"
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("form", {
              novalidate: "",
              class: "flex--full-width no-pad",
              onSubmit: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_BoostInput, {
                  modelValue: _ctx.firstName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName) = $event)),
                  label: "First Name",
                  type: "text",
                  "input-id": "buyer-first-name"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_BoostInput, {
                  modelValue: _ctx.lastName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastName) = $event)),
                  label: "Last Name",
                  type: "text",
                  "input-id": "buyer-last-name"
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(_component_BoostInput, {
                modelValue: _ctx.currentBuyer.affiliateUsername,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentBuyer.affiliateUsername) = $event)),
                label: "Advocate username",
                type: "text",
                "input-id": "affiliate-username",
                disabled: ""
              }, null, 8, ["modelValue"]),
              _createVNode(_component_BoostInput, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.email) = $event)),
                label: "Email address",
                rules: "required|email",
                type: "text",
                "input-id": "email-address"
              }, null, 8, ["modelValue"]),
              (_ctx.email && !_ctx.currentBuyer.isVerified)
                ? (_openBlock(), _createBlock(_component_BoostButton, {
                    key: 0,
                    class: "mb-4 text-xs sm:text-sm",
                    "button-style": "link",
                    onClick: _ctx.resendBuyerVerification
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Resend verification email")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_BoostInput, {
                  modelValue: _ctx.currentBuyer.mobileNumber,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentBuyer.mobileNumber) = $event)),
                  label: "Mobile number",
                  type: "text",
                  "input-id": "mobile-number",
                  autocomplete: "tel",
                  disabled: true
                }, null, 8, ["modelValue"]),
                _createVNode(_component_BoostButton, {
                  class: "pl-0 text-xs sm:text-sm",
                  label: "Change number",
                  "button-style": "link",
                  type: "button",
                  onClick: _ctx.changeMobileNumber
                }, null, 8, ["onClick"]),
                (_openBlock(), _createBlock(_Teleport, { to: "#action-buttons" }, [
                  _createVNode(_component_BoostButton, {
                    label: "Save",
                    "button-style": "primary",
                    class: "margin-left-4 margin-bottom-0",
                    type: "button",
                    disabled: !meta.valid,
                    onClick: _ctx.commitChanges
                  }, null, 8, ["disabled", "onClick"])
                ]))
              ])
            ], 32)
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    })
  ], 64))
}