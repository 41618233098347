
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoostToggle',
  props: {
    inputId: {
      type: String,
      required: true
    },
    description: {
      type: String
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit('update:modelValue', value);
      }
    }
  }
});
