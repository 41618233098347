
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';

export default defineComponent({
  name: 'MarketplaceHeaderComponent',
  data() {
    return {
      isMounted: false,
      isMobileMenuOpen: false,
      links: [
        { label: 'Creators', url: 'https://support.tagscommerce.com/creators' },
        { label: 'Brands', url: 'https://support.tagscommerce.com/brands' },
        { label: 'Dashboard', url: '/' },
        { label: 'Contact', url: 'mailto:hello@tagscommerce.com' }
      ]
    };
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    ...mapMutations('marketplace', ['setIsSearchOpen']),
    ...mapMutations('auth', ['getCurrentBuyer']),
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    onSearchButtonClicked() {
      this.isMobileMenuOpen = false;
      this.setIsSearchOpen(true);
    }
  }
});
