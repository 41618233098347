
import { AccountObject } from '@/models/accountObject';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SellerSettingsIntegrations',
  computed: {
    currentAccount() {
      return this.$store.getters['accounts/currentAccount'] as AccountObject;
    },
    showUpgradeOption(): boolean {
      return (
        this.currentAccount.currentPlan === 'Basic' ||
        !this.currentAccount.currentPlan
      );
    },
    shopifyButtonLabel(): string {
      return this.showUpgradeOption
        ? 'Upgrade'
        : this.currentAccount.isShopifyConnected
        ? 'Manage'
        : 'Connect';
    }
  },
  methods: {
    async connectShopify() {
      if (this.showUpgradeOption) {
        this.$router.replace({
          name: 'seller-settings',
          query: { tab: 'paymentPlans' }
        });
      } else {
        await this.$router.push({ name: 'shopify-integration' });
      }
    }
  }
});
