export enum AudienceSortOptions {
  MostRecent = 1,
  MostOrders = 2,
  LeastOrders = 3,
  HighestRevenue = 4,
  LowestRevenue = 5,
  HighestRefunds = 6,
  LeastRefunds = 7,
  AlphaAscending = 8,
  AlphaDescending = 9
}

export enum AudienceFilterOptions {
  All = '1',
  Active = '2',
  NewCustomer = '3',
  PreRegistered = '4',
  HighRefunds = '5',
  Inactive = '6',
  Suspended = '7'
}

export enum AudienceType {
  Customers = 'Customers',
  Advocates = 'Advocates'
}

export interface FilterAudienceEvent {
  filterOption: AudienceFilterOptions;
  sortOption: AudienceSortOptions;
  searchValue?: string;
  typeSelection: AudienceType;
}
