
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { store } from '@/store';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import CheckoutDetailDivider from './CheckoutDetailDivider.vue';

const ReplyCodes = {
  NUMBER_NOT_FOUND: 'NUMBER_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_FOUND: 'USER_FOUND',
  INVALID_DATA: 'INVALID_DATA',
  USERNAME_IN_USE: 'USERNAME_IN_USE',
  SHARE_DISABLED: 'SHARE_DISABLED',
  SUSPENDED: 'SUSPENDED',
  SELLER_DOES_EXIST: 'SELLER_DOES_EXIST',
  PRODUCT_ID_MISSING: 'PRODUCT_ID_MISSING'
};

enum AffiliateOnCheckoutStatus {
  Suspended,
  SignupStep1,
  SignupStep2,
  Completed
}

interface AffiliateProductSignupResponse {
  userName: string;
  id: string;
  affiliateTag: string;
}

export default defineComponent({
  name: 'AffiliateOnCheckout',
  components: { CheckoutDetailDivider },
  props: {
    productName: {
      type: String,
      default: ''
    },
    productId: {
      type: String,
      default: ''
    },
    accountId: {
      type: String,
      default: ''
    },
    shopifyStoreUrl: {
      type: String,
      default: ''
    },
    percentage: {
      type: String,
      default: ''
    }
  },
  emits: ['backToCheckout'],
  data() {
    return {
      AffiliateOnCheckoutStatus,
      status: AffiliateOnCheckoutStatus.Suspended,
      username: '',
      usernameError: [] as string[],
      affiliateTag: '',
      accountAffiliateId: '',
      copyButtonState: ButtonLoadingState.initial,
      getCodeState: ButtonLoadingState.initial
    };
  },
  computed: {
    ...mapGetters({
      getBrandName: 'global/getBrandName',
      getBrandGuidelines: 'global/getBrandGuidelines',
      getBrandLogo: 'global/getBrandLogo'
    }),
    customColor(): string {
      return store.getters['global/getCustomColor'] as string;
    },
    checkoutUrl() {
      return `${window.location.origin}/buy/${this.affiliateTag}`;
    },
    currentBuyer(): Buyer {
      return store.getters['auth/getCurrentBuyer'] as Buyer;
    }
  },
  async mounted() {
    if (this.currentBuyer.affiliateUsername) {
      this.setLoading({ isLoading: true, message: 'Generating your Tags...' });
      const payload = {
        accountId: this.accountId,
        productId: this.productId,
        username: this.username
      };
      try {
        const response =
          await FirebaseRequest.createRequest<AffiliateProductSignupResponse>(
            FirebaseRequestTypes.COMPLETE_AFFILIATE_SIGNUP_FOR_PRODUCT,
            payload
          );
        if (response.status === APIResponseStatus.OK) {
          // If not suspended then generate the affiliate tag, store the results, and congratulate
          this.affiliateTag = response.data?.affiliateTag || '';
          this.accountAffiliateId = response.data?.id || '';
          this.status = AffiliateOnCheckoutStatus.Completed;
        } else if (response.code === 'SUSPENDED') {
          this.status = AffiliateOnCheckoutStatus.Suspended;
        } else {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }
      } catch (err) {
        console.error(err);
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    } else {
      // No affiliate username so this buyer needs to be signed up as an affiliate
      this.status = AffiliateOnCheckoutStatus.SignupStep2;
    }
    this.setLoading(false);
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    async registerAffiliate() {
      this.usernameError = [];

      this.getCodeState = ButtonLoadingState.loading;
      if (!(await this.validateAffiliate())) {
        this.getCodeState = ButtonLoadingState.complete;
        return;
      }
      const payload = {
        username: this.username,
        accountId: this.accountId,
        productId: this.productId
      };
      try {
        const response =
          await FirebaseRequest.createRequest<AffiliateProductSignupResponse>(
            FirebaseRequestTypes.COMPLETE_AFFILIATE_SIGNUP_FOR_PRODUCT,
            payload
          );
        if (response.status === APIResponseStatus.OK && response.data) {
          if (response.code === ReplyCodes.SELLER_DOES_EXIST) {
            this.usernameError.push(
              `Sorry, the username "${this.username}" is not available.`
            );
            this.getCodeState = ButtonLoadingState.initial;
            return;
          }

          this.affiliateTag = response.data.affiliateTag;
          this.accountAffiliateId = response.data.id;
          this.username = response.data.userName;
          this.status = AffiliateOnCheckoutStatus.Completed;
        } else {
          this.tagCreationFailed(response.code);
          this.getCodeState = ButtonLoadingState.initial;
        }
      } catch (err) {
        console.error(err);
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.getCodeState = ButtonLoadingState.complete;
    },
    async validateAffiliate(): Promise<boolean> {
      const response = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.CHECK_SELLER_HASHTAG,
        { hashtagPrefix: this.username }
      );
      if (response.code === 'SELLER_DOES_EXIST') {
        this.usernameError.push(
          `Sorry, the username "${this.username}" is not available.`
        );
        this.getCodeState = ButtonLoadingState.initial;
        // store.commit('global/setBannerStatus', {
        //   text: BannerMessageStrings.SELLER_TAG_UNAVAILABLE,
        //   type: BannerType.error
        // });
        return false;
      }
      return true;
    },
    tagCreationFailed(code: string) {
      if (code === ReplyCodes.SUSPENDED) {
        // TODO: show a suspension message on this view. Don't both going
        // to another view.
        console.error(
          'Sorry, your account is suspended and you are unable to create checkout tags'
        );
      }

      if (code === ReplyCodes.USERNAME_IN_USE) {
        this.usernameError.push(
          `Sorry, the username "${this.username}" is not available.`
        );
      }

      console.error('tag creation failed: ', code);
    },
    async copyLink() {
      this.copyButtonState = ButtonLoadingState.loading;
      await navigator.clipboard.writeText(this.checkoutUrl);
      this.copyButtonState = ButtonLoadingState.complete;
    },
    async downloadQR() {
      this.setLoading(true);
      const responseData = await FirebaseRequest.requestFile(
        FirebaseRequestTypes.GENERATE_PRODUCT_LINKS,
        {
          productId: this.productId,
          accountId: this.accountId,
          singleFile: true,
          accountAffiliateId: this.accountAffiliateId
        }
      );
      const FILE = window.URL.createObjectURL(await responseData.blob());
      const downloadUrl = document.createElement('a');
      downloadUrl.href = FILE;
      downloadUrl.setAttribute(
        'download',
        `${this.productName.substring(0, 50).replace(/[^a-z0-9]/gi, '-')}.png`
      );
      document.body.appendChild(downloadUrl);
      downloadUrl.click();
      this.setLoading(false);
    },
    goToStore() {
      if (this.shopifyStoreUrl) {
        window.location.href = this.shopifyStoreUrl;
      }
    }
  }
});
