import BuyerBase from '@/buyer/BuyerBase.vue';
import BuyerRegistrationComponent from '@/buyer/registration/BuyerRegistration.vue';
import BuyerProductOrders from '@/buyer/BuyerProductOrders.vue';
import BuyerSettings from '@/buyer/settings/BuyerSettings.vue';
import BuyerCheckout from '@/buyer/checkout/BuyerCheckout.vue';
import BuyerUpdateNumberComponent from '@/buyer/settings/BuyerUpdateNumberComponent.vue';
import BuyerCardInformationComponent from '@/buyer/settings/BuyerCardInformationComponent.vue';
import BuyerShippingAddressComponent from '@/buyer/settings/BuyerShippingAddressComponent.vue';
import InstantCheckout from '@/buyer/checkout/InstantCheckout.vue';
import ProductCheckout from '@/buyer/checkout/ProductCheckout.vue';
import BuyerOrderDetail from '@/buyer/BuyerOrderDetail.vue';
import AffiliateSignup from '@/buyer/affiliate/AffiliateSignup.vue';
import BuyerDashboard from '@/buyer/BuyerDashboard.vue';
import BuyerTags from '@/buyer/BuyerTags.vue';
import BuyerInsights from '@/buyer/BuyerInsights.vue';
import BuyerEarnings from '@/buyer/BuyerEarnings.vue';
import BuyerBrandTags from '@/buyer/BuyerBrandTags.vue';
import { RouteLocation, RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/user',
    redirect: '/user/purchases',
    component: BuyerBase,
    meta: { requiresBuyerAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'buyer-dashboard',
        component: BuyerDashboard,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showBackToTop: false,
          showSubHeader: true
        }
      },
      {
        path: 'tags',
        name: 'buyer-tags',
        component: BuyerTags,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showBackToTop: false,
          showSubHeader: true
        }
      },
      {
        path: 'tags/:accountAffiliateId',
        name: 'buyer-brand-tags',
        component: BuyerBrandTags,
        meta: {
          showHelp: false,
          layout: 'OpenDetailLayout',
          showBackToTop: false,
          showSubHeader: true
        }
      },
      {
        path: 'insights',
        name: 'buyer-insights',
        component: BuyerInsights,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showBackToTop: false,
          showSubHeader: true
        }
      },
      {
        path: 'withdraw',
        name: 'buyer-earnings',
        component: BuyerEarnings,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showBackToTop: false,
          showSubHeader: true
        }
      },
      {
        path: 'purchases',
        name: 'buyer-product-orders',
        component: BuyerProductOrders,
        meta: {
          showHelp: true,
          layout: 'OpenLayout',
          showBackToTop: false,
          showSubHeader: true
        }
      },
      {
        path: 'purchases/:accountId/:transactionId',
        name: 'buyer-product-order',
        component: BuyerOrderDetail,
        meta: {
          layout: 'OpenDetailLayout',
          showBackToTop: false,
          showHelp: true
        }
      },
      {
        path: 'settings',
        name: 'buyer-settings',
        component: BuyerSettings,
        meta: {
          layout: 'OpenDetailLayout',
          showBackToTop: false,
          showHelp: false,
          showSubHeader: true
        }
      },
      {
        path: 'numberchange',
        name: 'buyer-number-change',
        component: BuyerUpdateNumberComponent,
        meta: {
          layout: 'OpenDetailLayout',
          showHelp: true
        }
      },
      {
        path: 'buyer-card',
        name: 'buyer-card-change',
        component: BuyerCardInformationComponent,
        props: true,
        meta: {
          layout: 'OpenDetailLayout'
        }
      },
      {
        path: 'buyer-shipping/:addressId?',
        name: 'buyer-shipping-address-change',
        component: BuyerShippingAddressComponent,
        props: true,
        meta: {
          layout: 'OpenDetailLayout'
        }
      }
    ]
  },
  {
    path: '/user/signup',
    name: 'buyer-registration',
    component: BuyerRegistrationComponent,
    meta: {
      layout: 'SingleLayout'
    }
  },
  {
    path: '/user/checkout/:isExpressOrShopify/:checkoutId',
    name: 'buyer-checkout',
    component: BuyerCheckout,
    meta: {
      layout: 'CheckoutLayout',
      showBackToTop: false
    }
  },
  {
    path: '/try/:tag/:attributionId?',
    name: 'instant-checkout-try',
    component: InstantCheckout,
    meta: {
      layout: 'CheckoutLayout',
      showBackToTop: false
    }
  },
  {
    path: '/buy-test/:tag/:attributionId?',
    name: 'product-checkout',
    component: ProductCheckout,
    meta: {
      layout: 'CheckoutLayout',
      showBackToTop: false
    }
  },
  {
    path: '/buy/:tag/:attributionId?',
    name: 'instant-checkout',
    component: InstantCheckout,
    meta: {
      layout: 'CheckoutLayout',
      showBackToTop: false
    }
  },
  {
    path: '/registration/affiliate/:slug',
    name: 'affiliate-signup',
    redirect: (to: RouteLocation) => {
      return { name: 'advocate-signup', params: { slug: to.params.slug } };
    }
  },
  {
    path: '/registration/advocate/:slug',
    name: 'advocate-signup',
    component: AffiliateSignup,
    meta: {
      layout: 'SingleLayout',
      showBackToTop: false
    }
  },
  {
    path: '/network',
    name: 'network-signup',
    component: AffiliateSignup,
    meta: {
      layout: 'SingleLayout',
      showBackToTop: false
    }
  }
] as RouteRecordRaw[];
