
import { defineComponent } from 'vue';
import { store } from '@/store';
import { auth } from '@/store/auth';
import { mapGetters, mapMutations } from 'vuex';
import AppSearch from './AppSearch.vue';
import BoostLogo from '@/common/controls/BoostLogo.vue';

export default defineComponent({
  components: {
    AppSearch,
    BoostLogo
  },
  props: {
    singleMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      hasSearchResults: false
    };
  },
  computed: {
    ...mapGetters({
      isBranded: 'global/isBranded',
      getBrandName: 'global/getBrandName',
      getBrandLogo: 'global/getBrandLogo',
      isSuperAdmin: 'auth/isSuperAdmin',
      isImpersonating: 'auth/isImpersonating',
      currentAccount: 'accounts/currentAccount',
      hasContextualButton: 'global/hasContextualButton'
    }),
    showLogo(): boolean {
      return (
        this.isBranded || (!this.isSellerLoggedIn && !this.isBuyerLoggedIn)
      );
    },
    isBuyerLoggedIn(): boolean {
      return store.getters['auth/isBuyerLoggedIn'];
    },
    isSellerLoggedIn(): boolean {
      return store.getters['auth/isSellerLoggedIn'];
    },
    showSellerNav(): boolean {
      if (this.isSuperAdmin) {
        return this.isImpersonating;
      } else {
        return this.isSellerLoggedIn;
      }
    },
    loginOrRegistrationType(): number {
      return auth.state.loginOrRegistrationType;
    },
    isCurrentPageExternalStore(): boolean {
      return this.$route.name === 'Store';
    },
    currentRouteName(): string {
      return (this.$route.name as string) || '';
    },
    currentRoutePath(): string {
      return this.$route.path || '';
    },
    hasBrandLogo(): boolean {
      return !!this.getBrandLogo;
    },
    logoImage(): string {
      return this.isBranded
        ? this.getBrandLogo
        : require('../assets/boost-logo-sm.svg');
    },
    showHelp(): boolean {
      return (this.$route.meta?.showHelp as boolean) || false;
    },
    showLogin(): boolean {
      if (this.isBuyerLoggedIn) {
        return true;
      } else {
        return this.currentRoutePath !== 'pre-registration';
      }
    },
    isTagsActive(): boolean {
      return (
        this.currentRoutePath.includes('tags') ||
        this.currentRoutePath.includes('tips') ||
        this.currentRouteName === 'seller-product-create' ||
        this.currentRouteName === 'seller-tip-create'
      );
    }
  },
  watch: {
    $route() {
      this.setMobileNavOpen(false);
      this.isOpen = false;
      this.hasSearchResults = false;
    }
  },
  methods: {
    ...mapMutations({
      setMobileNavOpen: 'global/setMobileNavOpen'
    }),
    toggleNavMenu() {
      this.isOpen = !this.isOpen;
      this.setMobileNavOpen(this.isOpen);
    },
    logout() {
      this.$store.dispatch('auth/signOut').then((success: boolean) => {
        if (success) {
          window.location.href = '/login';
        }
      });
    },
    handleSearch(val: boolean) {
      this.hasSearchResults = val;
    }
  }
});
