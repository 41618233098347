import BoostButton from '@/common/controls/BoostButton.vue';
import BoostInput from '@/common/controls/BoostInput.vue';
import BoostSelect from '@/common/controls/BoostSelect/BoostSelect.vue';
import BoostTextarea from '@/common/controls/BoostTextarea.vue';
import BoostKeywords from '@/common/controls/BoostKeywords.vue';
import BoostTable from '@/common/controls/BoostTable/BoostTable.vue';
import BoostStripeCard from '@/common/controls/BoostStripeCard.vue';
import BoostWalletPay from '@/common/controls/BoostWalletPay.vue';
import BoostControlFooter from '@/common/controls/BoostControlFooter.vue';
import BoostImageUpload from '@/common/controls/BoostImageUpload.vue';
import BoostViewOnlyImage from '@/common/controls/BoostViewOnlyImage.vue';
import BoostLoader from '@/common/controls/BoostLoader.vue';
import BoostPagination from '@/common/controls/BoostPagination.vue';
import BoostToggle from '@/common/controls/BoostToggle.vue';
import TagsToggle from '@/common/controls/TagsToggle.vue';
import BoostSectionHeader from '@/common/BoostSectionHeader.vue';
import BoostCard from '@/common/BoostCard.vue';
import BoostStepCard from '@/common/BoostStepCard.vue';
import BoostInfoCard from '@/common/BoostInfoCard.vue';
import BoostStatePicker from '@/common/controls/BoostStatePicker.vue';
import BoostIcon from '@/common/controls/BoostIcon.vue';
import TagsTextField from '@/common/controls/TagsTextField.vue';
import TagsPill from '@/common/controls/TagsPill.vue';
import TagsToast from '@/common/ToastComponent.vue';
import { Form as ValidationObserver } from 'vee-validate';
import { App } from 'vue';
import sanitize, { IOptions } from 'sanitize-html';
import { store } from '@/store';
import BoostContextMenu from '@/common/controls/BoostContextMenu.vue';
import Helpers from '@/helpers';

export function registerThirdPartyComponents(Vue: App): void {
  Vue.component('ValidationObserver', ValidationObserver);

  const FILTER_OPTIONS: IOptions = {
    allowedTags: [
      'a',
      'b',
      'br',
      'em',
      'i',
      'span',
      'strike',
      'strong',
      'u',
      'p',
      'div'
    ],
    allowedAttributes: {
      a: ['href', 'target'],
      span: ['style'],
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading']
    },
    selfClosing: ['br', 'img'],
    allowedSchemes: ['http', 'https', 'mailto'],
    parser: {
      decodeEntities: true
    }
  };
  Vue.directive('sanitized', {
    beforeMount: (el, binding) => {
      el.innerHTML = sanitize(binding.value, FILTER_OPTIONS);
    },
    updated: (el, binding) => {
      el.innerHTML = sanitize(binding.value, FILTER_OPTIONS);
    }
  });

  Vue.directive('requires', {
    beforeMount: (el, binding) => {
      if (!Helpers.userHasPermission(binding.value, store)) {
        el.style.display = 'none';
      }
    }
  });
}

export function registerCommmonComponents(Vue: App): void {
  Vue.component('BoostButton', BoostButton);
  Vue.component('BoostInput', BoostInput);
  Vue.component('BoostSelect', BoostSelect);
  Vue.component('BoostTextarea', BoostTextarea);
  Vue.component('BoostKeywords', BoostKeywords);
  Vue.component('BoostTable', BoostTable);
  Vue.component('BoostStripeCard', BoostStripeCard);
  Vue.component('BoostWalletPay', BoostWalletPay);
  Vue.component('BoostControlFooter', BoostControlFooter);
  Vue.component('BoostImageUpload', BoostImageUpload);
  Vue.component('BoostViewOnlyImage', BoostViewOnlyImage);
  Vue.component('BoostLoader', BoostLoader);
  Vue.component('BoostPagination', BoostPagination);
  Vue.component('BoostToggle', BoostToggle);
  Vue.component('BoostContextMenu', BoostContextMenu);
  Vue.component('BoostSectionHeader', BoostSectionHeader);
  Vue.component('BoostInfoCard', BoostInfoCard);
  Vue.component('BoostCard', BoostCard);
  Vue.component('BoostStepCard', BoostStepCard);
  Vue.component('BoostStatePicker', BoostStatePicker);
  Vue.component('BoostIcon', BoostIcon);
  Vue.component('TagsTextField', TagsTextField);
  Vue.component('TagsPill', TagsPill);
  Vue.component('TagsToggle', TagsToggle);
  Vue.component('TagsToast', TagsToast);
}
