
import { defineComponent } from 'vue';
import { TagType } from '@/../enums/tagType';
import { ExpressCheckout } from '@/models/expressCheckout';
import settings from '@/settings';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes,
  ProductReplyCodes
} from '@/firebaseRequest';
import { store } from '@/store';
import { BrandInformation } from '@/store/types';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import Helpers from '@/helpers';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import InstantCheckoutProduct from '@/buyer/checkout/InstantCheckoutProduct.vue';
import InstantCheckoutProductImage from '@/buyer/checkout/InstantCheckoutProductImage.vue';
import CheckoutPriceSummary from '@/buyer/checkout/CheckoutPriceSummary.vue';
import UnavailableProductComponent from '@/buyer/checkout/UnavailableProductComponent.vue';
import MetaPixelScript from '@/common/MetaPixelScript.vue';
import {
  loadStripe,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestShippingAddressEvent,
  PaymentRequestShippingOptionEvent,
  Stripe,
  StripeCardElement
} from '@stripe/stripe-js';
import { Charges } from '@/models/charges';
import { TagDetail, TagSku } from '@/models/tag';
import { mapGetters } from 'vuex';
import { ShippingRate } from '@/models/shipping';
import { ProductSource } from '@/../models/sellerOrder';
import {
  Buyer,
  PaymentOption,
  ShippingAddress
} from '../settings/BuyerSettingsInterfaces';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import BoostIcon from '@/common/controls/BoostIcon.vue';
import StripeCreditCardSummaryComponent from '../../common/info/StripeCreditCardSummaryComponent.vue';
import StripeCreditCardDetailsComponent from '../../common/info/StripeCreditCardDetailsComponent.vue';
import AddressSummaryComponent from '../../common/info/AddressSummaryComponent.vue';
import AddressDetailComponent from '../../common/info/AddressDetailComponent.vue';
import UpdatePaymentOptions from './UpdatePaymentOptions.vue';
import UpdateShippingOptions from './UpdateShippingOptions.vue';
import AffiliateOnCheckout from './AffiliateOnCheckout.vue';
import CheckoutDetailHeader from './CheckoutDetailHeader.vue';
import helpers from '@/helpers';
import CheckoutDetailDivider from './CheckoutDetailDivider.vue';

interface GetProductInfoResponse {
  product: TagDetail;
  charges: Charges;
  brandInfo: BrandInformation;
  refundPolicy: string;
  actionId: string;
  metaPixel?: string;
  shopifyStoreUrl?: string;
  enableShareEarn?: boolean;
  enableSaveForLater?: boolean;
  enableMarketplace?: boolean;
  globalCommission?: number;
  affiliateInfo?: { firstName: string; commission: number };
  userIsInBrandNetwork?: boolean;
  stripe: {
    clientSecret: string;
    paymentId: string;
    customerId: string;
  };
}

interface PurchaseResponse {
  message: string;
  transactionId: string;
  userTransactionId: string;
  accountId: string;
  buyerHasAffiliateTag: boolean;
  userIsInBrandNetwork?: boolean;
}

enum InstantCheckoutStatus {
  DisplayProduct,
  ConfirmMobileNumber,
  CollectUserDetails,
  CompletedPurchase,
  ShowPolicy,
  UpdatePaymentOptions,
  UpdateShippingOptions,
  ProductUnavailable,
  AffiliateSignup
}
export default defineComponent({
  components: {
    InstantCheckoutProduct,
    InstantCheckoutProductImage,
    CheckoutPriceSummary,
    MetaPixelScript,
    'boost-icon': BoostIcon,
    StripeCreditCardSummaryComponent,
    StripeCreditCardDetailsComponent,
    AddressSummaryComponent,
    AddressDetailComponent,
    UpdatePaymentOptions,
    UpdateShippingOptions,
    UnavailableProductComponent,
    CheckoutDetailHeader,
    AffiliateOnCheckout,
    CheckoutDetailDivider
  },
  data() {
    return {
      InstantCheckoutStatus,
      TagType,
      status: InstantCheckoutStatus.DisplayProduct as InstantCheckoutStatus,
      showPolicy: false,
      previousStatus:
        InstantCheckoutStatus.DisplayProduct as InstantCheckoutStatus,
      isNewUser: true,
      skuId: '',
      product: {} as TagDetail | undefined,
      charges: {
        price: 0,
        shipping: 0,
        tax: 0,
        total: 0
      } as Charges,
      checkout: {} as ExpressCheckout,
      shipping: {
        firstName: '',
        lastName: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States',
        isDefault: false
      },
      initialStripeIds: {
        clientSecret: undefined as string | undefined,
        paymentId: undefined as string | undefined,
        customerId: undefined as string | undefined
      },
      stripeClientSecret: '',
      stripeLoaded: false,
      stripeCardElement: null as null | StripeCardElement,
      stripe: null as null | Stripe,
      cardError: '' as string | undefined,
      showWalletPay: false,
      shippingAmount: 0,
      chargeAmount: 0,
      isTransactionCompleted: false,
      isShippingCalculated: false,
      hasSentConfirmationCode: false,
      mobileNumber: '',
      getCodeState: ButtonLoadingState.initial as ButtonLoadingState,
      completeButtonState: ButtonLoadingState.initial as ButtonLoadingState,
      confirmationCode: '',
      taxesUpdated: false,
      taxesCalculated: false,
      refundPolicy: '',
      isWaiting: false,
      sku: undefined as TagSku | undefined,
      selectedShippingOptionId: 'default',
      shippingOptions: [
        {
          id: 'default',
          label: 'Awaiting Zip Code',
          detail: 'Awaiting Zip Code',
          amount: 0
        }
      ] as ShippingRate[],
      actionId: '',
      metaPixel: '',
      orderEmail: '',
      customColor: 'default',
      updatePaymentOptions: false,
      updateShippingOptions: false,
      selectedShippingAddress: {} as ShippingAddress | undefined,
      selectedPaymentOption: {} as PaymentOption | undefined,
      shopifyStoreUrl: undefined as string | undefined,
      receipt: '',
      affiliateInfo: {
        isAffiliateTag: false,
        firstName: '',
        commission: 0
      },
      buyerHasAffiliateTag: false,
      buyerIsInNetwork: false,
      signInToken: '',
      brandShareEnabled: false,
      globalCommission: ''
    };
  },
  computed: {
    ...mapGetters({
      isBuyerLoggedIn: 'auth/isBuyerLoggedIn',
      currentUser: 'auth/getCurrentUser',
      currentBuyer: 'auth/getCurrentBuyer',
      getBrandName: 'global/getBrandName',
      bannerError: 'global/getBannerStatus'
    }),
    showAffiliateSection(): boolean {
      if (!this.affiliateInfo || !this.product || !this.brandShareEnabled) {
        return false;
      }
      return (
        this.affiliateInfo.isAffiliateTag ||
        this.product?.shopifyShareEarn ||
        false
      );
    },
    affiliateSectionHeader(): string {
      // If brandShareEnabled === false, this won't even be shown
      if (!this.brandShareEnabled) return '';

      // When ANY user buys a product from an affiliate.
      if (this.affiliateInfo?.isAffiliateTag) {
        return `${this.affiliateInfo.firstName} earned ${this.affiliateInfo.commission}% from this order!`;
      }

      // When an affiliate buys a product directly from a Brand whose network they are a part of.
      if (this.buyerIsInNetwork) {
        return 'Thanks for your purchase!';
      }

      // A user/buyer is not in the brand's network.
      return 'Join our Network!';
    },
    checkoutButtonText(): string {
      return 'Proceed to Checkout';
    },
    buyButtonText(): string {
      return this.isBuyerLoggedIn
        ? `Pay ${this.charges ? this.getPrice(this.charges.total) : ''}`
        : 'Manual Checkout';
    },
    buyButtonLoadingText(): string {
      return this.isBuyerLoggedIn ? 'Confirming purchase...' : 'Thinking...';
    },
    showShippingOptions(): boolean {
      return (
        this.isShopifyProduct &&
        this.shippingOptions?.length > 0 &&
        !this.shippingOptions.some((o) => o.label === 'Awaiting Zip Code')
      );
    },
    isShopifyProduct(): boolean {
      return this.product?.productSource === ProductSource.Shopify;
    },
    isMobile(): boolean {
      return helpers.isMobile();
    },
    showProductImage(): boolean {
      if (
        this.status === InstantCheckoutStatus.ShowPolicy ||
        ((this.status === InstantCheckoutStatus.CompletedPurchase ||
          this.status === InstantCheckoutStatus.AffiliateSignup) &&
          helpers.isMobile())
      ) {
        return false;
      }
      if (this.status === InstantCheckoutStatus.ConfirmMobileNumber) {
        return !helpers.isMobile();
      }
      if (
        helpers.isMobile() &&
        (this.updatePaymentOptions || this.updateShippingOptions)
      ) {
        return false;
      }
      return true;
    },
    isReturnPolicyLink(): boolean {
      return this.refundPolicy.startsWith('http');
    },
    skuOptionsString(): string {
      const sku = this.sku;
      if (sku && this.product?.variants?.length) {
        return this.product.variants
          .map((v) => {
            const t = sku[v.name]?.toString();
            const x = v.options.find((o) => o.name === t);
            return x?.label || '';
          })
          .join(',');
      }
      return '';
    }
  },
  watch: {
    async skuId() {
      this.isWaiting = true;
      // If the sku id changes, we have to update taxes and get updated shipping rates
      await this.onPostalCodeChange();
      await this.getShippingRates();
      this.isWaiting = false;
    }
  },
  async mounted() {
    await this.getProductData();
    await this.initializeStripe();
  },
  methods: {
    openLink(url: string) {
      window.open(url, '_blank');
    },
    updatePaymentOption() {
      this.updatePaymentOptions = true;
      this.scrollToTop();
    },
    updateShippingOption() {
      this.updateShippingOptions = true;
      this.scrollToTop();
    },
    parsePriceString(price: string): number {
      let updatedPrice = parseFloat(price.replace(/^\$/, '')) * 100;
      updatedPrice = parseInt(updatedPrice.toFixed(0));
      return updatedPrice;
    },
    handleReadyToBuy(selectedSku: TagSku) {
      if (selectedSku?.id) {
        this.sku = selectedSku;
        this.skuId = selectedSku.id;
        this.charges.price = this.parsePriceString(
          selectedSku.price.toString()
        );
        this.charges.total =
          this.charges.price + this.charges.shipping + this.charges.tax;
        this.chargeAmount = this.charges.total;
      } else {
        this.sku = undefined;
        this.skuId = '';
      }
    },
    async initializeStripe() {
      // Instantiate stripe instance to be used for card and payment processing
      this.stripe = await loadStripe(settings.STRIPE_PUBLISHABLE_KEY);
    },
    initWalletPayment() {
      if (this.stripe) {
        this.shippingAmount = this.charges ? this.charges.shipping : 0;
        this.chargeAmount = this.charges ? this.charges.total : 0;

        this.showWalletPay = true;
      }
    },
    async getProductData() {
      store.commit('global/setLoading', true);
      this.skuId = this.$route.params.sku as string;
      try {
        const results =
          await FirebaseRequest.createRequest<GetProductInfoResponse>(
            FirebaseRequestTypes.GET_PRODUCT_INFO,
            {
              hashtag: this.$route.params.tag,
              sku: this.skuId,
              qr: this.$route.query.qr,
              attributionId: this.$route.params.attributionId,
              sms: this.$route.query.sms,
              sfl: this.$route.query.sfl
            }
          );

        if (results.status === APIResponseStatus.ERROR) {
          // If the product is inactive, set-up the data needed to display the
          // inactive product view and then return out of this method.
          if (
            results.code === ProductReplyCodes.INACTIVE ||
            results.code === ProductReplyCodes.NO_INVENTORY
          ) {
            this.setupInactiveProductData(results.data);
            store.commit('global/setLoading', false);
            return;
          }

          const message = this.getBannerMessageForProductReplyCode(
            results.code
          );
          this.metaPixel = results.data?.metaPixel || '';
          store.commit('global/setBannerStatus', {
            text: message,
            type: BannerType.error
          });
        } else {
          this.product = results.data?.product;
          this.charges = results.data?.charges || ({} as Charges);
          this.refundPolicy = results.data?.refundPolicy || '';
          this.actionId = results.data?.actionId || '';
          this.metaPixel = results.data?.brandInfo.metaPixel || '';
          this.customColor = results.data?.brandInfo.customColor || 'default';
          this.orderEmail = results.data?.brandInfo.orderEmail || '';
          this.shopifyStoreUrl = results.data?.shopifyStoreUrl || '';
          this.brandShareEnabled = results.data?.enableShareEarn || false;
          this.globalCommission = (
            results.data?.globalCommission || ''
          ).toString();
          if (results.data?.affiliateInfo) {
            this.affiliateInfo = {
              ...results.data.affiliateInfo,
              isAffiliateTag: true
            };
          }
          this.buyerIsInNetwork = results.data?.userIsInBrandNetwork || false;
          this.initialStripeIds = {
            clientSecret: results.data?.stripe.clientSecret,
            paymentId: results.data?.stripe.paymentId,
            customerId: results.data?.stripe.customerId
          };
          this.stripeClientSecret = results.data?.stripe.clientSecret ?? '';

          if (this.product?.skus && this.skuId) {
            this.sku = this.product.skus.find((x) => x.id === this.skuId);
          }
          if (!this.sku && this.product?.skus.length === 1) {
            this.sku = this.product.skus[0];
            this.skuId = this.sku.id;
            this.charges.price = this.parsePriceString(
              this.sku.price.toString()
            );
            this.charges.total =
              this.charges.price + this.charges.shipping + this.charges.tax;
            this.chargeAmount = this.charges.total;
          }
          store.commit('global/setBrandInformation', results.data?.brandInfo);

          await this.setDefaultShippingAndPaymentOptions();
        }
      } catch (ex) {
        console.log(ex);
      }
      store.commit('global/setLoading', false);
    },
    setupInactiveProductData(productInfoData?: GetProductInfoResponse) {
      if (!productInfoData) {
        return;
      }
      this.product = productInfoData.product;
      this.metaPixel = productInfoData.brandInfo.metaPixel || '';
      this.customColor = productInfoData.brandInfo.customColor || 'default';
      store.commit('global/setBrandInformation', productInfoData.brandInfo);

      this.status = InstantCheckoutStatus.ProductUnavailable;
      this.shopifyStoreUrl = productInfoData.shopifyStoreUrl;
    },
    getBannerMessageForProductReplyCode(replyCode: string): string {
      let message = BannerMessageStrings.GENERIC_ERROR_MESSAGE;
      if (replyCode === ProductReplyCodes.NO_INVENTORY) {
        message = BannerMessageStrings.BUYER_NO_INVENTORY;
      } else if (replyCode === ProductReplyCodes.SKU_NOT_FOUND) {
        message = BannerMessageStrings.BUYER_PRODUCT_NOT_FOUND;
      } else if (replyCode === ProductReplyCodes.INACTIVE) {
        message = BannerMessageStrings.BUYER_PRODUCT_INACTIVE;
      }

      return message;
    },
    async setDefaultShippingAndPaymentOptions() {
      // TODO: Figure out what to do if not logged in?
      if (this.isBuyerLoggedIn && this.currentBuyer) {
        const defaultShippingAddress = Helpers.getDefaultShippingAddress(
          this.currentBuyer
        ) as ShippingAddress;
        await this.setShippingAddress(defaultShippingAddress);

        // Get the default/Selected PaymentOption
        this.selectedPaymentOption = Helpers.getDefaultPaymentOption(
          this.currentBuyer
        );
      }
    },
    onPaymentOptionUpdated(paymentOption: PaymentOption) {
      this.selectedPaymentOption = paymentOption;
      this.updatePaymentOptions = false;
    },
    onShippingAddressUpdated(shippingAddress: ShippingAddress) {
      this.setShippingAddress(shippingAddress);
      this.updateShippingOptions = false;
    },
    async setShippingAddress(shippingAddress: ShippingAddress) {
      this.selectedShippingAddress = shippingAddress;

      this.shipping.postalCode = shippingAddress?.postalCode ?? '';
      this.shipping.state = shippingAddress?.state ?? '';
      this.updateTaxes();
      await this.getShippingRates();
    },
    async onShippingOptionChange(ev: PaymentRequestShippingOptionEvent) {
      if (ev.shippingOption) {
        this.selectedShippingOptionId = ev.shippingOption.id;
        this.processShippingRate();
        ev.updateWith({
          status: 'success',
          total: {
            label: 'Checkout total',
            amount: this.charges.total
          }
        });
      }
    },
    // Wallet pay shipping address change
    async onShippingAddressChange(ev: PaymentRequestShippingAddressEvent) {
      // Perform server-side request to fetch shipping options
      // CLIENT -> API(id, skuId, ev.shippingAddress.postalCode)
      // API -> charges
      try {
        if (ev.shippingAddress) {
          this.shipping.postalCode = ev.shippingAddress.postalCode || '';
          this.shipping.state = ev.shippingAddress.region || '';
          await this.getShippingRates();

          const payload = {
            hashtag: this.$route.params.tag,
            sku: this.skuId,
            postalCode: ev.shippingAddress.postalCode
          };
          this.isWaiting = true;

          const chargesUpdate = await FirebaseRequest.createRequest<{
            charges: Charges;
          }>(FirebaseRequestTypes.GET_PRODUCT_TAXES, payload);

          if (chargesUpdate) {
            this.charges = chargesUpdate.data?.charges || ({} as Charges);
            this.taxesCalculated = true;

            const shippingRates: ShippingRate[] | undefined = this
              .shippingOptions?.length
              ? this.shippingOptions
              : [
                  {
                    id: 'Default-shipping',
                    label: 'Default Shipping',
                    detail: '',
                    amount: this.charges.shipping
                  }
                ];

            this.selectedShippingOptionId = shippingRates[0].id;
            this.shippingAmount = shippingRates[0].amount;
            this.charges.shipping = this.shippingAmount;
            this.charges.total =
              this.charges.price + this.charges.tax + shippingRates[0].amount;
            // Update wallet pay total
            ev.updateWith({
              status: 'success',
              shippingOptions: shippingRates,
              total: {
                label: 'Checkout total',
                amount: this.charges.total
              }
            });
            this.isWaiting = false;
          }
        } else {
          console.log('No address information');
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }
      } catch (err) {
        console.log(err);
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    },
    onWalletPaymentSuccess(ev: PaymentRequestPaymentMethodEvent) {
      this.completeButtonState = ButtonLoadingState.loading;
      // set up shipping data
      const shippingAddress = ev.shippingAddress;
      if (shippingAddress) {
        this.shipping.address1 = shippingAddress.addressLine
          ? shippingAddress.addressLine[0]
          : '';
        this.shipping.address2 =
          shippingAddress.addressLine && shippingAddress.addressLine.length > 1
            ? shippingAddress.addressLine[1]
            : '';
        this.shipping.city = shippingAddress.city || '';
        this.shipping.state = shippingAddress.region || '';
        this.shipping.postalCode = shippingAddress.postalCode || '';
        this.shipping.country = shippingAddress.country || '';
      }
      this.shipping.email = ev.payerEmail || '';
      // TODO: determine how to parse the name
      this.shipping.lastName = ev.payerName || '';

      this.onPaymentSuccess();
    },
    onHasWallet(ev: boolean) {
      this.showWalletPay = ev;
    },
    handlePurchase() {
      if (this.completeButtonState !== ButtonLoadingState.loading) {
        // Manually trigger an empty card error
        if (!this.stripeCardElement) {
          this.cardError = 'Please enter your card details.';
          return;
        }

        this.completeButtonState = ButtonLoadingState.loading;

        // 1. API create setup Intent, pass clientSecret
        // 2. handlePayment, call confirmCardSetup pass secret and card
        // 3. Stripe processes payment setup
        // 4. on success, client sends rest of data payload to API (shipping address)
        // 5. Display UI confirmation to User

        if (this.stripe && this.stripeCardElement) {
          // 1. setup intent capture
          this.stripe
            .confirmCardSetup(this.stripeClientSecret, {
              // eslint-disable-next-line
              payment_method: {
                card: this.stripeCardElement
              }
            })
            // eslint-disable-next-line
            // @ts-ignore
            .then((result) => {
              if (result.error) {
                this.completeButtonState = ButtonLoadingState.complete;
                // Inform the customer that there was an error
                store.commit('global/setBannerStatus', {
                  text: BannerMessageStrings.BUYER_PURCHASE_ERROR,
                  type: BannerType.error
                });
                this.cardError = result.error.message;
              } else {
                // API needs to listen to webhook payment_intent.succeeded
                // Call new API endpoint and pass data
                // 4. on success, client sends rest of data payload to API (shipping address)
                // 5. Display UI confirmation to User
                this.onPaymentSuccess();
              }
            });
        }
      }
    },
    async onPaymentSuccess() {
      this.$store.commit('global/setBannerStatus', null);

      store.commit('global/setLoading', {
        isLoading: true,
        message: 'Confirming purchase...'
      });
      const shippingRate = this.shippingOptions.find((option) => {
        return option.id === this.selectedShippingOptionId;
      });

      // We need to remove the firstName, lastName, & email from the
      // MailingAddress object. Those properties belong on the User/Buyer.
      const { firstName, lastName, email, ...mailingAddress } = this.shipping;
      const payload = {
        checkoutId: this.checkout.checkoutId,
        emailAddress: email,
        mailingAddress: mailingAddress,
        firstName,
        lastName,
        shippingRate,
        actionId: this.actionId
      };

      this.isTransactionCompleted = true;

      const results = await FirebaseRequest.createRequest<PurchaseResponse>(
        FirebaseRequestTypes.PROCESS_CHECKOUT,
        payload
      );

      this.completeButtonState = ButtonLoadingState.complete;
      store.commit('global/setLoading', false);

      if (results.status === APIResponseStatus.ERROR) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      } else {
        if (this.signInToken) {
          await this.signIn(this.signInToken, true);
        }
        this.status = InstantCheckoutStatus.CompletedPurchase;
        this.receipt = results.data?.userTransactionId || '';
        this.buyerHasAffiliateTag = results.data?.buyerHasAffiliateTag || false;
        this.buyerIsInNetwork = results.data?.userIsInBrandNetwork || false;
      }
      this.scrollToTop();
    },
    getPrice(price: number) {
      return Helpers.currencyFormatter(price);
    },
    async showConfirmBuyer() {
      if (this.isBuyerLoggedIn && this.currentUser) {
        const userId = this.currentUser.uid;
        this.completeButtonState = ButtonLoadingState.loading;
        await this.handleBuyerPurchase(userId);
        this.completeButtonState = ButtonLoadingState.complete;
      } else {
        this.completeButtonState = ButtonLoadingState.loading;
        //Call the beginInstantCheckout method to update the user action record
        await FirebaseRequest.createRequest(
          FirebaseRequestTypes.BEGIN_INSTANT_CHECKOUT,
          { actionId: this.actionId }
        );
        this.completeButtonState = ButtonLoadingState.complete;
        this.status = InstantCheckoutStatus.ConfirmMobileNumber;
        this.hasSentConfirmationCode = false;
      }

      this.scrollToTop();
    },
    async handleConfirmationCode() {
      if (this.hasSentConfirmationCode) {
        await this.confirmBuyer();
      } else {
        await this.requestConfirmationCode();
      }
    },
    async requestConfirmationCode() {
      if (this.getCodeState === ButtonLoadingState.loading) {
        return;
      }

      try {
        this.getCodeState = ButtonLoadingState.loading;

        // Before logging in, if this user is logged in as a seller,
        // log them out.
        await store.dispatch('auth/signOut');

        const payload = {
          number: Helpers.prefixPhoneNumber(this.mobileNumber),
          context: 'PREREGISTER_USER',
          actionId: this.actionId
        };

        const confirmationCodeResponse = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.REQUEST_CONFIRMATION_CODE,
          payload
        );

        if (confirmationCodeResponse) {
          this.isNewUser =
            confirmationCodeResponse.code === 'PREREGISTER_NEW_USER';
          this.hasSentConfirmationCode = true;
        }
      } catch (ex) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.hasSentConfirmationCode = false;
      }

      this.getCodeState = ButtonLoadingState.complete;
    },
    async confirmBuyer() {
      if (this.getCodeState === ButtonLoadingState.loading) {
        return;
      }

      this.getCodeState = ButtonLoadingState.loading;

      const payload = {
        number: Helpers.prefixPhoneNumber(this.mobileNumber),
        code: this.confirmationCode,
        context: 'PREREGISTER_USER',
        actionId: this.actionId
      };
      const confirmationCodeResponse = await FirebaseRequest.createRequest<{
        userId: string;
        token: string;
      }>(FirebaseRequestTypes.VALIDATE_CONFIRMATION_CODE, payload);

      if (confirmationCodeResponse?.code !== 'OK') {
        console.error('Error: ', confirmationCodeResponse);
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.BUYER_INVALID_CODE,
          type: BannerType.error
        });
        this.getCodeState = ButtonLoadingState.complete;
        return;
      }

      if (this.isNewUser) {
        this.signInToken = confirmationCodeResponse.data?.token || '';
        await this.getInstantCheckoutForNewUser(
          confirmationCodeResponse.data?.userId || ''
        );
      } else {
        await this.signIn(confirmationCodeResponse.data?.token || '');
      }

      this.getCodeState = ButtonLoadingState.complete;
    },
    async getInstantCheckoutForNewUser(userId: string) {
      const instantCheckoutResponse = await FirebaseRequest.createRequest<{
        clientSecret: string;
        checkoutData: ExpressCheckout;
      }>(FirebaseRequestTypes.GET_INSTANT_CHECKOUT, {
        hashtag: this.$route.params.tag,
        skuId: this.skuId,
        userId: userId,
        attributionId: this.$route.params.attributionId,
        actionId: this.actionId,
        potentialPayment: {
          customerId: this.initialStripeIds.customerId,
          paymentId: this.initialStripeIds.paymentId
        },
        stripeClientSecret: this.initialStripeIds.clientSecret
      });
      if (instantCheckoutResponse?.status === APIResponseStatus.OK) {
        this.stripeClientSecret =
          instantCheckoutResponse.data?.clientSecret || '';
        this.checkout =
          instantCheckoutResponse.data?.checkoutData || ({} as ExpressCheckout);
        if (this.checkout.charges) {
          this.charges = this.checkout.charges;
        }
        this.status = InstantCheckoutStatus.CollectUserDetails;
        this.initWalletPayment();
        this.scrollToTop();
      } else {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    },
    async signIn(token: string, signInOnly?: boolean) {
      const auth = getAuth();
      const response = await signInWithCustomToken(auth, token);
      if (!(response && response.user && response.user.uid)) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.BUYER_PHONE_NUMBER_LOGIN_ERROR,
          type: BannerType.error
        });
        return;
      }

      store.commit('auth/setCurrentUser', auth.currentUser);

      const buyerResponse = await FirebaseRequest.createRequest<Buyer>(
        FirebaseRequestTypes.GET_BUYER_INFO,
        {}
      );
      if (buyerResponse.status === APIResponseStatus.OK && buyerResponse.data) {
        store.commit('auth/setCurrentBuyer', buyerResponse.data);

        // Once the new user is logged in, make sure to set their default payment
        // and shipping options. Then, display the product.
        // TODO: there seems to be an issue with Shopify SKU products. The selected
        // SKU Option seems to be reset.
        await this.setDefaultShippingAndPaymentOptions();
        if (!signInOnly) {
          this.status = InstantCheckoutStatus.DisplayProduct;
        }
      }
    },
    async handleBuyerPurchase(userId: string) {
      store.commit('global/setLoading', {
        isLoading: true,
        message: 'Confirming purchase...'
      });
      // Handle an existing buyer as a direct purchase without prompting for user details
      const shippingRate = this.shippingOptions.find((option) => {
        return option.id === this.selectedShippingOptionId;
      });

      const results = await FirebaseRequest.createRequest<PurchaseResponse>(
        FirebaseRequestTypes.BUY_INSTANT_CHECKOUT,
        {
          hashtag: this.$route.params.tag,
          skuId: this.skuId,
          userId: userId,
          attributionId: this.$route.params.attributionId,
          shippingRate,
          actionId: this.actionId,
          shipToAddressId: this.selectedShippingAddress?.id,
          selectedPaymentOptionId: this.selectedPaymentOption?.id
        }
      );
      if (results.status === APIResponseStatus.OK) {
        this.status = InstantCheckoutStatus.CompletedPurchase;
        this.receipt = results.data?.userTransactionId || '';
        this.buyerHasAffiliateTag = results.data?.buyerHasAffiliateTag || false;
        this.buyerIsInNetwork = results.data?.userIsInBrandNetwork || false;
        this.scrollToTop();
      } else {
        if (results.code === ProductReplyCodes.NO_INVENTORY) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_NO_INVENTORY,
            type: BannerType.error
          });
        } else if (results.code === ProductReplyCodes.PURCHASE_OWN_TAG) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYUER_PURCHASE_OWN_TAG,
            type: BannerType.error
          });
        } else {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_PURCHASE_ERROR,
            type: BannerType.error
          });
        }
      }
      store.commit('global/setLoading', false);
    },
    showReturnPolicy() {
      this.showPolicy = true;
      this.scrollToTop();
    },
    hideReturnPolicy() {
      this.showPolicy = false;
      this.scrollToTop();
    },
    viewAccount() {
      store.commit('global/setBrandInformation', null);
      this.$router.push({ path: '/login', query: { type: '1' } });
    },
    collectCard(card: StripeCardElement) {
      this.stripeCardElement = card;
      this.cardError = '';
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async updateTaxes() {
      if (!this.selectedShippingAddress) {
        return;
      }

      await this.getProductTaxes();
    },
    async onPostalCodeChange() {
      if (
        this.shipping?.postalCode?.length < 5 ||
        this.shipping?.state?.length < 2
      ) {
        return;
      }

      await this.getProductTaxes();
    },
    async getProductTaxes() {
      // TODO: It's not really clear why this method is being called when there is
      // no skuId. It stems from InstantCheckoutProduct emitting the readyToBuy event
      // with no sku, even though it has a product.
      // But this seems to fix the issue mentioned in Linear issue CORE-308.
      if (!this.skuId) {
        return;
      }

      store.commit('global/setBannerStatus', null);

      const payload = {
        hashtag: this.$route.params.tag,
        sku: this.skuId,
        postalCode: this.shipping.postalCode
      };

      this.isWaiting = true;
      const chargesUpdate = await FirebaseRequest.createRequest<{
        charges: Charges;
      }>(FirebaseRequestTypes.GET_PRODUCT_TAXES, payload);

      if (chargesUpdate.status === APIResponseStatus.OK) {
        this.charges = chargesUpdate.data?.charges || ({} as Charges);
        this.taxesUpdated = true;
        this.taxesCalculated = true;
        await this.getShippingRates();
        this.isWaiting = false;
        setTimeout(() => {
          this.taxesUpdated = false;
        }, 5000);
      } else {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.BUYER_PURCHASE_INVALID_ADDRESS,
          type: BannerType.error
        });
      }
    },
    async getShippingRates() {
      if (
        this.product?.productSource === ProductSource.Shopify &&
        this.product?.accountId &&
        this.skuId &&
        this.shipping?.postalCode?.length >= 5 &&
        this.shipping?.state?.length > 1
      ) {
        const payload = {
          accountId: this.product.accountId,
          productId: this.product.productId,
          postalCode: this.shipping.postalCode,
          stateNameOrCode: this.shipping.state,
          skuId: this.skuId
        };

        const shippingRates = await FirebaseRequest.createRequest<
          ShippingRate[]
        >(FirebaseRequestTypes.GET_ECOMMERCE_SHIPPING_RATES, payload);

        if (shippingRates.status === APIResponseStatus.OK) {
          this.isShippingCalculated = true;
          if (shippingRates.data) {
            this.shippingOptions = shippingRates.data;
            this.selectedShippingOptionId = this.shippingOptions[0]?.id;
            this.processShippingRate();
          }
        }
      } else if (
        this.product?.productSource === ProductSource.Boost &&
        this.product?.accountId &&
        this.skuId
      ) {
        if (this.charges.shipping > 0) {
          this.shippingOptions = [
            {
              id: 'Default-shipping',
              label: 'Default Shipping',
              detail: '',
              amount: this.charges.shipping
            }
          ];
        } else {
          this.shippingOptions = [
            {
              id: 'Default-shipping',
              label: 'Free',
              detail: '',
              amount: this.charges.shipping
            }
          ];
        }
      }
    },
    processShippingRate() {
      // Update the total
      const shippingOption = this.shippingOptions.find((option) => {
        return option.id === this.selectedShippingOptionId;
      });

      if (shippingOption) {
        this.shippingAmount = shippingOption.amount;
      }

      // Recalculate totals
      if (this.charges) {
        this.charges.shipping = this.shippingAmount;
        this.charges.total =
          this.charges.price + this.charges.shipping + this.charges.tax;
      }
    }
  }
});
