
import { defineComponent } from 'vue';
import { PurchaseOrder } from '@/../models/purchaseOrder';
import { ShippingStatus } from '@/../enums/shippingStatus';
import Helpers from '@/helpers';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { store } from '@/store';
import { mapMutations } from 'vuex';
import { TagType } from '@/../enums/tagType';
import { TransactionSearch } from '@/seller/transactions/TransactionsList.vue';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';

export default defineComponent({
  name: 'BuyerProductOrders',
  data() {
    return {
      TagType,
      ShippingStatus,
      searchObject: {
        filterValue: 'All',
        sortBy: 'Date',
        searchValue: ''
      } as TransactionSearch,
      transactionCount: 0,
      filterOptions: [
        { name: 'All', value: 'All' },
        { name: 'Products', value: 'Products' },
        { name: 'Digital', value: 'Digital' }
      ],
      sortOptions: [
        { name: 'Most Recent', value: 'Date' },
        { name: 'Least Recent', value: 'DateAsc' },
        { name: 'Least Expensive', value: 'Amount' },
        { name: 'Most Expensive', value: 'AmountAsc' },
        { name: 'Name', value: 'Name' }
      ],
      orders: [] as PurchaseOrder[],
      columns: [
        {
          id: 'imageUrl',
          label: ''
        },
        {
          id: 'productName',
          label: 'Name'
        },
        {
          id: 'productHashtag',
          label: 'Tag'
        },
        {
          id: 'total',
          label: 'Amount'
        },
        {
          id: 'orderDate',
          label: 'Date'
        },
        {
          id: 'badges',
          label: 'Status'
        }
      ] as ColumnItem[]
    };
  },
  computed: {
    computedTransactions(): PurchaseOrder[] {
      const list = this.orders
        .filter(this.filerPurchases)
        .sort(this.sortPurchases);
      this.setPurchasesIds(
        list.map((x) => `${x.accountId}/${x.transactionId}`)
      );
      return list;
    }
  },
  async mounted() {
    await this.getOrders();
  },
  methods: {
    ...mapMutations('global', ['setLoading']),
    ...mapMutations('purchases', ['setPurchasesIds']),
    filerPurchases(po: PurchaseOrder): boolean {
      let results = true;
      switch (this.searchObject.filterValue) {
        case 'Products':
          results = po.type === TagType.PRODUCT;
          break;
        case 'Digital':
          results = po.type === TagType.TIP;
          break;
      }
      return results;
    },
    sortPurchases(a: PurchaseOrder, b: PurchaseOrder): number {
      let results = 0;

      switch (this.searchObject.sortBy) {
        case 'Name':
          results = a.productName.localeCompare(b.productName);
          break;
        case 'Date':
          results = b.orderDate - a.orderDate;
          break;
        case 'DateAsc':
          results = a.orderDate - b.orderDate;
          break;
        case 'AmountAsc':
          results =
            parseFloat(b.total.replace('$', '')) -
            parseFloat(a.total.replace('$', ''));
          break;
        case 'Amount':
          results =
            parseFloat(a.total.replace('$', '')) -
            parseFloat(b.total.replace('$', ''));
          break;
      }

      return results;
    },
    processDate(date: number) {
      return Helpers.processDate(date);
    },
    getShippingStatusLabel(status: ShippingStatus) {
      return Helpers.getShippingStatusLabel(status);
    },
    async getOrders() {
      if (store.state.auth.currentUser) {
        this.setLoading(true);
        const result = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.GET_BUYER_PURCHASES,
          { userId: store.state.auth.currentUser.uid }
        );
        if (result.status === APIResponseStatus.OK) {
          this.orders = (result.data as PurchaseOrder[]).sort((a, b) => {
            return b.orderDate - a.orderDate;
          });
        } else {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }
        this.setLoading(false);
      }
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    },
    viewOrder(transactionId: string) {
      const order = this.orders.find((order) => {
        return order.transactionId === transactionId;
      });
      if (order) {
        this.$router.push({
          path: `/user/purchases/${order.accountId}/${transactionId}`
        });
      }
    }
  }
});
