
import { defineComponent } from 'vue';
import {
  APIResponseCode,
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import Helpers from '@/helpers';
import { store } from '@/store';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';

export default defineComponent({
  name: 'LoginBuyer',
  data() {
    return {
      loginType: {
        MOBILE: 1,
        EMAIL: 2
      },
      loginContext: {
        LOGIN_EMAIL: 'LOGIN_EMAIL',
        LOGIN_MOBILE_NUMBER: 'LOGIN_MOBILE_NUMBER'
      },
      currentLoginType: 1,
      mobileNumber: '',
      email: '',
      confirmationCode: '',
      hasSentConfirmationCode: false,
      getCodeState: ButtonLoadingState.initial,
      loginState: ButtonLoadingState.initial
    };
  },
  mounted() {
    const mobileNumber = this.$route.query['mobileNumber'];

    if (mobileNumber) {
      this.mobileNumber = mobileNumber.toString() || '';
    }
  },
  methods: {
    onSubmit() {
      if (this.hasSentConfirmationCode) {
        this.loginUser();
      } else {
        this.requestConfirmationCode();
      }
    },
    setLoginType(type: number) {
      this.currentLoginType = type;
    },
    async loginUser() {
      try {
        this.loginState = ButtonLoadingState.loading;
        let payload = {};

        if (this.currentLoginType === this.loginType.MOBILE) {
          payload = {
            number: Helpers.prefixPhoneNumber(this.mobileNumber),
            code: this.confirmationCode,
            context: this.loginContext.LOGIN_MOBILE_NUMBER
          };
        } else {
          payload = {
            email: this.email,
            code: this.confirmationCode,
            context: this.loginContext.LOGIN_EMAIL
          };
        }

        const confirmationCodeResponse = await FirebaseRequest.createRequest<{
          token: string;
        }>(FirebaseRequestTypes.VALIDATE_CONFIRMATION_CODE, payload);

        const auth = getAuth();
        const response = await signInWithCustomToken(
          auth,
          confirmationCodeResponse.data?.token || ''
        );
        if (!(response && response.user && response.user.uid)) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_PHONE_NUMBER_LOGIN_ERROR,
            type: BannerType.error
          });
        }

        store.commit('auth/setCurrentUser', auth.currentUser);

        const buyerResponse = await FirebaseRequest.createRequest<Buyer>(
          FirebaseRequestTypes.GET_BUYER_INFO,
          {}
        );
        if (
          buyerResponse.status === APIResponseStatus.OK &&
          buyerResponse.data
        ) {
          store.commit('auth/setCurrentBuyer', buyerResponse.data);
        }

        await this.$router.push({ name: 'base-index' });
      } catch (ex) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.loginState = ButtonLoadingState.complete;
      }
    },
    async requestConfirmationCode() {
      try {
        let payload = {};
        this.getCodeState = ButtonLoadingState.loading;

        if (this.currentLoginType === this.loginType.MOBILE) {
          payload = {
            number: Helpers.prefixPhoneNumber(this.mobileNumber),
            context: this.loginContext.LOGIN_MOBILE_NUMBER
          };
        } else {
          payload = {
            email: this.email,
            context: this.loginContext.LOGIN_EMAIL
          };
        }

        const confirmationCodeResponse =
          await FirebaseRequest.createRequest<string>(
            FirebaseRequestTypes.REQUEST_CONFIRMATION_CODE,
            payload
          );

        if (confirmationCodeResponse) {
          if (
            confirmationCodeResponse.code ===
            APIResponseCode.INVALID_PHONE_NUMBER
          ) {
            store.commit('global/setBannerStatus', {
              text: BannerMessageStrings.BUYER_LOGIN_INVALID_PHONE_NUMBER,
              type: BannerType.error
            });
            this.getCodeState = ButtonLoadingState.complete;
            return;
          }

          this.getCodeState = ButtonLoadingState.complete;
          this.hasSentConfirmationCode = true;
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_SENDING_CONFIRMATION_CODE,
            type: BannerType.success
          });
        }
      } catch (ex) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.hasSentConfirmationCode = false;
      }
    }
  }
});
