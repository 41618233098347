
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageNotFoundComponent',
  props: {
    routePath: {
      type: String,
      default: '/'
    },
    externalRoute: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '404'
    },
    buttonLabel: {
      type: String,
      default: 'Back to Home'
    }
  },
  methods: {
    goHome() {
      this.$router.push({ path: this.routePath });
    }
  }
});
