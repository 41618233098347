
import { defineComponent, computed } from 'vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapActions, mapMutations, useStore } from 'vuex';
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';
import { MarketplaceProduct } from '@/models/marketplaceProduct';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

type STATE = 'REGISTER' | 'LOGIN';

const ReplyCodes = {
  NUMBER_NOT_FOUND: 'NUMBER_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_FOUND: 'USER_FOUND',
  INVALID_DATA: 'INVALID_DATA',
  USERNAME_IN_USE: 'USERNAME_IN_USE',
  SHARE_DISABLED: 'SHARE_DISABLED',
  SUSPENDED: 'SUSPENDED',
  PRODUCT_ID_MISSING: 'PRODUCT_ID_MISSING'
};

interface AffiliateProductSignupResponse {
  userName: string;
  id: string;
  affiliateTag: string;
}

export default defineComponent({
  name: 'MarketplaceCreateCheckoutComponent',
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ['checkout-created'](payload: string) {
      return true;
    }
  },
  setup() {
    const store = useStore();

    // my suspicion is that currentBuyer is not deeply reactive.
    // so, when currentBuyer.affiliateUsername is updated, it doesn't
    // trigger the change notification and thus, shows the username
    // box even though we have a username.
    const currentBuyer = computed<Buyer>(
      () => store.getters['auth/getCurrentBuyer']
    );

    const selectedProduct = computed<MarketplaceProduct>(
      () => store.getters['marketplace/getSelectedProductToSell']
    );

    // I would think we want store['auth/getCurrentBuyer']
    // and this would be what we use to determine how the modal/drawer looks

    return {
      selectedProduct,
      currentBuyer
    };
  },
  data() {
    return {
      createCheckoutButtonState: ButtonLoadingState.initial,
      currentState: 'REGISTER' as STATE,
      usernameError: [] as string[],
      loginInstead: false,
      requestedCode: false,
      invalidConfirmationCode: false,
      username: ''
    };
  },
  computed: {
    usernameRequired() {
      return !this.currentBuyer?.affiliateUsername;
    }
  },
  mounted() {
    this.refreshCurrentBuyer();
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    ...mapActions('auth', ['updateAffiliateUsername', 'refreshCurrentBuyer']),
    ...mapActions('marketplace', ['addTaggedProduct']),
    async submitForm() {
      this.createCheckoutButtonState = ButtonLoadingState.loading;

      if (this.usernameRequired && !this.username) {
        this.createCheckoutButtonState = ButtonLoadingState.initial;
        console.error('You need to provide a username!');
        return;
      }

      this.usernameError = [];

      const payload = {
        accountId: this.selectedProduct.accountId,
        productId: this.selectedProduct.id,
        username: this.username ?? ''
      };

      try {
        const response =
          await FirebaseRequest.createRequest<AffiliateProductSignupResponse>(
            FirebaseRequestTypes.COMPLETE_AFFILIATE_SIGNUP_FOR_PRODUCT,
            payload
          );
        if (response.status === APIResponseStatus.OK) {
          this.tagCreationComplete(
            response.data as AffiliateProductSignupResponse
          );

          this.createCheckoutButtonState = ButtonLoadingState.complete;
        } else {
          // TODO: show an error somewhere
          this.tagCreationFailed(response.code);
          this.createCheckoutButtonState = ButtonLoadingState.initial;
        }
      } catch (err) {
        console.error(err);
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.createCheckoutButtonState = ButtonLoadingState.initial;
      }
    },
    tagCreationComplete(responseData: AffiliateProductSignupResponse) {
      this.updateAffiliateUsername(responseData.userName);
      this.addTaggedProduct({
        productId: this.selectedProduct.id,
        productTag: responseData.affiliateTag
      });
      this.$emit('checkout-created', responseData.affiliateTag);
    },
    tagCreationFailed(code: string) {
      if (code === ReplyCodes.SUSPENDED) {
        // TODO: show a suspension message on this view. Don't both going
        // to another view.
        const message =
          'Sorry, your account is suspended and you are unable to create checkout tags';
        this.setBannerStatus({
          text: message,
          type: BannerType.error
        });
        console.error(message);
      }

      if (code === ReplyCodes.USERNAME_IN_USE) {
        this.usernameError.push(
          `Sorry, the username "${this.username}" is not available.`
        );
      }

      console.error('tag creation failed: ', code);
    }
  }
});
