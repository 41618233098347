
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';

const SellerSettingsPaymentPlans = defineComponent({
  name: 'SellerSettingsShopify',
  emits: ['plan-upgraded'],
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    })
  },
  async mounted() {
    const typeFormScript = document.createElement('script');
    typeFormScript.setAttribute(
      'src',
      'https://embed.typeform.com/next/embed.js'
    );
    document.head.appendChild(typeFormScript);
    const sessionId = this.$route.query.session_id as string;
    if (sessionId) {
      // Call API to handle stripe session id being passed back from stripe
      this.setLoading(true);
      const stripeResult = await FirebaseRequest.createRequest<{
        plan: string;
      }>(FirebaseRequestTypes.COMPLETE_SUBSCRIPTION_PORTAL_STRIPE, {
        accountId: this.currentAccount.accountId,
        sessionId
      });
      this.setLoading(false);
      if (
        stripeResult.status === APIResponseStatus.OK &&
        stripeResult.data?.plan
      ) {
        this.currentAccount.currentPlan = stripeResult.data.plan;
        //Clear the subscription id from the query string
        const query = Object.assign({}, this.$route.query);
        delete query.session_id;
        await this.$router.replace({ query });
        this.$store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.SELLER_SETTINGS_PLAN_UPGRADE,
          type: BannerType.success
        });
        this.$emit('plan-upgraded');
      }
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'global/setLoading',
      setBannerStatus: 'global/setBannerStatus'
    }),
    async selectPlan(plan: string) {
      this.setLoading(true);
      const stripeResult = await FirebaseRequest.createRequest<{
        portalUrl: string;
      }>(FirebaseRequestTypes.GET_SUBSCRIPTION_PORTAL_STRIPE, {
        accountId: this.currentAccount.accountId,
        plan
      });

      if (
        stripeResult.status === APIResponseStatus.OK &&
        stripeResult.data?.portalUrl
      ) {
        window.location.assign(stripeResult.data?.portalUrl);
      } else {
        this.setLoading(false);
      }
    }
  }
});

export default SellerSettingsPaymentPlans;
