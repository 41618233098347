
import { defineComponent } from 'vue';
import Helpers from '@/helpers';
import { Charges } from '@/models/charges';
import { TagDetail } from '@/models/tag';
import { ProductSource } from '@/../models/sellerOrder';

export default defineComponent({
  props: {
    product: {
      type: Object as () => TagDetail,
      required: true
    },
    charges: {
      type: Object as () => Charges,
      required: true
    },
    isShippingCalculated: {
      type: Boolean,
      default: false
    },
    isTaxCalculated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shippingText(): string {
      if (this.product.productSource === 'Shopify') {
        return this.isShippingCalculated
          ? this.getPrice(this.charges.shipping)
          : 'To Be Determined';
      } else {
        return this.charges.shipping
          ? this.getPrice(this.charges.shipping)
          : 'Free';
      }
    },
    taxShippingText(): string {
      const taxToBeDetermined = !this.isTaxCalculated && this.product.chargeTax;
      const shippingToBeDetermined =
        !this.isShippingCalculated &&
        this.product.productSource === ProductSource.Shopify;

      if (taxToBeDetermined && shippingToBeDetermined) {
        return 'Tax & Shipping yet to be applied';
      } else if (taxToBeDetermined) {
        return 'Tax yet to be applied';
      } else if (shippingToBeDetermined) {
        return 'Shipping yet to be applied';
      } else {
        return '';
      }
    }
  },
  methods: {
    getPrice(price: number) {
      return Helpers.currencyFormatter(price);
    }
  }
});
