import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24ac68a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "boost-stripe-card" }
const _hoisted_2 = { class: "boost-stripe-card__element" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify--between"
}
const _hoisted_4 = { class: "boost-input__label" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  ref: "card",
  class: "boost-stripe-card__card"
}
const _hoisted_7 = {
  key: 1,
  class: "boost-stripe-card__error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.label), 1),
              (_ctx.showRequiredAsteriskOnLabel)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "*"))
                : _createCommentVNode("", true)
            ]),
            _renderSlot(_ctx.$slots, "link", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, null, 512),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}