
import { defineComponent } from 'vue';
import { AudienceMember } from '@/models/audienceMember';
import Helpers from '@/helpers';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import AudienceMenuControls from '@/seller/audience/AudienceMenuControls.vue';
import { mapGetters, mapMutations } from 'vuex';
import {
  AudienceSortOptions,
  AudienceFilterOptions,
  FilterAudienceEvent,
  AudienceType
} from '@/../enums/audienceTypes';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import PopupComponent from '@/common/PopupComponent/PopupComponent.vue';
import {
  PopupMessageStrings,
  PopupMessageTitles
} from '@/common/PopupComponent/PopupMessages';

export default defineComponent({
  name: 'AudienceList',
  components: {
    AudienceMenuControls,
    PopupComponent
  },
  data() {
    return {
      PopupMessageStrings,
      PopupMessageTitles,
      AudienceType,
      typeSelection: AudienceType.Customers,
      selectedAffiliate: undefined as AudienceMember | undefined,
      useGlobalCommission: false,
      showAffiliateStatus: false,
      showCommission: false,
      audience: [] as AudienceMember[],
      filteredAudience: [] as AudienceMember[],
      affiliateColumns: [
        {
          id: 'image',
          label: ''
        },
        {
          id: 'name',
          label: 'Name'
        },
        {
          id: 'products',
          label: 'Products'
        },
        {
          id: 'revenue',
          label: 'Revenue'
        },
        {
          id: 'earnings',
          label: 'Earnings'
        },
        {
          id: 'commission',
          label: 'Commission (%)'
        },
        {
          id: 'status',
          label: 'Status'
        },
        {
          id: 'menu'
        }
      ] as ColumnItem[],
      columns: [
        {
          id: 'image',
          label: ''
        },
        {
          id: 'name',
          label: 'Name'
        },
        {
          id: 'email',
          label: 'Email'
        },
        {
          id: 'phone',
          label: 'Phone'
        },
        {
          id: 'revenue',
          label: 'Revenue'
        },
        {
          id: 'badges',
          label: 'Status'
        },
        {
          id: 'menu'
        }
      ] as ColumnItem[],
      menuItems: [
        {
          label: 'View Customer',
          onClick: (row) => {
            this.viewCustomer(row);
          }
        },
        {
          label: 'Copy Email',
          onClick: (row) => {
            this.copyEmail(row);
          }
        },
        {
          label: 'Copy Phone',
          onClick: (row) => {
            this.copyPhone(row);
          }
        }
      ] as ContextMenuItem[],
      affiliateMenu: [
        {
          label: 'View Advocate',
          onClick: (row) => {
            this.viewAffiliate(row);
          }
        },
        {
          label: 'Edit Commission',
          onClick: (row) => {
            this.showEditCommission(row);
          }
        },
        {
          label: 'Suspend Advocate',
          onClick: (row) => {
            this.showEditAffiliateStatus(row);
          },
          isHidden: (row) => {
            return (
              (row as unknown as AudienceMember).affiliateStatus === 'suspended'
            );
          }
        },
        {
          label: 'Reinstate Advocate',
          onClick: (row) => {
            this.showEditAffiliateStatus(row);
          },
          isHidden: (row) => {
            return (
              (row as unknown as AudienceMember).affiliateStatus === 'active'
            );
          }
        }
      ] as ContextMenuItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    })
  },
  mounted() {
    if (this.currentAccount === null) {
      return;
    }
    this.getAudience();
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    ...mapMutations('audience', ['setAudienceIds']),
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    formatAudienceName(
      firstName: string | undefined,
      lastName: string | undefined
    ) {
      const fullName = [firstName, lastName].join(' ').trim();
      return fullName !== '' ? fullName : 'N/A';
    },
    async getAudience() {
      this.setLoading(true);
      const payload = {
        accountId: this.currentAccount.accountId,
        audienceType: this.typeSelection
      };
      const responseData = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_AUDIENCE,
        payload
      );

      if (responseData.status === APIResponseStatus.OK) {
        this.audience = responseData.data as AudienceMember[];
        await this.handleFilterSort({
          filterOption: AudienceFilterOptions.All,
          sortOption: AudienceSortOptions.MostRecent,
          typeSelection: this.typeSelection
        });

        this.setLoading(false);
      }
    },
    isActive(member: AudienceMember): boolean {
      if (this.typeSelection === AudienceType.Advocates) {
        return member.affiliateStatus === 'active';
      }
      return member.totalOrders > 1 && !this.isInactive(member);
    },
    decodeStatus(member: AudienceMember): string {
      if (this.isActive(member)) {
        return 'Active';
      } else if (this.isInactive(member)) {
        return 'Inactive';
      } else if (member.totalOrders === 1) {
        return 'New';
      } else if (member.totalOrders === 0) {
        return 'Registered';
      } else {
        return '';
      }
    },
    isInactive(member: AudienceMember): boolean {
      if (this.typeSelection === AudienceType.Advocates) {
        return member.affiliateStatus === 'active' && !member.affiliateEarnings;
      }
      const sixtyDaysPast = new Date().getTime() - 60 * 24 * 60 * 60 * 1000;
      if (member.lastTransaction) {
        return (
          member.totalOrders > 1 &&
          new Date(member.lastTransaction).getTime() < sixtyDaysPast
        );
      }
      return false;
    },
    sortAdvocates(
      sortOption: AudienceSortOptions,
      a: AudienceMember,
      b: AudienceMember
    ) {
      if (sortOption === AudienceSortOptions.HighestRevenue) {
        return (b?.affiliateRevenue ?? 0) - (a?.affiliateRevenue ?? 0);
      }

      if (sortOption === AudienceSortOptions.LowestRevenue) {
        return (a?.affiliateRevenue ?? 0) - (b?.affiliateRevenue ?? 0);
      }

      const aFullName = [a.firstName, a.lastName].join(' ').trim();
      const bFullName = [b.firstName, b.lastName].join(' ').trim();
      if (sortOption === AudienceSortOptions.AlphaAscending) {
        return aFullName.localeCompare(bFullName);
      }

      if (sortOption === AudienceSortOptions.AlphaDescending) {
        return bFullName.localeCompare(aFullName);
      }

      return b.joinedOn - a.joinedOn;
    },
    async handleFilterSort(options: FilterAudienceEvent) {
      // If the type has changed need to reload the audience
      if (this.typeSelection !== options.typeSelection) {
        this.typeSelection = options.typeSelection;
        await this.getAudience();
      }
      this.filteredAudience = this.audience
        .filter((x: AudienceMember) => {
          let results = true;
          if (options.searchValue) {
            if (x.shippingAddress) {
              results =
                (x.lastName || '')
                  .toLocaleLowerCase()
                  .includes(options.searchValue.toLocaleLowerCase()) ||
                x.userId
                  .toLocaleLowerCase()
                  .includes(options.searchValue.toLocaleLowerCase()) ||
                (x.firstName || '')
                  .toLocaleLowerCase()
                  .includes(options.searchValue.toLocaleLowerCase());
            }
          }
          switch (options.filterOption) {
            case AudienceFilterOptions.Active:
              results = results && this.isActive(x);
              break;
            case AudienceFilterOptions.Inactive:
              results = results && this.isInactive(x);
              break;
            case AudienceFilterOptions.NewCustomer:
              results = results && x.totalOrders === 1;
              break;
            case AudienceFilterOptions.PreRegistered:
              results = results && x.totalOrders === 0;
              break;
            case AudienceFilterOptions.HighRefunds:
              results = results && x.totalRefundAmount > x.totalRevenue;
              break;
            case AudienceFilterOptions.Suspended:
              results = results && x.affiliateStatus === 'suspended';
              break;
            default:
              break;
          }
          return results;
        })
        .sort((a: AudienceMember, b: AudienceMember) => {
          if (options.typeSelection === AudienceType.Advocates) {
            return this.sortAdvocates(options.sortOption, a, b);
          }

          let sortResult = 0;
          const aFullName = [a.firstName, a.lastName].join(' ').trim();
          const bFullName = [b.firstName, b.lastName].join(' ').trim();
          switch (options.sortOption) {
            case AudienceSortOptions.AlphaAscending:
              if (aFullName && bFullName) {
                sortResult = aFullName.localeCompare(bFullName);
              }
              break;
            case AudienceSortOptions.AlphaDescending:
              if (aFullName && bFullName) {
                sortResult = bFullName.localeCompare(aFullName);
              }
              break;
            case AudienceSortOptions.MostOrders:
              sortResult = b.totalOrders - a.totalOrders;
              break;
            case AudienceSortOptions.LeastOrders:
              sortResult = a.totalOrders - b.totalOrders;
              break;
            case AudienceSortOptions.HighestRevenue:
              sortResult = b.totalRevenue - a.totalRevenue;
              break;
            case AudienceSortOptions.LowestRevenue:
              sortResult = a.totalRevenue - b.totalRevenue;
              break;
            case AudienceSortOptions.HighestRefunds:
              sortResult = b.totalRefundAmount - a.totalRefundAmount;
              break;
            case AudienceSortOptions.LeastRefunds:
              sortResult = a.totalRefundAmount - b.totalRefundAmount;
              break;
            default:
              sortResult = b.joinedOn - a.joinedOn;
          }
          return sortResult;
        });
      this.setAudienceIds(
        this.filteredAudience.map((x) =>
          options.typeSelection === AudienceType.Advocates
            ? x.affiliateId
            : x.userId
        )
      );
    },
    viewCustomer(member: AudienceMember | string) {
      let memberId = '';
      if (typeof member === 'string') {
        memberId = member;
      } else {
        memberId = (member as AudienceMember).userId;
      }
      this.$router.push({
        name: 'audience-member',
        params: { memberId }
      });
    },
    viewAffiliate(member: AudienceMember | string) {
      let memberId = '';
      if (typeof member === 'string') {
        memberId = member;
      } else {
        memberId = (member as AudienceMember).affiliateId as string;
      }
      this.$router.push({
        name: 'audience-advocate-member',
        params: { memberId }
      });
    },
    async copyEmail(member: AudienceMember) {
      await navigator.clipboard.writeText(member.email || 'unknown email');
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    async copyPhone(member: AudienceMember) {
      await navigator.clipboard.writeText(
        member.mobileNumber || 'unknown phone'
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    showEditCommission(member: AudienceMember) {
      this.showCommission = true;
      this.useGlobalCommission = !member.affiliateCommission;
      this.selectedAffiliate = member;
    },
    showEditAffiliateStatus(member: AudienceMember) {
      this.showAffiliateStatus = true;
      this.selectedAffiliate = member;
    },
    cancelPopup() {
      this.showAffiliateStatus = false;
      this.showCommission = false;
      this.selectedAffiliate = undefined;
      this.useGlobalCommission = false;
    },
    async saveAffiliateEventHandler(changeStatus: boolean) {
      if (this.selectedAffiliate) {
        this.setLoading(true);

        const payload = {
          userId: this.selectedAffiliate.userId,
          accountId: this.currentAccount.accountId,
          useGlobal: this.useGlobalCommission,
          statusChange: undefined as string | undefined,
          commission: undefined as number | undefined
        };
        if (changeStatus) {
          payload.statusChange =
            this.selectedAffiliate.affiliateStatus === 'active'
              ? 'suspended'
              : 'active';
          this.selectedAffiliate.affiliateStatus = payload.statusChange;
        } else {
          payload.commission = this.selectedAffiliate.affiliateCommission;
        }
        await FirebaseRequest.createRequest(
          FirebaseRequestTypes.UPDATE_BRAND_AFFILIATE,
          payload
        );
        this.showAffiliateStatus = false;
        this.showCommission = false;
        this.selectedAffiliate = undefined;
        this.useGlobalCommission = false;
        await this.getAudience();
      }
    }
  }
});
