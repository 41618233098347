
import { defineComponent } from 'vue';
import {
  APIResponseCode,
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import Helpers from '@/helpers';
import Stepper from '@/common/Stepper.vue';
import BuyerCreateAccount from '@/buyer/registration/BuyerCreateAccount.vue';
import BuyerRegistrationShipping from './BuyerRegistrationShipping.vue';
import BuyerRegistrationCard from './BuyerRegistrationCard.vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { Address } from '@/models/mailingAddress';
import { mapMutations } from 'vuex';
import { store } from '@/store';
import { Buyer } from '../settings/BuyerSettingsInterfaces';

export default defineComponent({
  name: 'BuyerRegistration',
  components: {
    BuyerCreateAccount,
    BuyerRegistrationShipping,
    BuyerRegistrationCard,
    Stepper
  },
  data() {
    return {
      registrationState: {
        CREATE_ACCOUNT: 1,
        CONFIRM_NUMBER: 2,
        SHIPPING: 3,
        CARD: 4
      },
      currentState: 1,
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: ''
      },
      addressInfo: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States'
      } as Address,
      firebaseToken: '',
      buttonLoadingState: ButtonLoadingState.initial
    };
  },
  methods: {
    ...mapMutations('global', ['setBannerStatus']),
    ...mapMutations('auth', ['setCurrentUser']),
    setRegistrationState(state: number) {
      this.currentState = state;
    },
    // eslint-disable-next-line @typescript-eslint/ban-types
    startRegistration(info: object) {
      Object.assign(this.userInfo, info);
      this.requestConfirmationCode();
    },
    cancelConfirmNumber() {
      this.setRegistrationState(this.registrationState.CREATE_ACCOUNT);
    },
    cancelRegistration() {
      this.setRegistrationState(this.registrationState.CREATE_ACCOUNT);
    },
    handleShippingCompleted(info: Address) {
      Object.assign(this.addressInfo, info);
      this.setRegistrationState(this.registrationState.CARD);
    },
    async requestConfirmationCode() {
      try {
        this.buttonLoadingState = ButtonLoadingState.loading;
        const payload = {
          number: Helpers.prefixPhoneNumber(this.userInfo.mobileNumber),
          context: 'SIGNUP_MOBILE_NUMBER'
        };

        const confirmationCodeResponse = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.REQUEST_CONFIRMATION_CODE,
          payload
        );

        if (confirmationCodeResponse.status !== APIResponseStatus.OK) {
          if (
            confirmationCodeResponse.code ===
            APIResponseCode.INVALID_PHONE_NUMBER
          ) {
            this.setBannerStatus({
              text: BannerMessageStrings.INVALID_PHONE_NUMBER_SIGNUP,
              type: BannerType.error
            });
            this.buttonLoadingState = ButtonLoadingState.complete;
            return;
          }

          if (
            confirmationCodeResponse.code ===
            APIResponseCode.PHONE_NUMBER_ALREADY_IN_USE
          ) {
            this.setBannerStatus({
              text: BannerMessageStrings.PHONE_NUMBER_IN_USE,
              type: BannerType.error
            });
            this.buttonLoadingState = ButtonLoadingState.complete;
            return;
          }

          this.setBannerStatus({
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
          this.buttonLoadingState = ButtonLoadingState.complete;
          return;
        }
        this.buttonLoadingState = ButtonLoadingState.complete;
        // this.setRegistrationState(this.registrationState.CONFIRM_NUMBER);
        this.setBannerStatus({
          text: BannerMessageStrings.BUYER_SENDING_CONFIRMATION_CODE,
          type: BannerType.success
        });
      } catch (ex) {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.buttonLoadingState = ButtonLoadingState.complete;
      }
    },
    async confirmMobileNumber(confirmationCode: string) {
      try {
        this.buttonLoadingState = ButtonLoadingState.loading;
        const payload = {
          number: Helpers.prefixPhoneNumber(this.userInfo.mobileNumber),
          code: confirmationCode,
          pendingEmail: this.userInfo.email,
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          context: 'SIGNUP_MOBILE_NUMBER'
        };

        const confirmationCodeResponse = await FirebaseRequest.createRequest<{
          token: string;
        }>(FirebaseRequestTypes.VALIDATE_CONFIRMATION_CODE, payload);

        if (!confirmationCodeResponse.data?.token) {
          this.setBannerStatus({
            text: BannerMessageStrings.BUYER_INVALID_CODE,
            type: BannerType.error
          });
          this.buttonLoadingState = ButtonLoadingState.complete;
          return;
        }

        // save token, but don't login the user yet
        this.firebaseToken = confirmationCodeResponse.data.token;

        this.setRegistrationState(this.registrationState.SHIPPING);
        this.buttonLoadingState = ButtonLoadingState.complete;
        this.setBannerStatus(null);
      } catch (ex) {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.buttonLoadingState = ButtonLoadingState.complete;
      }
    },
    async loginUser() {
      // login user
      const a = await signInWithCustomToken(getAuth(), this.firebaseToken);
      if (!(a && a.user && a.user.uid)) {
        this.setBannerStatus({
          text: BannerMessageStrings.BUYER_PHONE_NUMBER_LOGIN_ERROR,
          type: BannerType.error
        });
      }

      const u = await getAuth().currentUser;
      this.setCurrentUser(u);

      const buyerResponse = await FirebaseRequest.createRequest<Buyer>(
        FirebaseRequestTypes.GET_BUYER_INFO,
        {}
      );
      if (buyerResponse.status === APIResponseStatus.OK && buyerResponse.data) {
        store.commit('auth/setCurrentBuyer', buyerResponse.data);
      }

      await this.$router.push({ path: '/user' });
    },
    goBack() {
      let newState = this.currentState - 1;
      if (newState === this.registrationState.CONFIRM_NUMBER) {
        newState = this.registrationState.CREATE_ACCOUNT;
      }
      this.setRegistrationState(newState);
    }
  }
});
