
import { defineComponent } from 'vue';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { BrandInformation } from '@/store/types';
import { store } from '@/store';

const Policy = defineComponent({
  name: 'StorePolicy',
  data() {
    return {
      sellerPolicy: 'No policy statement available for this seller.'
    };
  },
  mounted() {
    this.getSellerInfo();
  },
  methods: {
    async getSellerInfo() {
      try {
        const payload = {
          slug: this.$route.params.storeSlug
        };

        const sellerInfo = await FirebaseRequest.createRequest<{
          seller: {
            name: string;
            phone: string;
            refundPolicy: string;
            slug: string;
            logoUrl: string;
            hashtagPrefix: string;
            email: string;
          };
        }>(FirebaseRequestTypes.GET_SELLER_INFO, payload);

        if (!sellerInfo) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
          return;
        }

        if (sellerInfo.data?.seller) {
          this.sellerPolicy = sellerInfo.data.seller.refundPolicy;
          const brandInfo: BrandInformation = {
            brandName: sellerInfo.data.seller.name,
            orderEmail: sellerInfo.data.seller.email,
            logoUrl: sellerInfo.data.seller.logoUrl || ''
          };
          store.commit('global/setBrandInformation', brandInfo);
        }
      } catch (ex) {
        console.error(ex);
      }
    }
  }
});

export default Policy;
