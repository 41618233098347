import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "boost-control-footer__container content container--nav" }
const _hoisted_2 = { class: "flex boost-control-footer__content align--center" }
const _hoisted_3 = { class: "boost-control-footer__content-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["boost-control-footer", {
      'boost-control-footer--mobile-column': _ctx.stackOnMobile
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BoostButton, {
          label: _ctx.cancelLabel,
          "button-style": "optional",
          onClick: _ctx.cancelHandler
        }, null, 8, ["label", "onClick"]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "additional-buttons"),
          _createVNode(_component_BoostButton, {
            label: _ctx.saveLabel,
            disabled: _ctx.saveDisabled,
            type: "submit",
            "button-style": "submit",
            "loading-state": _ctx.saveLoadingState,
            "loading-label": _ctx.saveLoadingLabel,
            "complete-label": _ctx.saveCompleteLabel,
            onClick: _ctx.saveHandler
          }, null, 8, ["label", "disabled", "loading-state", "loading-label", "complete-label", "onClick"])
        ])
      ])
    ])
  ], 2))
}