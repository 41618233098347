
import { defineComponent } from 'vue';
import { store } from '@/store';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { PurchaseOrder } from '@/models/purchaseOrder';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import Helpers from '@/helpers';
import { TagType } from '@/../enums/tagType';
import { ShippingStatus } from '@/../enums/shippingStatus';

export default defineComponent({
  name: 'BuyerOrderDetail',
  data() {
    return {
      TagType,
      ShippingStatus,
      order: {} as PurchaseOrder,
      previousId: '',
      nextId: '',
      index: 1
    };
  },
  computed: {
    ...mapGetters({
      purchaseListLength: 'purchases/getPurchasesListLength'
    })
  },
  async mounted() {
    const accountId = this.$route.params.accountId as string;
    const transactionId = this.$route.params.transactionId as string;
    if (accountId && transactionId) {
      await this.getOrder(accountId, transactionId);
    }
  },
  methods: {
    ...mapMutations('global', ['setLoading']),
    ...mapActions('purchases', ['getNext', 'getPrevious', 'getCurrentIndex']),
    goBack() {
      this.$router.push({
        name: 'buyer-product-orders'
      });
    },
    async getOrder(accountId: string, transactionId: string) {
      if (store.state.auth.currentUser) {
        this.setLoading(true);
        const result = await FirebaseRequest.createRequest<PurchaseOrder>(
          FirebaseRequestTypes.GET_BUYER_PURCHASE,
          {
            userId: store.state.auth.currentUser.uid,
            accountId,
            transactionId
          }
        );
        if (result.status === APIResponseStatus.OK) {
          if (result.data) {
            this.order = result.data;
            const key = `${this.order.accountId}/${this.order.transactionId}`;
            this.index = (await this.getCurrentIndex(key)) + 1;
            this.nextId = await this.getNext(key);
            this.previousId = await this.getPrevious(key);
          }
        } else {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }
        this.setLoading(false);
      }
    },
    processDate(date: string | number) {
      return Helpers.processDate(new Date(date).getTime());
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    async previous() {
      if (this.previousId) {
        await this.$router.push({ path: `/user/purchases/${this.previousId}` });
        const keys = this.previousId.split('/');
        this.getOrder(keys[0], keys[1]);
      }
    },
    async next() {
      if (this.nextId) {
        await this.$router.push({ path: `/user/purchases/${this.nextId}` });
        const keys = this.nextId.split('/');
        this.getOrder(keys[0], keys[1]);
      }
    }
  }
});
