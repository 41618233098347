
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import { SelectOption } from '@/common/controls/BoostSelect/Select.interface';

export default defineComponent({
  name: 'BoostSelect',
  props: {
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String
    },
    options: {
      type: Array as () => SelectOption[],
      required: true
    },
    modelValue: {
      type: [String, Number, Boolean]
    },
    rules: {
      type: String
    },
    placeHolder: {
      type: String
    },
    name: {
      type: String
    },
    inputId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideErrors: {
      type: Boolean,
      default: false
    },
    attributes: {
      type: String
    },
    hideLabel: {
      type: Boolean,
      default: true
    }
  },
  compatConfig: { COMPONENT_V_MODEL: false },
  emits: ['input', 'inputObject', 'update:modelValue'],
  setup(props) {
    const { value, errorMessage, handleBlur, handleChange, meta } = useField(
      props.inputId,
      props.rules,
      {
        initialValue: props.modelValue
      }
    );

    return {
      value,
      handleChange,
      handleBlur,
      errorMessage,
      meta
    };
  },
  data() {
    return {
      defaultOption: null as null | string,
      innerValue: null as null | string | number | boolean | undefined
    };
  },
  computed: {
    isRequired(): boolean {
      if (this.rules) {
        return this.rules.includes('required');
      } else {
        return false;
      }
    },
    showRequiredIndicator(): boolean {
      if (!this.rules || !this.rules.includes('required')) {
        return false;
      }

      return !(
        this.attributes && this.attributes.includes('no-required-indicator')
      );
    }
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.value = newVal;
      },
      deep: true
    }
  },
  mounted() {
    this.setPlaceholder();
    if (this.modelValue !== null) {
      this.innerValue = this.modelValue;
    }
  },
  methods: {
    setPlaceholder() {
      if (!this.modelValue) {
        this.defaultOption = 'Please choose an option';
      }
    },
    getSelectedOption(
      value?: string | number | boolean
    ): SelectOption | undefined {
      return this.options.find((option: SelectOption) => value == option.value);
    },
    isOptionSelected(option: SelectOption): boolean | undefined {
      if (this.modelValue) {
        return option.value === this.modelValue;
      }
    },
    change(e: Event) {
      if (e.target) {
        const option = this.getSelectedOption(this.value);
        if (option) {
          // TODO: which set of emitters is needed?
          // we use @input, but the using update:modelValue will directly update the value
          // so @input triggers our logic, but modelValue supplies the value
          // inputObject delivers a the entire option instead of just the value
          this.$emit('inputObject', option);
          this.$emit('update:modelValue', option.value);
          this.$emit('input', option.value);
        }
      }
    }
  }
});
