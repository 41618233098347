import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align--center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Stepper = _resolveComponent("Stepper")!
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _component_BuyerCreateAccount = _resolveComponent("BuyerCreateAccount")!
  const _component_BuyerRegistrationShipping = _resolveComponent("BuyerRegistrationShipping")!
  const _component_BuyerRegistrationCard = _resolveComponent("BuyerRegistrationCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.currentState > 2)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#stepper-indicator"
        }, [
          _createVNode(_component_Stepper, {
            steps: 3,
            "current-step": _ctx.currentState - 1
          }, null, 8, ["current-step"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentState > 2)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 1,
          to: "#header-back-menu"
        }, [
          _createVNode(_component_BoostButton, {
            "button-style": "none",
            onClick: _ctx.goBack
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: require('../../assets/arrow-left.svg'),
                  alt: "Back arrow"
                }, null, 8, _hoisted_2),
                _createTextVNode(" " + _toDisplayString(_ctx.currentState === 3 ? 'Create account' : 'Shipping'), 1)
              ])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.currentState === _ctx.registrationState.CREATE_ACCOUNT)
        ? (_openBlock(), _createBlock(_component_BuyerCreateAccount, {
            key: 0,
            "button-loading-state": _ctx.buttonLoadingState,
            onStartRegistration: _ctx.startRegistration,
            onConfirmMobileNumber: _ctx.confirmMobileNumber
          }, null, 8, ["button-loading-state", "onStartRegistration", "onConfirmMobileNumber"]))
        : (_ctx.currentState === _ctx.registrationState.SHIPPING)
          ? (_openBlock(), _createBlock(_component_BuyerRegistrationShipping, {
              key: 1,
              "button-loading-state": _ctx.buttonLoadingState,
              "onUpdate:button-loading-state": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.buttonLoadingState) = $event)),
              onCompletedShipping: _ctx.handleShippingCompleted
            }, null, 8, ["button-loading-state", "onCompletedShipping"]))
          : (_ctx.currentState === _ctx.registrationState.CARD)
            ? (_openBlock(), _createBlock(_component_BuyerRegistrationCard, {
                key: 2,
                "button-loading-state": _ctx.buttonLoadingState,
                "onUpdate:button-loading-state": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.buttonLoadingState) = $event)),
                "buyer-info": _ctx.userInfo,
                "address-info": _ctx.addressInfo,
                onBuyerRegistered: _ctx.loginUser
              }, null, 8, ["button-loading-state", "buyer-info", "address-info", "onBuyerRegistered"]))
            : _createCommentVNode("", true)
    ])
  ], 64))
}