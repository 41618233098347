
import { defineComponent } from 'vue';
import { TagSortOptions } from '@/constants';
import Helpers from '@/helpers';
import { Tag, TagDetail, TipTag } from '@/models/tag';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { FilterProductListEvent } from '@/enums/productTypes';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';
import { mapGetters, mapMutations } from 'vuex';
import { TagType } from '@/../enums/tagType';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import DownloadTagsPopupComponent, {
  DownloadTagItem
} from '@/common/DownloadTagsPopupComponent.vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

export default defineComponent({
  name: 'ProductList',
  components: {
    DownloadTagsPopupComponent
  },
  props: {
    tagFilter: {
      type: Object as () => FilterProductListEvent
    },
    display: {
      type: String,
      required: true
    }
  },
  emits: ['update:filteredCount'],
  data() {
    return {
      showDownloadTags: false,
      selectedRow: {} as DownloadTagItem,
      TagType,
      tagSortBy: TagSortOptions.MOSTRECENT,
      tagSearchValue: '',
      tags: [] as Tag[],
      filteredTags: [] as Tag[],
      menuItems: [
        {
          label: 'View',
          onClick: (row) => {
            const productId = row.productId as string;
            this.$router.push({
              name: 'seller-product-detail',
              params: { productId }
            });
          }
        },
        {
          label: 'Edit',
          onClick: (row) => {
            const productId = row.productId as string;
            if (row?.type === TagType.TIP) {
              this.$router.push({
                name: 'seller-tip-edit',
                params: { productId }
              });
            } else {
              const routeName =
                row?.productSource === 'Shopify'
                  ? 'seller-product-edit-shopify'
                  : 'seller-product-edit';
              this.$router.push({ name: routeName, params: { productId } });
            }
          }
        },
        {
          label: 'Download Tags',
          onClick: (row) => {
            this.downloadEventHandler(row);
          }
        }
      ] as ContextMenuItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      currentFilter: 'product/currentFilter',
      productSource: 'accounts/selectedFeatureTypeId'
    }),
    columns(): ColumnItem[] {
      const step1 = [
        {
          id: 'image',
          label: ''
        },
        {
          id: 'name',
          label: 'Name'
        }
      ] as ColumnItem[];

      if (this.currentFilter === TagType.TIP) {
        const digitalColumns = [
          {
            id: 'amounts',
            label: 'Amounts'
          },
          {
            id: 'payments',
            label: 'Payments'
          },
          {
            id: 'saved',
            label: 'Saved'
          },
          {
            id: 'revenue',
            label: 'Revenue'
          },
          {
            id: 'badges',
            label: 'Status'
          },
          {
            id: 'menu',
            label: ''
          }
        ];
        step1.push(...digitalColumns);
      } else {
        const productColumns = [
          {
            id: 'price',
            label: 'Price'
          },
          {
            id: 'sold',
            label: 'Sold'
          },
          {
            id: 'saved',
            label: 'Saved'
          },
          {
            id: 'revenue',
            label: 'Revenue'
          },
          {
            id: 'quantity',
            label: 'Quantity'
          },
          {
            id: 'badges',
            label: 'Status'
          },
          {
            id: 'menu',
            label: ''
          }
        ];
        step1.push(...productColumns);
      }

      //Remove the saved column if not shopify
      if (this.productSource !== 'Shopify') {
        step1.splice(
          step1.findIndex((x) => x.id === 'saved'),
          1
        );
      }

      return step1;
    }
  },
  watch: {
    // When the app boots, the currentAccount may not be set
    async currentAccount(newValue) {
      if (newValue != null) {
        await this.getTags();
      }
    },
    tagFilter(searchObject) {
      this.handleFiltering(searchObject);
    }
  },
  async mounted() {
    if (this.currentAccount == null) {
      return;
    }
    // Need to clear the current product selection
    this.setCurrentProduct(null);
    // await this.getTags();
  },
  methods: {
    ...mapMutations('product', ['setCurrentProduct', 'setProductIds']),
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    handlePopupVisibleChange(show: boolean) {
      this.showDownloadTags = show;
    },
    downloadEventHandler(tag: Tag) {
      this.selectedRow = tag as DownloadTagItem;
      this.showDownloadTags = true;
    },
    async downloadQR(tag: Tag, singleFile?: boolean) {
      this.setLoading(true);
      const responseData = await FirebaseRequest.requestFile(
        FirebaseRequestTypes.GENERATE_PRODUCT_LINKS,
        {
          productId: tag.productId,
          accountId: tag.accountId,
          singleFile
        }
      );
      const FILE = window.URL.createObjectURL(await responseData.blob());
      const downloadUrl = document.createElement('a');
      downloadUrl.href = FILE;
      downloadUrl.setAttribute(
        'download',
        `${tag.title.substring(0, 50).replace(/[^a-z0-9]/gi, '-')}.${
          singleFile ? 'png' : 'zip'
        }`
      );
      document.body.appendChild(downloadUrl);
      downloadUrl.click();
      this.showDownloadTags = false;
      this.setLoading(false);
    },
    async copyUrl() {
      await navigator.clipboard.writeText(
        `${window.location.origin}/buy/${this.selectedRow?.hashtag}`
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
      this.showDownloadTags = false;
    },
    async copySMS() {
      await navigator.clipboard.writeText(
        Helpers.formatHashTag(this.selectedRow?.hashtag)
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
      this.showDownloadTags = false;
    },
    decodeStatus(tag: Tag): string {
      return tag.disabled ? 'Inactive' : 'Active';
    },
    editTag(productId: string) {
      this.$router.push({
        name: 'seller-product-detail',
        params: { productId }
      });
    },
    getAllAvailableStock(tag: Tag): number {
      if (!tag.skus) {
        // If we don't have any SKUs, we have no tags to sell (and probably a data corruption)
        return 0;
      }

      return tag.skus.map((x) => +x.quantity).reduce((a, b) => a + b, 0);
    },
    getTypeImage(tag: Tag): string {
      if (tag.shopifyShareEarn && tag.shopifySaveForLater) {
        return require('@/assets/multi-app-icon.svg');
      }
      if (tag.shopifySaveForLater) {
        return require('@/assets/later_icon.svg');
      }
      if (tag.shopifyShareEarn) {
        return require('@/assets/share-icon.svg');
      }
      return '';
    },
    getPrice(tag: Tag) {
      if (tag.type === TagType.TIP) {
        const prices = (tag as never as TipTag).amounts.map((x) => +x);

        const min = Math.min(...prices);
        const max = Math.max(...prices);

        if (min !== max) {
          return `${Helpers.currencyFormatter(
            min
          )} - ${Helpers.currencyFormatter(max)}`;
        } else {
          return `${Helpers.currencyFormatter(min)}`;
        }
      } else {
        if (tag?.skus && (tag as TagDetail)?.variants?.length) {
          const skus = Object.entries(tag.skus);
          if (skus.length > 0) {
            const prices = [] as number[];
            skus.forEach((sku) => {
              prices.push(+sku[1].price);
            });
            const min = Helpers.currencyFormatter(
              Math.min(...prices).toString()
            );
            const max = Helpers.currencyFormatter(
              Math.max(...prices).toString()
            );
            if (min !== max) {
              return `${min} - ${max}`;
            } else {
              return `${min}`;
            }
          }
        }

        const price = tag?.price || 0;
        return Helpers.currencyFormatter(price);
      }
    },
    formatPrice(amount: number): string {
      return Helpers.currencyFormatter(amount);
    },
    async handleFiltering(searchObject: FilterProductListEvent) {
      this.tagSortBy = searchObject.tagSortOption || 1;

      if (searchObject.searchValue != null) {
        this.tagSearchValue = searchObject.searchValue
          .toLowerCase()
          .replace(/ +/g, ' ');
      }

      await this.getTags();

      this.filteredTags = this.tags
        .filter((t) => {
          return this.tagSearchValue.split(' ').some((value: string) => {
            value = value.toLocaleLowerCase();
            return (
              (t.hashtag || '').toLocaleLowerCase().includes(value) ||
              (t.title || '').toLocaleLowerCase().includes(value) ||
              (t.description || '').toLocaleLowerCase().includes(value)
            );
          });
        })
        .sort(this.sortTags);
      this.setProductIds(this.filteredTags.map((x) => x.productId));
      this.$emit('update:filteredCount', this.filteredTags.length);
    },
    async getTags() {
      this.setLoading(true);
      const responseData = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_SELLER_PRODUCTS,
        {
          accountId: this.currentAccount?.accountId,
          tagType: this.currentFilter,
          productSource: this.tagFilter?.productSource
        }
      );

      if (responseData.status === APIResponseStatus.OK) {
        this.tags = (responseData.data as Tag[]).sort(this.sortTags);
        this.filteredTags = this.tags;
      }
      this.setLoading(false);
    },
    sortTags(a: Tag, b: Tag): number {
      let results = 0;
      switch (this.tagSortBy) {
        case TagSortOptions.LEASTRECENT: {
          const hashTagNoA = a.hashtag.replace(/\D/g, '') || '0';
          const hashTagNoB = b.hashtag.replace(/\D/g, '') || '0';
          results = +hashTagNoA - +hashTagNoB;
          break;
        }
        case TagSortOptions.HIGHESTSALES:
          results = b.totalSales - a.totalSales;
          break;
        case TagSortOptions.LOWESTSALES:
          results = a.totalSales - b.totalSales;
          break;
        case TagSortOptions.MOSTRECENT: // Most recent will have the highest tag as well
        default: {
          // Get just the number portion out of the tag to use for sorting, use 0 if no numbers in the tag
          const hashTagNoA = a.hashtag.replace(/\D/g, '') || '0';
          const hashTagNoB = b.hashtag.replace(/\D/g, '') || '0';
          results = +hashTagNoB - +hashTagNoA;
        }
      }

      return results;
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    }
  }
});
