
import { h, defineComponent } from 'vue';

export default defineComponent({
  name: 'MetaPixelScript',
  functional: true,
  props: {
    pixelId: {
      type: String,
      default: ''
    }
  },
  render() {
    return h('script', {
      pixelId: this.pixelId,
      src: '/js/meta-pixel-script.js'
    });
  }
});
