
import { defineComponent } from 'vue';
import {
  PreRegShipping,
  PreRegStatus,
  SellerAccount
} from '@/pre-registration/PreRegistration.vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { store } from '@/store';
export default defineComponent({
  name: 'PreRegShipping',
  props: {
    shipping: {
      type: Object as () => PreRegShipping,
      required: true
    },
    status: {
      type: String as () => PreRegStatus
    },
    loading: {
      type: String as () => ButtonLoadingState
    },
    seller: {
      type: Object as () => SellerAccount
    }
  },
  emits: ['update:shipping', 'next', 'back'],
  data() {
    return {
      getCodeState: ButtonLoadingState.initial
    };
  },
  computed: {
    customColor(): string {
      return store.getters['global/getCustomColor'] as string;
    }
  },
  methods: {
    saveShipping() {
      this.$emit('update:shipping', this.shipping);
      this.$emit('next');
    },
    back() {
      this.$emit('back');
    }
  }
});
