
import { defineComponent } from 'vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { store } from '@/store';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseCode,
  APIResponseStatus,
  Context,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapGetters } from 'vuex';
import { Buyer } from './BuyerSettingsInterfaces';
import Helpers from '@/helpers';

export default defineComponent({
  data() {
    return {
      newNumber: '',
      email: '',
      confirmationCode: '',
      hasSentConfirmationCode: false,
      getCodeState: ButtonLoadingState.initial
    };
  },
  computed: {
    ...mapGetters({
      currentBuyer: 'auth/getCurrentBuyer'
    })
  },
  methods: {
    gotoSettings() {
      this.$router.push({ name: 'buyer-settings' });
    },
    async onSubmit() {
      if (this.hasSentConfirmationCode && this.confirmationCode) {
        await this.changeNumber();
      } else {
        await this.requestConfirmationCode();
      }
    },
    async requestConfirmationCode() {
      try {
        const payload = {
          context: Context.UPDATE_MOBILE_NUMBER,
          number: Helpers.prefixPhoneNumber(this.newNumber),
          email: (this.currentBuyer as Buyer).email
        };
        this.getCodeState = ButtonLoadingState.loading;

        const confirmationCodeResponse =
          await FirebaseRequest.createRequest<string>(
            FirebaseRequestTypes.REQUEST_CONFIRMATION_CODE,
            payload
          );

        if (confirmationCodeResponse) {
          if (
            confirmationCodeResponse.code ===
            APIResponseCode.PHONE_NUMBER_ALREADY_IN_USE
          ) {
            store.commit('global/setBannerStatus', {
              text: BannerMessageStrings.BUYER_REENTERED_PHONE_NUMBER,
              type: BannerType.error
            });
            this.getCodeState = ButtonLoadingState.complete;
            return;
          }

          this.getCodeState = ButtonLoadingState.complete;
          this.hasSentConfirmationCode = true;
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_SENDING_CONFIRMATION_CODE,
            type: BannerType.success
          });
        }
      } catch (ex) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.hasSentConfirmationCode = false;
      }
    },
    async changeNumber() {
      try {
        this.getCodeState = ButtonLoadingState.loading;
        const payload = {
          context: Context.UPDATE_MOBILE_NUMBER,
          code: this.confirmationCode,
          number: Helpers.prefixPhoneNumber(this.newNumber)
        };

        const confirmationCodeResponse = await FirebaseRequest.createRequest<{
          token: string;
        }>(FirebaseRequestTypes.VALIDATE_CONFIRMATION_CODE, payload);
        if (confirmationCodeResponse.status !== APIResponseStatus.OK) {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
          this.getCodeState = ButtonLoadingState.complete;
          return;
        }

        const buyerResponse = await FirebaseRequest.createRequest<Buyer>(
          FirebaseRequestTypes.GET_BUYER_INFO,
          {}
        );
        if (
          buyerResponse.status === APIResponseStatus.OK &&
          buyerResponse.data
        ) {
          store.commit('auth/setCurrentBuyer', buyerResponse.data);
          await this.$router.push({ path: '/user/settings' });
        }
      } catch (ex) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.getCodeState = ButtonLoadingState.complete;
      }
    }
  }
});
