
import { defineComponent } from 'vue';
import { InputType } from '@/common/controls/BoostInput.vue';
import Helpers from '@/helpers';
import { useField } from 'vee-validate';

export interface Keyword {
  id: number;
  label: string | number;
  isLocked?: boolean;
}

export default defineComponent({
  name: 'BoostKeywords',
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String as () => InputType,
      default: 'text'
    },
    modelValue: {
      type: Array as () => Keyword[]
    },
    name: {
      type: String
    },
    description: {
      type: String
    },
    rules: {
      type: String
    },
    placeholder: {
      type: String
    },
    inputId: {
      type: String,
      required: true
    },
    attributes: {
      type: String
    },
    maxCount: {
      type: Number,
      default: 5
    },
    minimumValue: {
      type: Number,
      default: null
    },
    hideTray: {
      type: Boolean
    },
    /**
     * Turn this on when using the keyword-added / removed hooks to avoid duplicated keys
     */
    muteChanges: {
      type: Boolean,
      default: false
    },
    customError: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'input', 'keyword-added', 'keyword-removed'],
  setup(props) {
    // Vee Validate
    const { value, errorMessage, handleBlur, handleChange, meta } = useField(
      props.inputId,
      props.rules,
      {
        initialValue: props.modelValue
      }
    );

    return {
      value,
      handleChange,
      handleBlur,
      errorMessage,
      meta
    };
  },
  compatConfig: { COMPONENT_V_MODEL: false },
  data() {
    return {
      innerValue: '',
      default: true,
      keywords: [] as Keyword[],
      internalErrors: ''
    };
  },
  computed: {
    canAddKeyword(): boolean {
      return this.keywords.length <= this.maxCount - 1;
    },
    canClickAddKeyword(): boolean {
      return this.canAddKeyword && this.innerValue !== '';
    },
    internalFailed(): boolean {
      return this.internalErrors.length > 0;
    }
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.keywords = newVal;
      },
      deep: true
    },
    keywords(newVal) {
      if (!this.default && !this.muteChanges) {
        this.$emit('update:modelValue', newVal);
        this.$emit('input', newVal);
      } else if (this.keywords.length > 0 && !this.muteChanges) {
        this.$emit('update:modelValue', newVal);
        this.$emit('input', newVal);
        this.default = false;
      }
    },
    innerValue() {
      this.clearErrors();
    },
    customError(val) {
      if (val.length) {
        this.internalErrors = val;
      } else {
        this.internalErrors = '';
      }
    }
  },
  mounted() {
    if (this.modelValue) {
      this.keywords = this.modelValue;
    }
  },
  methods: {
    addKeyword() {
      if (!this.canClickAddKeyword) {
        this.throwError(`The maximum number of options is ${this.maxCount}.`);
        return;
      }
      // Duplicate check
      const keywords = this.keywords.map((keywordToCheck: Keyword) =>
        keywordToCheck.toString().toLowerCase()
      );
      if (
        this.innerValue &&
        keywords.includes(this.innerValue.toString().toLowerCase())
      ) {
        this.clearErrors();
        this.throwError('Cannot add a duplicate option.');
        return;
      }

      if (this.minimumValue) {
        // If the type === 'currency', then the minimumValue should be converted
        // to cents (* 100) since that is what the input value is converted to.
        const minValue =
          this.type === 'currency'
            ? this.minimumValue * 100
            : this.minimumValue;
        if (parseFloat(this.innerValue) < minValue) {
          this.clearErrors();
          this.throwError(
            `Cannot add a value less than ${
              this.type === 'currency' ? '$' : ''
            }${this.minimumValue}.`
          );
          return;
        }
      }

      // Add Keyword
      const keyword: Keyword = {
        id: this.keywords.length + 1,
        label: this.innerValue,
        isLocked: false
      };
      if (!this.muteChanges) {
        this.keywords.push(keyword);
      }
      this.innerValue = '';
      this.$emit('keyword-added', keyword);
    },
    removeKeyword(index: number) {
      this.keywords = this.keywords.filter(
        (keyword: Keyword, arrayIndex: number) => arrayIndex !== index
      );
      this.$emit('keyword-removed', index);
    },
    throwError(error: string) {
      this.internalErrors = error;
    },
    formatPrice(amount: string | number): string {
      return Helpers.currencyFormatter(amount);
    },
    clearErrors() {
      this.internalErrors = '';
    }
  }
});
