
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';
import { defineComponent } from 'vue';
import Helpers from '@/helpers';
import { mapGetters, mapMutations } from 'vuex';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import {
  AttributionStatus,
  AttributionType
} from '@/../models/firebase/product';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import DownloadTagsPopupComponent, {
  DownloadTagItem
} from '@/common/DownloadTagsPopupComponent.vue';

interface AttributionItem {
  identifier: string;
  name: string;
  showDetails: boolean;
  status: AttributionStatus;
  type: AttributionType;
  sold: number;
  revenue: number;
  conversion: number;
  disabled: boolean;
  hashtag: string;
  imageUrl: string;
  productSource: string;
  productId: string;
  accountId: string;
}

export default defineComponent({
  name: 'AttributionList',
  components: {
    DownloadTagsPopupComponent
  },
  data() {
    return {
      showDownloadTags: false,
      AttributionStatus,
      allData: [] as AttributionItem[],
      selectedRow: {} as DownloadTagItem,
      filterOptions: [
        { name: 'All', value: 'all' },
        { name: 'Active', value: 'Active' },
        { name: 'Inactive', value: 'Inactive' }
      ],
      filter: 'all',
      filter2Options: [
        { name: 'All', value: 'all' },
        { name: 'Account', value: 'Account' },
        { name: 'Advertisement', value: 'Advertisement' },
        { name: 'Influencer', value: 'Influencer' },
        { name: 'Print', value: 'Print' },
        { name: 'Packaging', value: 'Packaging' },
        { name: 'Podcast', value: 'Podcast' }
      ],
      secondFilter: 'all',
      sort: '',
      sortOptions: [
        { name: 'By Tag', value: 'tag' },
        { name: 'By Amount Sold', value: 'sold' },
        { name: 'By Revenue', value: 'revenue' },
        { name: 'By Conversion Rate', value: 'conversion' },
        { name: 'By Alphabetical Order', value: 'name' },
        { name: 'By Recently Added', value: 'new' }
      ],
      columns: [
        {
          id: 'image',
          label: ''
        },
        {
          id: 'name',
          label: 'Name'
        },
        {
          id: 'type',
          label: 'Type'
        },
        {
          id: 'tag',
          label: 'Tag'
        },
        {
          id: 'sold',
          label: 'Sold'
        },
        {
          id: 'revenue',
          label: 'Revenue'
        },
        {
          id: 'conversion',
          label: 'Conversion'
        },
        {
          id: 'menu',
          label: ''
        }
      ] as ColumnItem[],
      menuItems: [
        {
          label: 'View Product',
          onClick: (row) => {
            const productId = row.productId as string;
            this.$router.push({
              name: 'seller-product-detail',
              params: { productId }
            });
          }
        },
        {
          label: 'View Orders',
          onClick: (row) => {
            const hashtag = row.hashtag as string;
            this.$router.push({
              name: 'seller-orders',
              params: { hashtag }
            });
          }
        },
        {
          label: 'Download Tags',
          onClick: (row) => {
            this.downloadEventHandler(row);
          }
        }
      ] as ContextMenuItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      getSearchText: 'global/getSearchText',
      productSource: 'accounts/selectedFeatureTypeId'
    }),
    filteredData(): AttributionItem[] {
      return this.allData
        .filter((x) => x.productSource === this.productSource)
        .filter(
          (x) =>
            this.filter === 'all' ||
            x.status ===
              AttributionStatus[this.filter as keyof typeof AttributionStatus]
        )
        .filter(
          (x) =>
            this.secondFilter === 'all' ||
            x.type ===
              AttributionType[this.secondFilter as keyof typeof AttributionType]
        )
        .filter(
          (x) =>
            !this.getSearchText ||
            x.hashtag
              .toLocaleLowerCase()
              .includes(this.getSearchText.toLocaleLowerCase()) ||
            x.name
              .toLocaleLowerCase()
              .includes(this.getSearchText.toLocaleLowerCase())
        )
        .sort((a: AttributionItem, b: AttributionItem) => {
          let sortResult = 0;
          if (!this.sort) return sortResult;
          switch (this.sort) {
            case 'tag':
              sortResult = a.hashtag.localeCompare(b.hashtag);
              break;
            case 'sold':
              sortResult = b.sold - a.sold;
              break;
            case 'name':
              sortResult = a.name.localeCompare(b.name);
              break;
            case 'revenue':
              sortResult = b.revenue - a.revenue;
              break;
            case 'conversion':
              sortResult = b.conversion - a.conversion;
              break;
            default:
              break;
          }
          return sortResult;
        });
    }
  },
  async mounted() {
    if (this.currentAccount === null) {
      return;
    }
    await this.getData();
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    handlePopupVisibleChange(show: boolean) {
      this.showDownloadTags = show;
    },
    downloadEventHandler(tag: DownloadTagItem) {
      this.selectedRow = tag;
      this.showDownloadTags = true;
    },
    decodeStatus(row: AttributionItem): string {
      return row.status === AttributionStatus.Inactive ? 'Inactive' : 'Active';
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    decodeType(row: AttributionItem): string {
      let results = '';
      switch (row.type) {
        case AttributionType.Account:
          results = 'Account';
          break;
        case AttributionType.Advertisement:
          results = 'Advertisement';
          break;
        case AttributionType.Audio:
          results = 'Audio';
          break;
        case AttributionType.Influencer:
          results = 'Influencer';
          break;
        case AttributionType.Magazine:
          results = 'Magazine';
          break;
        case AttributionType.Newspaper:
          results = 'Newspaper';
          break;
        case AttributionType.Packaging:
          results = 'Packaging';
          break;
        case AttributionType.Print:
          results = 'Print';
          break;
        case AttributionType.Podcast:
          results = 'Podcast';
          break;
        default:
          break;
      }
      return results;
    },
    async getData() {
      this.setLoading(true);
      try {
        const payload = { accountId: this.currentAccount.accountId };
        const responseData = await FirebaseRequest.createRequest<
          AttributionItem[]
        >(FirebaseRequestTypes.GET_SELLER_ATTRIBUTION_LIST, payload);

        if (responseData.status === APIResponseStatus.OK && responseData.data) {
          this.allData = responseData.data;
        }
      } catch (err) {
        console.error(err);
      }
      this.setLoading(false);
    }
  }
});
