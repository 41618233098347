import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ProductState, RootState } from './types';
import { TagDetail, TagSku, TagVariant } from '@/models/tag';
import { TagType } from '@/../enums/tagType';
import { Attribution } from '@/models/firebase/product';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';

const productState: ProductState = {
  currentProduct: null,
  currentFilter: TagType.PRODUCT,
  productIds: []
};

const productActions: ActionTree<ProductState, RootState> = {
  async setupCurrentProduct(
    context,
    payload: { accountId: string; productId: string }
  ) {
    let currentProduct: TagDetail | undefined;

    if (payload.productId && payload.accountId) {
      const responseData = await FirebaseRequest.createRequest<TagDetail>(
        FirebaseRequestTypes.GET_SELLER_TAG,
        {
          accountId: payload.accountId,
          productId: payload.productId
        }
      );
      if (responseData.status === APIResponseStatus.OK) {
        currentProduct = responseData.data;
      }
    }
    context.commit('setCurrentProduct', currentProduct);
  },
  async saveCurrentProductToDb(context) {
    if (
      context.state.currentProduct &&
      context.state.currentProduct.accountId
    ) {
      const responseData = await FirebaseRequest.createRequest<TagDetail>(
        FirebaseRequestTypes.SAVE_TAG,
        {
          product: context.state.currentProduct
        }
      );
      if (responseData.status === APIResponseStatus.OK && responseData.data) {
        context.state.currentProduct = responseData.data;
      } else {
        //TODO: Show error message!!
      }
    }
  },
  getNext(context, currentId: string): string | null {
    const index = context.state.productIds.findIndex((x) => x === currentId);
    return index >= context.state.productIds.length - 1
      ? null
      : context.state.productIds[index + 1];
  },
  getPrevious(context, currentId: string): string | null {
    const index = context.state.productIds.findIndex((x) => x === currentId);
    return index <= 0 ? null : context.state.productIds[index - 1];
  },
  getCurrentIndex(context, currentId: string): number {
    const index = context.state.productIds.findIndex((x) => x === currentId);
    return index;
  }
};

const productMutations: MutationTree<ProductState> = {
  setCurrentProduct: (state: ProductState, payload: TagDetail) => {
    state.currentProduct = payload;
  },
  setCurrentFilter: (state: ProductState, payload: TagType) => {
    state.currentFilter = payload;
  },
  setCurrentProductAttributions: (
    state: ProductState,
    payload: Attribution[]
  ) => {
    if (state.currentProduct) {
      state.currentProduct.attributions = payload;
    }
  },
  setCurrentProductVariants: (
    state: ProductState,
    payload: { variants: TagVariant[]; skus: TagSku[] }
  ) => {
    if (state.currentProduct) {
      state.currentProduct.variants = payload.variants;
      state.currentProduct.skus = payload.skus;
    }
  },
  setProductIds: (state: ProductState, payload: string[]) => {
    state.productIds = payload;
  }
};

const productGetters: GetterTree<ProductState, RootState> = {
  currentProduct(state): TagDetail | null {
    return state.currentProduct;
  },
  currentFilter(state): TagType | 'All' {
    return state.currentFilter;
  },
  getProductListLength(state): number {
    return state.productIds.length;
  }
};

export const product = {
  actions: productActions,
  mutations: productMutations,
  state: productState,
  getters: productGetters,
  namespaced: true
};
