
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';
import {
  Attribution,
  AttributionType,
  AttributionStatus
} from '@/../models/firebase/product';
import { defineComponent, PropType } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { SelectOption } from '@/common/controls/BoostSelect/Select.interface';
import { TagDetail } from '@/models/tag';
import helpers from '@/helpers';

export default defineComponent({
  name: 'ProductEditAttributions',
  props: {
    product: {
      type: Object as PropType<TagDetail>,
      required: true
    }
  },
  emits: {
    backNavigationRequested() {
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveAttributions(payload: Attribution[]) {
      return true;
    }
  },
  data() {
    return {
      AttributionType,
      AttributionStatus,
      attributions: [] as Attribution[],
      columns: [
        {
          id: 'name',
          label: 'Name/Location'
        },
        {
          id: 'attributionType',
          label: 'Type'
        },
        {
          id: 'status',
          label: 'Status'
        },
        {
          id: 'action',
          lable: ''
        }
      ] as ColumnItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    }),
    disableDone(): boolean {
      return (
        !!this.attributions?.length &&
        this.attributions.some((x) => !x.name?.length)
      );
    }
  },
  created() {
    if (!this.product) {
      this.$router.push({
        name: 'seller-products'
      });
      return;
    }
    // Get a copy of the current attributions to start, these should be abandoned if navigate back
    // or saved back to the store when the user clicks the save button
    if (this.product?.attributions?.length) {
      (this.product.attributions as Attribution[]).forEach((x) => {
        this.attributions.push({ ...x });
      });
    }
  },
  methods: {
    ...mapMutations('product', ['setCurrentProductAttributions']),
    getOptions(enumType: unknown): Array<SelectOption> {
      if (enumType && typeof enumType === 'object') {
        return Object.values(enumType)
          .filter((x) => typeof x === 'string')
          .map((x) => ({ name: x, value: parseInt((enumType as never)[x]) }));
      }
      return [];
    },
    addAttribution() {
      this.attributions.push({
        name: '',
        type: AttributionType.Advertisement,
        status: AttributionStatus.Active,
        identifier: helpers.getTemporaryId()
      });
    },
    deleteAttribution(attribution: Attribution) {
      const index = this.attributions.indexOf(attribution);
      this.attributions.splice(index, 1);
    },
    goBack() {
      this.$emit('backNavigationRequested');
    },
    async commitChanges() {
      this.$emit('saveAttributions', this.attributions);
    }
  }
});
