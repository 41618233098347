
import { defineComponent } from 'vue';
import { AudienceMember } from '@/models/audienceMember';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Helpers from '@/helpers';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';

interface AffiliateDetails {
  name: string;
  email: string;
  phone: string;
  signup: number;
  status: string;
  tagCount: number;
  salesCount: number;
  revenue: number;
  earnings: number;
  conversionRate: number;
  commission?: number;
}

export default defineComponent({
  name: 'AudienceAffiliateMember',
  emits: ['update:filteredCount'],
  data() {
    return {
      memberId: '',
      nextId: '',
      previousId: '',
      index: 1,
      member: null as null | AudienceMember,
      affiliateDetails: undefined as unknown as AffiliateDetails
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      audienceListLength: 'audience/getAudienceListLength'
    })
  },
  mounted() {
    if (this.$route.params.memberId) {
      this.memberId = this.$route.params.memberId as string;
    }
    this.getAudience();
  },
  methods: {
    ...mapMutations('global', ['setLoading']),
    ...mapMutations('orders', ['setBuyerName']),
    ...mapActions('audience', ['getNext', 'getPrevious', 'getCurrentIndex']),
    async getAudience() {
      this.setLoading(true);
      const payload = {
        accountId: this.currentAccount.accountId,
        affiliateId: this.memberId
      };
      const responseData = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_AFFILIATE_DETAILS,
        payload
      );

      if (responseData.status === APIResponseStatus.OK) {
        this.member = responseData.data as AudienceMember;
        this.affiliateDetails = responseData.data as AffiliateDetails;

        this.index = (await this.getCurrentIndex(this.memberId)) + 1;
        this.nextId = await this.getNext(this.memberId);
        this.previousId = await this.getPrevious(this.memberId);

        this.setLoading(false);
      } else {
        this.setLoading(false);
        this.$router.push({
          name: 'PageNotFound'
        });
      }
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    processDate(date: number | string) {
      return Helpers.processDate(new Date(date).getTime());
    },
    goBack() {
      this.$router.push({
        name: 'audience'
      });
    },
    async previous() {
      if (this.previousId) {
        await this.$router.push({
          name: 'audience-advocate-member',
          params: {
            memberId: this.previousId
          }
        });
        this.memberId = this.previousId;
        this.getAudience();
      }
    },
    async next() {
      if (this.nextId) {
        await this.$router.push({
          name: 'audience-advocate-member',
          params: {
            memberId: this.nextId
          }
        });
        this.memberId = this.nextId;
        this.getAudience();
      }
    }
  }
});
