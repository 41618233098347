
import { defineComponent } from 'vue';
import { OrderSearch } from './OrdersList.vue';
import OrdersList from './OrdersList.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'OrdersRoot',
  components: {
    OrdersList
  },
  data() {
    return {
      searchObject: {
        filterValue: 'All',
        sortBy: 'Date',
        searchValue: ''
      } as OrderSearch,
      transactionCount: 0,
      filterOptions: [
        { name: 'All', value: 'All' },
        { name: 'Pending', value: 'Pending' },
        { name: 'Fulfilled', value: 'Fulfilled' },
        { name: 'Refunded', value: 'Refunded' }
      ],
      sortOptions: [
        { name: 'Most Recent', value: 'Date' },
        { name: 'Least Recent', value: 'DateAsc' },
        { name: 'Sales Highest', value: 'Amount' },
        { name: 'Sales Lowest', value: 'AmountAsc' },
        { name: 'Name', value: 'Name' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getSearchText: 'global/getSearchText'
    })
  },
  watch: {
    getSearchText(val) {
      this.searchObject.searchValue = val;
    }
  },
  methods: {
    async exportOrdersToCSV() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (this.$refs.transactionsList as any).exportOrdersToCSV();
    }
  }
});
