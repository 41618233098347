
import { defineComponent } from 'vue';
import { TagSortOptions } from '@/constants';
import { FilterProductListEvent } from '@/../enums/productTypes';
import { mapGetters, mapMutations } from 'vuex';
import { TagType } from '@/../enums/tagType';
import { ProductSource } from '@/../models/sellerOrder';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

export default defineComponent({
  props: {
    filteredCount: {
      type: Number
    }
  },
  emits: ['addNewTag', 'filterTagList'],
  data() {
    return {
      sort: TagSortOptions.MOSTRECENT,
      sortOptions: [
        { name: 'Most Recent', value: TagSortOptions.MOSTRECENT },
        { name: 'Least Recent', value: TagSortOptions.LEASTRECENT },
        { name: 'Highest Sales', value: TagSortOptions.HIGHESTSALES },
        { name: 'Lowest Sales', value: TagSortOptions.LOWESTSALES }
      ],
      searchString: ''
    };
  },
  computed: {
    ...mapGetters({
      getSearchText: 'global/getSearchText',
      filter: 'product/currentFilter',
      productSource: 'accounts/selectedFeatureTypeId'
    }),
    filterOptions(): { name: string; value: string; disabled: boolean }[] {
      if (this.productSource === 'Kiosk') {
        return [
          { name: 'Products', value: TagType.PRODUCT, disabled: false },
          { name: 'Digital', value: TagType.TIP, disabled: false },
          { name: 'Subscriptions', value: 'Subscriptions', disabled: true }
        ];
      }
      const options = [
        { name: 'Products', value: TagType.PRODUCT, disabled: false },
        { name: 'Subscriptions', value: 'Subscriptions', disabled: true }
      ];
      return options;
    }
  },
  watch: {
    getSearchText(val: string) {
      this.searchString = val;
      this.setTagFilter();
    }
  },
  created() {
    const productSource = this.$route.query.productSource?.toString();
    if (productSource) {
      this.productSource = productSource;
      if (productSource === ProductSource.Shopify) {
        // Show banner message
        this.setBannerStatus({
          text: BannerMessageStrings.SHOPIFY_TAGS_ADDED,
          type: BannerType.info
        });
      }
    }
    this.viewTags();
  },
  methods: {
    ...mapMutations({
      setFilter: 'product/setCurrentFilter',
      setBannerStatus: 'global/setBannerStatus'
    }),
    setTagFilter() {
      this.viewTags();
    },
    viewTags() {
      this.setFilter(this.filter as TagType);
      const filterProductListEventArgs: FilterProductListEvent = {
        filterType: this.filter,
        searchValue: this.searchString,
        tagSortOption: this.sort,
        productSource: this.productSource
      };
      this.$emit('filterTagList', filterProductListEventArgs);
    }
  }
});
