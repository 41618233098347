
import { defineComponent } from 'vue';
import { ProductSource, SellerOrder } from '@/../models/sellerOrder';
import Helpers from '@/helpers';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { TagType } from '@/../enums/tagType';
import { store } from '@/store';

export default defineComponent({
  name: 'TransactionDetail',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (vm as any).returnTo = from.name;
    });
  },
  data() {
    return {
      TagType,
      ProductSource,
      order: null as null | SellerOrder,
      userTrackingNo: '',
      userCarrier: '',
      returnTo: 'seller-orders',
      nextId: '',
      previousId: '',
      index: 1,
      loadingMarkFulfilled: ButtonLoadingState.initial,
      loadingRefund: ButtonLoadingState.initial,
      loadingSendTracking: ButtonLoadingState.initial,
      loadingExport: ButtonLoadingState.initial,
      trackingInfo: {
        sellerName: '',
        accountId: '',
        productName: '',
        transactionId: '',
        trackingNo: '',
        carrier: '',
        mobileNumber: '',
        sendMessage: false
      }
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      orderListLength: 'orders/getOrderListLength',
      productSource: 'accounts/selectedFeatureTypeId'
    })
  },
  mounted() {
    const orderId = this.$route.params.orderId as string;
    this.getOrder(orderId);
  },
  methods: {
    ...mapMutations('global', ['setLoading']),
    ...mapActions('orders', ['getNext', 'getPrevious', 'getCurrentIndex']),

    async getOrder(orderId: string) {
      this.setLoading(true);
      try {
        const result = await FirebaseRequest.createRequest<SellerOrder>(
          FirebaseRequestTypes.GET_SELLER_ORDER_BY_ID,
          {
            accountId: this.currentAccount.accountId,
            transactionId: orderId
          }
        );
        if (result.status === 'ok') {
          if (result.data?.userTrackingNo) {
            this.userTrackingNo = result.data.userTrackingNo;
          } else {
            this.userTrackingNo = '';
          }
          if (result.data?.userCarrier) {
            this.userCarrier = result.data.userCarrier;
          } else {
            this.userCarrier = '';
          }

          this.order = result.data as SellerOrder;

          this.index = (await this.getCurrentIndex(orderId)) + 1;
          this.nextId = await this.getNext(orderId);
          this.previousId = await this.getPrevious(orderId);

          this.setLoading(false);
        } else {
          //
        }

        // this.loadingMarkFulfilled = ButtonLoadingState.complete;
      } catch (err) {
        console.log(err);
        this.setLoading(false);
        // this.loadingMarkFulfilled = ButtonLoadingState.complete;
      }
    },
    processDate(date: string) {
      return Helpers.processDate(new Date(date).getTime());
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },

    async setFulfillTransaction(rowData: SellerOrder, fulfilled: boolean) {
      if (!this.order) {
        return;
      }
      this.loadingMarkFulfilled = ButtonLoadingState.loading;

      try {
        const result = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.SET_TRANSACTION_FULFILLMENT,
          {
            accountId: rowData.accountId,
            transactionId: rowData.transactionId,
            fulfilled
          }
        );
        if (result.status === 'ok') {
          // TODO: update status pill
          this.order.fulfilled = fulfilled;
        } else {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }

        this.loadingMarkFulfilled = ButtonLoadingState.complete;
      } catch (err) {
        console.log(err);
        this.loadingMarkFulfilled = ButtonLoadingState.complete;
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    },

    async refundTransaction(accountId: string, transactionId: string) {
      this.loadingRefund = ButtonLoadingState.loading;
      try {
        const result = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.REFUND_PURCHASE,
          { accountId, transactionId }
        );
        // TODO: Change the cloud function to return the standard result object
        if (result.status === APIResponseStatus.ERROR) {
          // TODO: update status pill
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }
        if (this.order) {
          this.order.refunded = true;
        }
        this.loadingRefund = ButtonLoadingState.complete;
      } catch {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.loadingRefund = ButtonLoadingState.complete;
      }
    },

    async saveTrackingInformation() {
      if (!this.order) {
        return;
      }
      const trackingNo = this.userTrackingNo;
      const carrier = this.userCarrier;
      const accountId = this.currentAccount.accountId;
      const sellerName = this.currentAccount.name;
      const productName = this.order.productHashtag;
      const mobileNumber = this.order.userMobileNumber;

      this.trackingInfo = {
        sellerName,
        accountId,
        productName,
        transactionId: this.order.transactionId,
        trackingNo,
        carrier,
        mobileNumber,
        sendMessage: true
      };

      this.loadingSendTracking = ButtonLoadingState.loading;
      try {
        this.trackingInfo.sellerName = this.currentAccount.name;
        this.trackingInfo.accountId = this.currentAccount.accountId;

        const result = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.SAVE_TRACKING,
          this.trackingInfo
        );
        if (result.status === 'ok') {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.CHANGES_UPDATED,
            type: BannerType.success
          });
        } else {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
        }
      } catch (err) {
        console.log(err);
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.loadingSendTracking = ButtonLoadingState.complete;
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    },
    goBack() {
      this.$router.push({ name: this.returnTo });
    },
    async previous() {
      if (this.previousId) {
        await this.$router.push({ path: `/store/orders/${this.previousId}` });
        this.getOrder(this.previousId);
      }
    },
    async next() {
      if (this.nextId) {
        await this.$router.push({ path: `/store/orders/${this.nextId}` });
        this.getOrder(this.nextId);
      }
    }
  }
});
