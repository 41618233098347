import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-col justify-start gap-3 pb-4 text-gray-900" }
const _hoisted_2 = { class: "py-5 text-sm font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductsGallery = _resolveComponent("ProductsGallery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "Featured (" + _toDisplayString(_ctx.products.length) + ")", 1),
    _createVNode(_component_ProductsGallery, {
      products: _ctx.products,
      "show-brand-logo": false
    }, null, 8, ["products"])
  ]))
}