
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

const FooterComponent = defineComponent({
  data() {
    return {
      year: new Date()
    };
  },
  computed: {
    ...mapGetters({ isBranded: 'global/isBranded' })
  }
});
export default FooterComponent;
