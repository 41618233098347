import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "section-header" }
const _hoisted_2 = {
  key: 0,
  class: "section-header__message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'section-header__title': !_ctx.isCompact,
        'section-header__title-compact': _ctx.isCompact
      })
    }, _toDisplayString(_ctx.title), 3),
    (!_ctx.hasContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["section-header__message", {
        'section-header__message__full-width': _ctx.isFullWidth
      }])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
  ]))
}