
import { defineComponent } from 'vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapGetters, mapMutations } from 'vuex';
import helpers from '@/helpers';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

interface AnalyticData {
  tagCount: number;
  grossRevenue: number;
  audienceCount: number;
  conversionRate: number;
  checkouts: {
    mostPopular: string;
    percents: { name: string; value: string }[];
  };
  sessions: {
    total: string;
    counts: { name: string; value: string }[];
  };
  average: {
    average: number;
    details: { name: string; value: string }[];
  };
  returningCustomers: {
    rate: string;
    details: { name: string; value: string }[];
  };
  savedForLater: {
    total: string;
    counts: { name: string; value: string }[];
  };
}

export default defineComponent({
  name: 'GeneralAnalytics',
  data() {
    return {
      cards: [
        {
          title: 'Gross Revenue',
          message: '$0.00'
        },
        {
          title: 'Conversion',
          message: '0.00%'
        },
        {
          title: 'Tags',
          message: '0'
        },
        {
          title: 'Audience',
          message: '0'
        }
      ],
      detailedCardsOne: [
        {
          title: 'Most Popular Checkout Method',
          message: '',
          summary:
            'Showing breakdown of the various checkout methods in (%). The order is defined by highest to lowest.',
          details: [
            { name: 'Link to Checkout', value: '0%' },
            { name: 'QR to Checkout', value: '0%' },
            { name: 'SMS to Checkout', value: '0%' }
          ]
        },
        {
          title: 'Total Checkout Sessions',
          message: '0',
          summary:
            'Showing breakdown of Total Sessions by stage reached at the checkout page.',
          details: [
            { name: 'Successful', value: '0' },
            { name: 'Unfinished', value: '0' }
          ]
        }
      ],
      detailedCardsTwo: [
        {
          title: 'Returning Customer Rate',
          message: '0%',
          summary:
            'Showing % breakdown of returning customers by number of products purchased.',
          details: [
            { name: '5+ Purchases', value: '0%' },
            { name: '2+ Purchases', value: '0%' },
            { name: 'Single Purchase', value: '0%' }
          ]
        },
        {
          title: 'Average Order Value',
          message: '$0',
          summary:
            'Showing breakdown of Average Order Value from highest to lowest purchase amount.',
          details: [
            { name: 'Highest Purchase Amount', value: '$0' },
            { name: 'Lowest Purchase Amount', value: '$0' }
          ]
        }
      ],
      detailedCardsThree: [
        {
          title: 'Saved for Later',
          message: '0',
          summary:
            'Showing breakdown of existing and new customers that have saved a product to purchase later using the <a href="https://app.tagscommerce.com/store/integrations/shopify">Shopify Integration.</a>',
          details: [
            { name: 'Existing Customers', value: '0' },
            { name: 'New Customers', value: '0' }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      productSource: 'accounts/selectedFeatureTypeId'
    })
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    async refresh() {
      this.setLoading(true);
      try {
        const payload = {
          accountId: this.currentAccount.accountId,
          productSource: this.productSource
        };
        const responseData = await FirebaseRequest.createRequest<AnalyticData>(
          FirebaseRequestTypes.GET_SELLER_ANALYTICS,
          payload
        );

        if (responseData.status === APIResponseStatus.OK && responseData.data) {
          this.cards[0].message = helpers.currencyFormatter(
            responseData.data.grossRevenue
          );
          this.cards[1].message =
            responseData.data.conversionRate.toLocaleString() + '%';
          this.cards[2].message = responseData.data.tagCount.toLocaleString();
          this.cards[3].message =
            responseData.data.audienceCount.toLocaleString();

          this.detailedCardsOne[0].details =
            responseData.data.checkouts.percents;
          this.detailedCardsOne[0].message =
            responseData.data.checkouts.mostPopular;

          this.detailedCardsOne[1].details = responseData.data.sessions.counts;
          this.detailedCardsOne[1].message =
            responseData.data.sessions.total.toString();

          this.detailedCardsTwo[0].details =
            responseData.data.returningCustomers.details;
          this.detailedCardsTwo[0].message =
            responseData.data.returningCustomers.rate;

          this.detailedCardsTwo[1].details = responseData.data.average.details;
          this.detailedCardsTwo[1].message = helpers.currencyFormatter(
            responseData.data.average.average
          );

          this.detailedCardsThree[0].details =
            responseData.data.savedForLater.counts;
          this.detailedCardsThree[0].message =
            responseData.data.savedForLater.total;
        }
      } catch (err) {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        console.error(err);
      }
      this.setLoading(false);
    }
  }
});
