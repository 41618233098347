
import { defineComponent } from 'vue';
import { store } from '@/store';
import LoginBuyer from './LoginBuyer.vue';
import LoginSeller from './LoginSeller.vue';
import { UserRegistration } from '@/store/types';

export default defineComponent({
  name: 'SharedLogin',
  components: {
    LoginBuyer,
    LoginSeller
  },
  data() {
    return {
      userType: {
        BUYER: UserRegistration.BUYER,
        SELLER: UserRegistration.SELLER
      },
      buyerConfirmationCodeInputInProcess: false,
      buyerRegistrationInProcess: false,
      sellerRegistrationInProcess: false,
      buyerInfo: {},
      hashtagPrefix: ''
    };
  },
  computed: {
    isSellerLoggedIn(): boolean {
      return this.$store.state.auth.isSellerLoggedIn;
    },
    loginType(): number {
      return this.$store.state.auth.loginOrRegistrationType;
    }
  },
  mounted() {
    const userType = this.$route.query['type'] || this.userType.SELLER;
    this.setUserType(+userType);
  },
  methods: {
    setUserType(userType: UserRegistration) {
      store.commit('auth/setLoginOrRegistrationType', userType);
    }
  }
});
