
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoostStepCard',
  props: {
    number: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    hasContent: {
      type: Boolean,
      default: false
    }
  }
});
