import { createStore } from 'vuex';
import { auth } from './auth';
import { accounts } from './accounts';
import { global } from './global';
import { product } from './product';
import { orders } from './orders';
import { audience } from './audience';
import { purchases } from './purchases';
import { marketplace } from './marketplace';

export const store = createStore({
  modules: {
    auth,
    accounts,
    global,
    product,
    orders,
    audience,
    purchases,
    marketplace
  }
});
