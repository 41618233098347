
import { defineComponent } from 'vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { Address } from '@/models/mailingAddress';

export default defineComponent({
  name: 'BuyerRegistrationShipping',
  props: {
    buttonLoadingState: {
      type: String as () => ButtonLoadingState,
      required: true
    }
  },
  emits: ['completedShipping'],
  data() {
    return {
      addressInfo: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States'
      } as Address
    };
  },
  methods: {
    startRegistration() {
      this.$emit('completedShipping', this.addressInfo);
    }
  }
});
