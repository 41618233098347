
import { defineComponent } from 'vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  Token,
  TokenResult
} from '@stripe/stripe-js';
import settings from '@/settings';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import Helpers from '@/helpers';
import { mapMutations } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';

export default defineComponent({
  name: 'BuyerRegistrationShipping',
  props: {
    buttonLoadingState: {
      type: String as () => ButtonLoadingState,
      required: true
    },
    buyerInfo: {
      type: Object,
      required: true
    },
    addressInfo: {
      type: Object,
      required: true
    }
  },
  emits: ['buyerRegistered', 'update:buttonLoadingState'],
  data() {
    return {
      agreeToTerms: false,
      stripeCardElement: null as null | StripeCardElement,
      stripe: null as null | Stripe,
      stripeToken: {} as Token,
      clientSecret: '',
      cardError: '' as string | undefined
    };
  },
  async mounted() {
    this.initializeStripe();
  },
  methods: {
    ...mapMutations('global', ['setBannerStatus']),
    async initializeStripe() {
      // Instantiate stripe instance to be used for card and payment processing
      this.stripe = await loadStripe(settings.STRIPE_PUBLISHABLE_KEY);
    },
    collectCard(card: StripeCardElement) {
      this.stripeCardElement = card;
      this.cardError = '';
    },
    async createAccount() {
      // Manually trigger an empty card error
      if (!this.stripeCardElement) {
        this.cardError = 'Please enter your card details.';
        return;
      }
      // TODO: Move this method to the parent with other methods so that we don't
      //       have to mutate the props.buttonLoadingState. However this is not
      //       simple since we also have to interact with the stripe element.
      this.$emit('update:buttonLoadingState', ButtonLoadingState.loading);
      // get Stripe token
      if (this.stripe && this.stripeCardElement) {
        await this.stripe
          .createToken(this.stripeCardElement)
          .then((result: TokenResult) => {
            if (result.error) {
              console.log(result);
              // Inform the customer that there was an error
              this.cardError = result.error.message;
            } else {
              // use the token
              this.stripeToken = result.token;
            }
          });
      }

      try {
        if (this.buyerInfo && this.addressInfo) {
          const updateInfoPayload = {
            userInfo: this.buyerInfo,
            mailingAddress: this.addressInfo,
            stripeToken: this.stripeToken
          };

          // format phone number properly
          updateInfoPayload.userInfo.mobileNumber = Helpers.prefixPhoneNumber(
            this.buyerInfo?.mobileNumber
          );

          const updateResponse = await FirebaseRequest.createRequest<{
            err: {
              message: string;
            };
          }>(FirebaseRequestTypes.UPDATE_BUYER_INFO, updateInfoPayload);

          if (!updateResponse) {
            this.setBannerStatus({
              text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
              type: BannerType.error
            });
            return;
          }

          if (updateResponse.status === 'error') {
            this.setBannerStatus({
              text: updateResponse.data?.err.message || 'error',
              type: BannerType.error
            });
            this.$emit(
              'update:buttonLoadingState',
              ButtonLoadingState.complete
            );
            return;
          }
          this.$emit('update:buttonLoadingState', ButtonLoadingState.complete);
          this.$emit('buyerRegistered');
        }
      } catch (ex) {
        this.$emit('update:buttonLoadingState', ButtonLoadingState.complete);
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    }
  }
});
