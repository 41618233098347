
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  emits: ['disconnectFromStripe', 'connectToStripe'],
  computed: {
    ...mapGetters({
      buyerSettings: 'auth/getCurrentBuyer'
    })
  }
});
