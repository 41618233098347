
import { defineComponent } from 'vue';
import { TransactionSearch } from './TransactionsList.vue';
import TransactionsList from './TransactionsList.vue';
import { mapGetters } from 'vuex';
import { TagType } from '@/../enums/tagType';

export default defineComponent({
  name: 'TransactionsRoot',
  components: {
    TransactionsList
  },
  data() {
    return {
      TagType,
      searchObject: {
        filterValue: 'All',
        sortBy: 'Date',
        searchValue: ''
      } as TransactionSearch,
      transactionCount: 0,
      filterOptions: [
        { name: 'All', value: 'All' },
        { name: 'Received', value: 'Received' },
        { name: 'Refunded', value: 'Refunded' },
        { name: 'Failed', value: 'Failed' }
      ],
      sortOptions: [
        { name: 'Most Recent', value: 'Date' },
        { name: 'Least Recent', value: 'DateAsc' },
        { name: 'Highest to Lowest', value: 'Amount' },
        { name: 'Lowest to Highest', value: 'AmountAsc' },
        { name: 'Name: A-Z', value: 'NameAsc' },
        { name: 'Name: Z-A', value: 'NameDesc' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      getSearchText: 'global/getSearchText'
    })
  },
  watch: {
    getSearchText(val) {
      this.searchObject.searchValue = val as string;
    }
  },
  methods: {}
});
