
import { defineComponent } from 'vue';
import Helpers from '@/helpers';
import settings from '@/settings';
import { ExpressCheckout } from '../../../models/expressCheckout';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes,
  ProductReplyCodes
} from '@/firebaseRequest';
import {
  loadStripe,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestShippingAddressEvent,
  PaymentRequestShippingOptionEvent,
  Stripe,
  StripeCardElement
} from '@stripe/stripe-js';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { analytics, AnalyticEventType } from '@/analytics';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import { Product } from '@/models/product';
import { store } from '@/store';
import { BrandInformation } from '@/store/types';
import { TagType } from '@/../enums/tagType';
import { Charges } from '@/models/charges';
import { ShippingRate } from '@/models/shipping';
import { Sku } from '@/models/firebase/product';
import BuyerCheckoutProductPrice from '@/buyer/checkout/BuyerCheckoutProductPrice.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  components: {
    BuyerCheckoutProductPrice
  },
  data() {
    return {
      TagType,
      product: {} as Product,
      checkout: {
        charges: {
          price: 0,
          shipping: 0,
          tax: 0,
          total: 0
        }
      } as ExpressCheckout,
      shipping: {
        firstName: '',
        lastName: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States'
      },
      isShowingCardDetails: true,
      isShowingReturnPolicy: false,
      months: Helpers.getExpirationMonths(),
      years: Helpers.getExpirationYears(),
      checkoutId: '',
      isApplePay: false,
      showWalletPay: false,
      stripeLoaded: false,
      stripeCardElement: null as null | StripeCardElement,
      stripe: null as null | Stripe,
      stripeClientSecret: '',
      cardError: '' as string | undefined,
      shippingAmount: 0,
      chargeAmount: 0,
      selectedShippingOptionId: 'default',
      shippingOptions: [
        {
          id: 'default',
          label: 'Awaiting Zip Code',
          detail: 'Awaiting Zip Code',
          amount: 0
        }
      ] as ShippingRate[],
      isTransactionCompleted: false,
      isShippingCalculated: false,
      completeButtonState: ButtonLoadingState.initial,
      isExpressOrShopify: this.$route.params.isExpressOrShopify,
      taxesUpdated: false,
      taxesCalculated: false,
      readMore: true,
      hasLongDescription: undefined as boolean | undefined,
      customColor: 'default'
    };
  },
  computed: {
    ...mapGetters({
      getBrandName: 'global/getBrandName'
    }),
    sku(): Sku | null {
      if (this.product && this.product.skus) {
        return this.product.skus[this.checkout.skuId];
      }
      return null;
    },
    isReturnPolicyLink(): boolean {
      return this.product?.account?.refundPolicy?.startsWith('http') || false;
    },
    isShopifyProduct(): boolean {
      return !!this.product?.shopifyProductId || false;
    }
  },
  async mounted() {
    await this.getProductData();
    await this.initializeStripe();
    this.initWalletPayment();
  },
  updated() {
    const input = this.$refs.descriptionInput as HTMLElement;
    if (input?.scrollHeight > 0 && this.hasLongDescription === undefined) {
      this.hasLongDescription = input.offsetHeight < input.scrollHeight;
    }
  },
  methods: {
    async initializeStripe() {
      // Instantiate stripe instance to be used for card and payment processing
      this.stripe = await loadStripe(settings.STRIPE_PUBLISHABLE_KEY);
    },
    initWalletPayment() {
      if (this.stripe) {
        this.shippingAmount = this.checkout.charges
          ? this.checkout.charges.shipping
          : 0;
        this.chargeAmount = this.checkout.charges
          ? this.checkout.charges.total
          : 0;

        this.showWalletPay = true;
      }
    },
    async onShippingOptionChange(ev: PaymentRequestShippingOptionEvent) {
      if (ev.shippingOption) {
        this.selectedShippingOptionId = ev.shippingOption.id;
        this.processShippingRate();
        ev.updateWith({
          status: 'success',
          total: {
            label: 'Checkout total',
            amount: this.checkout.charges?.total || 0
          }
        });
      }
    },
    // Wallet pay shipping address change
    async onShippingAddressChange(ev: PaymentRequestShippingAddressEvent) {
      // Perform server-side request to fetch shipping options
      // CLIENT -> API(id, skuId, ev.shippingAddress.postalCode)
      // API -> charges
      try {
        if (ev.shippingAddress) {
          this.shipping.postalCode = ev.shippingAddress.postalCode || '';
          this.shipping.state = ev.shippingAddress.region || '';
          await this.getShippingRates();

          const payload = {
            hashtag: this.checkout.hashtag,
            sku: this.checkout.skuId,
            postalCode: ev.shippingAddress.postalCode
          };

          const chargesUpdate = await FirebaseRequest.createRequest<{
            charges: Charges;
          }>(FirebaseRequestTypes.GET_PRODUCT_TAXES, payload);

          if (chargesUpdate.status === APIResponseStatus.OK) {
            this.checkout.charges = chargesUpdate.data?.charges as Charges;

            // If product === shopify product then provide shipping rates

            const shippingRates: ShippingRate[] | undefined = this
              .shippingOptions?.length
              ? this.shippingOptions
              : [
                  {
                    id: 'Default-shipping',
                    label: 'Default Shipping',
                    detail: '',
                    amount: this.checkout.charges.shipping
                  }
                ];

            this.shippingAmount = shippingRates[0].amount;
            this.selectedShippingOptionId = shippingRates[0].id;
            this.checkout.charges.shipping = this.shippingAmount;
            this.checkout.charges.total =
              this.checkout.charges.price +
              this.checkout.charges.tax +
              shippingRates[0].amount;

            // Update wallet pay total
            ev.updateWith({
              status: 'success',
              shippingOptions: shippingRates,
              total: {
                label: 'Checkout total',
                amount: this.checkout.charges.total
              }
            });
          }
        } else {
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_PURCHASE_INVALID_ADDRESS,
            type: BannerType.error
          });
        }
      } catch (err) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
    },
    onWalletPaymentSuccess(ev: PaymentRequestPaymentMethodEvent) {
      // set up shipping data
      const shippingAddress = ev.shippingAddress;
      if (shippingAddress) {
        this.shipping.address1 = shippingAddress.addressLine
          ? shippingAddress.addressLine[0]
          : '';
        this.shipping.address2 =
          shippingAddress.addressLine && shippingAddress.addressLine.length > 1
            ? shippingAddress.addressLine[1]
            : '';
        this.shipping.city = shippingAddress.city || '';
        this.shipping.state = shippingAddress.region || '';
        this.shipping.postalCode = shippingAddress.postalCode || '';
        this.shipping.country = shippingAddress.country || '';
      }
      this.shipping.email = ev.payerEmail || '';
      // TODO: determine how to parse the name
      this.shipping.lastName = ev.payerName || '';
      this.isApplePay = true;
      this.onPaymentSuccess();
    },
    async getProductData() {
      try {
        store.commit('global/setLoading', true);
        this.checkoutId = this.$route.params.checkoutId as string;

        const payload = { checkoutId: this.checkoutId };
        const results = await FirebaseRequest.createRequest<{
          checkoutData: ExpressCheckout;
          clientSecret: string;
        }>(FirebaseRequestTypes.GET_CHECKOUT, payload);

        if (results.status === APIResponseStatus.ERROR) {
          let message = BannerMessageStrings.GENERIC_ERROR_MESSAGE;
          if (results.code === ProductReplyCodes.NO_INVENTORY) {
            message = BannerMessageStrings.BUYER_NO_INVENTORY;
          } else if (results.code === ProductReplyCodes.SKU_NOT_FOUND) {
            message = BannerMessageStrings.BUYER_PRODUCT_NOT_FOUND;
          } else if (results.code === ProductReplyCodes.INACTIVE) {
            message = BannerMessageStrings.BUYER_PRODUCT_INACTIVE;
          }
          store.commit('global/setBannerStatus', {
            text: message,
            type: BannerType.error
          });
        } else {
          this.checkout = results.data?.checkoutData || ({} as ExpressCheckout);

          this.product = this.checkout.product;
          this.stripeClientSecret = results.data?.clientSecret || '';
          const brandInfo: BrandInformation = {
            brandName: this.product.account.name,
            logoUrl: this.product.account.logoUrl || '',
            orderEmail: this.product.account.orderEmail || '',
            customColor: this.product.account.customColor || 'default'
          };
          this.customColor = brandInfo.customColor || 'default';
          store.commit('global/setBrandInformation', brandInfo);
        }
      } catch (ex) {
        analytics.logEvent(
          AnalyticEventType.expressCheckoutPurchaseError,
          this.checkout,
          ex
        );
        console.log(ex);
        analytics.logEvent(
          AnalyticEventType.expressCheckoutPurchaseError,
          this.checkout,
          ex
        );
      }
      store.commit('global/setLoading', false);
    },
    showReturnPolicy() {
      this.isShowingReturnPolicy = true;
      analytics.logEvent(
        AnalyticEventType.expressCheckoutReturnsPolicyClicked,
        this.checkout
      );
    },
    hideReturnPolicy() {
      this.isShowingReturnPolicy = false;
      this.isShowingCardDetails = false;
    },
    showCardDetails() {
      this.isShowingCardDetails = true;
      analytics.logEvent(
        AnalyticEventType.expressCheckoutPayByCardClicked,
        this.checkout
      );
    },
    collectCard(card: StripeCardElement) {
      this.stripeCardElement = card;
      this.cardError = '';
    },
    handlePurchase() {
      // Manually trigger an empty card error
      if (!this.stripeCardElement) {
        this.cardError = 'Please enter your card details.';
        return;
      }

      this.completeButtonState = ButtonLoadingState.loading;

      analytics.logEvent(
        AnalyticEventType.expressCheckoutCompletePurchaseClicked,
        this.checkout
      );
      // 1. API create setup Intent, pass clientSecret
      // 2. handlePayment, call confirmCardSetup pass secret and card
      // 3. Stripe processes payment setup
      // 4. on success, client sends rest of data payload to API (shipping address)
      // 5. Display UI confirmation to User

      if (this.stripe && this.stripeCardElement) {
        // 1. setup intent capture
        this.stripe
          .confirmCardSetup(this.stripeClientSecret, {
            // eslint-disable-next-line
            payment_method: {
              card: this.stripeCardElement
            }
          })
          // eslint-disable-next-line
          // @ts-ignore
          .then((result) => {
            if (result.error) {
              this.completeButtonState = ButtonLoadingState.complete;
              analytics.logEvent(
                AnalyticEventType.expressCheckoutPurchaseError,
                this.checkout,
                result.error
              );

              // Inform the customer that there was an error
              store.commit('global/setBannerStatus', {
                text: BannerMessageStrings.BUYER_PURCHASE_ERROR,
                type: BannerType.error
              });
              this.cardError = result.error.message;
            } else {
              // API needs to listen to webhook payment_intent.succeeded
              // Call new API endpoint and pass data
              // 4. on success, client sends rest of data payload to API (shipping address)
              // 5. Display UI confirmation to User
              this.onPaymentSuccess();
            }
          });
      }
    },
    async onPaymentSuccess() {
      // TODO: Identify payload interface
      if (this.isApplePay) {
        analytics.logEvent(
          AnalyticEventType.expressCheckoutApplePayClicked,
          this.checkout
        );
      }

      const shippingRate = this.shippingOptions.find((option) => {
        return option.id === this.selectedShippingOptionId;
      });

      const payload = {
        checkoutId: this.checkoutId,
        emailAddress: this.shipping.email,
        mailingAddress: this.shipping,
        shippingRate
      };

      this.isTransactionCompleted = true;

      const results = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.PROCESS_CHECKOUT,
        payload
      );
      // TODO: Identify results interface
      if (results.status === APIResponseStatus.ERROR) {
        analytics.logEvent(
          AnalyticEventType.expressCheckoutPurchaseError,
          this.checkout,
          'Express checkout error, nothing returned'
        );
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      } else {
        analytics.logEvent(
          AnalyticEventType.expressCheckoutPurchaseSuccessful,
          this.checkout
        );
      }
      this.completeButtonState = ButtonLoadingState.complete;
    },
    gotoBoostLogin() {
      analytics.logEvent(
        AnalyticEventType.expressCheckoutGoToAccount,
        this.checkout
      );
      store.commit('global/setBrandInformation', null);
      this.$router.push({ path: '/login', query: { type: '1' } });
    },
    async onPostalCodeChange() {
      if (
        this.shipping &&
        this.shipping.postalCode &&
        this.shipping.postalCode.length >= 5 &&
        this.shipping.state?.length > 1
      ) {
        const payload = {
          hashtag: this.checkout.hashtag,
          sku: this.checkout.skuId,
          postalCode: this.shipping.postalCode
        };

        const chargesUpdate = await FirebaseRequest.createRequest<{
          charges: Charges;
        }>(FirebaseRequestTypes.GET_PRODUCT_TAXES, payload);
        if (chargesUpdate.status === APIResponseStatus.OK) {
          this.checkout.charges = chargesUpdate.data?.charges;
        }

        await this.getShippingRates();
        this.taxesUpdated = true;
        this.taxesCalculated = true;
        setTimeout(() => {
          this.taxesUpdated = false;
        }, 5000);
      }
    },
    async getShippingRates() {
      if (
        this.product.accountId &&
        this.product.shopifyProductId &&
        this.shipping &&
        this.shipping.postalCode &&
        this.shipping.postalCode.length >= 5 &&
        this.shipping.state?.length > 1
      ) {
        const payload = {
          accountId: this.product.accountId,
          productId: this.product.productId,
          postalCode: this.shipping.postalCode,
          stateNameOrCode: this.shipping.state,
          skuId: this.checkout.skuId
        };

        const shippingRates = await FirebaseRequest.createRequest<
          ShippingRate[]
        >(FirebaseRequestTypes.GET_ECOMMERCE_SHIPPING_RATES, payload);

        if (shippingRates.status === APIResponseStatus.OK) {
          this.isShippingCalculated = true;
          if (shippingRates.data) {
            this.shippingOptions = shippingRates.data;
            this.selectedShippingOptionId = this.shippingOptions[0].id;
            this.processShippingRate();
          }
        }
      } else {
        if (this.checkout?.charges?.shipping) {
          this.shippingOptions = [
            {
              id: 'Default-shipping',
              label: 'Default Shipping',
              detail: '',
              amount: this.checkout.charges.shipping
            }
          ];
        } else {
          this.shippingOptions = [
            {
              id: 'Default-shipping',
              label: 'Free',
              detail: '',
              amount: this.checkout?.charges?.shipping || 0
            }
          ];
        }
      }
    },
    processShippingRate() {
      // Update the total
      const shippingOption = this.shippingOptions.find((option) => {
        return option.id === this.selectedShippingOptionId;
      });

      if (shippingOption) {
        this.shippingAmount = shippingOption.amount;
      }

      // TODO: the checkout charges amounts are in cents, the shipping amount is not
      // Recalculate totals
      if (this.checkout.charges) {
        this.checkout.charges.shipping = this.shippingAmount;
        this.checkout.charges.total =
          this.checkout.charges.price +
          this.checkout.charges.shipping +
          this.checkout.charges.tax;
      }
    },
    getPrice(price: number | undefined): string {
      return price ? Helpers.currencyFormatter(price) : '';
    }
  }
});
