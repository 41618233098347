
import { defineComponent } from 'vue';
import PreRegEntry from '@/pre-registration/PreRegEntry.vue';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import PreRegAccount from '@/pre-registration/PreRegAccount.vue';
import PreRegShipping from '@/pre-registration/PreRegShipping.vue';
import Stepper from '@/common/Stepper.vue';
import PreRegCard from '@/pre-registration/PreRegCard.vue';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import settings from '@/settings';
import PreRegComplete from '@/pre-registration/PreRegComplete.vue';
import Helpers from '@/helpers';
import { PreRegistrationUserInfo } from '@/models/audienceMember';
import { Address } from '@/models/mailingAddress';
import { mapMutations } from 'vuex';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import PageNotFoundComponent from '@/common/PageNotFoundComponent.vue';
import { store } from '@/store';
import { BrandInformation } from '@/store/types';

export interface SellerAccount {
  accountId: string;
  name: string;
  phone: string;
  refundPolicy: string;
  slug: string;
  logoUrl: string;
  hashtagPrefix: string;
  email: string;
  metaPixel?: string;
  customColor?: string;
  globalCommission?: number;
  shopifyStore?: string;
  plan?: string;
  enableShareEarn?: boolean;
  enableSaveForLater?: boolean;
  enableMarketplace?: boolean;
  widgetAutomaticPlacement?: boolean;
}

// eslint-disable-next-line no-redeclare
export interface PreRegAccount {
  firstName: string;
  lastName: string;
  email: string;
}

// eslint-disable-next-line no-redeclare
export interface PreRegShipping {
  address1?: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export enum PreRegStatus {
  MOBILE_NEEDS_VALIDATION = 'MOBILE_NEEDS_VALIDATION',
  MOBILE_VALIDATED = 'MOBILE_VALIDATED',
  SETUP_INTENT_CREATED = 'SETUP_INTENT_CREATED',
  CARD_CAPTURED = 'CARD_CAPTURED',
  REGISTERED = 'REGISTERED'
}

export default defineComponent({
  name: 'PreRegistration',
  components: {
    PreRegEntry,
    PreRegAccount,
    PreRegShipping,
    PreRegCard,
    PreRegComplete,
    Stepper,
    PageNotFoundComponent
  },
  data() {
    return {
      PreRegStatus,
      userTypeContext: '',
      status: PreRegStatus.MOBILE_NEEDS_VALIDATION as PreRegStatus,
      getCodeState: ButtonLoadingState.initial,
      currentStep: 1,
      totalSteps: 5,
      sellerId: '',
      imageUrl: '',
      mobileNumber: '',
      account: {
        firstName: '',
        lastName: '',
        email: ''
      } as PreRegAccount,
      shipping: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States'
      } as PreRegShipping,
      stripeCardElement: null as null | StripeCardElement,
      stripe: null as null | Stripe,
      stripeClientSecret: '',
      errorMessage: '' as string | undefined,
      sellerAccount: null as null | SellerAccount,
      preRegistrationUserInfo: {} as PreRegistrationUserInfo
    };
  },
  async mounted() {
    if (this.$route.params.sellerId) {
      this.sellerId = this.$route.params.sellerId as string;
      this.setLoading(true);
      await this.getSellerAccount();
      await this.initializeStripe();
      this.setLoading(false);
    }
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setShowLogo', 'setShowHelp']),
    async initializeStripe() {
      // Instantiate stripe instance to be used for card and payment processing
      this.stripe = await loadStripe(settings.STRIPE_PUBLISHABLE_KEY);
    },
    async getSellerAccount() {
      const payload = {
        slug: this.sellerId
      };

      const sellerAccount = await FirebaseRequest.createRequest<{
        seller: SellerAccount;
      }>(FirebaseRequestTypes.GET_SELLER_INFO, payload);

      if (sellerAccount) {
        this.sellerAccount = sellerAccount.data?.seller as SellerAccount;
        console.log('🔥 ', this.sellerAccount);
        store.commit('global/setCustomColor', this.sellerAccount.customColor);
        store.commit('global/setBrandInformation', {
          brandName: this.sellerAccount.name,
          logoUrl: this.sellerAccount.logoUrl,
          metaPixel: this.sellerAccount.metaPixel,
          customColor: this.sellerAccount.customColor
        } as BrandInformation);
      }
    },

    async captureNumber(mobileNumber: string, context: string) {
      this.mobileNumber = mobileNumber;
      this.userTypeContext = context;
      // do something based on context
      // returning user or new user
      this.status = PreRegStatus.MOBILE_VALIDATED;

      if (this.userTypeContext === 'PREREGISTER_EXISTING_USER') {
        await this.completePreRegistration();
      } else {
        this.nextStep();
      }
    },

    async createSetupIntent() {
      if (this.status === PreRegStatus.MOBILE_VALIDATED) {
        try {
          this.getCodeState = ButtonLoadingState.loading;
          const payload = {
            mobileNumber: Helpers.prefixPhoneNumber(this.mobileNumber)
          };

          const setupIntent = await FirebaseRequest.createRequest<{
            clientSecret: string;
          }>(FirebaseRequestTypes.CREATE_PAYMENT_INTENT, payload);

          if (setupIntent) {
            this.stripeClientSecret = setupIntent.data?.clientSecret || '';
            this.status = PreRegStatus.SETUP_INTENT_CREATED;
          }
        } catch (err) {
          console.error('Error: ', err);
          this.errorMessage = err as string;
        }
      }

      this.getCodeState = ButtonLoadingState.complete;
      this.nextStep();
    },

    captureCard(card: StripeCardElement) {
      this.stripeCardElement = card;
      this.confirmSetupIntent();
    },

    confirmSetupIntent() {
      if (this.status !== PreRegStatus.SETUP_INTENT_CREATED) {
        this.nextStep();
      } else {
        if (this.stripe && this.stripeCardElement) {
          this.getCodeState = ButtonLoadingState.loading;
          // 1. setup intent capture
          this.stripe
            .confirmCardSetup(this.stripeClientSecret, {
              // eslint-disable-next-line
              payment_method: {
                card: this.stripeCardElement
              }
            })
            // eslint-disable-next-line
            // @ts-ignore
            .then((result) => {
              if (result.error) {
                console.error(result);
                // Inform the customer that there was an error
                this.errorMessage = result.error.message;
              } else {
                this.status = PreRegStatus.CARD_CAPTURED;
                this.completePreRegistration();
              }
            });
        }
      }
    },

    async completePreRegistration() {
      if (this.status === PreRegStatus.CARD_CAPTURED && this.sellerAccount) {
        try {
          const shippingAddress: Address = this.shipping;
          const isNewUser = true;
          const slug = this.sellerAccount.slug;

          const payload: PreRegistrationUserInfo = {
            shippingAddress,
            email: this.account.email,
            firstName: this.account.firstName,
            lastName: this.account.lastName,
            mobileNumber: Helpers.prefixPhoneNumber(this.mobileNumber),
            slug,
            isNewUser
          };

          const registrationResult =
            await FirebaseRequest.createRequest<PreRegistrationUserInfo>(
              FirebaseRequestTypes.COMPLETE_PRE_REGISTRATION,
              payload
            );

          if (registrationResult) {
            this.preRegistrationUserInfo =
              registrationResult.data as PreRegistrationUserInfo;
            this.status = PreRegStatus.REGISTERED;
            this.getCodeState = ButtonLoadingState.complete;
            this.nextStep();
          }
        } catch (err) {
          console.error('Error: ', err);
          this.errorMessage = err as string;
        }
      } else if (
        this.status === PreRegStatus.MOBILE_VALIDATED &&
        this.userTypeContext === 'PREREGISTER_EXISTING_USER' &&
        this.sellerAccount
      ) {
        const payload: PreRegistrationUserInfo = {
          mobileNumber: Helpers.prefixPhoneNumber(this.mobileNumber),
          slug: this.sellerAccount.slug,
          isNewUser: false
        };

        const registrationResult =
          await FirebaseRequest.createRequest<PreRegistrationUserInfo>(
            FirebaseRequestTypes.COMPLETE_PRE_REGISTRATION,
            payload
          );

        if (registrationResult) {
          this.preRegistrationUserInfo =
            registrationResult.data as PreRegistrationUserInfo;
          this.status = PreRegStatus.REGISTERED;
          this.currentStep = 5;
        }
      }
    },

    handleErrorMessage(err: string) {
      this.errorMessage = err;
    },

    handleError() {
      store.commit('global/setBannerStatus', {
        text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
        type: BannerType.error
      });
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
      this.showHideLogoAndHelp();
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
      this.showHideLogoAndHelp();
    },
    showHideLogoAndHelp() {
      if (this.currentStep === 3 || this.currentStep === 4) {
        this.setShowHelp(true);
        this.setShowLogo(false);
      } else {
        this.setShowHelp(false);
        this.setShowLogo(true);
      }
    }
  }
});
