import { GetterTree, MutationTree, ActionTree } from 'vuex';
import settings from '@/settings';
import { MarketplaceState, RootState } from './types';
import {
  MarketplaceProduct,
  MarketplaceSearchResults
} from '@/models/marketplaceProduct';
import { AffiliateBrand } from '@/models/affiliateBrands';
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';

const marketplaceState: MarketplaceState = {
  isSearchOpen: false,
  searchResults: undefined,
  selectedProductToSell: undefined,
  taggedProducts: {},
  featuredProducts: [],
  allBrands: []
};

const marketplaceGetters: GetterTree<MarketplaceState, RootState> = {
  isSearchOpen: (state: MarketplaceState): boolean => {
    return state.isSearchOpen;
  },
  getSearchResults: (
    state: MarketplaceState
  ): MarketplaceSearchResults | undefined => {
    return state.searchResults;
  },
  getTaggedProducts: (state: MarketplaceState) => {
    return state.taggedProducts;
  },
  getAllBrands: (state: MarketplaceState) => {
    return state.allBrands;
  },
  getSelectedProductToSell: (
    state: MarketplaceState
  ): MarketplaceProduct | undefined => {
    return state.selectedProductToSell;
  },
  getFeaturedProducts: (
    state: MarketplaceState
  ): MarketplaceProduct[] | undefined => {
    return state.featuredProducts;
  }
};

const marketplaceMutations: MutationTree<MarketplaceState> = {
  setIsSearchOpen: (state: MarketplaceState, payload: boolean) => {
    state.isSearchOpen = payload;
  },
  setSearchResults: (
    state: MarketplaceState,
    payload: MarketplaceSearchResults | undefined
  ) => {
    state.searchResults = payload;
  },
  setSelectedProductToSell: (
    state: MarketplaceState,
    payload: MarketplaceProduct | undefined
  ) => {
    state.selectedProductToSell = payload;
  },
  setAllBrands: (state: MarketplaceState, payload: AffiliateBrand[]) => {
    state.allBrands = payload;
  },
  setFeaturedProducts: (
    state: MarketplaceState,
    payload: MarketplaceProduct[]
  ) => {
    state.featuredProducts = payload;
  },
  setTaggedProducts: (
    state: MarketplaceState,
    payload: { [key: string]: string }
  ) => {
    state.taggedProducts = payload;
  }
};

const marketplaceActions: ActionTree<MarketplaceState, RootState> = {
  performSearch: async ({ commit }, searchTerm: string): Promise<void> => {
    const products = await fetch(
      `${settings.FIREBASE_CLOUD_FUNCTIONS_URL}/api/products/search?search=${searchTerm}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json'
        }
      }
    );

    const results = await products.json();
    commit('setSearchResults', {
      products: results.products,
      query: searchTerm
    });
  },
  getFeaturedProducts: async ({ commit }): Promise<void> => {
    const products = await fetch(
      `${settings.FIREBASE_CLOUD_FUNCTIONS_URL}/api/products/featured`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json'
        }
      }
    );

    const results = await products.json();
    commit('setFeaturedProducts', results.products as MarketplaceProduct[]);
  },

  loadAllBrands: async ({ commit, rootGetters, dispatch }): Promise<void> => {
    const currentBuyer = rootGetters['auth/getCurrentBuyer'] as Buyer;

    // Kick off getting the featured products.
    dispatch('getFeaturedProducts');

    const products = await fetch(
      `${settings.FIREBASE_CLOUD_FUNCTIONS_URL}/api/brands${
        currentBuyer !== null ? '/' + currentBuyer.userId : ''
      }`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json'
        }
      }
    );

    const results = await products.json();
    commit('setAllBrands', results.brands);
    commit('setTaggedProducts', results.taggedProducts);
  },
  addTaggedProduct: (
    { commit, state },
    payload: { productId: string; productTag: string }
  ): void => {
    commit('setTaggedProducts', {
      ...state.taggedProducts,
      [payload.productId]: payload.productTag
    });
  }
};

export const marketplace = {
  getters: marketplaceGetters,
  state: marketplaceState,
  mutations: marketplaceMutations,
  actions: marketplaceActions,
  namespaced: true
};
