
import { defineComponent } from 'vue';
import Helpers from '@/helpers';
import { ExpressCheckout } from '@/models/expressCheckout';

export default defineComponent({
  props: {
    checkout: {
      type: Object as () => ExpressCheckout,
      required: true
    },
    isShippingCalculated: {
      type: Boolean,
      default: false
    },
    isTaxCalculated: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPrice(price: number) {
      return Helpers.currencyFormatter(price);
    }
  }
});
