import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostSelect = _resolveComponent("BoostSelect")!

  return (_openBlock(), _createBlock(_component_BoostSelect, {
    modelValue: _ctx.selection,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
    "place-holder": "Please select a state",
    "input-id": _ctx.inputId,
    options: _ctx.stateList,
    "hide-label": false,
    label: _ctx.label,
    rules: _ctx.rules,
    attributes: _ctx.attributes,
    onInput: _ctx.onChange
  }, null, 8, ["modelValue", "input-id", "options", "label", "rules", "attributes", "onInput"]))
}