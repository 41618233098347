
import { defineComponent } from 'vue';
import CheckoutDetailDivider from './CheckoutDetailDivider.vue';

export default defineComponent({
  name: 'BaseCheckoutStep',
  components: {
    CheckoutDetailDivider
  },
  props: {
    title: {
      type: String,
      default: 'Change Card'
    }
  },
  emits: ['back-navigation-requested']
});
