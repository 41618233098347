
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { store } from '@/store';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Buyer, ShippingAddress } from './BuyerSettingsInterfaces';

export default defineComponent({
  name: 'BuyerShippingAddressComponent',
  data() {
    return {
      addressInfo: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'United States',
        isDefault: false
      } as ShippingAddress,
      addAddressButtonState: ButtonLoadingState.initial
    };
  },
  computed: {
    ...mapGetters({
      currentBuyer: 'auth/getCurrentBuyer'
    })
  },
  mounted() {
    const addressId = this.$route.params.addressId as string;
    if (addressId) {
      const addr = (this.currentBuyer as Buyer).shippingAddresses.find(
        (addr) => {
          return addr.id === addressId;
        }
      );
      if (addr) {
        this.addressInfo = { ...addr };
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'buyer-settings',
        query: { menu: 'shipping' }
      });
    },
    async addOrUpdateShippingAddress() {
      this.addAddressButtonState = ButtonLoadingState.loading;
      const result = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.UPDATE_BUYER_SHIPPING_ADDRESS,
        { shippingAddress: this.addressInfo }
      );

      if (result.status === APIResponseStatus.OK) {
        this.$store.commit('global/setLoading', {
          isLoading: true,
          message: 'Retrieving shipping addresses..'
        });
        await this.$store.dispatch('auth/refreshCurrentBuyer');
        this.$store.commit('global/setLoading', false);
        this.addAddressButtonState = ButtonLoadingState.complete;

        this.$router.push({
          name: 'buyer-settings',
          query: { menu: 'shipping' }
        });
      }

      store.commit('global/setBannerStatus', {
        text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
        type: BannerType.error
      });
      this.addAddressButtonState = ButtonLoadingState.initial;
    }
  }
});
