import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54d47f59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feature-selector bottom-border" }
const _hoisted_2 = { class: "feature-selector__header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "feature-selector__row"
}
const _hoisted_9 = { class: "flex flex-nowrap items-center" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.selectedOptionImageUrl,
        alt: "Active product icon indicator",
        class: "feature-selector__left-image"
      }, null, 8, _hoisted_3),
      _createElementVNode("span", null, _toDisplayString(_ctx.selectedFeatureType), 1),
      (!_ctx.showUpgradeOption)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: 
          _ctx.isExpanded
            ? require('../assets/chevron-up.svg')
            : require('../assets/chevron-down.svg')
        ,
            class: "feature-selector__right-image",
            alt: "Expand or close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded))
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isExpanded)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.featureOptions.filter((x) => x.connected), (feature, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "feature-selector__row",
            onClick: ($event: any) => (_ctx.selectFeature(feature))
          }, [
            _createElementVNode("img", {
              src: feature.imageUrl,
              alt: feature.featureType + ' icon indicator',
              class: "feature-selector__left-image"
            }, null, 8, _hoisted_6),
            _createElementVNode("span", null, _toDisplayString(feature.featureType), 1),
            (_ctx.selectedFeatureType === feature.featureType)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: require('../assets/green-check.svg'),
                  alt: "Active selection icon indicator",
                  class: "feature-selector__right-image"
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true)
          ], 8, _hoisted_5))
        }), 128))
      : _createCommentVNode("", true),
    (
        (_ctx.isExpanded && !_ctx.currentAccount.isShopifyConnected && _ctx.allowConnect) ||
        (_ctx.showUpgradeOption && _ctx.allowConnect)
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_BoostButton, {
            type: "button",
            "button-style": "none",
            onClick: _ctx.connectShopify
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: require('../assets/add-simple.svg'),
                  class: "feature-selector__left-image",
                  alt: "Add icon indicator"
                }, null, 8, _hoisted_10),
                _createTextVNode(" Connect store ")
              ])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}