
import { MarketplaceProduct } from '@/models/marketplaceProduct';
import { PropType, defineComponent } from 'vue';
import ProductsGallery from './ProductsGrid.vue';

export default defineComponent({
  name: 'FeaturedProductsComponent',
  components: {
    ProductsGallery
  },
  props: {
    products: {
      type: undefined as unknown as PropType<MarketplaceProduct[]>,
      required: true
    }
  }
});
