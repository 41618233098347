import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SellerCreateAccount = _resolveComponent("SellerCreateAccount")!
  const _component_SellerRegistrationDetails = _resolveComponent("SellerRegistrationDetails")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.currentState === _ctx.registrationState.CREATE_ACCOUNT)
      ? (_openBlock(), _createBlock(_component_SellerCreateAccount, {
          key: 0,
          "is-loading": _ctx.isLoading,
          onReserveTag: _ctx.reserveTag
        }, null, 8, ["is-loading", "onReserveTag"]))
      : (_openBlock(), _createBlock(_component_SellerRegistrationDetails, {
          key: 1,
          "hashtag-prefix": _ctx.userInfo.hashtagPrefix,
          "is-loading": _ctx.isLoading,
          onCancelRegistration: _ctx.cancelRegistration,
          onCreateAccount: _ctx.createAccount
        }, null, 8, ["hashtag-prefix", "is-loading", "onCancelRegistration", "onCreateAccount"]))
  ]))
}