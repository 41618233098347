
import { defineComponent, PropType } from 'vue';
import { SettingsType } from '@/seller/settings/SellerSettingsInterfaces';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { store } from '@/store';
import { ImageFilePreviewedEvent } from '@/common/controls/BoostImageUpload.vue';
import { mapMutations } from 'vuex';
import ManageUsers from './ManageUsers.vue';
import SellerSettingsCustom from '@/seller/settings/SellerSettingsCustom.vue';
import SellerSettingsIntegrations from './SellerSettingsIntegrations.vue';
import SellerSettingsPaymentPlans from './SellerSettingsPaymentPlans.vue';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import {
  SellerBusinessSettings,
  SellerCustomSettings,
  SellerShippingSettings
} from '@/seller/settings/SellerSettingsInterfaces';
import Helpers from '@/helpers';
import { AccountObject, AccountPlan } from '@/models/accountObject';

interface MenuItems {
  id: string;
  label: string;
  plan: AccountPlan;
}

export default defineComponent({
  name: 'SellerSettingsAccount',
  components: {
    ManageUsers,
    SellerSettingsCustom,
    SellerSettingsIntegrations,
    SellerSettingsPaymentPlans
  },
  props: {
    accountSettings: {
      type: Object as PropType<SellerBusinessSettings>,
      required: true
    },
    customSettings: {
      type: Object as PropType<SellerCustomSettings>,
      required: true
    },
    shippingSettings: {
      type: Object as PropType<SellerShippingSettings>,
      required: true
    },
    saveButtonLoadingState: {
      type: String,
      required: true
    },
    disconnectStripeButtonLoadingState: {
      type: String,
      required: true
    }
  },
  emits: [
    'cancelUpdateSellerSettings',
    'updateSellerSettings',
    'disconnectFromStripe',
    'connectToStripe',
    'changePassword'
  ],
  data() {
    return {
      newEmail: '',
      brandTag: '',
      settingType: SettingsType.BUSINESS,
      password: '',
      showPasswordModal: false,
      logoChanged: false,
      selectedMenu: 'account',
      refundPolicyIsLink: true,
      menuItems: [
        {
          label: 'Account',
          id: 'account',
          plan: 'Basic'
        },
        {
          label: 'Users',
          id: 'users',
          plan: 'Plus'
        },
        {
          label: 'Customizations',
          id: 'custom',
          plan: 'Plus'
        },
        {
          label: 'Stripe',
          id: 'stripe',
          plan: 'Basic'
        },
        {
          label: 'Plans & Billing',
          id: 'paymentPlans',
          plan: 'Basic'
        },
        {
          label: 'Integrations',
          id: 'integrations',
          plan: 'Basic'
        }
      ] as MenuItems[]
    };
  },
  computed: {
    canDeactivateAccount(): boolean {
      return this.brandTag === this.accountSettings.hashTagPrefix;
    },
    currentAccount() {
      return this.$store.getters['accounts/currentAccount'] as AccountObject;
    },
    currentPlanMenuItems() {
      return this.menuItems.filter((menuItem) =>
        Helpers.currentPlanHasAccess(
          menuItem.plan,
          this.currentAccount.currentPlan
        )
      );
    }
  },
  watch: {
    '$route.query.tab': function (newTabName) {
      this.selectTabById(newTabName);
    }
  },
  mounted() {
    if (this.$route.query.tab) {
      this.selectTabById(this.$route.query.tab as string);
    }
  },
  methods: {
    ...mapMutations({
      setBannerStatus: 'global/setBannerStatus'
    }),
    logoUploaded(event: ImageFilePreviewedEvent) {
      this.accountSettings.logoUrl = event.data;
      this.logoChanged = true;
      store.commit('global/setBannerStatus', {
        text: BannerMessageStrings.SELLER_BRAND_IMAGE_UPLOADED,
        type: BannerType.success
      });
    },
    cancelSellerSettings() {
      this.$emit('cancelUpdateSellerSettings');
    },
    commitChanges() {
      this.logoChanged = false;
      this.$emit('updateSellerSettings', {
        type: this.settingType,
        data: {
          accountSettings: this.accountSettings,
          shippingSettings: this.shippingSettings
        }
      });
    },
    async changeEmail() {
      const currentAccount = this.$store.state.accounts.currentAccount;
      if (currentAccount) {
        await this.$store
          .dispatch('auth/updateSellerEmail', {
            newEmail: this.newEmail,
            oldEmail: currentAccount?.email,
            password: this.password
          })
          .then(async (success: boolean) => {
            if (success) {
              // Update the account firebase table with the new email
              await FirebaseRequest.createRequest(
                FirebaseRequestTypes.UPDATE_SELLER_ACCOUNT,
                {
                  accountId: currentAccount.accountId,
                  updateEmailOnly: true,
                  email: this.newEmail
                }
              );

              // Update the store account email
              currentAccount.email = this.newEmail;

              this.accountSettings.email = this.newEmail;
              this.newEmail = '';
              this.setBannerStatus({
                text: BannerMessageStrings.SELLER_PASSWORD_EMAIL_SENT,
                type: BannerType.info
              });
            } else {
              this.setBannerStatus({
                text: BannerMessageStrings.SELLER_INVALID_EMAIL,
                type: BannerType.error
              });
            }
          });
      }
      this.showPasswordModal = false;
    },
    goBack() {
      this.$router.push({
        name: 'seller-home'
      });
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    },
    selectTabById(tabId: string): void {
      if (!tabId) return;

      const foundTab = this.menuItems.find((m) => m.id === tabId);
      if (foundTab) {
        this.selectedMenu = foundTab.id;
        return;
      }

      // as a fallback, if an invalid tab name is given, go to first tab.
      this.selectedMenu = this.menuItems[0].id;
    },
    navigateToTab(tabId: string) {
      this.$router.replace({
        name: 'seller-settings',
        query: { tab: tabId }
      });
    }
  }
});
