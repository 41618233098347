
import { defineComponent } from 'vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { getAuth } from '@firebase/auth';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { mapGetters } from 'vuex';
import { store } from '@/store';

export default defineComponent({
  name: 'ResetPassword',
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      hasChangedPassword: false
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    })
  },
  methods: {
    resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.SELLER_PASSWORDS_DO_NOT_MATCH,
          type: BannerType.error
        });
        return;
      }
      const actionCode = this.$route.query.actionCode as string;
      const auth = getAuth();

      // Verify the password reset code is valid.
      verifyPasswordResetCode(auth, actionCode)
        .then(() => {
          const newPassword = this.newPassword;

          // Save the new password
          confirmPasswordReset(auth, actionCode, newPassword)
            .then(() => {
              this.hasChangedPassword = true;
            })
            .catch((error) => {
              console.log(error);
              // 'An error during confirmation.  The reset code may have expired, or the password is too weak.  Please reset your password again.';
              store.commit('global/setBannerStatus', {
                text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
                type: BannerType.error
              });
              // Error occurred during confirmation. The code might have expired or the
              // password is too weak.
            });
        })
        .catch((error) => {
          console.log(error);
          // 'Invalid or expired code.  Please reset your password again.';
          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
            type: BannerType.error
          });
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
        });
    }
  }
});
