
interface SelectProduct extends Product {
  isSelected: boolean;
}

import { defineComponent } from 'vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { mapGetters, mapMutations } from 'vuex';
import { Product } from '@/models/product';
import helpers from '@/helpers';
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';
import { ProductSource } from '@/../models/sellerOrder';
import { ConnectShopify } from '@/../models/connectShopify';
import { ShopifyImportStatus } from '@/../enums/shopifyImportStatus';
import SearchBar, { SearchActionEvent } from '@/common/controls/SearchBar.vue';

export default defineComponent({
  name: 'ShopifyIntegration',
  components: {
    SearchBar
  },
  data() {
    return {
      shopifyConfig: {} as ConnectShopify,
      shopifyProducts: [] as SelectProduct[],
      searchValue: '' as string,
      productFilter: 'All',
      productFilterOptions: [
        { name: 'All', value: 'All' },
        { name: 'Ready', value: ShopifyImportStatus.ready },
        { name: 'Tags created', value: ShopifyImportStatus.tagsCreated },
        { name: 'Invalid', value: ShopifyImportStatus.invalid }
      ],
      columns: [
        {
          id: 'image',
          label: 'Name'
        },
        {
          id: 'title',
          label: ''
        },
        {
          id: 'price',
          label: 'Price'
        },
        {
          id: 'variantCount',
          label: 'Variations'
        },
        {
          id: 'totalQuantity',
          label: 'Quantity'
        },
        {
          id: 'status',
          label: 'Status'
        },
        {
          id: 'selection',
          label: ''
        }
      ] as ColumnItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    }),
    filteredShopifyProducts(): SelectProduct[] {
      return this.shopifyProducts.filter(this.filterShopifyProducts);
    }
  },
  async mounted() {
    const responseData = await FirebaseRequest.createRequest<{
      configData: {
        name: string;
        configuration: string;
        status: string;
      };
    }>(FirebaseRequestTypes.GET_ECOMMERCE_CONFIG, {
      accountId: this.currentAccount?.accountId,
      name: 'Shopify'
    });

    if (
      responseData.status === APIResponseStatus.OK &&
      responseData.data?.configData?.configuration
    ) {
      this.shopifyConfig = JSON.parse(
        responseData.data?.configData?.configuration
      ) as ConnectShopify;
    }
  },
  methods: {
    ...mapMutations('global', [
      'setLoading',
      'setBannerStatus',
      'setContextualButton'
    ]),
    async importShopifyProducts() {
      this.shopifyProducts.length = 0;

      this.setLoading({ isLoading: true, message: 'Connecting to Shopify...' });
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const responseData = await FirebaseRequest.createRequest<Product[]>(
        FirebaseRequestTypes.GET_ECOMMERCE_PRODUCTS,
        {
          accountId: this.currentAccount?.accountId,
          onlyValidProducts: true
        }
      );
      if (responseData.status === APIResponseStatus.OK) {
        this.setLoading({
          isLoading: true,
          message: `Importing ${responseData.data?.length} products...`
        });
        const data = (responseData.data as SelectProduct[]) || [];
        data.forEach((product) => {
          product.isSelected = false;
          product.imageUrl =
            product.imageUrl ?? require('../../assets/default-product.jpg');
        });
        this.shopifyProducts = data;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.setLoading({ isLoading: true, message: 'Import Complete!' });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.setLoading(false);
    },
    async createTags() {
      if (this.shopifyProducts.some((x) => x.isSelected && !x.productId)) {
        this.setLoading({ isLoading: true, message: 'Creating Tags...' });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const responseData = await FirebaseRequest.createRequest<boolean>(
          FirebaseRequestTypes.CREATE_ECOMMERCE_TAGS,
          {
            accountId: this.currentAccount?.accountId,
            products: this.shopifyProducts.filter((x) => x.isSelected)
          }
        );
        if (responseData.status === APIResponseStatus.OK) {
          this.setLoading({ isLoading: true, message: 'Tags created!' });
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.setLoading({ isLoading: false });
        this.$router.push({
          name: 'seller-products',
          query: { productSource: ProductSource.Shopify }
        });
      }
    },
    getCount(o: unknown): number {
      if (o && typeof o === 'object') {
        return Object.keys(o).length;
      }
      return 0;
    },
    getTotalQuantity(p: Product): number {
      if (!p?.skus) {
        return 0;
      }
      return Object.entries(p.skus).reduce((quantity, [, sku]) => {
        if (sku.quantity === 'UNLIMITED') {
          return 999;
        } else {
          // quantity is sometimes delivered as a string and sometimes as a number, so just call toString()
          if (sku.quantity) {
            const skuQuantity = parseInt(sku.quantity.toString(), 10);
            return quantity + skuQuantity;
          }
          return quantity;
        }
      }, 0);
    },
    formatPrice(price: string): string {
      return helpers.currencyFormatter(price);
    },
    filterShopifyProducts(product: SelectProduct) {
      let match = true;
      if (this.searchValue) {
        match =
          product.title
            .toLowerCase()
            .indexOf(this.searchValue.toLowerCase()) !== -1;
      }
      if (match && this.productFilter != 'All') {
        match = this.decodeStatus(product) === this.productFilter;
      }
      return match;
    },
    selectAll() {
      this.shopifyProducts.forEach((p) => {
        if (this.decodeStatus(p) === ShopifyImportStatus.ready) {
          p.isSelected = true;
        }
      });
    },
    unselectAll() {
      this.shopifyProducts.forEach((p) => {
        p.isSelected = false;
      });
    },
    selectAllInColumn(toggle: boolean) {
      if (toggle) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    },
    viewProducts(data: SearchActionEvent) {
      this.searchValue = data.searchValue ?? '';
    },
    decodeStatus(p: SelectProduct): ShopifyImportStatus {
      if (!this.validatePrice(p.price)) {
        return ShopifyImportStatus.invalid;
      }
      return p.productId
        ? ShopifyImportStatus.tagsCreated
        : ShopifyImportStatus.ready;
    },
    validatePrice(price: string) {
      return parseInt(price, 10) > 0;
    }
  }
});
