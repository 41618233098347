import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d344f4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tags-menu-controls-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _component_BoostSelect = _resolveComponent("BoostSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "#subheader" }, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterOptions, (f, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, [
            _createVNode(_component_BoostButton, {
              label: f.name,
              "button-style": "pill",
              class: _normalizeClass({
            'pill pill-white': true,
            'pill-blue': _ctx.filter === f.value
          }),
              disabled: f.disabled,
              onClick: ($event: any) => {
            _ctx.setFilter(f.value);
            _ctx.setTagFilter();
          }
            }, null, 8, ["label", "class", "disabled", "onClick"])
          ]))
        }), 128))
      ])
    ])),
    (_ctx.filteredCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BoostSelect, {
            modelValue: _ctx.sort,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sort) = $event)),
            options: _ctx.sortOptions,
            "input-id": "select-sort-option",
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTagFilter()))
          }, null, 8, ["modelValue", "options"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}