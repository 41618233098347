
import { defineComponent } from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
// import ProductsGallery from '@/marketplace/components/ProductsGrid.vue';
import SearchResults from '@/marketplace/components/MarketplaceSearchResultsComponent.vue';
import FeaturedProducts from '@/marketplace/components/MarketplaceFeaturedProductsComponent.vue';
import MarketplaceSearchComponent from '@/marketplace/components/MarketplaceSearchComponent.vue';
import MarketplaceDrawerComponent from '@/marketplace/components/MarketplaceDrawerComponent.vue';
import BoostLoader from '@/common/controls/BoostLoader.vue';
import SearchIcon from '@/marketplace/components/SearchIcon.vue';
import { MarketplaceProduct } from '@/models/marketplaceProduct';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { AffiliateBrand } from '@/models/affiliateBrands';

export default defineComponent({
  name: 'MarketplaceLayout',
  components: {
    SearchResults,
    FeaturedProducts,
    BoostLoader,
    MarketplaceSearchComponent,
    MarketplaceDrawerComponent,
    SearchIcon
  },
  data() {
    return {
      query: '',
      products: undefined as unknown as MarketplaceProduct[],
      isSearching: false
    };
  },
  computed: {
    ...mapGetters('marketplace', {
      searchResults: 'getSearchResults',
      brands: 'getAllBrands',
      featuredProducts: 'getFeaturedProducts'
    }),
    ...mapGetters('auth', {
      currentBuyer: 'getCurrentBuyer'
    })
  },
  async mounted() {
    // First call an endpoint that will QUICKLY return all? or featured products?
    // the search autocomplete will work against this for now, I guess?
    // Then, hitting enter will navigate to a search results page.
    // create a function to publically get list of ALL of our products
    // then create a search endpoint, pass in a query, get back list of products
    // decide fastest way to retrieve that data
    await this.loadAllBrands();

    if (this.$route.query.search !== undefined) {
      this.query = this.$route.query.search as string;
      this.isSearching = true;
      await this.performSearch(this.query);
      this.isSearching = false;
    }
  },
  methods: {
    ...mapMutations('marketplace', ['setIsSearchOpen', 'setAllBrands']),
    ...mapActions('marketplace', ['performSearch', 'loadAllBrands']),
    async getCurrentBuyerProducts() {
      const result = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_AFFILIATE_BRANDS,
        {}
      );
      if (result.status === APIResponseStatus.OK) {
        this.brands = result.data as AffiliateBrand[];
      }
    }
  }
});
