
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';

export default defineComponent({
  name: 'SellerTransferOwnership',
  async mounted() {
    this.setLoading(true);
    this.setBrandInformation({
      brandName: '',
      logoUrl: require('../assets/boost-logo-sm.svg')
    });
    const accountId = this.$route.query.a as string;
    const code = this.$route.query.b as string;
    const email = (this.$route.query.c as string) || '';
    const decodedEmail = atob(email);
    if (
      this.$route.name === 'accept-transfer' &&
      accountId &&
      code &&
      decodedEmail
    ) {
      await FirebaseRequest.createRequest(
        FirebaseRequestTypes.ACCEPT_TRANSFER_OWNERSHIP,
        {
          accountId,
          email: decodedEmail,
          code,
          command: 'accept'
        }
      );
    }
    if (
      this.$route.name === 'deny-transfer' &&
      accountId &&
      code &&
      decodedEmail
    ) {
      await FirebaseRequest.createRequest(
        FirebaseRequestTypes.ACCEPT_TRANSFER_OWNERSHIP,
        {
          accountId,
          email: decodedEmail,
          code,
          command: 'deny'
        }
      );
    }

    this.setLoading(false);
    await this.$router.push({ path: '/login' });
  },
  methods: {
    ...mapMutations('global', [
      'setBannerStatus',
      'setBrandInformation',
      'setLoading'
    ])
  }
});
