import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid-cols-[repeat(1,minmax(50px,1fr)] grid auto-rows-min grid-rows-[repeat(1,min-content)] justify-center gap-3 p-0 min-[600px]:max-[767px]:grid-cols-[repeat(2,minmax(50px,1fr))] min-[768px]:max-[999px]:grid-cols-[repeat(3,minmax(50px,1fr))] min-[1000px]:max-[1259px]:grid-cols-[repeat(4,minmax(50px,1fr))] min-[1260px]:grid-cols-[repeat(5,minmax(50px,1fr))] min-[1680px]:grid-cols-[repeat(6,minmax(50px,1fr))]"
}
const _hoisted_2 = { class: "aspect-h-1 aspect-w-1 aspect-square w-full overflow-hidden relative mb-5" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center justify-center overflow-hidden rounded-full h-6 w-6 absolute left-5 top-5 border border-gray-200 shadow-sm"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!

  return (_ctx.products)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
          return (_openBlock(), _createElementBlock("div", {
            key: product.id,
            class: "flex flex-col flex-nowrap items-start content-start self-start gap-0 h-100 justify-center justify-self-start w-100 pb-8 relative"
          }, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.showBrandLogo)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: _ctx.brandsIdMap[product.accountId] ?? '/img/default-image.png',
                      alt: "",
                      class: "object-cover rounded-full aspect-square"
                    }, null, 8, _hoisted_4)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("img", {
                class: "object-cover object-center w-full h-full rounded-lg overflow-hidden",
                src: product.imageUrl,
                alt: product.title
              }, null, 8, _hoisted_5),
              _createVNode(_component_BoostButton, {
                class: "absolute bottom-4 right-4",
                "button-style": "optional",
                label: "Sell",
                onClick: ($event: any) => (_ctx.selectProduct(product))
              }, null, 8, ["onClick"])
            ]),
            _createElementVNode("div", null, _toDisplayString(product.title), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatPrice(product.price)), 1)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}