
import { defineComponent } from 'vue';
import Layout from '@/layouts/Layout.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'AppRoot',
  components: {
    Layout
  },
  computed: {
    ...mapGetters({
      isLoading: 'global/isLoading',
      isSuperAdmin: 'auth/isSuperAdmin'
    }),
    currentRouteName(): string {
      return this.$route.name ? (this.$route.name as string).toLowerCase() : '';
    }
  }
});
