
import helpers from '@/helpers';
import { AffiliateBrand } from '@/models/affiliateBrands';
import { MarketplaceProduct } from '@/models/marketplaceProduct';
import { PropType, defineComponent } from 'vue';
import { mapMutations, mapGetters, useStore } from 'vuex';

export default defineComponent({
  name: 'ProductsGallery',
  props: {
    products: {
      type: undefined as unknown as PropType<MarketplaceProduct[]>,
      required: false,
      default: undefined
    },
    showBrandLogo: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup() {
    const store = useStore();

    function formatPrice(price: string): string {
      return helpers.currencyFormatter(price);
    }

    const allBrands = store.getters[
      'marketplace/getAllBrands'
    ] as AffiliateBrand[];
    const brandsIdMap: { [key: string]: string } = allBrands.reduce(
      (prev, curr) => {
        prev = { ...prev, [curr.accountId]: curr.imageUrl };
        return prev;
      },
      {}
    );

    return {
      brandsIdMap,
      formatPrice
    };
  },
  computed: {
    ...mapGetters('marketplace', {
      allBrands: 'getAllBrands'
    })
  },
  methods: {
    ...mapMutations('marketplace', {
      selectProduct: 'setSelectedProductToSell'
    })
  }
});
