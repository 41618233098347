
import { defineComponent } from 'vue';

const BoostViewOnlyImage = defineComponent({
  props: {
    title: {
      type: String,
      default: 'Image'
    },
    altText: {
      type: String,
      default: 'Image'
    },
    imageUrl: {
      type: String,
      required: false,
      default: undefined
    },
    typeImageUrl: {
      type: String,
      required: false,
      default: undefined
    }
  }
});

export default BoostViewOnlyImage;
