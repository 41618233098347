
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { PaymentOption } from '../../buyer/settings/BuyerSettingsInterfaces';
import { getMaskedCreditCardNumber } from './StripeCreditCardDetailsComponent.vue';

export default defineComponent({
  name: 'CreditCardSummaryComponent',
  props: {
    card: {
      type: Object as PropType<PaymentOption>,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    verticallyCenterActionSlot: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    formattedCardNumber() {
      if (!this.card) {
        return 'No card information';
      }

      return `${this.card.brand?.toUpperCase()} ${getMaskedCreditCardNumber(
        this.card.brand,
        this.card.last4
      )}`;
    },
    labelText() {
      return this.hideLabel ? '' : 'Card';
    }
  }
});
