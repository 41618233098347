
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoostInfoCard',
  props: {
    icon: {
      type: String,
      default: ''
    }
  }
});
