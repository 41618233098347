import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec7b1bf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card__wrapper" }
const _hoisted_3 = { class: "card__title" }
const _hoisted_4 = { class: "card__message" }
const _hoisted_5 = {
  key: 0,
  class: "rounded-image rounded-image--small card__image"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  v_if: "subMessage",
  class: "sub-message"
}
const _hoisted_8 = { class: "flex align--center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "card__expander"
}
const _hoisted_11 = {
  key: 0,
  class: "card__expander__details"
}
const _hoisted_12 = { class: "card__summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoostButton = _resolveComponent("BoostButton")!
  const _directive_sanitized = _resolveDirective("sanitized")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.imageUrl)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createElementVNode("img", {
                src: _ctx.imageUrl,
                alt: "image"
              }, null, 8, _hoisted_6)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.message), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.subMessage), 1)
        ]),
        (_ctx.hasExpander)
          ? (_openBlock(), _createBlock(_component_BoostButton, {
              key: 1,
              "button-style": "none",
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("img", {
                    src: 
                _ctx.isExpanded
                  ? require('../assets/chevron-up.svg')
                  : require('../assets/chevron-down.svg')
              ,
                    alt: "Expand or close"
                  }, null, 8, _hoisted_9)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.hasExpander && _ctx.isExpanded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true),
            (_ctx.details)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.name,
                      class: "card__expander__details__item"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(item.name), 1),
                      _createElementVNode("span", null, _toDisplayString(item.value), 1)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_12, null, 512), [
              [_directive_sanitized, _ctx.summary]
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}