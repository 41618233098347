
import { defineComponent } from 'vue';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';

export default defineComponent({
  name: 'BoostControlFooter',
  props: {
    saveLabel: {
      type: String,
      default: 'Save'
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    saveLoadingLabel: {
      type: String
    },
    saveCompleteLabel: {
      type: String
    },
    saveLoadingState: {
      type: String as () => ButtonLoadingState,
      default: ButtonLoadingState.initial
    },
    stackOnMobile: {
      type: Boolean,
      default: true
    }
  },
  emits: ['cancel', 'save'],
  methods: {
    cancelHandler() {
      this.$emit('cancel');
    },

    // Call @save="method" on parent when not using a form
    saveHandler() {
      this.$emit('save');
    }
  }
});
