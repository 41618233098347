
import { defineComponent } from 'vue';
import BannerComponent from '@/common/BannerComponent/BannerComponent.vue';
import SideBar from '@/common/SideBar.vue';
import { mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    BannerComponent,
    SideBar
  },
  data() {
    return {
      isMounted: false,
      isOpen: false
    };
  },
  computed: {
    ...mapGetters({
      isMobileNavOpen: 'global/isMobileNavOpen',
      getBannerStatus: 'global/getBannerStatus'
    }),
    isBannerShowing(): boolean {
      return !!this.getBannerStatus?.text;
    },
    showHelp(): boolean {
      return (this.$route.meta?.showHelp as boolean) || false;
    },
    showSubHeader(): boolean {
      return (this.$route.meta.showSubHeader as boolean) || false;
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    ...mapMutations({
      setMobileNavOpen: 'global/setMobileNavOpen'
    }),
    toggleNavMenu() {
      this.isOpen = !this.isOpen;
      this.setMobileNavOpen(this.isOpen);
    }
  }
});
