
import { ref, defineComponent } from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import {
  BannerMessageStrings,
  BannerType
} from './BannerComponent/BannerMessages';

export default defineComponent({
  name: 'TagsToastComponent',
  setup() {
    const show = ref(true);
    return {
      show
    };
  },
  computed: {
    ...mapGetters({
      getBannerStatus: 'global/getBannerStatus',
      currentAccount: 'accounts/currentAccount',
      isBuyerLoggedIn: 'auth/isBuyerLoggedIn'
    }),
    message(): BannerMessageStrings | null {
      return this.getBannerStatus?.text;
    },
    messageType(): BannerType {
      return this.getBannerStatus?.type || BannerType.info;
    }
  },
  methods: {
    ...mapMutations({
      setBannerStatus: 'global/setBannerStatus'
    }),
    dismissToast(): void {
      this.setBannerStatus(null);
    }
  }
});
