import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "boost-wallet-pay" }
const _hoisted_2 = {
  ref: "paymentRequestButton",
  class: "boost-wallet-pay__button"
}
const _hoisted_3 = {
  key: 0,
  class: "boost-wallet-pay__error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}