
import { defineComponent } from 'vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '../../firebaseRequest';
import Helpers from '@/helpers';
import { Charges } from '@/models/charges';
import { mapGetters, mapMutations } from 'vuex';
import TransactionsListView from './TransactionsListView.vue';
import { Transaction } from '@/models/transaction';

export interface TransactionSearch {
  filterValue: string;
  sortBy: string;
  searchValue: string;
}

export default defineComponent({
  components: {
    TransactionsListView
  },
  props: {
    limit: {
      type: Number,
      required: false
    },
    filteredCount: {
      type: Number
    },
    searchInput: {
      type: Object as () => TransactionSearch,
      required: true
    },
    type: {
      type: String,
      required: false
    }
  },
  emits: ['update:filteredCount'],
  data() {
    return {
      tableTransactionData: [] as Transaction[]
    };
  },

  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      productSource: 'accounts/selectedFeatureTypeId'
    }),
    tableFilteredTransactionData(): Transaction[] {
      const results = this.tableTransactionData
        .filter(this.filterTransactionList)
        .sort(this.sortTransactionList) as Transaction[];
      this.$emit('update:filteredCount', results.length);
      return results;
    }
  },

  async mounted() {
    if (!this.currentAccount) {
      return;
    }
    await this.getTransactions();
  },

  methods: {
    ...mapMutations('global', ['setLoading']),
    viewOrder(orderId: string) {
      this.$router.push({ path: `/store/orders/${orderId}` });
    },
    processDate(date: number) {
      return Helpers.processDate(date);
    },
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    async getTransactions() {
      this.setLoading(true);
      const query = this.$route.params;
      const params = {
        accountId: this.currentAccount.accountId,
        transactionId: query.transactionId, // This will be undefined in no transactionId
        productSource: this.productSource
      };

      const results = await FirebaseRequest.createRequest<Transaction[]>(
        FirebaseRequestTypes.GET_SELLER_TRANSACTIONS,
        params
      );

      if (results.status === APIResponseStatus.OK) {
        this.tableTransactionData = results.data || [];
        if (this.limit) {
          this.tableTransactionData = this.tableTransactionData.slice(
            0,
            this.limit
          );
        }
      }

      this.$emit(
        'update:filteredCount',
        this.tableFilteredTransactionData.length
      );

      this.setLoading(false);
    },
    sortTransactionList(a: Transaction, b: Transaction): number {
      const getAmount = (c?: Charges) => {
        return c != null && c.total ? c.total : 0;
      };

      let results = 0;

      if (this.searchInput.sortBy) {
        if (a && b) {
          switch (this.searchInput.sortBy) {
            case 'NameAsc':
              results = (a.shippedTo?.lastName || '').localeCompare(
                b.shippedTo?.lastName || ''
              );
              break;
            case 'NameDesc':
              results = (b.shippedTo?.lastName || '').localeCompare(
                a.shippedTo?.lastName || ''
              );
              break;
            case 'Amount':
              results = getAmount(b.charges) - getAmount(a.charges);
              break;
            case 'AmountAsc':
              results = getAmount(a.charges) - getAmount(b.charges);
              break;
            case 'Date':
              results = b.created - a.created;
              break;
            case 'DateAsc':
              results = a.created - b.created;
              break;
            default:
              results = 1;
          }
        }
      }
      return results;
    },
    filterTransactionList(sellerOrder: Transaction) {
      let filterResults = true;

      const isRefunded = !!sellerOrder.refunded;
      const isFailed = sellerOrder.state.includes('failed');

      switch (this.searchInput.filterValue) {
        case 'Received':
          filterResults =
            (sellerOrder.stripeDetails?.status?.includes('succeeded') &&
              !isRefunded &&
              !isFailed) ||
            false;
          break;
        case 'Refunded':
          filterResults = isRefunded;
          break;
        case 'Failed':
          filterResults = isFailed;
          break;
        default:
          filterResults;
      }

      if (this.searchInput.searchValue) {
        filterResults =
          filterResults && this.filterTransactionsBySearchValue(sellerOrder);
      }
      return filterResults;
    },

    filterTransactionsBySearchValue(transaction: Transaction) {
      const searchValue = this.searchInput.searchValue.toLocaleLowerCase();
      const productTitle = transaction.productTitle?.toLocaleLowerCase() || '';
      const firstName = transaction.shippedTo?.firstName
        ? transaction.shippedTo?.firstName.toLowerCase()
        : '';
      const lastName = transaction.shippedTo?.lastName
        ? transaction.shippedTo?.lastName.toLowerCase()
        : '';
      const date = transaction.created
        ? new Date(transaction.created).toLocaleDateString()
        : '';
      const hashtag = transaction.productHashtag
        ? transaction.productHashtag.toLowerCase()
        : '';
      const userTransactionId = transaction.userTransactionId
        ? transaction.userTransactionId
        : '';
      const amount = transaction.charges
        ? Helpers.currencyFormatter(transaction.charges.price)
        : '';
      // For some reason, transaction.stripeDetails doesn't specify last4 as a
      // property. But it is used in the TransactionsListView component and it
      // does seem to exist. Cast as unknown as any to get around this limitation
      // for now.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const last4 = (transaction.stripeDetails as unknown as any)?.last4 ?? '';

      if (searchValue.length > 0) {
        const searchComponents = searchValue.split(' ');
        let value;

        for (value of searchComponents) {
          if (
            !firstName.includes(value) &&
            !lastName.includes(value) &&
            !date.includes(value) &&
            !hashtag.includes(value) &&
            !productTitle.includes(value) &&
            !userTransactionId.includes(value) &&
            !amount.includes(value) &&
            !last4.includes(value)
          ) {
            return false;
          }
        }
      }
      return true;
    },
    getHashtag(hashtag: string) {
      return Helpers.formatHashTag(hashtag);
    }
  }
});
