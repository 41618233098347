
import { store } from '@/store';
import { defineComponent, Ref, ref } from 'vue';
import { mapGetters } from 'vuex';

let observer: MutationObserver;

export default defineComponent({
  data() {
    return {
      isMounted: false,
      showPoweredBySection: true,
      teleportContainerRef: ref<HTMLDivElement | null>(
        null
      ) as Ref<HTMLDivElement | null>
    };
  },
  computed: {
    ...mapGetters({
      getBrandName: 'global/getBrandName',
      getBrandLogo: 'global/getBrandLogo'
    }),
    hasBrandLogo(): boolean {
      return !!this.getBrandLogo;
    },
    logoImage(): string {
      return this.getBrandLogo || require('../assets/default-product.jpg');
    },
    customColor(): string {
      return store.getters['global/getCustomColor'] as string;
    },
    showHelp(): boolean {
      return store.getters['global/getShowHelp'] as boolean;
    },
    showLogo(): boolean {
      return store.getters['global/getShowLogo'] as boolean;
    },
    isTeleportContainerEmpty(): boolean {
      if (!this.isMounted) {
        return true;
      }

      return this.teleportContainerRef?.children.length === 0;
    }
  },
  beforeUnmount() {
    observer.disconnect();
  },
  mounted() {
    // This checkout header has a "Powered by Tags" or "Help" section
    // at the top right. But sometimes, a page needs to override the
    // content of that section. So, we use a teleport. BUT Vue seems to
    // have a hard time with reactivity when it comes to a Div's children.
    // We want to show the default section if the teleport container div
    // has NO children; and then hide the default section if the teleport
    // container div HAS children.
    // We use a MutationObserver (not addEventListener) in order to do this.
    this.teleportContainerRef = this.$refs
      .checkoutHeaderTeleportContainer as HTMLDivElement;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    observer = new MutationObserver((mutationList, _) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          this.showPoweredBySection =
            this.teleportContainerRef?.children.length === 0;
        }
      }
    });

    observer.observe(this.teleportContainerRef, { childList: true });
  }
});
