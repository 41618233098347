import { CommerceType } from '../../enums/commerceTypes';
import { TagType } from '@/enums/tagType';

/**
 * FB Product model.
 *
 * @export
 * @interface FBProduct
 */
export interface FBProduct {
  chargeTax?: boolean;
  commerce: CommerceType;
  description: string;
  disabled?: boolean;
  hashtag: string;
  imageUrl?: string;
  keywords?: string[];
  parcel?: Parcel;
  price: string;
  sauceyProductId?: string;
  shippingCharge?: number;
  shopifyProductId: string;
  shopifyVariantId?: number;
  skipShipping?: boolean;
  skus?: { [skuId: string]: Sku };
  title: string;
  type: TagType;
  variants?: { [variantId: string]: Variant };
  message?: string;
  messageUrl?: string;
  attributions?: Attribution[];
}

export interface Sku {
  description: string;
  sku: string;
  imageUrl?: string;
  options?: Array<{
    key: string;
    value: string;
  }>;
  price: string;
  quantity: number | 'UNLIMITED';
}

export interface Variant {
  name: string;
  options: string[];
  order: number;
}

export interface Parcel {
  height: number;
  length: number;
  weight: number;
  width: number;
}

export enum AttributionType {
  Account,
  Advertisement,
  Audio,
  Influencer,
  Magazine,
  Newspaper,
  Packaging,
  Print,
  Podcast
}

export enum AttributionStatus {
  Active,
  Inactive
}
export interface Attribution {
  name: string;
  type: AttributionType;
  status: AttributionStatus;
  identifier: string;
}
