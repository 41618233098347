import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-18 fixed w-full z-[100]" }
const _hoisted_2 = { class: "checkout-main-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "pt-18 checkout-container px-5 mt-[72px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagsToast = _resolveComponent("TagsToast")!
  const _component_MarketplaceHeaderComponent = _resolveComponent("MarketplaceHeaderComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TagsToast),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MarketplaceHeaderComponent)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isMounted)
          ? (_openBlock(), _createElementBlock("main", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}