
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  name: 'SellerUserInvitation',
  data() {
    return {
      sellerName: '',
      accountId: '',
      code: '',
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      getBrandName: 'global/getBrandName',
      isBuyerLoggedIn: 'auth/isBuyerLoggedIn',
      isSellerLoggedIn: 'auth/isSellerLoggedIn'
    })
  },
  async mounted() {
    // If the user is logged in, log them out automatically
    if (this.isBuyerLoggedIn || this.isSellerLoggedIn) {
      await this.$store.dispatch('auth/signOut');
    }
    this.setLoading(true);
    this.setBrandInformation({
      brandName: '',
      logoUrl: require('../assets/boost-logo-sm.svg')
    });
    this.accountId = this.$route.query.a as string;
    this.code = this.$route.query.b as string;
    const email = (this.$route.query.c as string) || '';
    this.newUser.email = atob(email);
    const results = await FirebaseRequest.createRequest<{
      seller: { name: string };
    }>(FirebaseRequestTypes.VALIDATE_SELLER_INVITE, {
      accountId: this.accountId,
      email: email,
      code: this.code
    });
    this.sellerName = results.data?.seller.name || '';
    this.setLoading(false);
    if (this.$route.name === 'deny-invitation') {
      await this.denyInvitation();
    }
  },
  methods: {
    ...mapMutations('global', [
      'setBannerStatus',
      'setBrandInformation',
      'setLoading'
    ]),
    async completeRegistration() {
      this.setLoading(true);
      const results = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.ACCEPT_SELLER_USER_INVITE,
        {
          accountId: this.accountId,
          email: this.newUser.email,
          code: this.code,
          lastName: this.newUser.lastName,
          firstName: this.newUser.firstName,
          password: this.newUser.password,
          command: 'accept'
        }
      );
      if (results.status === APIResponseStatus.OK) {
        // Go to login page
        this.setBannerStatus({
          text: BannerMessageStrings.CHANGES_UPDATED,
          type: BannerType.info
        });
        this.setBrandInformation(null);
        await this.$router.push({ path: '/login' });
      } else {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.setLoading(false);
    },
    async denyInvitation() {
      this.setLoading(true);
      await FirebaseRequest.createRequest(
        FirebaseRequestTypes.ACCEPT_SELLER_USER_INVITE,
        {
          accountId: this.accountId,
          email: this.newUser.email,
          code: this.code,
          lastName: '',
          firstName: '',
          password: '',
          command: 'deny'
        }
      );
      this.setBrandInformation(null);
      this.setLoading(false);
      await this.$router.push({ path: '/login' });
    }
  }
});
