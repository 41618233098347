export interface SellerBusinessSettings {
  companyName: string;
  email: string;
  companyEmail: string;
  companyNumber?: string;
  companyZipCode: string;
  refundPolicy: string;
  hashTagPrefix: string;
  logoUrl: string;
  isConnectedToStripe: boolean;
  accountHolderLastName?: string;
  accountHolderFirstName?: string;
}

export interface SellerBillingSettings {
  cardNumber: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardSecurityCode: string;
  cardZipCode: string;
}

export interface SellerShippingSettings {
  companyName: string;
  companyAddress1: string;
  companyAddress2: string;
  companyCity: string;
  companyState: string;
  companyZipCode: string;
  companyCountry: string;
}

export interface SellerCustomSettings {
  metaPixel?: string;
  customColor?: string;
}

export enum SettingsType {
  BUSINESS = 'business',
  SHIPPING = 'shipping'
}

export interface AllSellerSettings {
  type: SettingsType;
  data: {
    accountSettings: SellerBusinessSettings;
    shippingSettings: SellerShippingSettings;
  };
}
