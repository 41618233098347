import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SellerSettingsAccount = _resolveComponent("SellerSettingsAccount")!

  return (_openBlock(), _createBlock(_component_SellerSettingsAccount, {
    "save-button-loading-state": _ctx.saveButtonLoadingState,
    "onUpdate:save-button-loading-state": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.saveButtonLoadingState) = $event)),
    "disconnect-stripe-button-loading-state": 
      _ctx.disconnectStripeButtonLoadingState
    ,
    "onUpdate:disconnect-stripe-button-loading-state": _cache[1] || (_cache[1] = ($event: any) => ((
      _ctx.disconnectStripeButtonLoadingState
    ) = $event)),
    "account-settings": _ctx.accountSettings,
    "shipping-settings": _ctx.shippingSettings,
    "custom-settings": _ctx.customSettings,
    onUpdateSellerSettings: _ctx.updateSellerSettings,
    onCancelUpdateSellerSettings: _ctx.cancelUpdateSellerSettings,
    onConnectToStripe: _ctx.connectToStripe,
    onDisconnectFromStripe: _ctx.disconnectFromStripe,
    onChangePassword: _ctx.changePassword
  }, null, 8, ["save-button-loading-state", "disconnect-stripe-button-loading-state", "account-settings", "shipping-settings", "custom-settings", "onUpdateSellerSettings", "onCancelUpdateSellerSettings", "onConnectToStripe", "onDisconnectFromStripe", "onChangePassword"]))
}