import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "footer"
}
const _hoisted_2 = { class: "footer__content content container--footer" }
const _hoisted_3 = { class: "footer__nav" }
const _hoisted_4 = { class: "footer__nav-item" }
const _hoisted_5 = { class: "footer__nav-item" }
const _hoisted_6 = { class: "footer__registration" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isBranded)
    ? (_openBlock(), _createElementBlock("footer", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", _hoisted_4, [
              _createVNode(_component_router_link, { to: "/privacy-policy" }, {
                default: _withCtx(() => [
                  _createTextVNode("Privacy")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_router_link, { to: "/seller-terms" }, {
                default: _withCtx(() => [
                  _createTextVNode("Terms of Use")
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", null, " © " + _toDisplayString(_ctx.year.getFullYear()) + " Tags Commerce Inc. All rights reserved. ", 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}