
import { defineComponent } from 'vue';
import {
  PreRegStatus,
  SellerAccount
} from '@/pre-registration/PreRegistration.vue';
import Helpers from '@/helpers';
import { FirebaseRequest, FirebaseRequestTypes } from '@/firebaseRequest';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import MetaPixelScript from '@/common/MetaPixelScript.vue';
import { store } from '@/store';

export default defineComponent({
  name: 'PreRegEntry',
  components: {
    MetaPixelScript
  },
  props: {
    seller: {
      type: Object as () => SellerAccount
    },
    mobileNumber: {
      type: String
    },
    status: {
      type: String as () => PreRegStatus
    }
  },
  emits: ['update:mobileNumber', 'next', 'error'],
  data() {
    return {
      internalMobileNumber: '',
      hasSentConfirmationCode: false,
      confirmationCode: '',
      userTypeContext: '',
      getCodeState: ButtonLoadingState.initial,
      loginContext: {
        LOGIN_EMAIL: 'LOGIN_EMAIL',
        LOGIN_MOBILE_NUMBER: 'LOGIN_MOBILE_NUMBER',
        SIGNUP_MOBILE_NUMBER: 'SIGNUP_MOBILE_NUMBER',
        PREREGISTER_USER: 'PREREGISTER_USER',
        PREREGISTER_EXISTING_USER: 'PREREGISTER_EXISTING_USER'
      }
    };
  },
  computed: {
    customColor(): string {
      return store.getters['global/getCustomColor'] as string;
    }
  },
  methods: {
    async requestConfirmationCode() {
      try {
        this.$emit('update:mobileNumber', this.internalMobileNumber);
        this.getCodeState = ButtonLoadingState.loading;
        const payload = {
          number: Helpers.prefixPhoneNumber(this.internalMobileNumber),
          context: this.loginContext.PREREGISTER_USER
        };

        const confirmationCodeResponse = await FirebaseRequest.createRequest(
          FirebaseRequestTypes.REQUEST_CONFIRMATION_CODE,
          payload
        );

        if (confirmationCodeResponse) {
          this.getCodeState = ButtonLoadingState.complete;
          this.userTypeContext = confirmationCodeResponse.code;
          this.hasSentConfirmationCode = true;

          store.commit('global/setBannerStatus', {
            text: BannerMessageStrings.BUYER_SENDING_CONFIRMATION_CODE,
            type: BannerType.success
          });
        }
      } catch (ex) {
        store.commit('global/setBannerStatus', {
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.getCodeState = ButtonLoadingState.complete;
        this.hasSentConfirmationCode = false;
      }
    },

    async checkConfirmationCode() {
      if (!this.mobileNumber) {
        return;
      }
      // Check if phone number exists
      // 1. call existing code validation api
      // 2. if existing, call new api to register the relationship
      // 3. if new customer, proceed to next step to collect info
      this.getCodeState = ButtonLoadingState.loading;

      const context = this.loginContext.PREREGISTER_USER;

      const payload = {
        number: Helpers.prefixPhoneNumber(this.mobileNumber),
        code: this.confirmationCode,
        context
      };

      const confirmationCodeResponse = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.VALIDATE_CONFIRMATION_CODE,
        payload
      );

      if (confirmationCodeResponse) {
        if (confirmationCodeResponse.code === 'OK') {
          this.$emit('next', this.internalMobileNumber, this.userTypeContext);

          if (this.userTypeContext !== 'PREREGISTER_EXISTING_USER') {
            this.getCodeState = ButtonLoadingState.complete;
          }
        } else {
          this.$emit('error', confirmationCodeResponse.code);
          this.getCodeState = ButtonLoadingState.complete;
        }
      }
    }
  }
});
