
import { ColumnItem } from '@/common/controls/BoostTable/Table.interface';
import {
  PopupMessage,
  PopupMessageButtonCaptions,
  PopupMessageStrings,
  PopupMessageTitles
} from '@/common/PopupComponent/PopupMessages';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PopupComponent from '@/common/PopupComponent/PopupComponent.vue';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ContextMenuItem } from '@/common/controls/BoostContextMenu.vue';
import { RoleType } from '@/../enums/roleTypes';
import helpers from '@/helpers';
import { Permissions } from '@/store/types';
import { AccountObject } from '@/models/accountObject';

interface AccountUser {
  userId: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  status: string;
  imageUrl: string;
}

const ManageUsers = defineComponent({
  name: 'ManageUsers',
  components: {
    PopupComponent
  },
  data() {
    return {
      showAddUser: false,
      showTransferOwner: false,
      ownerPassword: '',
      transferTo: {} as AccountUser,
      transferPopup: {} as PopupMessage,
      addUserPopup: {} as PopupMessage,
      addUserEmail: '',
      addUserErrorMessage: [] as string[],
      selectedRole: '',
      roles: [
        { name: 'Admin', value: RoleType.SellerAdmin },
        { name: 'Staff', value: RoleType.Staff },
        { name: 'Agency', value: RoleType.Agency }
      ],
      usersList: [] as AccountUser[],
      columns: [
        {
          id: 'imageUrl',
          label: ''
        },
        {
          id: 'userName',
          label: 'Name'
        },
        {
          id: 'userRole',
          label: 'Role'
        },
        {
          id: 'email',
          label: 'Email'
        },
        {
          id: 'badges',
          label: 'Status'
        },
        {
          id: 'menu',
          label: ''
        }
      ] as ColumnItem[],
      menuItems: [
        {
          label: 'Copy Email',
          onClick: async (entity) => {
            await this.copyEmail(entity);
          }
        },
        {
          label: 'Resend Invitation',
          onClick: async (entity) => {
            await this.resendInvite(entity);
          },
          isHidden: (entity) => {
            return entity.status !== 'invite sent';
          }
        },
        {
          label: 'Remove User',
          onClick: async (entity) => {
            const results = confirm(
              `Are you sure you want to remove this user? ${entity.email}`
            );
            if (results) {
              await this.removeUser(entity);
            }
          },
          isDisabled: (entity) => {
            return entity.role === RoleType.Owner;
          }
        },
        {
          label: 'Transfer Ownership',
          onClick: (entity) => {
            this.handleTransferOwnership(entity);
          },
          isDisabled: (entity) => {
            return (
              entity.status !== 'active' || entity.role !== RoleType.SellerAdmin
            );
          },
          isHidden: () => {
            return !helpers.userHasPermission(
              Permissions.TransferOwnership,
              this.$store
            );
          }
        }
      ] as ContextMenuItem[]
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    }),
    canAddUser(): boolean {
      return !!this.addUserEmail && !!this.selectedRole && this.isUnderMaxUsers;
    },
    isUnderMaxUsers(): boolean {
      const maxCount =
        (this.currentAccount as AccountObject).currentPlan === 'Plus' ? 3 : 0;
      return this.usersList.length < maxCount;
    }
  },
  async mounted() {
    if (!this.currentAccount) {
      return;
    }
    await this.loadData();
  },
  methods: {
    ...mapMutations('global', [
      'setBannerStatus',
      'setBrandInformation',
      'setLoading'
    ]),
    ...mapActions('auth', ['reAuthenticate']),
    getProfile(user: AccountUser) {
      return user.imageUrl || require('../../assets/default-product.jpg');
    },
    async handleRoleChange(e: AccountUser) {
      this.setLoading(true);
      const results = await FirebaseRequest.createRequest<{ error: string }>(
        FirebaseRequestTypes.CHANGE_SELLER_USER_ROLE,
        {
          accountId: this.currentAccount.accountId,
          email: e.email,
          role: e.role
        }
      );
      if (results.status !== APIResponseStatus.OK) {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.setLoading(false);
    },
    async copyEmail(user: AccountUser) {
      await navigator.clipboard.writeText(user.email);
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    handleTransferOwnership(user: AccountUser) {
      if (user?.email) {
        this.transferPopup = {
          title: PopupMessageTitles.TRANSFER_OWNER,
          message: `Transfer ownership to an Admin on your account. Please check the name before transferring. <br /><br /> You are transferring ownership to: <strong>${user.firstName} ${user.lastName}</strong>`,
          button1: {
            caption: PopupMessageButtonCaptions.OK_YES_12,
            primary: true,
            event: ''
          },
          button2: {
            caption: PopupMessageButtonCaptions.CANCEL_NO
          }
        };
        this.transferTo = user;
        this.showTransferOwner = true;
      }
    },
    async transferOwnership() {
      // Must validate the password provided
      const authorized = await this.reAuthenticate({
        password: this.ownerPassword
      });
      if (!authorized) {
        this.showTransferOwner = false;
        this.setBannerStatus({
          text: BannerMessageStrings.SELLER_EMAIL_LOGIN_ERROR,
          type: BannerType.error
        });
        return;
      }
      this.setLoading(true);
      const results = await FirebaseRequest.createRequest<{ error: string }>(
        FirebaseRequestTypes.REQUEST_TRANSFER_OWNERSHIP,
        {
          accountId: this.currentAccount.accountId,
          transferToEmail: this.transferTo.email
        }
      );
      this.showTransferOwner = false;
      if (results.status === APIResponseStatus.ERROR) {
        this.setBannerStatus({
          text:
            results.data?.error || BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.setLoading(false);
      } else {
        await this.loadData();
      }
    },
    async removeUser(user: AccountUser) {
      this.setLoading(true);
      const results = await FirebaseRequest.createRequest<{ error: string }>(
        FirebaseRequestTypes.REMOVE_SELLER_USER,
        {
          accountId: this.currentAccount.accountId,
          userId: user.userId,
          email: user.email
        }
      );

      if (results.status === APIResponseStatus.ERROR) {
        this.setBannerStatus({
          text:
            results.data?.error || BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
        this.setLoading(false);
      } else {
        await this.loadData();
      }
    },
    async loadData() {
      this.setLoading(true);
      this.usersList = [];
      const results = await FirebaseRequest.createRequest<AccountUser[]>(
        FirebaseRequestTypes.GET_SELLER_USERS,
        {
          accountId: this.currentAccount.accountId
        }
      );
      let index = 0;
      const roleIndex = Object.keys(RoleType).map((x) => x.toLocaleLowerCase());
      if (results.data?.map) {
        this.usersList =
          results.data
            ?.sort(
              (a, b) =>
                roleIndex.indexOf(a.role.toLocaleLowerCase()) -
                roleIndex.indexOf(b.role.toLocaleLowerCase())
            )
            ?.map((x) => ({ ...x, index: ++index })) || [];
      }
      this.setLoading(false);
    },
    handleAddUser() {
      this.addUserErrorMessage = [];
      this.showAddUser = true;
      this.addUserPopup = {
        title: PopupMessageTitles.ADD_USER,
        message: PopupMessageStrings.ADD_USER
      };
    },
    async resendInvite(accountUser: AccountUser) {
      this.selectedRole = accountUser.role;
      this.addUserEmail = accountUser.email;
      await this.addUserEventHandler();
    },
    async addUserEventHandler() {
      if (this.selectedRole && this.addUserEmail) {
        this.setLoading(true);
        // Validate the email and role
        await this.checkIfEmailInUse(this.addUserEmail);
        if (!this.addUserErrorMessage.length) {
          // Call the API to create the invitation
          const results = await FirebaseRequest.createRequest<{
            error: string;
          }>(FirebaseRequestTypes.ADD_SELLER_USER_INVITE, {
            accountId: this.currentAccount.accountId,
            role: this.selectedRole,
            email: this.addUserEmail
          });

          this.selectedRole = '';
          this.addUserEmail = '';
          if (results.status === APIResponseStatus.ERROR) {
            this.setBannerStatus({
              text:
                results.data?.error ||
                BannerMessageStrings.GENERIC_ERROR_MESSAGE,
              type: BannerType.error
            });
            this.showAddUser = false;
          } else {
            this.showAddUser = false;
            await this.loadData();
          }
        }
        this.setLoading(false);
      }
    },
    async checkIfEmailInUse(email: string) {
      const results = await FirebaseRequest.createRequest<{ exists: boolean }>(
        FirebaseRequestTypes.CHECK_USER_EMAIL_EXISTS,
        {
          accountId: this.currentAccount.accountId,
          email
        }
      );
      if (results?.data?.exists) {
        this.addUserErrorMessage = ['User already exists. Please add another.'];
      } else {
        this.addUserErrorMessage = [];
      }
    }
  }
});

export default ManageUsers;
