
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Helpers from '@/helpers';
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import {
  FirebaseRequest,
  FirebaseRequestTypes,
  APIResponseStatus
} from '@/firebaseRequest';
import {
  AffiliateEarnings,
  AffiliateEarningHistory
} from '@/models/affiliateBrands';
import { mapMutations } from 'vuex';
import PopupComponent from '@/common/PopupComponent/PopupComponent.vue';
import {
  PopupMessageStrings,
  PopupMessageTitles
} from '@/common/PopupComponent/PopupMessages';

export default defineComponent({
  name: 'BuyerEarnings',
  components: {
    PopupComponent
  },
  data() {
    return {
      PopupMessageStrings,
      PopupMessageTitles,
      totalAmount: '',
      pendingAmount: '',
      availableAmount: '',
      canWithdraw: false,
      showWithdraw: false,
      withdrawAmount: 0,
      maxAmount: 0,
      affiliateTransactions: [] as AffiliateEarningHistory[],
      columns: [
        {
          id: 'image',
          label: ''
        },
        {
          id: 'brandName',
          label: 'Brand'
        },
        {
          id: 'productName',
          label: 'Product'
        },
        {
          id: 'quantity',
          label: 'Quantity'
        },
        {
          id: 'amount',
          label: 'Total'
        },
        {
          id: 'activityDate',
          label: 'Date'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      buyerSettings: 'auth/getCurrentBuyer'
    })
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    ...mapMutations('global', ['setLoading', 'setBannerStatus']),
    formatCurrency(value: number | string) {
      return Helpers.currencyFormatter(value);
    },
    async loadData() {
      this.setLoading(true);
      const result = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.GET_AFFILIATE_EARNINGS,
        {}
      );
      if (result.status === APIResponseStatus.OK) {
        const earnings = result.data as AffiliateEarnings;
        this.totalAmount = this.formatCurrency(earnings.total);
        this.pendingAmount = this.formatCurrency(earnings.pending);
        this.availableAmount = this.formatCurrency(earnings.available);
        this.canWithdraw = !!earnings.available;
        this.maxAmount = earnings.available / 100;
        this.affiliateTransactions = earnings.history || [];
      } else {
        this.setBannerStatus({
          text: BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.setLoading(false);
    },
    async withdraw() {
      this.showWithdraw = false;
      this.setLoading(true);
      const result = await FirebaseRequest.createRequest(
        FirebaseRequestTypes.AFFILIATE_WITHDRAW_AMOUNT,
        {
          withdrawAmount: this.withdrawAmount
        }
      );
      if (result.status === APIResponseStatus.OK) {
        const earnings = result.data as AffiliateEarnings;
        this.totalAmount = this.formatCurrency(earnings.total);
        this.pendingAmount = this.formatCurrency(earnings.pending);
        this.availableAmount = this.formatCurrency(earnings.available);
        this.canWithdraw = !!earnings.available;
        this.maxAmount = earnings.available / 100;
        this.affiliateTransactions = earnings.history || [];
        this.setBannerStatus({
          text: BannerMessageStrings.BUYER_AFFILIATE_WITHDRAW_SUCCESS,
          type: BannerType.success
        });
      } else {
        const errorMessage =
          result.code === 'STRIPE_NOT_CONNECTED' ||
          result.code === 'STRIPE_ERROR'
            ? (result.data as { message: string })?.message
            : BannerMessageStrings.GENERIC_ERROR_MESSAGE;
        this.setBannerStatus({
          text: errorMessage ?? BannerMessageStrings.GENERIC_ERROR_MESSAGE,
          type: BannerType.error
        });
      }
      this.setLoading(false);
    }
  }
});
