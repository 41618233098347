import {
  getAuth,
  User,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  EmailAuthProvider,
  updateEmail,
  reauthenticateWithCredential
} from 'firebase/auth';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AuthState, RoleAccount, RootState, UserRegistration } from './types';
import { Buyer } from '@/buyer/settings/BuyerSettingsInterfaces';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { Role } from '@/models/role';
import { RoleType } from '@/../enums/roleTypes';
import { AccountObject } from '@/../models/accountObject';

const authState: AuthState = {
  currentUser: null,
  currentBuyer: null,
  isBuyerLoggedIn: false,
  isSellerLoggedIn: false,
  isSuperAdmin: false,
  isImpersonating: false,
  roleAccounts: [],
  loginOrRegistrationType: UserRegistration.BUYER
};

const authActions: ActionTree<AuthState, RootState> = {
  // seller sign in
  signIn: async (
    { commit },
    payload: { email: string; password: string }
  ): Promise<boolean> => {
    try {
      const authData = await signInWithEmailAndPassword(
        getAuth(),
        payload.email,
        payload.password
      );

      if (!(authData && authData.user && authData.user.uid)) {
        return false;
      }

      commit('setCurrentUser', authData.user);

      try {
        const getRoleResponse = await FirebaseRequest.createRequest<Role>(
          FirebaseRequestTypes.GET_LOGGED_IN_USER_ROLE,
          {}
        );
        if (getRoleResponse.data) {
          const role = getRoleResponse.data;
          commit('setSuperAdmin', !!role.admin);

          const roleAccounts: RoleAccount[] = [];
          if (role.accounts) {
            for (const key of Object.keys(role.accounts)) {
              roleAccounts.push({
                accountId: key,
                role: role.accounts[key]
              });
            }
          }

          // If the account is a basic account, only owner can log in
          if (!role.admin) {
            // Non-admin users should only have one account
            const firstAccount = roleAccounts[0];
            if (firstAccount.role !== RoleType.Owner) {
              // Get the account to check the plan type
              const accountResult =
                await FirebaseRequest.createRequest<AccountObject>(
                  FirebaseRequestTypes.GET_ACCOUNT_DATA,
                  {
                    accountId: firstAccount.accountId
                  }
                );
              if (
                !accountResult.data?.currentPlan ||
                accountResult.data.currentPlan === 'Basic'
              ) {
                await getAuth().signOut();
                commit('setCurrentUser', null);
                commit('setRoleAccounts', []);
                return false;
              }
            }
          }

          commit('setRoleAccounts', roleAccounts);
        }
      } catch (error) {
        // It's possible that a user doesn't have a role.
        // In that case the RoleService throws.
        commit('setSuperAdmin', false);
      }

      return true;
    } catch (ex) {
      console.log('Login exception: ', ex);
      return false;
    }
  },
  // seller forgot password
  forgotPassword: async (
    { commit },
    payload: { email: string }
  ): Promise<boolean> => {
    let isPassResetEmailSend = false;

    await sendPasswordResetEmail(getAuth(), payload.email)
      .then(() => {
        isPassResetEmailSend = true;
      })
      .catch(() => {
        isPassResetEmailSend = false;
      });

    commit('setCurrentUser', getAuth().currentUser);
    return isPassResetEmailSend;
  },
  updateSellerEmail: async (
    { commit },
    payload: { newEmail: string; password: string }
  ): Promise<boolean> => {
    // TODO Firebase will throw a 400 if the user hasn't logged in recently, so need to checkout for this
    // state and determine whether we need to call reauthenticateWithCredential first
    let emailUpdated = false;
    const user = getAuth().currentUser;
    if (user) {
      // Re-authenticate the user first
      const cred = EmailAuthProvider.credential(
        user.email || '',
        payload.password
      );
      await reauthenticateWithCredential(user, cred)
        .then(() => {
          emailUpdated = true;
        })
        .catch((error) => {
          console.log(error);
          emailUpdated = false;
        });
      // Now that the user has been re-authenticated then the email can be updated
      if (emailUpdated) {
        await updateEmail(user, payload.newEmail)
          .then(() => {
            emailUpdated = true;
          })
          .catch((error) => {
            console.log(error);
            emailUpdated = false;
          });
      }
    }

    commit('setCurrentUser', user);
    return emailUpdated;
  },
  reAuthenticate: async (
    { commit },
    payload: { password: string }
  ): Promise<boolean> => {
    const user = getAuth().currentUser;
    let results = false;
    if (user) {
      try {
        // Re-authenticate the user first
        const cred = EmailAuthProvider.credential(
          user.email || '',
          payload.password
        );
        await reauthenticateWithCredential(user, cred)
          .then(() => {
            results = true;
            commit('setCurrentUser', user);
          })
          .catch((error) => {
            console.log('FB Reauthentication error', error);
          });
      } catch (ex) {
        console.log('Reathentication error', ex);
      }
    }
    return results;
  },
  // seller sign out
  signOut: async ({ commit }): Promise<boolean> => {
    await getAuth().signOut();
    commit('setCurrentUser', null);
    commit('setSuperAdmin', false);
    commit('setRoleAccounts', []);

    return true;
  },
  refreshCurrentBuyer: async ({ commit, getters }): Promise<boolean> => {
    if (!getters['isBuyerLoggedIn']) {
      return false;
    }

    try {
      const buyerResponse = await FirebaseRequest.createRequest<Buyer>(
        FirebaseRequestTypes.GET_BUYER_INFO,
        {}
      );
      if (buyerResponse.status !== APIResponseStatus.OK) {
        return false;
      }

      if (buyerResponse.data) {
        commit('setCurrentBuyer', buyerResponse.data);
      }
    } catch (error) {
      console.log('auth/refreshCurrentBuyer ERROR: ', error);
      return false;
    }

    return true;
  },
  updateAffiliateUsername: ({ state }, payload: string): void => {
    if (state.currentBuyer) {
      state.currentBuyer.affiliateUsername = payload;
    }
  }
};

const authGetters: GetterTree<AuthState, RootState> = {
  isBuyerLoggedIn: (state: AuthState): boolean => {
    const hasAccounts = state.roleAccounts.length > 0;
    return !hasAccounts && state.currentUser && state.currentUser.uid
      ? true
      : false;
  },
  isSellerLoggedIn: (state: AuthState): boolean => {
    const hasAccounts = state.roleAccounts.length > 0;
    return hasAccounts && state.currentUser && state.currentUser.uid
      ? true
      : false;
  },
  isSuperAdmin: (state: AuthState): boolean => {
    return state.currentUser && state.currentUser.uid && state.isSuperAdmin
      ? true
      : false;
  },
  isImpersonating: (state: AuthState): boolean => {
    return state.isImpersonating;
  },
  roleAccounts: (state: AuthState): RoleAccount[] => {
    return state.roleAccounts;
  },
  loginOrRegistrationType: (state: AuthState): number => {
    return state.loginOrRegistrationType;
  },
  getCurrentUser: (state: AuthState): User | null => {
    return state.currentUser || null;
  },
  getCurrentBuyer: (state: AuthState): Buyer | null => {
    if (state.currentBuyer && !state.currentBuyer.imageUrl) {
      state.currentBuyer.imageUrl = '/img/default-image-large.png';
    }
    return state.currentBuyer;
  }
};

const authMutations: MutationTree<AuthState> = {
  setCurrentUser: (state: AuthState, payload: User) => {
    state.currentUser = payload;
  },
  setCurrentBuyer: (state: AuthState, payload: Buyer) => {
    state.currentBuyer = payload;
  },
  setSuperAdmin: (state: AuthState, payload: boolean) => {
    state.isSuperAdmin = payload;
  },
  setRoleAccounts: (state: AuthState, payload: RoleAccount[]) => {
    state.roleAccounts = payload;
  },
  setLoginOrRegistrationType: (state: AuthState, payload: number) => {
    state.loginOrRegistrationType = payload;
  },
  setImpersonating: (state: AuthState, payload: boolean) => {
    state.isImpersonating = payload;
  },
  setBuyerVerificationAttempted: (state: AuthState, payload: boolean) => {
    if (state.currentBuyer) {
      state.currentBuyer.hasAttemptedVerification = payload;
    }
  }
};

export const auth = {
  actions: authActions,
  getters: authGetters,
  mutations: authMutations,
  state: authState,
  namespaced: true
};
