import { renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-nowrap justify-center flex-col w-full" }
const _hoisted_2 = { class: "text-sm leading-6" }
const _hoisted_3 = { class: "w-full h-full flex flex-nowrap flex-col gap-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckoutDetailDivider = _resolveComponent("CheckoutDetailDivider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Teleport, { to: "#checkoutDetailHeaderDiv" }, [
      _renderSlot(_ctx.$slots, "step-header")
    ])),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CheckoutDetailDivider),
      _renderSlot(_ctx.$slots, "action-button")
    ])
  ]))
}