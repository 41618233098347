
import { defineComponent } from 'vue';
import StripeCreditCardDetailsComponent from '../src/common/info/StripeCreditCardDetailsComponent.vue';
import StripeCreditCardSummaryComponent from '../src/common/info/StripeCreditCardSummaryComponent.vue';
import AddressDetailComponent from '../src/common/info/AddressDetailComponent.vue';
import AddressSummaryComponent from '../src/common/info/AddressSummaryComponent.vue';

import {
  PaymentOption,
  ShippingAddress
} from './buyer/settings/BuyerSettingsInterfaces';
import { ContextMenuItem } from './common/controls/BoostContextMenu.vue';

export default defineComponent({
  name: 'SamplePage',
  components: {
    StripeCreditCardDetailsComponent,
    StripeCreditCardSummaryComponent,
    AddressDetailComponent,
    AddressSummaryComponent
  },
  data() {
    return {
      message:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum fermentum lacus.',
      cardFourDetails: [
        { name: 'Detail One', value: 'Here is the value' },
        { name: 'Detail Two', value: 'Here is another value' }
      ],
      primaryCreditCard: {
        last4: '4242',
        brand: 'visa',
        expiredMonth: 12,
        expiredYear: 2034,
        id: 'card_1Lf0mZJp8IAm945lRvthiJ1Z',
        isDefault: true,
        name: null,
        email: null,
        phone: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          country: null,
          state: null,
          postalCode: '33435'
        }
      },
      secondaryCreditCard: {
        last4: '3535',
        brand: 'mastercard',
        expiredMonth: 5,
        expiredYear: 2025,
        id: 'card_1Lf0mZJp8IAm945lRvthiJ1Z',
        isDefault: false,
        name: null,
        email: null,
        phone: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          country: null,
          state: null,
          postalCode: '33435'
        }
      },
      address: {
        address1: '10755 SW 108 Ave',
        address2: 'Apt 305',
        city: 'Boynton Beach',
        state: 'FL',
        postalCode: '33435',
        country: 'US',
        isDefault: true
      } as ShippingAddress,
      secondaryAddress: {
        address1: '123 Main Street',
        city: 'Hialeah',
        state: 'FL',
        postalCode: '33016',
        country: 'US',
        isDefault: false
      } as ShippingAddress,
      menuItems: [
        {
          label: 'Make Default',
          onClick: (creditCard) => {
            this.makeCreditCardDefault(creditCard as unknown as PaymentOption);
          }
        },
        {
          label: 'Edit Card',
          onClick: (creditCard) => {
            alert(`Edit card ${creditCard.brand}`);
          }
        },
        {
          label: 'Remove Card',
          onClick: (creditCard) => {
            alert(`Remove card ${creditCard.brand}`);
          }
        }
      ] as ContextMenuItem[],
      addressMenuItems: [
        {
          label: 'Make Default',
          onClick: (address) => {
            alert(`Make Default ${address.address1}`);
          }
        },
        {
          label: 'Edit Address',
          onClick: (address) => {
            alert(`Edit card ${address.address1}`);
          }
        },
        {
          label: 'Remove Address',
          onClick: (address) => {
            alert(`Remove card ${address.address1}`);
          }
        }
      ] as ContextMenuItem[]
    };
  },
  methods: {
    makeCreditCardDefault(creditCard: PaymentOption) {
      // this is hacky for now on the samples page
      // first, reset all the defaults
      this.primaryCreditCard.isDefault = false;
      this.secondaryCreditCard.isDefault = false;

      // Now, set default on the creditCard that was passed in.
      creditCard.isDefault = true;
    }
  }
});
