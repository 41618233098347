
import {
  BannerMessageStrings,
  BannerType
} from '@/common/BannerComponent/BannerMessages';
import { ButtonLoadingState } from '@/common/controls/BoostButton.vue';
import {
  APIResponseStatus,
  FirebaseRequest,
  FirebaseRequestTypes
} from '@/firebaseRequest';
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { ConnectShopify } from '@/../models/connectShopify';

const SaveForLater = defineComponent({
  name: 'SaveForLater',
  emits: ['updateSaveForLaterAutomatic', 'connectToShopify', 'setSelectedMenu'],
  data() {
    return {
      saveButtonState: ButtonLoadingState.initial,
      shopifyConfig: {} as ConnectShopify
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount'
    })
  },
  async mounted() {
    const responseData = await FirebaseRequest.createRequest<{
      configData: {
        name: string;
        configuration: string;
        status: string;
      };
    }>(FirebaseRequestTypes.GET_ECOMMERCE_CONFIG, {
      accountId: this.currentAccount?.accountId,
      name: 'Shopify'
    });

    if (
      responseData.status === APIResponseStatus.OK &&
      responseData.data?.configData?.configuration
    ) {
      this.shopifyConfig = JSON.parse(
        responseData.data?.configData?.configuration
      ) as ConnectShopify;
    }
  },
  methods: {
    ...mapMutations('global', ['setBannerStatus']),
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    },
    async copyCode() {
      await navigator.clipboard.writeText(
        '<div id="boostSaveForLaterWrapper"></div>'
      );
      this.setBannerStatus({
        text: BannerMessageStrings.GENERIC_COPIED_CLIPBOARD,
        type: BannerType.success
      });
    },
    async save() {
      this.saveButtonState = ButtonLoadingState.loading;
      const responseData = await FirebaseRequest.createRequest<{
        connectionResults: {
          isSuccessful: boolean;
          message: string;
        };
      }>(FirebaseRequestTypes.UPDATE_ECOMMERCE_APPS, {
        accountId: this.currentAccount?.accountId,
        isSaveForLaterAutomatic: this.shopifyConfig.isSaveForLaterAutomatic
      });
      if (responseData.status === APIResponseStatus.OK) {
        this.setBannerStatus({
          text: BannerMessageStrings.CHANGES_UPDATED,
          type: BannerType.success
        });
      } else {
        this.setBannerStatus({
          text:
            responseData?.data?.connectionResults?.message ||
            BannerMessageStrings.SHOPIFY_ERROR,
          type: BannerType.error
        });
      }
      this.saveButtonState = ButtonLoadingState.complete;
    },
    onOpenHowTo() {
      window.open(
        'https://support.tagscommerce.com/hc/en-us/articles/8343081242903-Activating-Save-for-Later',
        '_blank'
      );
    }
  }
});
export default SaveForLater;
