
import { defineComponent } from 'vue';
import Helpers, { PredicateBuilder } from '@/helpers';
import { Charges } from '@/models/charges';
import { TagDetail, TagSku, TagVariant } from '@/models/tag';
import { TagType } from '@/../enums/tagType';
import { KeyValue } from '@/models/sellerOrder';
import InstantCheckoutProductImage from '@/buyer/checkout/InstantCheckoutProductImage.vue';
import CheckoutDetailDivider from './CheckoutDetailDivider.vue';

interface VariantSelect {
  selectedOption: string;
  variant: TagVariant;
}

export default defineComponent({
  components: {
    InstantCheckoutProductImage,
    CheckoutDetailDivider
  },
  props: {
    product: {
      type: Object as () => TagDetail,
      required: true
    },
    skuId: {
      type: String,
      default: ''
    },
    charges: {
      type: Object as () => Charges,
      required: true
    },
    completedPurchase: {
      type: Boolean,
      default: false
    },
    customColor: {
      type: String,
      default: 'default'
    }
  },
  emits: ['readyToBuy'],
  data() {
    return {
      selections: [] as VariantSelect[],
      internalSku: {} as TagSku,
      TagType: TagType,
      readMore: true,
      hasLongDescription: undefined as boolean | undefined
    };
  },
  computed: {
    sku(): TagSku | undefined {
      if (this.product && this.product.skus) {
        return this.product.skus.find((x) => x.id === this.skuId);
      }
      return undefined;
    },
    skuOptions(): KeyValue[] {
      return this.getSkuOptions();
    },
    selectionComplete(): boolean {
      const results = !this.selections.some((x) => !x.selectedOption);
      return results;
    }
  },
  watch: {
    product(newVal: TagDetail) {
      if (newVal?.variants) {
        const tempVariantList = Helpers.deepCopy(newVal.variants);

        const options = tempVariantList[0]?.options;
        if (options) {
          for (let i = options.length - 1; i >= 0; i--) {
            const matchingSku = newVal.skus.find(
              (sku) => sku[newVal.variants[0].name] === options[i].name
            );
            if (!matchingSku) {
              options.splice(i, 1);
            }
          }
        }

        this.selections.length = 0;
        const newItems = tempVariantList.map((x) => ({
          selectedOption: '',
          variant: x
        }));
        this.selections.push(...newItems);
        if (newItems[0]) {
          newItems[0].selectedOption = newItems[0].variant.options[0].name;
          this.updateReadyToBuy(newItems[0].selectedOption, 0);
        }
      }
    }
  },
  mounted() {
    this.resetOptions(false);
    this.checkDescriptionLength();
  },
  updated() {
    this.checkDescriptionLength();
  },
  methods: {
    checkDescriptionLength() {
      const input = this.$refs.descriptionInput as HTMLElement;
      if (input?.scrollHeight > 0 && this.hasLongDescription === undefined) {
        this.hasLongDescription = input.offsetHeight < input.scrollHeight;
      }
    },
    resetOptions(updateReadyToBuy: boolean) {
      const sku = this.product?.skus?.find((x) => x.id === this.skuId);

      if (this.product?.variants?.length) {
        const tempVariantList = Helpers.deepCopy(this.product?.variants);
        const options = tempVariantList[0]?.options;
        if (options) {
          for (let i = options.length - 1; i >= 0; i--) {
            const matchingSku = this.product.skus.find(
              (sku) => sku[this.product.variants[0].name] === options[i].name
            );
            if (!matchingSku) {
              options.splice(i, 1);
            }
          }
        }

        this.selections.length = 0;
        const newItems = tempVariantList.map((x) => ({
          selectedOption: sku
            ? sku[x.name]?.toString()
            : x.options.length === 1
            ? x.options[0].name
            : '',
          variant: x
        }));
        this.selections.push(...newItems);
        if (updateReadyToBuy) {
          this.$emit('readyToBuy', undefined);
        }
      }
    },
    getPrice(price: number) {
      return Helpers.currencyFormatter(price);
    },
    updateReadyToBuy(value: string, index: number) {
      const selectedVariant = this.selections[index].variant;
      // clear out any selections after this variant
      if (index < this.selections.length) {
        for (let i = ++index; i < this.selections.length; i++) {
          const element = this.selections[i];
          const variant = element.variant;

          // Restore options to the original state
          const tempOptions = Helpers.deepCopy(this.product?.variants).find(
            (v) => v.id === variant.id
          )?.options;
          if (tempOptions) {
            variant.options = tempOptions;
          }

          element.selectedOption =
            variant.options.length === 1 ? variant.options[0].name : '';
        }
      }
      const readyToBuy = !this.selections.some((x) => !x.selectedOption);

      if (readyToBuy) {
        const q = new PredicateBuilder<TagSku>((x: TagSku) => {
          const selection = this.selections[0];
          const variantName = selection.variant.name;
          return x[variantName] === selection.selectedOption;
        });
        for (let index = 1; index < this.selections.length; index++) {
          q.and((x: TagSku) => {
            const selection = this.selections[index];
            const variantName = selection.variant.name;
            return x[variantName] === selection.selectedOption;
          });
        }
        const selectedSku = this.product.skus.find(q.predicate);
        if (selectedSku) {
          this.internalSku = selectedSku;
        }
        this.$emit('readyToBuy', selectedSku);
      } else {
        //Build a query to find all skus that match current selections
        const q = new PredicateBuilder<TagSku>((x) => {
          const variantName = selectedVariant.name;
          return x[variantName] === value;
        });
        for (const opt of this.selections.filter(
          (x) => x.variant.id !== selectedVariant.id && !!x.selectedOption
        )) {
          q.and((x) => {
            const variantName = opt.variant.name;
            return x[variantName] === opt.selectedOption;
          });
        }

        //Get the set of possible variant options for these skus
        const possibleSkus = this.product.skus.filter(q.predicate);

        const possibleOptions: {
          variantName: string;
          optionValue: string | number;
        }[] = [];

        possibleSkus.forEach((s) => {
          const step2 = this.selections.map((x) => {
            const skuValue = s[x.variant.name];
            return { variantName: x.variant.name, optionValue: skuValue };
          });
          possibleOptions.push(...step2);
        });

        // remove any options for selections not yet made that are not available in the possible skus
        for (const variant of this.selections.filter(
          (s) => s.variant.id !== selectedVariant.id && !s.selectedOption
        )) {
          let i = variant.variant.options.length;
          while (i--) {
            const opt = variant.variant.options[i];

            if (
              !possibleOptions.some(
                (po) =>
                  po.variantName === variant.variant.name &&
                  po.optionValue === opt.name
              )
            ) {
              const indexToDelete = variant.variant.options.indexOf(opt);
              variant.variant.options.splice(indexToDelete, 1);
            }
          }
        }
        this.$emit('readyToBuy', undefined);
      }
    },
    getSkuOptions(): KeyValue[] {
      if (this.sku && this.product?.variants?.length) {
        const options: KeyValue[] = [];
        this.product.variants.forEach((v) => {
          if (this.sku) {
            const skuValue = this.sku[v.name].toString();
            const opt = v.options.find((o) => o.name === skuValue);
            const kv = {
              key: v.label,
              value: opt?.label || opt?.name || ''
            };
            options.push(kv);
          }
        });
        return options;
      }
      return [];
    }
  }
});
